import {
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Typography,
} from '@mui/material'
import { common, grey } from '@mui/material/colors'
import React, { useContext, useMemo, useState } from 'react'
import { ModalType } from '../../constants/modalType'
import { theme } from '../../constants/theme'
import { CloseRounded } from '@mui/icons-material'

interface ModalWithButtonProps {
  id: string
  type: ModalType
  title?: string
  description?: string | React.ReactNode
  child?: any
  confirmBtnText?: string
  cancleBtnText?: string
  open: boolean
  onClose: () => void
  handleClickConfirm?: () => void
  disableCancelBtn?: boolean
  disableConfirmBtn?: boolean
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
}

// MODAL WITH TITLE, DESCRIPTION & BUTTONS
export default function ModalWithButton(props: ModalWithButtonProps) {
  const {
    id,
    type,
    title,
    description,
    confirmBtnText,
    cancleBtnText,
    open,
    onClose,
    handleClickConfirm,
    disableCancelBtn,
    disableConfirmBtn,
  } = props

  const color: any = useMemo(() => {
    if (type === 'DEFAULT') {
      return 'primary'
    } else if (type === 'ERROR') {
      return 'error'
    } else if (type === 'SUCCESS') {
      return 'secondary'
    }
  }, [type])

  function handleOnClickConfirm() {
    console.log('confirm')
  }

  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // onClick={onClose}
    >
      <Modal open={open} onClose={onClose}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          sx={ModalStyle}
          width={{ xs: '80vw', md: '640px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'flex-end'}
            alignItems={'flex-end'}
            flex={'1 0 0'}
          >
            {/* title & description section */}
            <Stack display={'flex'} alignItems={'flex-start'} width={'100%'}>
              <Typography
                id='modal-modal-title'
                variant='h5'
                color={color}
                width={'100%'}
              >
                {title}
              </Typography>
              <Divider
                variant='fullWidth'
                sx={{ color: grey[200], padding: '4px' }}
                flexItem
              />
              <Typography
                id='modal-modal-description'
                sx={{ mt: 2 }}
                variant='subtitle1'
                color={grey[600]}
              >
                {description}
              </Typography>
            </Stack>

            <Box height={theme.spacing(2)} />
            <Stack // buttons
              display={'flex'}
              flexDirection={{ sm: 'column', md: 'row' }}
              gap={theme.spacing(2)}
              alignItems={'flex-end'}
              justifyContent={'flex-end'}
            >
              <Button // cancel btn
                sx={{ color: grey[600] }}
                color='inherit'
                variant='outlined'
                onClick={onClose}
                disabled={disableCancelBtn}
              >
                <Typography variant='h6'>{cancleBtnText}</Typography>
              </Button>
              <Button // confitm btn
                color={color}
                variant='contained'
                onClick={handleClickConfirm}
                disabled={disableConfirmBtn}
              >
                <Typography variant='h6'>{confirmBtnText}</Typography>
              </Button>
            </Stack>
          </Box>
          <Button // close button
            onClick={onClose}
            color='inherit'
            size='small'
            sx={{
              minWidth: '16px',
              position: 'absolute',
              right: '0px',
              top: '0px',
              p: '16px',
            }}
          >
            <CloseRounded sx={{ color: grey[400] }} fontSize='small' />
          </Button>
        </Box>
      </Modal>
    </Backdrop>
  )
}
