import { TableCell, TableRow, Typography } from '@mui/material'

import StatusBar from './StatusBar'
import { useNavigate } from 'react-router-dom'
import {
  generateChurchReceiptRoute,
  generateViewReceiptAdminRoute,
} from '../../utils/routeGenerator'
import formatCompactNumber from '../../utils/formatNumber'

type EarningListProps = {
  churchId: string
  id: string
  startDate: number
  endDate: number
  total: number
  status: string
  varaint?: 'ADMIN' | 'CHURCH'
}

export default function EarningList(props: EarningListProps) {
  const { churchId, id, startDate, endDate, total, status, varaint } = props

  const start = new Date(startDate)
  const end = new Date(endDate)

  const navigate = useNavigate()

  const handleClickTableRow = () => {
    if (varaint && varaint === 'CHURCH') {
      navigate(generateChurchReceiptRoute(churchId, id))
    } else {
      navigate(generateViewReceiptAdminRoute(churchId, id))
    }
  }

  return (
    <TableRow key={id} onClick={handleClickTableRow}>
      <TableCell align='left' sx={{ width: 300, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'text.primary'}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          {' ' +
            start.getDate().toString() +
            '/' +
            (start.getMonth() + 1) +
            '/' +
            start.getFullYear()}
        </Typography>
      </TableCell>
      <TableCell align='left' sx={{ width: 300, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'text.primary'}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          {' ' +
            end.getDate().toString() +
            '/' +
            (end.getMonth() + 1) +
            '/' +
            end.getFullYear()}
        </Typography>
      </TableCell>
      <TableCell align='left' sx={{ width: 450, flexWrap: 'wrap' }}>
        <Typography
          variant='h5'
          color={'text.primary'}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'nowrap',
          }}
        >
          {formatCompactNumber(total)}
        </Typography>
      </TableCell>
      <TableCell align='left' sx={{ flexWrap: 'wrap' }}>
        <StatusBar variant={status}></StatusBar>
      </TableCell>
      <TableCell align='right' sx={{ width: 200, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'primary'}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => navigate(generateViewReceiptAdminRoute(churchId, id))}
        >
          View
        </Typography>
      </TableCell>
    </TableRow>
  )
}
