import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import { Box, Button, Typography } from '@mui/material'
import Footer from '../components/Footer/Footer'
import { COLOR } from '../constants/color-constant'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../services/UserContext'
import { Role } from '../models/UserTypes'
import ResponsiveAppBar from '../components/Admin/ResponsiveAppBar'

export default function PermissionDeny() {
  const navigate = useNavigate()
  const userRole: Role | undefined = useUser().userData?.userrole.userRole

  const handleNavigate = () => {
    if (userRole && userRole === 'ADMIN') {
      navigate('/admin/dashboard')
    } else {
      navigate('/')
    }
  }
  return (
    <>
      {userRole! === 'ADMIN' ? <ResponsiveAppBar /> : <Navbar />}

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        height={'70vh'}
        gap={'4px'}
        padding={'8vw'}
      >
        <Typography variant='h3'>
          ขออภัย คุณไม่สามารถรับชมเนื้อหานี้ได้ หรือ เนื้อหานี้ไม่มีอยู่แล้ว
        </Typography>
        <Typography variant='h6' color={COLOR.GRAY_600}>
          โปรดติดต่อแอดมินหลัก หากคุณคิดว่านี่คือความผิดพลาด
        </Typography>
        <Button
          variant='outlined'
          onClick={() => handleNavigate()}
          sx={{ maxWidth: '50vw', margin: '8px' }}
        >
          กลับไปที่หน้าหลัก
        </Button>
      </Box>
      <Footer />
    </>
  )
}
