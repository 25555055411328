import { Autocomplete, Typography, Stack, Box, TextField } from '@mui/material'

import { ChurchMemberCard } from '../../components/ChurchMemberCard'
import { COLOR } from '../../constants/color-constant'
import { churchMemberPosition } from '../../constants/churchMemberType'
import { useEffect, useState } from 'react'
import { useGet } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import { useDebounce } from '../../controller/useDebounce'
import { UserShortDetail } from '../../models/UserTypes'
import React from 'react'

interface MemberSectionViewOnlyProps {
  members: UserShortDetail[]
  title: string
  subtitle: string
  position: churchMemberPosition
  isRequired: boolean
}

const MemberSectionViewOnly: React.FC<MemberSectionViewOnlyProps> = ({
  members,
  title,
  subtitle,
  position,
  isRequired,
}) => {
  const [autocompleteInputVal, setAutocompleteInputVal] = useState('')
  const [autocompleteVal, setAutocompleteVal] =
    useState<UserShortDetail | null>(null)

  const [foundUsers, loading, fetchUsers, statusCode] = useGet<
    UserShortDetail[]
  >({
    url: '/users/short',
    autoFetch: false,
    queryParams: {
      query: autocompleteInputVal,
    },
    jwtToken: AuthService.getTokenUser()!,
  })

  const debouncedFetchUsers = useDebounce(fetchUsers, 500) // refetch every 500ms
  const defaultProps = {}

  useEffect(() => {
    if (autocompleteInputVal.trim()) debouncedFetchUsers()
  }, [autocompleteInputVal])

  return (
    <Stack flexDirection='row' flexWrap='wrap' alignItems='center' gap='20px'>
      <Stack flexDirection='column' width='250px' textAlign={'start'}>
        <span>
          {isRequired && (
            <Typography display='inline' variant='h5' color={COLOR.PRIMARY_1}>
              *{' '}
            </Typography>
          )}
          <Typography display='inline' variant='h5'>
            {title}
          </Typography>
        </span>
        <Typography variant='subtitle1' color={COLOR.GRAY_600}>
          {subtitle}
        </Typography>
      </Stack>
      <Stack maxWidth={'100%'} gap={'16px'}>
        <Autocomplete
          id='member-section-select-disabled'
          size='small'
          fullWidth
          options={[]}
          {...defaultProps}
          disabled
          renderInput={(params) => <TextField {...params} label={title} />}
          sx={{
            width: { xs: '250px', sm: '320px', md: '350px' },
            maxWidth: { sm: '100%' },
            '& fieldset': { borderRadius: '8px' },
          }}
        />
        <Stack gap={'16px'}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            gap={'24px'}
            flexWrap='wrap'
          >
            {members.length === 0 && (
              <Typography>ไม่มีสมาชิกในตำแหน่งนี้</Typography>
            )}
            {members.map((m, i) => (
              <ChurchMemberCard
                key={i}
                isMe={false}
                variant='ADMIN-VIEW'
                position={position}
                firstName={m.firstName}
                lastName={m.lastName}
                handleClickDeleteMember={() => {}}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MemberSectionViewOnly
