import { Box, Typography } from '@mui/material'
import Nodata from '../../icons/nodata.svg'
import { grey } from '@mui/material/colors'
import { ReactSVG } from 'react-svg'

function NoRecentCampaigns() {
  return (
    <Box
      id='no-recent-campaign-box'
      height={'400px'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'8px'}
    >
      <ReactSVG src={Nodata} width={'150px'} />
      <Typography variant='subtitle1' color={grey[500]}>
        คริสตจักรยังไม่มีโครงการ
      </Typography>
    </Box>
  )
}

export default NoRecentCampaigns
