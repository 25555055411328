import { Box, Typography } from '@mui/material'
import { ReactComponent as UploadIcon } from '../../assets/icons/Admin/uploadCover.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/Admin/round_delete.svg'
import { useEffect, useState } from 'react'

const ACCEPTED_EXTENSION = '.jpg, .jpeg .png'
const MAX_FILE_SIZE = 5 * 1000 * 1000 // 3 MB

type Prop = {
  imgURL: string | null
  onChange: ({
    files,
    haveFile,
  }: {
    files: File | null
    haveFile: boolean
  }) => void
}

export default function UploadCoverImage({ imgURL, onChange }: Prop) {
  const [selectedFile, setSelectedFile] = useState<File | null>()
  const [oldImgURL, setOldImgURL] = useState<string | null>()

  useEffect(() => {
    setOldImgURL(imgURL)
    onChange({
      files: null,
      haveFile: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Below is from https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
  const fileDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()
    const newFiles: File[] = []

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      ;[...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them

        if (item.kind === 'file') {
          const file = item.getAsFile()
          if (file) {
            newFiles.push(file)
          }
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s), in case the browser
      // doesn't support the above interface
      newFiles.push(...e.dataTransfer.files)
    }

    onChange({
      files: newFiles[0],
      haveFile: true,
    })
    setSelectedFile(newFiles[0])
  }

  // This function will be triggered when the file field change
  const imageChange = (e: React.ChangeEvent<any>) => {
    if (e.target.files && e.target.files.length === 1) {
      if (e.target.files[0].size < MAX_FILE_SIZE) {
        setSelectedFile(e.target.files[0])
        onChange({
          files: e.target.files[0],
          haveFile: true,
        })
      } else {
        alert('File size is larger than 5MB')
      }
    } else {
      alert('Please select only 1 file.')
    }
  }

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedFile(null)
    onChange({
      files: null,
      haveFile: false,
    })
    setOldImgURL(null)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      width={'100%'}
      height={'fit-content'}
      gap='10px'
    >
      {selectedFile || oldImgURL ? (
        <Box width={'100%'} position={'relative'}>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 1,
            }}
            onClick={removeSelectedImage}
          >
            <DeleteIcon
              style={{
                top: '50%',
                bottom: '50%',
                width: '38px',
                height: '38px',
                borderRadius: 100,
                backgroundColor: 'white',
                boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                padding: '3px',
                margin: '5px',
                cursor: 'pointer',
              }}
            />
          </div>
          <img
            src={oldImgURL ? oldImgURL : URL.createObjectURL(selectedFile!)}
            alt='Thumb'
          />
        </Box>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '20px',
            outline: '2px dashed black',
            width: '100%',
            height: 'fit-content',
          }}
          onDrop={fileDropHandler}
          onDragOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <label
            htmlFor='file-upload'
            style={{
              display: 'flex',
              width: '100%',
              height: 'fit-content',
              cursor: 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <input
              id='file-upload'
              type='file'
              accept={ACCEPTED_EXTENSION}
              multiple={false}
              className='hidden'
              onChange={imageChange}
            />
            <UploadIcon width='80px' height='80px'></UploadIcon>
            <Typography variant='h6'>อัปโหลดรูปหน้าปก</Typography>
            <Typography variant='subtitle2' align='center'>
              รองรับไฟล์ JPEG, JPG, PNG ขนาดไม่เกิน 5 MB
            </Typography>{' '}
          </label>
        </div>
      )}
    </Box>
  )
}
