import {
  Box,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  Button,
  Container,
} from '@mui/material'
import { DeleteRounded } from '@mui/icons-material'

import { grey } from '@mui/material/colors'

import StatusBar from '../Admin/StatusBar'

import { ReactComponent as Fund } from '../../assets/icons/Fundforadmin.svg'

import {
  generateCampaignProgressUpdateRoute,
  generateChurchCampaignProposalRoute,
  generateChurchRoute,
  generateEditCampaignProgressUpdateRoute,
  generateViewCampaignRoute,
} from '../../utils/routeGenerator'
import { Link } from 'react-router-dom'
import { HolderChurch } from '../../models/admin/CampaignRequest'
import ProgressBar from '../ProgressBar'

type RequestListProps = {
  variant: string
  onDeleteRequestId_hook: React.Dispatch<React.SetStateAction<String>>
  onDeleteClick_hook: React.Dispatch<React.SetStateAction<boolean>>
  requestId: string
  name: string
  userCanDelete: boolean

  version?: number
  holder?: HolderChurch[]
  type: string
  status: string
  currentDonationAmount: number | null
  targetBudget: number | null
}

export default function ChurchCampaignRow(props: RequestListProps) {
  const fundIcon = useMediaQuery('(min-width:900px)')
  const isLargeScreen = useMediaQuery('(min-width:600px)')

  const {
    variant,
    onDeleteRequestId_hook,
    onDeleteClick_hook,
    requestId,
    name,
    userCanDelete,

    version,
    holder,
    type,
    status,
    currentDonationAmount,
    targetBudget,
  } = props

  const StyledTableCell = styled(TableCell)({
    paddingTop: '8px',
    paddingBottom: '8px',
  })

  const StyledTableCell2 = styled(TableCell)({
    paddingTop: '12px',
    paddingBottom: '12px',
  })

  return (
    <TableRow key={requestId}>
      <StyledTableCell2 sx={{ width: 600, flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            flexWrap: 'no wrap',
            alignItems: 'left',
            gap: '8px',
          }}
        >
          {variant === 'funding' && fundIcon && (
            <Box width={'40px'}>
              <Fund></Fund>
            </Box>
          )}
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'Column',
              flexWrap: 'wrap',
              alignItems: 'left',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'Column',
                columnGap: '4px',
                alignItems: 'left',
              }}
            >
              {/* name */}

              <Typography
                component={'span'}
                variant='body1'
                sx={{
                  color: '#0079FF',
                }}
              >
                {variant === 'funding' && (
                  <Link to={generateViewCampaignRoute(requestId)}>{name}</Link>
                )}
                {variant !== 'funding' && type !== 'UPDATE' && (
                  <Link to={generateChurchCampaignProposalRoute(requestId)}>
                    {name}
                  </Link>
                )}
                {variant !== 'funding' && type === 'UPDATE' && (
                  <Link to={generateEditCampaignProgressUpdateRoute(requestId)}>
                    {name}
                  </Link>
                )}
              </Typography>

              {isLargeScreen && (
                <Typography variant='body2' color={grey[400]}>
                  {requestId}
                </Typography>
              )}
            </Stack>
            {/* detail หน้าจอเล็ก */}

            {!isLargeScreen && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'Column',
                  gap: '5px',
                  alignItems: 'left',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'120px'}>
                    <Typography variant='body2' color={grey[800]}>
                      id
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {requestId}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'120px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Holder Church
                    </Typography>
                  </Box>
                  <Box>
                    {holder?.map((church) => (
                      <Link
                        key={church.churchId}
                        to={generateChurchRoute(church.churchId)}
                      >
                        <Typography variant='body2' color={'text.primary'}>
                          {church.churchName}
                        </Typography>
                      </Link>
                    ))}
                    <Typography
                      variant='body2'
                      color={'text.primary'}
                    ></Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'120px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Version
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {version}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'120px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Type
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {type}
                    </Typography>
                  </Box>
                </Box>
                {variant === 'funding' && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'left',
                    }}
                  >
                    <Box width={'120px'}>
                      <Typography variant='body2' color={grey[800]}>
                        Donation
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body2' color={'text.primary'}>
                        {currentDonationAmount}
                      </Typography>
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'120px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Status
                    </Typography>
                  </Box>
                  <Box>
                    {status === 'REQUEST_FIX' ? (
                      <>
                        {type === 'UPDATE' ? (
                          <StatusBar variant={'REVISE'} />
                        ) : (
                          <StatusBar variant={status} />
                        )}
                      </>
                    ) : (
                      <StatusBar variant={status} />
                    )}
                  </Box>
                </Box>
                {variant === 'proposed' && userCanDelete && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'left',
                    }}
                  >
                    <Box width={'120px'}>Delete</Box>
                    <Box>
                      <Button
                        color='error'
                        variant='outlined'
                        onClick={async () => {
                          onDeleteRequestId_hook(requestId)
                          onDeleteClick_hook(true)
                        }}
                      >
                        <DeleteRounded />
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Stack>
        </Stack>
      </StyledTableCell2>

      {/* detail หน้าจอใหญ่ */}
      {isLargeScreen && (
        <>
          <StyledTableCell
            align='left'
            sx={{ paddingLeft: '0px', width: 200, flexWrap: 'wrap' }}
          >
            {holder?.map((church) => (
              <Link
                key={church.churchId}
                to={generateChurchRoute(church.churchId)}
              >
                <Typography variant='body1' color={'text.primary'}>
                  {church.churchName}
                </Typography>
              </Link>
            ))}
          </StyledTableCell>
          {variant === 'proposed' && (
            <TableCell
              align='right'
              sx={{
                width: 300,
                flexWrap: 'wrap',
              }}
            >
              <Typography variant='body1' color={'text.primary'}>
                {`v.${version}`}
              </Typography>
            </TableCell>
          )}
          <TableCell align='left' sx={{ width: 200, flexWrap: 'wrap' }}>
            <Box>
              {status === 'REQUEST_FIX' ? (
                <>
                  {type === 'UPDATE' ? (
                    <StatusBar variant={'REVISE'} />
                  ) : (
                    <StatusBar variant={status} />
                  )}
                </>
              ) : (
                <StatusBar variant={status} />
              )}
            </Box>
          </TableCell>
          {variant === 'funding' && (
            <TableCell
              align='right'
              sx={{
                width: 600,
                flexWrap: 'wrap',
              }}
            >
              <ProgressBar
                amount={currentDonationAmount ?? 0}
                goal={targetBudget ?? 10}
              />
            </TableCell>
          )}
          {variant === 'proposed' && (
            <TableCell
              align='right'
              sx={{ paddingLeft: '8px', flexWrap: 'wrap' }}
            >
              {userCanDelete ? (
                <Button
                  color='error'
                  onClick={async () => {
                    onDeleteRequestId_hook(requestId)
                    onDeleteClick_hook(true)
                  }}
                >
                  <DeleteRounded />
                </Button>
              ) : (
                <Typography variant='body1' color={'text.primary'}></Typography>
              )}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  )
}
