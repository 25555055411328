import { CloseRounded } from '@mui/icons-material'
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { theme } from '../../constants/theme'
import { useGet, usePost } from '../../hooks/useFetch'
import { Fragment, useContext, useEffect, useState } from 'react'
import AuthService from '../../services/auth.service'
import { ChurchMemberManagementContext } from '../../pages/ChurchManagement/ChurchManagement'
import { useDebounce } from '../../controller/useDebounce'

interface ModalWithSearchProps {
  id: string
  title: string
  confirmText: string
  open: boolean
  onClickConfirm: () => void
  onClose: () => void
}

interface SearchInviteMember {
  id: string
  firstName: string
  lastName: string
  email: string
}

interface SearchInviteMemberParams {
  email: string
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  paddingBottom: 16,
  p: 3,
}

export default function ModalWithSearch(props: ModalWithSearchProps) {
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  const { id, title, open, confirmText, onClose, onClickConfirm } = props
  const token = AuthService.getTokenUser()
  const [userIds, setUserIds] = useContext(ChurchMemberManagementContext)
  const [searchResult, setSearchResult] = useState<SearchInviteMember[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [validateBtn, setValidateBtn] = useState<boolean>(true)
  const [handlingOptions, setHandlingOptions] = useState<boolean>(true)

  const [result, searching, refreshSearching, statusCodeSearching] =
    useGet<SearchInviteMember>({
      url: '/users/short/invitable',
      autoFetch: false,
      queryParams: { email: searchQuery },
      jwtToken: token ? token : '',
      autoNavigate: false,
    })

  const debouncedFetchUsers = useDebounce(refreshSearching, 500)

  useEffect(() => {
    if (searchQuery.trim()) debouncedFetchUsers()
  }, [searchQuery])

  const handleAutocompleteInputChange = (event: any, value: string) => {
    setSearchQuery(value)
  }

  const handleUserIds = (value: SearchInviteMember[]) => {
    let unHandleUserIds = value.map((value) => value.email)
    setUserIds(unHandleUserIds)
  }

  // if inviting member = 0, then can't click invite
  useEffect(() => {
    if (userIds.length > 0) setValidateBtn(false)
    else setValidateBtn(true)
  }, [userIds, setUserIds])

  useEffect(() => {
    setHandlingOptions(true)
    if (statusCodeSearching === 200 && result) {
      if (searchResult.filter((x) => x.email === result.email).length === 0)
        searchResult.push(result)
      setSearchResult(result ? searchResult : [])
      setHandlingOptions(false)
    }
  }, [statusCodeSearching, refreshSearching, searching])

  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // onClick={onClose}
    >
      <Modal open={open} onClose={onClose}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          sx={ModalStyle}
          width={{ xs: '80vw', md: '640px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'flex-end'}
            alignItems={'flex-end'}
            flex={'1 0 0'}
          >
            {/* title & description section */}
            <Typography
              id='modal-modal-title'
              variant='h5'
              color='primary'
              width={'100%'}
            >
              {title}
            </Typography>
            <Divider variant='fullWidth' sx={{ color: grey[200] }} flexItem />
            <Typography
              id='modal-modal-description'
              sx={{ mt: 2 }}
              variant='subtitle1'
              color={grey[600]}
            ></Typography>

            <Stack // buttons
              display={'flex'}
              flexDirection={{ sm: 'column', md: 'row' }}
              gap={theme.spacing(2)}
              width={'100%'}
              justifyContent={'space-between'}
            >
              <Autocomplete
                id='size-small-outlined'
                size='small'
                multiple
                fullWidth
                options={searchResult ? searchResult : []}
                getOptionLabel={(option) => option.email}
                onChange={(event, value) => handleUserIds(value)}
                renderOption={(props, option) => (
                  <Box component='li' {...props}>
                    {option.email}
                  </Box>
                )}
                loading={searching || handlingOptions}
                onInputChange={handleAutocompleteInputChange}
                inputValue={searchQuery}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'กรุณาใส่อีเมลของคนที่ต้องการเชิญ'}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {searching ? (
                            <CircularProgress color='primary' size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                sx={{ height: '40px' }}
                color='primary'
                variant='outlined'
                disabled={validateBtn}
                onClick={() => onClickConfirm()}
              >
                {confirmText}
              </Button>
            </Stack>
          </Box>
          <Button // close button
            onClick={onClose}
            color='inherit'
            size='small'
            sx={{
              minWidth: '16px',
              position: 'absolute',
              right: '0px',
              top: '0px',
              p: '16px',
            }}
          >
            <CloseRounded sx={{ color: grey[400] }} fontSize='small' />
          </Button>
        </Box>
      </Modal>
    </Backdrop>
  )
}
