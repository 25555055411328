import { Backdrop, Box, Button, Modal, Typography } from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { useMemo } from 'react'
import { ModalType } from '../../constants/modalType'
import { CloseRounded } from '@mui/icons-material'
interface ModalProps {
  id: string
  type: ModalType
  SvgIcon: React.ReactNode
  title?: string
  description?: string
  confirmText?: string
  open: boolean
  onClickConfirm?: () => void
  onClose: () => void
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
}

// ONLY TITLE & DESCRIPTION MODAL
export default function ModalNotification(props: ModalProps) {
  const {
    id,
    type,
    SvgIcon,
    title,
    description,
    onClose,
    open,
    confirmText,
    onClickConfirm,
  } = props

  const color: any = useMemo(() => {
    if (type === 'DEFAULT') {
      return 'primary'
    } else if (type === 'ERROR') {
      return 'error'
    } else if (type === 'SUCCESS') {
      return 'secondary'
    }
  }, [type])

  return (
    <Backdrop
      id={id}
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={onClose}
    >
      <Modal open={open} onClose={onClose}>
        <Box
          alignItems={'center'}
          justifyItems={'center'}
          textAlign={'center'}
          sx={ModalStyle}
          width={{ xs: '80vw', md: '840px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            alignItems={'center'}
            width={'100%'}
            padding={'36px'}
          >
            {SvgIcon}
            {/* title & description section */}
            <Typography
              id='modal-modal-title'
              variant='h5'
              sx={{ mt: 2 }}
              color={color}
            >
              {title}
            </Typography>
            <Typography
              id='modal-modal-description'
              sx={{ mt: 1 }}
              variant='subtitle1'
              color={grey[600]}
            >
              {description}
            </Typography>
            {confirmText && (
              <Button variant='outlined' color={color} onClick={onClickConfirm}>
                {confirmText}
              </Button>
            )}
          </Box>
          <Button // close button
            onClick={onClose}
            color='inherit'
            size='small'
            sx={{
              minWidth: '16px',
              position: 'absolute',
              right: '0px',
              top: '0px',
              p: '16px',
            }}
          >
            <CloseRounded sx={{ color: grey[400] }} fontSize='small' />
          </Button>
        </Box>
      </Modal>
    </Backdrop>
  )
}
