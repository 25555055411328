import { Container, Box, Stack, Typography, useMediaQuery } from '@mui/material'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

export default function CookiePolicy() {
  const matches = useMediaQuery('(min-width:540px)')
  function returnTitleAndLink(title: string, link: string, lg: boolean) {
    if (!lg) {
      return (
        <Typography
          variant='h6'
          sx={{ display: 'inline-block', paddingLeft: '40px' }}
        >
          <a target='_blank' rel='noopener noreferrer' href={link}>
            <Typography
              variant='h6'
              // sx={{ display: 'inline-block' }}
              color={'primary'}
            >
              {title}
            </Typography>
          </a>
        </Typography>
      )
    } else {
      return (
        <Typography
          variant='h6'
          sx={{ display: 'inline-block', paddingLeft: '40px' }}
        >
          {title}{' '}
          <a target='_blank' rel='noopener noreferrer' href={link}>
            <Typography
              variant='h6'
              // sx={{ display: 'inline-block' }}
              color={'primary'}
            >
              {link}
            </Typography>
          </a>
        </Typography>
      )
    }
  }
  return (
    <>
      <div className='flex min-h-screen flex-col justify-between'>
        <Navbar />
        <Box
          display={'flex'}
          padding={'40px'}
          flexDirection={'column'}
          // justifyContent={'space-between'}
          alignItems={'center'}
          textAlign={'center'}
          gap={'8px'}
          bgcolor={'#EFFBFF'}
        >
          <Typography variant='h1'>นโยบายคุกกี้ (Cookies Policy)</Typography>
        </Box>
        <Container>
          <Box
            display={'flex'}
            padding={'24px'}
            flexDirection={'column'}
            alignItems={'center'}
            textAlign={'left'}
            gap={'8px'}
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              นโยบายนี้จะให้ข้อมูลเกี่ยวกับคุกกี้ประเภทต่าง ๆ
              และเทคโนโลยีที่คล้ายกัน ซึ่งมีการใช้งานบนเว็บไซต์ น้ำใจ
              (NamJaiSociety.com) เว็บไซต์ที่แสดงบนอุปกรณ์มือถือ และแอปพลิเคชัน
              (ต่อไปเรียกรวมกันว่า “เว็บไซต์น้ำใจ”)
              ซึ่งควบคุมและจัดการโดยมูลนิธิดรุณาทร (ต่อไปเรียกว่า
              “มูลนิธิดรุณาทร”)
              โดยนโยบายนี้จะอธิบายว่ามูลนิธิดรุณาทรใช้คุกกี้อย่างไร เพื่ออะไร
              และท่านสามารถควบคุมคุกกี้อย่างไร
              ซึ่งนโยบายนี้จะใช้ควบคู่ไปกับข้อตกลงการใช้บริการ
              และนโยบายความเป็นส่วนบุคคล
            </Typography>

            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                1.คุกกี้คืออะไร
              </Typography>

              <Typography variant='h6' sx={{ display: 'inline-block' }}>
                คุกกี้เป็นไฟล์ข้อมูลขนาดเล็ก จัดเก็บในลักษณะของ text ไฟล์
                โดยเว็บไซต์ของมูลนิธิดรุณาทรจะส่งคุกกี้ไปยังเบราว์เซอร์ของท่าน
                และอาจมีการบันทึกลงในเครื่องคอมพิวเตอร์หรืออุปกรณ์ที่ท่านใช้เข้าเว็บไซต์
                ซึ่งคุกกี้มีประโยชน์สำคัญในการทำให้เว็บไซต์สามารถจดจำการตั้งค่าต่าง
                ๆ บนอุปกรณ์ของท่านได้
                แต่คุกกี้จะไม่มีการเก็บข้อมูลที่สามารถใช้ระบุตัวตนท่าน ทั้งนี้
                ท่านสามารถค้นหาข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้ได้ที่{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.allaboutcookies.org'
                >
                  <Typography
                    variant='h6'
                    sx={{ display: 'inline-block' }}
                    color={'primary'}
                  >
                    www.allaboutcookies.org
                  </Typography>
                </a>
              </Typography>
            </Box>
            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                2.มูลนิธิดรุณาทรใช้คุกกี้อย่างไร
              </Typography>
              <Typography variant='h6'>
                มูลนิธิดรุณาทรจะใช้คุกกี้และเทคโนโลยีอื่น ๆ ที่คล้ายกัน
                เพื่อวัตถุประสงค์ที่แตกต่างกัน
                ทั้งในทางเทคนิคและการปรับปรุงบริการของเว็บไซต์น้ำใจ ดังนี้
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                2.1 เพื่อช่วยจดจำข้อมูลเกี่ยวกับเบราว์เซอร์และการตั้งค่าของท่าน
                และช่วยให้ท่านเข้าใช้บริการเว็บไซต์น้ำใจได้สะดวกรวดเร็ว
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                2.2
                เพื่อช่วยประเมินประสิทธิภาพและผลการให้บริการเว็บไซต์น้ำใจที่ยังทำงานได้ไม่ดีและควรปรับปรุง
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                2.3
                เพื่อรวบรวมและวิเคราะห์ข้อมูลการเข้าเยี่ยมชมเว็บไซต์น้ำใจที่จะทำให้มูลนิธิดรุณาทรเข้าใจว่าผู้คนมีความสนใจอะไร
                และมีการใช้บริการของเว็บไซต์น้ำใจอย่างไร
                ซึ่งเป็นสิ่งสำคัญต่อการปรับปรุงประสิทธิภาพและปรับปรุงบริการของเว็บไซต์น้ำใจให้ดียิ่งขึ้น
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                2.4
                เพื่อให้เว็บไซต์น้ำใจได้ส่งมอบประสบการณ์ในการใช้บริการเว็บไซต์ที่ดียิ่งขึ้นให้กับท่าน
                รวมถึงช่วยให้เว็บไซต์น้ำใจสามารถส่งมอบบริการและการประชาสัมพันธ์ได้ตรงตามสิ่งที่ท่านสนใจ
              </Typography>
            </Box>

            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                3.มูลนิธิดรุณาทรใช้คุกกี้อะไรบ้าง
              </Typography>
              <Typography variant='h6'>
                เว็บไซต์น้ำใจใช้คุกกี้ทั้งของเว็บไซต์น้ำใจ (First party cookies)
                และคุกกี้ที่เป็นของบุคคลอื่น (Third party cookies)
                ซึ่งกำหนดและตั้งค่าโดยผู้ให้บริการบุคคลภายนอก เช่น
                บริษัทภายนอกที่เว็บไซต์น้ำใจใช้บริการ
                เพื่อเพิ่มเติมคุณสมบัติของการทำงานให้กับเว็บไซต์น้ำใจของมูลนิธิดรุณาทร
                นอกจากนี้
                เว็บไซต์น้ำใจอาจมีการฝังเนื้อหาหรือวีดีโอที่มาจากโซเชียลมีเดียเว็บไซต์
                เช่น YouTube หรือ Facebook เป็นต้น
                ซึ่งเว็บไซต์เหล่านี้จะมีการกำหนดและตั้งค่าคุกกี้ขึ้นมาเองที่เว็บไซต์น้ำใจไม่สามารถควบคุมหรือรับผิดชอบต่อคุกกี้เหล่านั้นได้
                ท่านควรเข้าไปอ่านและศึกษานโยบายคุกกี้ของบุคคลภายนอกที่เกี่ยวข้องดังกล่าวข้างต้นสำหรับข้อมูลเพิ่มเติมเกี่ยวกับการใช้คุกกี้ของพวกเขา
              </Typography>
              <Typography variant='h6'>
                เว็บไซต์น้ำใจใช้คุกกี้อยู่ 2 ประเภทคือ
              </Typography>

              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '16px',
                  display: 'inline-block',
                }}
              >
                Session Cookies
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                เป็นคุกกี้ที่จะอยู่ชั่วคราวเพื่อจดจำท่านในระหว่างที่ท่านเข้าเยี่ยมชมเว็บไซต์น้ำใจของมูลนิธิดรุณาทร
                เช่น เฝ้าติดตามภาษาที่ท่านได้ตั้งค่าและเลือกใช้ เป็นต้น
                และจะถูกลบออกจากเครื่องคอมพิวเตอร์หรืออุปกรณ์ของท่าน
                เมื่อท่านออกจากเว็บไซต์หรือได้ทำการปิดเว็บเบราว์เซอร์
              </Typography>
              <Typography
                variant='h6'
                sx={{ fontWeight: 'bold', paddingLeft: '16px' }}
              >
                Persistent Cookie
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                เป็นคุกกี้ที่จะอยู่ตามระยะเวลาที่กำหนดหรือจนกว่าท่านจะลบออก
                คุกกี้ประเภทนี้จะช่วยให้เว็บไซต์น้ำใจของมูลนิธิดรุณาทรจดจำท่านและการตั้งค่าต่าง
                ๆ ของท่านเมื่อท่านกลับมาใช้งานเว็บไซต์อีกครั้ง
                ซึ่งจะช่วยให้ท่านเข้าใช้งานเว็บไซต์ได้สะดวกรวดเร็วยิ่งขึ้น
              </Typography>
              <Typography variant='h6' sx={{ paddingTop: '8px' }}>
                ทั้งนี้ คุกกี้ที่เว็บไซต์น้ำใจใช้อาจแบ่งตามวัตถุประสงค์การใช้งาน
                ได้ดังนี้
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '16px',
                  display: 'inline-block',
                }}
              >
                คุกกี้จำเป็นถาวร (Strictly necessary cookies)
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์น้ำใจของมูลนิธิดรุณาทร
                เพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง ๆ ของเว็บไซต์ได้
                รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '16px',
                  display: 'inline-block',
                }}
              >
                คุกกี้การประมวลผลและวิเคราะห์ (Analytical/performance cookies)
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                คุกกี้นี้ช่วยให้เว็บไซต์น้ำใจเห็นการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์น้ำใจของมูลนิธิดรุณาทร
                รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม
                ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ
                เว็บไซต์น้ำใจยังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์
                และเพื่อเข้าใจพฤติกรรมของผู้ใช้งาน อย่างไรก็ดี
                ข้อมูลที่คุกกี้นี้เก็บรวบรวมจะเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
                และถูกนำมาใช้วิเคราะห์ทางสถิติเท่านั้น
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '16px',
                  display: 'inline-block',
                }}
              >
                คุกกี้ฟังก์ชันการใช้งาน (Functionality cookies)
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                คุกกี้นี้จะช่วยให้เว็บไซต์น้ำใจของมูลนิธิดรุณาทรจดจำตัวเลือกต่าง
                ๆ
                ที่ท่านได้ตั้งค่าไว้และช่วยให้เว็บไซต์ส่งมอบคุณสมบัติและเนื้อหาเพิ่มเติมให้ตรงกับการใช้งานของท่านได้
                เช่น ช่วยจดจำชื่อบัญชีผู้ใช้งานของท่าน
                หรือจดจำการเปลี่ยนแปลงการตั้งค่าต่าง ๆ
                ของหน้าเพจซึ่งท่านสามารถปรับแต่งได้
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '16px',
                  display: 'inline-block',
                }}
              >
                คุกกี้การกำหนดเป้าหมายหรือโฆษณา (Targeting/Advertising cookies)
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                คุกกี้นี้จะถูกบันทึกในคอมพิวเตอร์หรืออุปกรณ์ของท่านเพื่อช่วยจดจำการเข้าเยี่ยมของท่าน
                รวมถึงหน้าเพจและลิงก์ที่ท่านได้แวะเยี่ยมชมหรือที่ติดตาม
                ข้อมูลเหล่านี้เว็บไซต์น้ำใจจะใช้เพื่อปรับเปลี่ยนเว็บไซต์และจัดแคมเปญโฆษณาประชาสัมพันธ์ของเว็บไซต์น้ำใจให้ตรงกับความสนใจของท่าน
              </Typography>
              <Typography
                variant='h6'
                sx={{
                  fontWeight: 'bold',
                  paddingLeft: '16px',
                  display: 'inline-block',
                }}
              >
                คุกกี้โซเชียลมีเดีย (Social media cookies)
              </Typography>
              <Typography variant='h6' sx={{ paddingLeft: '16px' }}>
                เว็บไซต์น้ำใจของมูลนิธิดรุณาทรอาจมีลิงก์ที่เชื่อมโยงไปยัง
                Facebook X(Twitter) หรือโซเชียลมีเดียแพลตฟอร์มอื่น ๆ
                ซึ่งจะช่วยให้ท่านแบ่งปันเนื้อหาจากเว็บไซต์น้ำใจของมูลนิธิดรุณาทรกับบุคคลอื่นบนโซเชียลมีเดียได้
                รวมถึงการสร้างปฏิสัมพันธ์อื่น ๆ
                กับโซเชียลมีเดียเหล่านั้นผ่านทางเว็บไซต์น้ำใจของมูลนิธิดรุณาทร
                ซึ่งผลที่ตามมาคือ
                คุกกี้เหล่านี้มีการกำหนดและตั้งค่าโดยผู้ให้บริการโซเชียลมีเดียที่เป็นบุคคลภายนอก
                เพื่อใช้ในการติดตามกิจกรรมออนไลน์ของท่าน
                เว็บไซต์น้ำใจไม่สามารถควบคุมข้อมูลที่มีการจัดเก็บโดยคุกกี้เหล่านี้ได้
                เว็บไซต์น้ำใจขอแนะนำให้ท่านเข้าไปตรวจสอบแพลตฟอร์มโซเชียลมีเดียเหล่านั้นสำหรับข้อมูลเพิ่มเติมเกี่ยวกับคุกกี้และวิธีการจัดการคุกกี้ของผู้ให้บริการโซเชียลมีเดีย
              </Typography>
            </Box>

            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                4.ท่านจะจัดการคุกกี้อย่างไร
              </Typography>
              <Typography variant='h6'>
                เบราว์เซอร์ส่วนใหญ่จะถูกตั้งค่าให้ยอมรับคุกกี้โดยอัตโนมัติ
                แต่ท่านสามารถเลือกยอมรับหรือปฏิเสธคุกกี้จากเว็บไซต์น้ำใจของมูลนิธิดรุณาทรได้ตลอดเวลาโดยการตั้งค่าบนเบราว์เซอร์ที่ท่านใช้งาน
                ลิงก์ด้านล่างนี้จะนำท่านไปสู่บริการ “การช่วยเหลือ”
                ของเบราว์เซอร์ต่าง ๆ ซึ่งเป็นที่นิยมใช้งานได้มีการบริการไว้
                ท่านสามารถค้นหาข้อมูลการจัดการคุกกี้ของเบราว์เซอร์ที่ท่านใช้งานได้
              </Typography>
              <Stack
                bgcolor={'#EFFBFF'}
                // width={'100%'}
                padding={'16px'}
                gap={'2px'}
              >
                {returnTitleAndLink(
                  'Internet Explorer',
                  'http://support.microsoft.com/kb/',
                  matches
                )}
                {returnTitleAndLink(
                  'Google Chrome',
                  'http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647',
                  matches
                )}
                {returnTitleAndLink(
                  'Firefox',
                  'http://support.mozilla.org/en-US/kb/Cookies',
                  matches
                )}
                {returnTitleAndLink(
                  'Opera',
                  'https://help.opera.com/en/latest/web-preferences/',
                  matches
                )}
                {returnTitleAndLink(
                  'Safari',
                  'https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac',
                  matches
                )}

                {returnTitleAndLink(
                  'Safari iOS',
                  'https://support.apple.com/en-us/HT201265',
                  matches
                )}
                {returnTitleAndLink(
                  'Android',
                  'http://support.google.com/chrome/answer/2392971?hl=en-GB',
                  matches
                )}
              </Stack>
              <Typography variant='h6'>
                ทั้งนี้ โปรดทราบว่า
                หากท่านเลือกที่จะปิดการใช้งานคุกกี้บนเบราว์เซอร์หรืออุปกรณ์ของท่าน
                ท่านอาจพบว่าบางส่วนของเว็บไซต์น้ำใจของมูลนิธิดรุณาทรไม่สามารถทำงานหรือให้บริการได้เป็นปกติสำหรับข้อมูลอื่น
                ๆ เพิ่มเติมในเรื่องนี้ท่านสามารถเข้าไปอ่านได้ที่{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://www.aboutcookies.org/how-to-delete-cookies'
                >
                  <Typography
                    variant='h6'
                    sx={{ display: 'inline-block' }}
                    color={'primary'}
                  >
                    https://www.aboutcookies.org/how-to-delete-cookies
                  </Typography>
                </a>{' '}
                มูลนิธิดรุณาทรจะไม่รับผิดชอบและมูลนิธิดรุณาทรไม่ได้มีความเกี่ยวข้องกับเว็บไซต์รวมทั้งเนื้อหาในเว็บไซต์ต่าง
                ๆ ที่กล่าวมาข้างบน
              </Typography>
            </Box>

            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                5.ติดต่อมูลนิธิดรุณาทร
              </Typography>
              <Box paddingLeft={'16px'}>
                <Typography variant='h6'>
                  <a>
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 'bold', display: 'inline-block' }}
                    >
                      ส่งถึง: มูลนิธิดรุณาทร
                    </Typography>
                  </a>{' '}
                  เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล 57/7 ซอย 3 ถนนทุ่งโฮเต็ล
                  ตำบลวัดเกต อำเภอเมือง จังหวัดเชียงใหม่ 50000
                </Typography>
                <Typography variant='h6'>
                  <a>
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 'bold', display: 'inline-block' }}
                    >
                      อีเมล
                    </Typography>
                  </a>{' '}
                  cithpr@gmail.com
                </Typography>
                <Typography variant='h6'>
                  <a>
                    <Typography
                      variant='h6'
                      sx={{ fontWeight: 'bold', display: 'inline-block' }}
                    >
                      โทรศัพท์
                    </Typography>
                  </a>{' '}
                  053 226 426 ถึง 9
                </Typography>
              </Box>
            </Box>
            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                การเปลี่ยนแปลงนโยบาย (Policy Change)
              </Typography>

              <Typography variant='h6'>
                นโยบายนี้อาจมีการปรับปรุงเป็นครั้งคราว
                และเว็บไซต์น้ำใจจะมีการประกาศนโยบายที่มีการปรับปรุงใหม่บนหน้าเพจนี้
                เว็บไซต์น้ำใจขอให้ท่านหมั่นเข้ามาตรวจสอบหน้าเพจนี้อย่างสม่ำเสมอ
              </Typography>
            </Box>
            <Box width={'100%'} textAlign={'left'} paddingY={'16px'}>
              <Typography variant='h6'>
                วันที่มีผลใช้บังคับของนโยบาย ฉบับนี้ วันที่ 1 มีนาคม 2567
              </Typography>
            </Box>
          </Box>
        </Container>

        <Footer />
      </div>
    </>
  )
}
