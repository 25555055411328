import axios from 'axios'
import {
  ActivityPlans,
  BudgetPlans,
  Detail,
  FundingDetail,
  ImagesUrl,
  Overview,
} from '../models/campaign'
import { GetCampaignProposalData } from '../models/CampaignProposal/GetCampaignProposalData'
import AuthService from './auth.service'
import transformCampaignProposalData from './TransformCampaignProposalData'

const API_URL = process.env.REACT_APP_BASE_API
const API_KEY = process.env.REACT_APP_XKEY_API

const getCampaignData = (campaignId: string) => {
  const token = AuthService.getTokenUser()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-api-key': API_KEY,
    },
  }

  const getOverviewData = () => {
    return axios.get<Overview>(
      API_URL + `/campaigns/${campaignId}/overview`,
      config
    )
  }

  const getDetailData = () => {
    return axios.get<Detail>(
      API_URL + `/campaigns/${campaignId}/detail`,
      config
    )
  }
  const getFundingDetailData = () => {
    return axios.get<FundingDetail>(
      API_URL + `/campaigns/${campaignId}/funding-detail`,
      config
    )
  }
  const getImagesData = () => {
    return axios.get<ImagesUrl>(
      API_URL + `/campaigns/${campaignId}/images`,
      config
    )
  }
  const getActivityPlans = () => {
    return axios.get<ActivityPlans[]>(
      API_URL + `/campaigns/${campaignId}/activity-plans`,
      config
    )
  }
  const getBudgetPlans = () => {
    return axios.get<BudgetPlans[]>(
      API_URL + `/campaigns/${campaignId}/budget-plans`,
      config
    )
  }
  return Promise.all([
    getOverviewData(),
    getDetailData(),
    getFundingDetailData(),
    getImagesData(),
    getActivityPlans(),
    getBudgetPlans(),
  ])
    .then((responses) => {
      const overviewData = responses[0].data
      const detailData = responses[1].data
      const fundingDetailData = responses[2].data
      const imagesData = responses[3].data
      const activityPlansData = responses[4].data
      const budgetPlansData = responses[5].data

      const modifiedResponse: GetCampaignProposalData = {
        campaignId: campaignId,
        name: detailData.name,
        startDate: detailData.startDate,
        endDate: detailData.endDate,
        campaignRequestAreaHolistics: detailData.areaHolistic,
        campaignRequestFundingDetail: {
          currentDonationAmount: fundingDetailData.currentDonationAmount,
          targetBudget: fundingDetailData.targetBudget,
          donatedUserCount: fundingDetailData.donatedUserCount,
          fundingStartDate: fundingDetailData.startDate,
          fundingEndDate: fundingDetailData.endDate,
          bankAccount: fundingDetailData.bankAccount,
        },

        projectHolders: detailData.holderChurches,
        campaignRequestContactPerson: detailData.contactPerson,
        objective: detailData.objective,
        background: overviewData.background,
        workProcess: overviewData.workProcess,
        location: detailData.location,
        childParticipantTarget: overviewData.targetGroup.map((target) => ({
          index: target.index,
          age: target.age,
          male: target.maleCount,
          female: target.femaleCount,
        })),

        expectedResult: detailData.expectedResult,

        activityPlan: activityPlansData.map((dataActivityPlan) => ({
          activityIndex: dataActivityPlan.index,
          startDate: dataActivityPlan.startDate,
          endDate: dataActivityPlan.endDate,
          description: dataActivityPlan.name,
        })),

        images: imagesData.imagesUrl.map((dataImages) => ({
          url: dataImages,
          fileName: '',
          fileSize: 0,
        })),
        budgetPlan: budgetPlansData,
        campaignRequestType: 'ADMIN-EDIT',
        proposerComment: 'admin edit',
        adminComment: '',
        campaignRequestStatus: 'PENDING',
      }
      return transformCampaignProposalData(modifiedResponse)
    })
    .catch((error) => {
      console.error(error)

      throw error // Rethrow the error to propagate it to the caller
    })
}

const getCampaignDesQuoteCoverData = async (campaignId: string) => {
  const token = AuthService.getTokenUser()
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'x-api-key': API_KEY,
    },
  }

  try {
    const getDetailData = () => {
      return axios.get<Detail>(
        API_URL + `/campaigns/${campaignId}/detail`,
        config
      )
    }

    return getDetailData().then((responses) => {
      const data = responses.data
      const detailData = {
        description: data.description,
        quote: data.quote,
        coverImageUrl: data.coverImageUrl,
      }
      return detailData
    })
  } catch (error) {
    console.error(error)
    throw error // Rethrow the error to propagate it to the caller
  }
}

const CampaignEditServices = {
  getCampaignData,
  getCampaignDesQuoteCoverData,
}

export default CampaignEditServices
