import { Box, ListItem, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { COLOR } from '../constants/color-constant'

type MemberProp = {
  name: string
}

const MemberList: React.FunctionComponent<MemberProp> = (props) => {
  const { name } = props

  return (
    <ListItem
      disablePadding
      className='flex-wrap gap-2 gap-y-1 p-2'
      sx={{
        borderStyle: 'solid',
        borderWidth: '1px',
        borderBottomColor: '#F0F0F0',
      }}
    >
      <div className='grid gap-0'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            columnGap: '4px',
            alignItems: 'center',
            overflow: 'auto',
          }}
        >
          <AccountCircleIcon
            sx={{
              height: 50,
              width: 50,
              color: COLOR.SECOUNDARY_SECOUND_3,
              marginLeft: 1,
              marginRight: 1,
            }}
          ></AccountCircleIcon>
          <Typography variant='body1' color={'text.primary'} pr={2}>
            {name}
          </Typography>
        </Box>
      </div>
    </ListItem>
  )
}

export default MemberList
