import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTE } from '../constants/route-constant'
import { COLOR } from '../constants/color-constant'
import Navbar from '../components/Navbar/Navbar'
import { Typography } from '@mui/material'
import Footer from '../components/Footer/Footer'
import AuthService from '../services/auth.service'
import CircularProgress from '@mui/material/CircularProgress'
import ModalNotification from '../components/Modal/ModalNotification'
import { Error } from '@mui/icons-material'
import RedirectGetServices from '../services/RedirectGetServices'
function Redirect() {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParam = new URLSearchParams(location.search)
  const actionTo = queryParam.get('action')
  const verifyCode = queryParam.get('verifyCode')
  const resetcode = queryParam.get('resetcode')
  const token = queryParam.get('token')

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const handleRedirection = async () => {
    switch (actionTo) {
      case 'resetpassword':
        console.log('Redirect to reset password')
        if (resetcode) {
          navigate(`${ROUTE.FORGOT_PASSWORD}?resetcode=${resetcode}`)
        }
        break
      case 'verify':
        if (verifyCode) {
          if (await AuthService.verifyUser(verifyCode)) {
            console.log('verification success')
          } else {
            setIsError(true)
          }
          setTimeout(() => {
            console.log('redirect to user profile')
            navigate(ROUTE.USER)
          }, 2000)
        }
        break
      case 'accept-cooperative-invitation':
        console.log('accept-cooperative')
        if (token) {
          if (await RedirectGetServices.acceptCooperativeInvitation(token)) {
            console.log('accept-cooperative ok')
            navigate('/')
          }
        }

        break
      case 'church-join':
        console.log('church-cooperative')
        if (token) {
          if (await RedirectGetServices.churchesJoin(token)) {
            console.log('churches Join ok')
            navigate(ROUTE.CHURCH)
          }
        }
        break
      default:
        navigate('/')
        // Handle invalid or missing query parameters here
        break
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)

    setTimeout(() => {
      handleRedirection()
    }, 200)
  }, [])

  // )
  return (
    <>
      <div className='flex min-h-screen flex-col'>
        <ModalNotification
          id={'invalid-reset-link'}
          type={'ERROR'}
          SvgIcon={
            <Error
              color='error'
              sx={{
                fontSize: '80px',
              }}
            />
          }
          title={`Invalid Link.`}
          description='Please request a new link.'
          open={isError}
          onClose={() => {
            setIsError(false)
          }}
        />
        <div className='flex h-screen flex-grow items-center justify-center'>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Typography variant='h1' style={{ color: COLOR.PRIMARY_1 }}>
              Redirecting ...
            </Typography>
          )}
        </div>
      </div>
    </>
  )
}

export default Redirect
