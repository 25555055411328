import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { COLOR } from '../constants/color-constant'
import { common } from '@mui/material/colors'
import NamjaiBagList from '../components/NamjaiBagList'
import { ReactComponent as NamjaiBagIcon } from '../assets/icons/namjaibag-small.svg'
import { ReactComponent as NamjaiBagIconDisabled } from '../assets/icons/namjaibag-small-disabled.svg'
import { createContext, useEffect, useState } from 'react'
import { PaymentType } from '../models/PaymentType'
import { ROUTE } from '../constants/route-constant'
import AuthService from '../services/auth.service'
import { NamjaiBagListData } from '../models/NamjaiBag'
import axios from 'axios'

export const NamjaiListContext = createContext<any>([])

export default function NamjaiBag() {
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''
  const navigate = useNavigate()
  const [selectPayment, setSelectPayment] = useState<PaymentType>('')
  const [openThankYouLetterSection, setOpenThankYouLetterSection] =
    useState<boolean>(false)
  const [openTaxDeductionSection, setOpenTaxDeductionSection] =
    useState<boolean>(false)
  const [namjaiBagListStatus, setNamjaiBagListStatus] = useState('initial')
  const [overallAmountError, setOverAllAmountError] = useState<boolean>(false)

  // get JWT
  const token = AuthService.getTokenUser()

  const [namjaiBagList, setNamjaiBagList] = useState<
    NamjaiBagListData[] | null
  >(null)

  const getNamjaiBagList = async () => {
    setNamjaiBagListStatus('loading')
    try {
      const response = await axios.get(`${API_URL}/namjai-bag`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-API-KEY': `${API_KEY}`,
        },
      })
      setNamjaiBagList(response.data)
      setNamjaiBagListStatus('ready')
    } catch (error) {
      // Handle errors (e.g., display an error message)
      console.error('Error fetching item:', error)
    }
  }

  const totalAmountCalC = () => {
    let amount = 0
    namjaiBagList?.map((data) => (amount += data!.donationAmount))
    return amount?.toLocaleString('en-US')
  }

  const deleteNamjaiBagList = () => {
    getNamjaiBagList()
  }

  const editNamjaiBagList = () => {
    getNamjaiBagList()
  }

  useEffect(() => {
    if (namjaiBagList === null) {
      getNamjaiBagList()
    }
  }, [])

  return (
    <>
      <div className='flex min-h-screen flex-col'>
        <Navbar />
        {token ? (
          <Container
            sx={{
              display: 'inline-flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#FBFDFF',
            }}
          >
            <Box height={'24px'} />
            <Typography variant='h1' color={COLOR.SECOUNDARY_FIRST_1}>
              กระเป๋าน้ำใจของคุณ
            </Typography>
            <Box height={'12px'} />
            {!namjaiBagList && <CircularProgress />}
            {namjaiBagList && (
              <>
                <Box
                  id='namjai-bag-content'
                  display={'flex'}
                  padding={'24px'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  gap={'12px'}
                  alignSelf={'stretch'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: COLOR.GRAY_200,
                    background: common.white,
                  }}
                >
                  {namjaiBagList?.map((data) => (
                    <NamjaiListContext.Provider
                      value={[
                        namjaiBagListStatus,
                        setNamjaiBagListStatus,
                        overallAmountError,
                        setOverAllAmountError,
                      ]}
                    >
                      <NamjaiBagList
                        key={data.namjaiBagId}
                        namjaiBagId={data.namjaiBagId}
                        campaignId={data.campaignId}
                        campaignName={data.campaignName}
                        campaignAmount={data.remainingGoal}
                        donationAmount={data.donationAmount}
                        onDelete={() => deleteNamjaiBagList()}
                        onEdit={() => editNamjaiBagList()}
                      />
                    </NamjaiListContext.Provider>
                  ))}
                  <Box
                    display={'flex'}
                    padding={'12px 40px'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    alignSelf={'stretch'}
                  >
                    <Typography variant='h4'>จำนวน "น้ำใจ" ทั้งหมด</Typography>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'flex-end'}
                      gap={'8px'}
                    >
                      {namjaiBagListStatus === 'ready' ? (
                        <Typography variant='h4' color={COLOR.PRIMARY_2}>
                          {totalAmountCalC()}
                        </Typography>
                      ) : (
                        <Typography variant='h4' color={COLOR.GRAY_600}>
                          {totalAmountCalC()}
                        </Typography>
                      )}

                      <Typography variant='h6'>บาท</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={{ xs: 'column', md: 'row' }}
                  alignItems={'center'}
                  gap={'24px'}
                  padding={'24px'}
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    disabled={namjaiBagListStatus === 'loading'}
                    startIcon={
                      namjaiBagListStatus === 'loading' ? (
                        <NamjaiBagIconDisabled />
                      ) : (
                        <NamjaiBagIcon />
                      )
                    }
                    onClick={() => navigate(ROUTE.ALL_CAMPAIGN)}
                  >
                    <Typography variant='h6'>
                      ต้องการเพิ่มโครงการอื่นอีก
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
          </Container>
        ) : (
          <Container>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign={'center'}
              height={'70vh'}
              gap={'4px'}
              padding={'8vw'}
            >
              <Typography variant='h3'>
                ขออภัย คุณไม่สามารถรับชมเนื้อหานี้ได้ หรือ
                เนื้อหานี้ไม่มีอยู่แล้ว
              </Typography>

              <Button
                variant='outlined'
                onClick={() => navigate('/')}
                sx={{ maxWidth: '50vw', margin: '8px' }}
              >
                กลับไปที่หน้าหลัก
              </Button>
            </Box>
          </Container>
        )}
      </div>
      <Box height={'24px'} />
      <Footer />
    </>
  )
}
