import { Box, Card, Typography, Grid, Divider, Button } from '@mui/material'
import { FundingDetail } from '../../models/campaign'
import { COLOR } from '../../constants/color-constant'
import { useNavigate } from 'react-router-dom'
import ProgressBar from '../ProgressBar'
import formatDate from '../../utils/formatDate'
import account from '../../assets/icons/account.svg'
import { ReactComponent as Facebook } from '../../assets/SocialIcons/Facebook.svg'
import { ReactComponent as Instagram } from '../../assets/SocialIcons/Instagram.svg'
export default function NowDonationCard(props: {
  fundingDetail: FundingDetail | null
  currentTimestamp: number
  isAdminView: boolean
}) {
  const fundingDetail = props.fundingDetail
  const currentTimestamp = props.currentTimestamp
  const isAdminView = props.isAdminView
  const navigate = useNavigate()
  const FACEBOOK_URL = 'https://www.facebook.com/'
  const INSTAGRAM_URL = 'https://twitter.com/'
  const ICON_SIZE = 36

  const isEndDateDefined = fundingDetail?.endDate !== undefined
  const isDisabled = isEndDateDefined
    ? fundingDetail.endDate - currentTimestamp < 0
    : false
  const borderColor = isEndDateDefined
    ? isDisabled
      ? COLOR.GRAY_200
      : COLOR.PRIMARY_2
    : COLOR.GRAY_200
  const textColor = isEndDateDefined
    ? isDisabled
      ? COLOR.GRAY_200
      : COLOR.PRIMARY_2
    : COLOR.GRAY_200

  return (
    <Card
      sx={{
        borderRadius: '20px',
        minHeight: '200px',
        p: '25px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        boxShadow: 3,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant='h6' color='text' sx={{ mt: 1 }}>
          ยอดบริจาคขณะนี้
        </Typography>
        <Typography variant='h2' color={COLOR.SECOUNDARY_FIRST_1}>
          {fundingDetail?.currentDonationAmount?.toLocaleString() ?? 0} บาท
        </Typography>
        <div
          className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
          style={{ backgroundColor: '#FFF2D0' }}
        >
          <Typography variant='h6' color={'#FF8600'}>
            {fundingDetail?.fundingStatus}
          </Typography>
        </div>

        <Typography variant='h6' color='text' sx={{ mt: 1 }}>
          เป้าหมาย
        </Typography>
        <ProgressBar
          amount={fundingDetail?.currentDonationAmount ?? 0}
          goal={fundingDetail?.targetBudget ?? 999999}
        ></ProgressBar>
        <Grid container direction='row' justifyContent='space-between'>
          <Typography variant='body2' color='text'>
            {fundingDetail ? (
              fundingDetail.endDate - currentTimestamp < 0 ? (
                <span>
                  ปิดรับการบริจาคเมื่อ {formatDate(fundingDetail.endDate)}
                </span>
              ) : (
                <span>
                  {Math.floor(
                    (fundingDetail.endDate - currentTimestamp) /
                      1000 /
                      60 /
                      60 /
                      24
                  )}{' '}
                  วัน จนถึง {formatDate(fundingDetail.endDate)}
                </span>
              )
            ) : null}
          </Typography>
          <Grid display={'flex'}>
            <img alt='account' src={account}></img>
            <Typography variant='body2' color='text'>
              {fundingDetail?.donatedUserCount ?? ' 9,999'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {!isAdminView && (
        <>
          <Button
            style={{
              borderBlockWidth: '2px',
              borderRadius: '10px',
              borderColor: borderColor,
            }}
            fullWidth
            variant='outlined'
            disabled={isDisabled}
            onClick={() => {
              navigate({ hash: '#JOIN' })
            }}
            sx={{ width: '100%', height: '42px' }}
          >
            <Typography variant='h6' color={textColor}>
              ร่วมบริจาค
            </Typography>
          </Button>
          <Button
            style={{
              borderBlockWidth: '2px',
              borderRadius: '10px',
              borderColor: COLOR.PRIMARY_2,
            }}
            fullWidth
            variant='outlined'
            onClick={() => {}}
            sx={{ width: '100%', height: '42px' }}
          >
            <Typography variant='h6' color={COLOR.PRIMARY_2}>
              เพิ่มลง กระเป๋าน้ำใจ
            </Typography>
          </Button>
        </>
      )}
      <Divider />
      <Typography variant='h5' color={COLOR.PRIMARY_1}>
        แชร์
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
        }}
      >
        <a href={FACEBOOK_URL} target='_blank' rel='noreferrer'>
          <Facebook
            color={COLOR.PRIMARY_1}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </a>
        <a href={INSTAGRAM_URL} target='_blank' rel='noreferrer'>
          <Instagram
            color={COLOR.GRAY_200}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </a>
      </Box>
    </Card>
  )
}
