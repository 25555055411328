import React, { useEffect } from 'react'
import {
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import { ReactComponent as KasikornBankLogo } from '../../assets/icons/Banks/kbank - ธนาคารกสิกรไทย.svg'
import { ReactComponent as ScbBankLogo } from '../../assets/icons/Banks/scb - ธนาคารไทยพาณิชย์.svg'
import { ReactComponent as KrungthaiBankLogo } from '../../assets/icons/Banks/ktb- ธนาคารกรุงไทย.svg'
import { ReactComponent as KrungsriBankLogo } from '../../assets/icons/Banks/bay - ธนาคารกรุงศรีอยุธยา.svg'
import { ReactComponent as BblBankLogo } from '../../assets/icons/Banks/bbl - ธนาคารกรุงเทพ.svg'
import { ReactComponent as IcbcBankLogo } from '../../assets/icons/Banks/icbc - ธนาคารไอซีบีซี (ไทย).svg'
import { ReactComponent as CitibankLogo } from '../../assets/icons/Banks/citi - ซิตี้แบงก์.svg'
import { ReactComponent as GsbBankLogo } from '../../assets/icons/Banks/gsb - ธนาคารออมสิน.svg'
import { ReactComponent as TtbBankLogo } from '../../assets/icons/Banks/ttb -ธนาคารทหารไทยธนชาต.svg'
import { ReactComponent as UobBankLogo } from '../../assets/icons/Banks/uob - ธนาคารยูโอบี.svg'
import { ReactComponent as CimbBankLogo } from '../../assets/icons/Banks/cimb - _ธนาคาร ซีไอเอ็มบี ไทย.svg'
import { ReactComponent as KiatnakinBankLogo } from '../../assets/icons/Banks/kk - ธนาคารเกียรตินาคิน.svg'
import { ReactComponent as OtherBankLogo } from '../../assets/icons/Banks/other bank.svg'
import { ReactComponent as GhbBankLogo } from '../../assets/icons/Banks/ghb - ธนาคารอาคารสงเคราะห์.svg'
import { ReactComponent as Lhbank } from '../../assets/icons/Banks/lhb - ธนาคารแลนด์ แอนด์ เฮ้าส์.svg'
import { ReactComponent as BaacBankLogo } from '../../assets/icons/Banks/baac - ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร.svg'
import { availableBanks } from './CampaignProposalForm3'
import { BankAccount } from '../../models/campaign'

interface SelectBankProps {
  viewOnly: boolean
  bankAccount: BankAccount
  setBank: (bank: string) => void
}

function isCustomBank(bank: string) {
  return !availableBanks.find((name) => name === bank)
}

export function SelectBank({
  viewOnly,
  bankAccount,
  setBank,
}: SelectBankProps) {
  const [option, setOption] = React.useState('')

  useEffect(() => {
    const custom = isCustomBank(bankAccount.bankName)
    if (custom) {
      setOption('ธนาคารอื่น ๆ (โปรดระบุ)')
    } else {
      setOption(bankAccount.bankName)
    }
  }, [])

  return (
    <Stack gap='8px' width='100%'>
      <FormControl>
        <InputLabel id='bank-select-label' size='small'>
          ธนาคาร
        </InputLabel>
        <Select
          labelId='bank-select-label'
          id='bank-select'
          value={option}
          label='ธนาคาร'
          onChange={(e) => {
            const s = e.target.value as string
            setOption(s)
            if (s === 'ธนาคารอื่น ๆ (โปรดระบุ)') {
              setBank('')
            } else {
              setBank(s)
            }
          }}
          size='small'
          disabled={viewOnly}
          style={{
            backgroundColor: 'white',
          }}
        >
          <MenuItem value={'ธนาคารไทยพาณิชย์'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <ScbBankLogo width='20px' height='20px' />
              ธนาคารไทยพาณิชย์
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารกสิกรไทย'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <KasikornBankLogo width='20px' height='20px' />
              ธนาคารกสิกรไทย
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารกรุงไทย'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <KrungthaiBankLogo width='20px' height='20px' />
              ธนาคารกรุงไทย
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารเพื่อการเกษตรและสกหรณ์การเกษตร'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <BaacBankLogo width='20px' height='20px' />
              ธนาคารเพื่อการเกษตรและสกหรณ์การเกษตร
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารกรุงศรีอยุธยา'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <KrungsriBankLogo width='20px' height='20px' />
              ธนาคารกรุงศรีอยุธยา
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารกรุงเทพ'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <BblBankLogo width='20px' height='20px' />
              ธนาคารกรุงเทพ
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคาร ซีไอเอ็มบี ไทย'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <CimbBankLogo width='20px' height='20px' />
              ธนาคาร ซีไอเอ็มบี ไทย
            </Stack>
          </MenuItem>
          <MenuItem value={'ซิตี้แบงค์'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <CitibankLogo width='20px' height='20px' />
              ซิตี้แบงค์
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารออมสิน'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <GsbBankLogo width='20px' height='20px' />
              ธนาคารออมสิน
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารทหารไทยธนชาต'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <TtbBankLogo width='20px' height='20px' />
              ธนาคารทหารไทยธนชาต
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารอาคารสงเคราะห์'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <GhbBankLogo width='20px' height='20px' />
              ธนาคารอาคารสงเคราะห์
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารยูโอบี'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <UobBankLogo width='20px' height='20px' />
              ธนาคารยูโอบี
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารไอซีบีซี (ไทย)'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <IcbcBankLogo width='20px' height='20px' />
              ธนาคารไอซีบีซี(ไทย)
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารเกียรตินาคินภัทร'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <KiatnakinBankLogo width='20px' height='20px' />
              ธนาคารเกียรตินาคินภัทร
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารแลนด์ แอนด์ เฮ้าส์'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <Lhbank width='20px' height='20px' />
              ธนาคารแลนด์ แอนด์ เฮ้าส์
            </Stack>
          </MenuItem>
          <MenuItem value={'ธนาคารอื่น ๆ (โปรดระบุ)'}>
            <Stack flexDirection='row' gap='16px' alignItems='center'>
              <OtherBankLogo width='20px' height='20px' />
              ธนาคารอื่น ๆ (โปรดระบุ)
            </Stack>
          </MenuItem>
        </Select>
      </FormControl>
      {option === 'ธนาคารอื่น ๆ (โปรดระบุ)' && (
        <TextField
          size='small'
          label='ชื่อธนาคาร'
          value={bankAccount.bankName}
          onChange={(e) => setBank(e.target.value)}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#212121',
            },
            input: {
              background: 'white',
            },
          }}
          disabled={viewOnly}
        />
      )}
    </Stack>
  )
}
