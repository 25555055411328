import {
  Stack,
  Typography,
  Divider,
  Button,
  Box,
  TextField,
  List,
  ListItem,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/th'
import { useMemo, useRef, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import { TypeOf, z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'

import MemberSection from '../MemberSection'
import { UserShortDetail } from '../../models/UserTypes'
import SmallAreaHolisticSelector from '../SmallAreaHolisticSelector'
import SearchChurch, { churchInfo } from '../SearchChurch'
import ChurchProfileCard from '../ChurchProfileCard'
import { COLOR } from '../../constants/color-constant'
import ModalWithButton from '../Modal/ModalWithButton'
import {
  CampaignRequestContactPerson,
  CampaignRequestProjectHolder,
} from '../../models/CampaignProposal/GetCampaignProposalData'

const MAX_CONTACT_PERSONS = 2
const MAX_PROJECT_HOLDERS = 10

export const campaignProposalForm1Schema = z
  .object({
    campaignName: z.string().trim().nonempty(),
    campaignStartDate: z.custom<Dayjs>(),
    campaignEndDate: z.custom<Dayjs>(),
    fundingGoal: z.number().refine((n) => n > 0),
    donationStartDate: z.custom<Dayjs>(),
    donationEndDate: z.custom<Dayjs>(),
  })
  .refine(
    (data) => data.campaignEndDate.unix() > data.campaignStartDate.unix(),
    {
      message: '* วันที่สิ้นสุดโครงการ ต้องมาทีหลัง วันที่เริ่มโครงการ',
      path: ['campaignEndDate'],
    }
  )
  .refine(
    (data) => data.donationEndDate.unix() > data.donationStartDate.unix(),
    {
      message: '* วันที่สิ้นสุดการระดมทุน ต้องมาทีหลัง วันที่เริ่มการระดมทุน',
      path: ['donationEndDate'],
    }
  )

export type CampaignProposalForm1Input = TypeOf<
  typeof campaignProposalForm1Schema
>

interface CampaignProposalForm1Props {
  contactPersons: UserShortDetail[] | CampaignRequestContactPerson[]
  holderChurches: churchInfo[] | CampaignRequestProjectHolder[]
  selectedAreas: boolean[]
  onContactPersonChange: (contactPerson: UserShortDetail[]) => void
  onHolderChurchChange: (holderChurch: any) => void
  onSelectedAreaChange: (newAreas: boolean[]) => void
  register: UseFormRegister<CampaignProposalForm1Input>
  control: Control<CampaignProposalForm1Input>
  errors: FieldErrors<CampaignProposalForm1Input>
  onSubmit: UseFormHandleSubmit<CampaignProposalForm1Input>
  onSubmissionPass: () => void
  allowEditProjectHolder: boolean
  viewOnly: boolean
}

function CampaignProposalForm1({
  contactPersons,
  holderChurches,
  selectedAreas,
  onContactPersonChange,
  onHolderChurchChange,
  onSelectedAreaChange,
  onSubmit,
  onSubmissionPass,
  register,
  control,
  errors,
  allowEditProjectHolder,
  viewOnly: adminView,
}: CampaignProposalForm1Props) {
  const [openSearchNotFoundChurch, setOpenSearchNotFoundChurch] =
    useState(false)

  const campaignEndDateRef = useRef<any>(null)
  const areaRef = useRef<any>(null)
  const donationEndDateRef = useRef<any>(null)
  const projectHolderRef = useRef<any>(null)
  const projectContactPersonRef = useRef<any>(null)

  const [openDeleteHolderChurch, setOpenDeleteHolderChurch] = useState(false)
  const [churchToRemove, setChurchToRemove] = useState<{
    churchId: string
    churchName: string
  } | null>(null)

  const [nonZodErrors, setNonZodErrors] = useState({
    areaError: false,
    projectHolderError: false,
    contactPersonError: false,
  })

  const assignedMembersID = useMemo(() => {
    return contactPersons.map((user) => (user ? user.id : ''))
  }, [contactPersons])

  const assignedChurchesID = useMemo(() => {
    return holderChurches.map((c) => c.churchId)
  }, [holderChurches])

  const handleAddContactPerson = (newPerson: UserShortDetail) => {
    setNonZodErrors((prev) => {
      return { ...prev, contactPersonError: false }
    })
    onContactPersonChange([...contactPersons, newPerson])
  }

  const handleDeleteContactPerson = (toDeletePerson: UserShortDetail) => {
    onContactPersonChange(
      [...contactPersons].filter((p) => p !== toDeletePerson)
    )
  }

  const handleAddHolderChurch = (newChurch: churchInfo | null) => {
    if (!newChurch || holderChurches.length >= MAX_PROJECT_HOLDERS) return

    setNonZodErrors((prev) => {
      return { ...prev, projectHolderError: false }
    })
    onHolderChurchChange(holderChurches.concat(newChurch))
  }

  const handleRemoveHolderChurch = (toRemoveId: string) => {
    if (contactPersons.length > 0) {
      onContactPersonChange([])
    }
    onHolderChurchChange(
      holderChurches.filter((c) => c.churchId !== toRemoveId)
    )
  }

  const markChurchHolderToRemove = (
    c: churchInfo | CampaignRequestProjectHolder
  ) => {
    return (id: string) => {
      if (contactPersons.length > 0) {
        setChurchToRemove({
          churchId: id,
          churchName: c.churchName,
        })
        setOpenDeleteHolderChurch(true)
      } else {
        handleRemoveHolderChurch(id)
      }
    }
  }

  const selectArea = (i: number, isSelected: boolean) => {
    setNonZodErrors((prev) => {
      return { ...prev, areaError: false }
    })
    const updated = [...selectedAreas]
    updated[i] = isSelected
    onSelectedAreaChange(updated)
  }

  const validateNonZodManagedState = () => {
    const newNonZodErrors = {
      areaError: selectedAreas.every((a) => !a),
      projectHolderError:
        holderChurches.length === 0 ||
        holderChurches.length > MAX_PROJECT_HOLDERS,
      contactPersonError:
        contactPersons.length === 0 ||
        contactPersons.length > MAX_CONTACT_PERSONS,
    }

    setNonZodErrors(newNonZodErrors)
    scrollToErrorInput(newNonZodErrors)

    const isPass =
      Object.keys(newNonZodErrors).every(
        (k) => !newNonZodErrors[k as keyof typeof newNonZodErrors]
      ) &&
      !errors.campaignEndDate &&
      !errors.donationEndDate
    return isPass
  }

  const scrollToErrorInput = (err: typeof nonZodErrors) => {
    if (err.areaError) {
      areaRef.current.scrollIntoView()
    } else if (err.projectHolderError) {
      projectHolderRef.current.scrollIntoView()
    } else if (err.contactPersonError) {
      projectContactPersonRef.current.scrollIntoView()
    }
  }

  const submitHandler = (values: CampaignProposalForm1Input) => {
    if (validateNonZodManagedState()) {
      onSubmissionPass()
    }
  }

  return (
    <Box
      width='100%'
      component='form'
      id='campaign-proposal-form-1'
      onSubmit={onSubmit(submitHandler)}
    >
      <Stack gap={'40px'}>
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <span>
              <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
                *{' '}
              </Typography>
              <Typography display='inline' variant='h3'>
                ชื่อโครงการ
              </Typography>
            </span>
          </div>
          <Stack flexGrow='1' minWidth='0' gap='24px'>
            <TextField
              placeholder='ชื่อโครงการ'
              size='small'
              fullWidth={true}
              {...register('campaignName')}
              error={Boolean(errors['campaignName'])}
              disabled={adminView}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#212121',
                },
              }}
            />
            <Stack flexDirection='row' gap='24px'>
              <Stack gap='8px'>
                <Typography variant='h6'>วันที่เริ่มโครงการ</Typography>
                <Controller
                  name='campaignStartDate'
                  control={control}
                  defaultValue={dayjs()}
                  render={({
                    field: { onChange, value, ...restField },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale='th'
                    >
                      <DatePicker
                        label='วันที่เริ่มโครงการ'
                        value={value}
                        {...restField}
                        onChange={(value) => {
                          onChange(value)
                        }}
                        slotProps={{
                          textField: {
                            error: Boolean(error),
                            size: 'small',
                          },
                        }}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
              <Stack gap='8px' ref={campaignEndDateRef}>
                <Typography variant='h6'>วันที่สิ้นสุดโครงการ</Typography>
                <Controller
                  name='campaignEndDate'
                  control={control}
                  defaultValue={dayjs()}
                  render={({
                    field: { onChange, value, ...restField },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale='th'
                    >
                      <DatePicker
                        label='วันที่สิ้นสุดโครงการ'
                        value={value}
                        {...restField}
                        onChange={(value) => {
                          onChange(value)
                        }}
                        slotProps={{
                          textField: {
                            error: Boolean(error),
                            size: 'small',
                          },
                        }}
                        ref={register('campaignEndDate').ref}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
            </Stack>
            {errors.campaignEndDate && (
              <Typography variant='body1' color={COLOR.BUTTON_RED}>
                {errors.campaignEndDate?.message}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px' ref={areaRef}>
          <span>
            <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
              *{' '}
            </Typography>
            <Typography display='inline' variant='h3'>
              การพัฒนาเด็กแบบองค์รวม
            </Typography>
          </span>
          <SmallAreaHolisticSelector
            selected={selectedAreas}
            setSelected={selectArea}
            adminView={adminView}
          />
          {nonZodErrors.areaError && (
            <Typography variant='body1' color={COLOR.BUTTON_RED}>
              * กรุณาเลือกการพัฒนาเด็กแบบองค์รวม
            </Typography>
          )}
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <span>
              <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
                *{' '}
              </Typography>
              <Typography display='inline' variant='h3'>
                เป้าหมายการระดมทุน (บาท)
              </Typography>
            </span>
          </div>
          <Stack flexGrow='1' minWidth='0' gap='24px'>
            <TextField
              placeholder='จำนวน (บาท)'
              type='number'
              size='small'
              fullWidth={true}
              inputProps={{
                pattern: '[0-9]*',
                inputMode: 'numeric',
                min: 0,
                step: 'any',
              }}
              {...register('fundingGoal', { valueAsNumber: true })}
              error={Boolean(errors['fundingGoal'])}
              disabled={adminView}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#212121',
                },
              }}
            />
            <Stack flexDirection='row' gap='24px'>
              <Stack gap='8px'>
                <Typography variant='h6'>วันที่เริ่มการระดมทุน</Typography>
                <Controller
                  name='donationStartDate'
                  control={control}
                  defaultValue={dayjs()}
                  render={({
                    field: { onChange, value, ...restField },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale='th'
                    >
                      <DatePicker
                        label='วันที่เริ่มการระดมทุน'
                        value={value}
                        {...restField}
                        onChange={(value) => {
                          onChange(value)
                        }}
                        slotProps={{
                          textField: {
                            error: Boolean(error),
                            size: 'small',
                          },
                        }}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
              <Stack gap='8px' ref={donationEndDateRef}>
                <Typography variant='h6'>วันที่สิ้นสุดการระดมทุน</Typography>
                <Controller
                  name='donationEndDate'
                  control={control}
                  defaultValue={dayjs()}
                  render={({
                    field: { onChange, value, ...restField },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale='th'
                    >
                      <DatePicker
                        label='วันที่สิ้นสุดการระดมทุน'
                        value={value}
                        {...restField}
                        onChange={(value) => {
                          onChange(value)
                        }}
                        slotProps={{
                          textField: {
                            error: Boolean(error),
                            size: 'small',
                          },
                        }}
                        ref={register('donationEndDate').ref}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Stack>
            </Stack>
            {errors.donationEndDate && (
              <Typography variant='body1' color={COLOR.BUTTON_RED}>
                {errors.donationEndDate?.message}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack
          flexDirection='row'
          flexWrap='wrap'
          gap='16px'
          ref={projectHolderRef}
        >
          <Stack>
            <div
              style={{
                width: '300px',
              }}
            >
              <span>
                <Typography
                  display='inline'
                  variant='h3'
                  color={COLOR.PRIMARY_1}
                >
                  *{' '}
                </Typography>
                <Typography display='inline' variant='h3'>
                  ผู้จัดโครงการ
                </Typography>
              </span>
            </div>
            <Typography variant='subtitle1' color={COLOR.GRAY_600}>
              สูงสุด 10 คริสตจักร
            </Typography>
          </Stack>
          <Stack gap='24px'>
            <Stack
              gap='16px'
              sx={{ minWidth: { md: '16vw', xs: '60vw' } }}
              flexGrow={1}
            >
              {holderChurches.length < MAX_PROJECT_HOLDERS &&
                allowEditProjectHolder && (
                  <>
                    <SearchChurch
                      setSelectingChurch={handleAddHolderChurch}
                      setOpenSearchNotFoundChurch={(isFound) =>
                        setOpenSearchNotFoundChurch(isFound)
                      }
                      excludedChurches={assignedChurchesID}
                    />

                    {openSearchNotFoundChurch && (
                      <Typography variant='subtitle1'>
                        ไม่มีรายชื่อคริสตจักรในพื้นที่ดังกล่าว
                        หรือท่านได้ทำการเลือกไปหมดแล้ว
                      </Typography>
                    )}
                    <Divider />
                  </>
                )}
              {holderChurches.map((c, i) => (
                <ChurchProfileCard
                  key={c.churchId}
                  churchId={c.churchId}
                  churchName={c.churchName}
                  isViewable={true}
                  openInNewTab={true}
                  onDelete={
                    allowEditProjectHolder && i >= 1
                      ? markChurchHolderToRemove(c)
                      : undefined
                  }
                />
              ))}
            </Stack>
            {nonZodErrors.projectHolderError && (
              <Typography variant='body1' color={COLOR.BUTTON_RED}>
                * กรุณาเลือกผู้จัดโครงการ
              </Typography>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack
          flexDirection='row'
          flexWrap='wrap'
          gap='16px'
          ref={projectContactPersonRef}
        >
          <Stack width='300px'>
            <span>
              <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
                *{' '}
              </Typography>
              <Typography display='inline' variant='h3'>
                ผู้ประสานงานโครงการ
              </Typography>
            </span>
            <Typography variant='subtitle1' color={COLOR.GRAY_600}>
              สูงสุด 2 ท่าน
            </Typography>
            <br />
            <List
              sx={{
                listStyleType: 'disc',
                pl: 4,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  padding: '2px',
                },
                color: COLOR.GRAY_600,
              }}
            >
              <ListItem>
                <Typography variant='subtitle1'>
                  สามารถเพิ่มได้เฉพาะสมาชิกที่ยืนยันอีเมลแล้วเท่านั้น
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant='subtitle1'>
                  ค้นหาด้วยชื่อจริง หรืออีเมลของสมาชิก เช่น ปรียา,
                  preeya@namjai.com
                </Typography>
              </ListItem>
            </List>
          </Stack>
          <Stack gap='24px'>
            <MemberSection
              members={contactPersons}
              showSideTitle={false}
              title={
                assignedChurchesID.length > 0
                  ? 'ค้นหาผู้ประสานงาน'
                  : 'กรุณาเลือกผู้จัดโครงการก่อน'
              }
              position='CP'
              addMember={handleAddContactPerson}
              deleteMember={handleDeleteContactPerson}
              maxMembers={MAX_CONTACT_PERSONS}
              excludedMembersID={assignedMembersID}
              churchIds={assignedChurchesID}
              customNoOptionsText='ไม่พบสมาชิกจากคริสตจักรที่เลือก'
              disabled={assignedChurchesID.length === 0}
              adminView={adminView}
            />
            {nonZodErrors.contactPersonError && (
              <Typography variant='body1' color={COLOR.BUTTON_RED}>
                * กรุณาเลือกผู้ประสานงานโครงการ
              </Typography>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection={'row-reverse'}>
          <Button
            variant='outlined'
            type='submit'
            fullWidth={false}
            form='campaign-proposal-form-1'
          >
            <Typography variant='h6'>{`ถัดไป >`}</Typography>
          </Button>
        </Stack>
      </Stack>
      <ModalWithButton
        id='confirm-remove-holder-church'
        type='ERROR'
        title='ยืนยันการนำผู้จัดโครงการออก'
        description={`ท่านต้องการนำ ${churchToRemove?.churchName} ออกจากผู้จัดโครงการหรือไม่ ท่านต้องทำการเลือกผู้ประสานงานโครงการใหม่อีกครั้ง`}
        confirmBtnText='ยืนยัน'
        cancleBtnText='ยกเลิก '
        open={openDeleteHolderChurch}
        onClose={() => {
          setOpenDeleteHolderChurch(false)
          setChurchToRemove(null)
        }}
        handleClickConfirm={() => {
          handleRemoveHolderChurch(churchToRemove?.churchId || '')
          setOpenDeleteHolderChurch(false)
          setChurchToRemove(null)
        }}
      />
    </Box>
  )
}

export default CampaignProposalForm1
