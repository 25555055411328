import { Box, Container, ThemeProvider, Typography } from '@mui/material'
import { theme } from '../../constants/theme'
import Navbar from '../../components/Navbar/Navbar'

import { useParams } from 'react-router-dom'
import CampaignProgressForm from '../../components/Campaign/CampaignProgress/CapaignProgressForm'

export default function CreateCampaignProgressUpdate() {
  const param = useParams()
  const campaignId = param.campaignId

  return (
    <ThemeProvider theme={theme}>
      {' '}
      <Navbar />
      <Container>
        <Box>
          <Box textAlign={'center'}>
            <Typography variant='h2' paddingTop={'24px'}>
              ส่งรายงานความคืบหน้าโครงการ
            </Typography>
            <Box height={'20px'}></Box>
            <CampaignProgressForm type={'CREATE'} id={campaignId!} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
