import { Stack, TableCell, TableRow, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import StatusBar from '../StatusBar'

import { generateAdminChurchReceiptRoute } from '../../../utils/routeGenerator'

type ChurchWeeklyEarningListItemProps = {
  churchId: string
  startDate: string
  endDate: string
  amount: number
  status: string
}

export default function ChurchWeeklyEarningList({
  churchId,
  startDate,
  endDate,
  amount,
  status,
}: ChurchWeeklyEarningListItemProps) {
  return (
    <TableRow key={churchId}>
      <TableCell sx={{ flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Column',
            flexWrap: 'wrap',
            alignItems: 'left',
            width: '80vm',
          }}
        >
          <Typography variant='subtitle1' color={'text.primary'}>
            {startDate}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Column',
            flexWrap: 'wrap',
            alignItems: 'left',
            width: '80vm',
          }}
        >
          <Typography variant='subtitle1' color={'text.primary'}>
            {endDate}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Column',
            flexWrap: 'wrap',
            alignItems: 'left',
            width: '80vm',
          }}
        >
          <Typography variant='subtitle1' color={'text.primary'}>
            {amount}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell sx={{ flexWrap: 'wrap' }} width={'40px'}>
        <Stack width={'100%'} flexDirection='row' gap={'24px'}>
          <StatusBar variant={status}></StatusBar>
          <Link to={generateAdminChurchReceiptRoute(churchId)}>
            <Typography
              component={'span'}
              variant='h6'
              sx={{
                color: '#0079FF',
                textDecoration: 'underline',
              }}
            >
              View
            </Typography>
          </Link>
        </Stack>
      </TableCell>
    </TableRow>
  )
}
