export default function appendHttpsIfMissing(url: string) {
  if (!url.trim()) {
    return url
  }

  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  } else {
    return `https://${url}`
  }
}
