import { ThemeProvider } from '@emotion/react'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Pagination,
  Stack,
  styled,
  Skeleton,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { theme } from '../../constants/theme'
import { UserData, Role } from '../../models/UserTypes'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import { useUser } from '../../services/UserContext'
import AuthService from '../../services/auth.service'
import StatusBar from '../../components/Admin/StatusBar'
import { useGet } from '../../hooks/useFetch'
import { TotalEarning, WeeklyEarning } from '../../models/admin/EarningStat'
import { ChurchDetail } from '../../models/ChurchDetail'
import { generateViewChurchAdminRoute } from '../../utils/routeGenerator'
import EarningList from '../../components/Admin/EarningList'
import formatCompactNumber from '../../utils/formatNumber'

export default function WeeklyEarningList() {
  const token = AuthService.getTokenUser()
  const tokenChecked = useAuthMiddleware()
  const navigate = useNavigate()

  const param = useParams()
  const churchId = param.churchId

  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()
  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const StyledTableHeadCell = styled(TableCell)({
    paddingTop: 0,
    paddingBottom: 0,
  })

  // get all information
  const [total, totalLoading] = useGet<TotalEarning>({
    url: `/confirm-earnings/${churchId}/total`,
    jwtToken: token ? token : '',
    autoFetch: true,
  })

  const [churchDetail, churchDetailLoading] = useGet<ChurchDetail>({
    url: `/churches/${churchId}/church-profile`,
    autoFetch: true,
  })

  const [page, setPage] = useState(1)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const [earningList, loading] = useGet<WeeklyEarning>({
    url: `/confirm-earnings/${churchId}/list`,
    ...(token && { jwtToken: token }),
    autoFetch: true,
    queryParams: {
      page: page,
    },
  })

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h1' paddingY={3}>
                  Confirm Earning
                </Typography>

                <Box
                  width={'95%'}
                  border={1}
                  bgcolor={'#FFFFFF'}
                  borderColor={'#C7C7C7'}
                  borderRadius={'40px'}
                  paddingX={'40px'}
                  paddingY={'20px'}
                >
                  <Stack flexDirection={'column'} width={'100%'} gap={'8px'}>
                    <Stack
                      flexDirection={'row'}
                      gap={'24px'}
                      alignItems={'center'}
                    >
                      <Typography variant='h6'> status: </Typography>
                      <StatusBar
                        variant={totalLoading ? 'UNCONFIRMED' : total?.status!}
                      ></StatusBar>
                    </Stack>
                    <Typography
                      variant='h3'
                      color={'primary'}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() =>
                        navigate(generateViewChurchAdminRoute(churchId!))
                      }
                    >
                      {churchDetailLoading ? (
                        <Skeleton
                          variant='rectangular'
                          width={'200px'}
                          height={'45px'}
                        />
                      ) : (
                        churchDetail?.churchName
                      )}
                    </Typography>

                    <Typography variant='h3'>
                      Total Earning:{' '}
                      {totalLoading ? '0' : formatCompactNumber(total?.total!)}{' '}
                      บาท
                    </Typography>
                  </Stack>
                </Box>

                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  marginY={'20px'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '95%',
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHeadCell
                            sx={{ width: 300, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Start Date
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 300, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              End Date
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 450, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Amount (บาท)
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Status
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='right'
                            sx={{ width: 200, flexWrap: 'wrap' }}
                          >
                            <Typography
                              variant='h6'
                              color={'text.primary'}
                              // style={{ visibility: 'hidden' }}
                            >
                              View
                            </Typography>
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>

                      {!loading && (
                        <TableBody>
                          {earningList?.confirmEarnings.map((req) => (
                            <EarningList
                              churchId={churchId!}
                              id={req.id}
                              startDate={req.startDate}
                              endDate={req.endDate}
                              total={req.total}
                              status={req.status}
                            />
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {loading && (
                    <Box
                      paddingY={2}
                      width={'100%'}
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}
                  <Pagination
                    count={earningList?.totalPages}
                    page={page}
                    onChange={handleChange}
                    sx={{ paddingTop: '5px' }}
                  />
                </Box>
              </Stack>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </ThemeProvider>
      )
    }
  }
}
