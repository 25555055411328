import { Divider, Popover, Typography, Button } from '@mui/material'
import { COLOR } from '../../constants/color-constant'
import { ROUTE } from '../../constants/route-constant'
import { Role } from '../../models/UserTypes'
import { Link } from 'react-router-dom'

import { generateChurchRoute } from '../../utils/routeGenerator'
import { ADMIN_ROUTE } from '../../constants/admin-route-constant'

type NavbarUserPopoverProps = {
  open: boolean
  username: string
  userRole: Role
  churchId: string
  popoverAnchor: HTMLButtonElement | null
  handlePopoverClose: () => void
  handleLogout: () => void
}

function NavbarUserPopover({
  open,
  username,
  userRole,
  churchId,
  popoverAnchor,
  handlePopoverClose,
  handleLogout,
}: NavbarUserPopoverProps) {
  const renderPopoverContent = () => {
    if (
      userRole === 'USER' ||
      userRole === 'ADMIN' ||
      userRole === 'SUPER_ADMIN'
    ) {
      return (
        <div style={{ backgroundColor: COLOR.BACKGROUD_MAIN }}>
          <Typography variant='subtitle1' sx={{ p: 1, color: COLOR.PRIMARY_1 }}>
            {username}
          </Typography>
          <Link to={ROUTE.USER} className='hover:text-[#0079FF]'>
            <Typography variant='h6' sx={{ p: 1 }}>
              บัญชีของฉัน
            </Typography>
          </Link>
          <Divider variant='middle' />
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <Link to={ADMIN_ROUTE.DASHBOARD} className='hover:text-[#0079FF]'>
              <Typography variant='h6' sx={{ p: 1 }}>
                กลับส่วนแอดมิน
              </Typography>
            </Link>
          ) : (
            <></>
          )}
          <Button
            onClick={handleLogout}
            variant='text'
            color='black'
            fullWidth={true}
            className='hover:text-[#0079FF]'
            sx={{ p: 1, justifyContent: 'start' }}
            disableRipple={true}
          >
            <Typography variant='h6' textAlign={'start'}>
              ออกจากระบบ
            </Typography>
          </Button>
        </div>
      )
    } else if (userRole === 'CHURCH') {
      return (
        <div style={{ backgroundColor: COLOR.BACKGROUD_MAIN }}>
          <Typography variant='subtitle1' sx={{ p: 1, color: COLOR.PRIMARY_1 }}>
            {username}
          </Typography>
          <Link
            to={generateChurchRoute(churchId)}
            className='hover:text-[#0079FF]'
          >
            <Typography variant='h6' sx={{ p: 1 }}>
              โบสถ์ของฉัน
            </Typography>
          </Link>
          <Link
            to={ROUTE.CREATE_CAMPAIGN_PROPOSAL}
            className='hover:text-[#0079FF]'
          >
            <Typography variant='h6' sx={{ p: 1 }}>
              สร้างโครงการ
            </Typography>
          </Link>
          <Link to={ROUTE.USER} className='hover:text-[#0079FF]'>
            <Typography variant='h6' sx={{ p: 1 }}>
              บัญชีของฉัน
            </Typography>
          </Link>
          <Divider variant='middle' />
          {/* TODO: logout */}
          <Button
            onClick={handleLogout}
            variant='text'
            color='black'
            fullWidth={true}
            className='hover:text-[#0079FF]'
            sx={{ p: 1, justifyContent: 'start' }}
            disableRipple={true}
          >
            <Typography variant='h6' textAlign={'start'}>
              ออกจากระบบ
            </Typography>
          </Button>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  return (
    <Popover
      open={open}
      anchorEl={popoverAnchor}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {renderPopoverContent()}
    </Popover>
  )
}

export default NavbarUserPopover
