import { Box, Typography } from '@mui/material'
import React from 'react'

interface AdminCommentSectionProps {
  comment: string
}

export default function AdminCommentSection(props: AdminCommentSectionProps) {
  const { comment } = props
  return (
    <Box
      bgcolor={'#E7F2FF'}
      borderRadius={'8px'}
      width={'100%'}
      textAlign={'start'}
      paddingX={'16px'}
      paddingY={'8px'}
    >
      <Typography variant='subtitle1'>
        ความคิดเห็นของแอดมิน: {comment}
      </Typography>
    </Box>
  )
}
