import { useState, useEffect, useCallback, useRef } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import isDeepEqual from 'fast-deep-equal/react'
import { useNavigate } from 'react-router-dom'
import { ROUTE } from '../constants/route-constant'

const API_KEY = process.env.REACT_APP_XKEY_API || ''
const baseURL = process.env.REACT_APP_BASE_API || ''

interface BaseProps {
  url: string
  autoFetch: boolean
  queryParams?: Record<string, any>
  jwtToken?: string
  autoNavigate?: boolean
}

type GetProps = {
  method: 'GET' | 'HEAD'
  data?: never
}

type PostProps<T> = {
  method?: 'POST' | 'DELETE' | 'PUT' | 'PATCH'
  data: T
}

type PutProps<T> = {
  method?: 'PUT'
  data: T
}

type DeleteProps = {
  method?: 'DELETE'
  data?: never
}

type PatchProps<T> = {
  method?: 'PATCH'
  data?: T
}

type ConditionalProps<T> =
  | GetProps
  | PostProps<T>
  | PutProps<T>
  | DeleteProps
  | PatchProps<T>

type Props<T> = BaseProps & ConditionalProps<T>

type FetchReturn<T> = [
  result: null | T,
  loading: boolean,
  refresh: () => void,
  statusCode: number,
]

const useFetch = <T>({
  url,
  method,
  autoFetch,
  queryParams,
  jwtToken,
  data,
  autoNavigate,
}: Props<T>): FetchReturn<T> => {
  const navigate = useNavigate()
  const [result, setResult] = useState<T | null>(null)
  const [loading, setLoading] = useState(false)
  const [statusCode, setStatusCode] = useState(-1)

  const dataRef = useRef(data)
  const queryParamsRef = useRef(queryParams)

  if (!isDeepEqual(dataRef.current, data)) {
    dataRef.current = data
  }

  if (!isDeepEqual(queryParamsRef.current, queryParams)) {
    queryParamsRef.current = queryParams
  }

  const fetchData = useCallback(async () => {
    setLoading(true)

    const config: AxiosRequestConfig = {
      url,
      method,
      baseURL,
      headers: { 'x-api-key': API_KEY },
      data,
      paramsSerializer: { indexes: null },
    }

    if (queryParams) {
      config.params = queryParams
    }

    if (jwtToken) {
      config.headers!.Authorization = `Bearer ${jwtToken}`
    }

    try {
      const response = await axios.request<T>(config)
      setResult(response.data)
      setStatusCode(response.status)
    } catch (error: any) {
      console.error('Error fetching data:', error)
      setResult(null)
      if (error.response) {
        const code = error.response.status
        setStatusCode(error.response.status)
        console.error('Response data:', error.response.data)
        if (autoNavigate === undefined || autoNavigate === true) {
          if (code === 404) {
            window.scrollTo(0, 0)
            navigate(ROUTE.NOT_FOUND)
          }
        }
        if (code === 409) {
          setResult(error.response.data)
        }
      } else {
        setStatusCode(500)
        console.error('Unknown error occurred. Request was not sent.')
      }
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRef.current, jwtToken, method, url, queryParamsRef.current])

  const refresh = () => {
    fetchData()
  }

  useEffect(() => {
    if (autoFetch) fetchData()
  }, [autoFetch, fetchData])

  return [result, loading, refresh, statusCode]
}

export function useGet<T>({
  url,
  autoFetch,
  queryParams,
  jwtToken,
  autoNavigate,
}: BaseProps): FetchReturn<T> {
  return useFetch({
    url,
    method: 'GET',
    autoFetch,
    queryParams,
    jwtToken,
    autoNavigate,
  })
}

export function usePost<T>({
  url,
  method = 'POST',
  autoFetch,
  queryParams,
  jwtToken,
  data,
}: BaseProps & PostProps<T>): FetchReturn<any> {
  return useFetch({
    url,
    method,
    autoFetch,
    queryParams,
    jwtToken,
    data,
  })
}

export function usePut<T>({
  url,
  autoFetch,
  queryParams,
  jwtToken,
  data,
}: BaseProps & PutProps<T>): FetchReturn<any> {
  return useFetch({
    url,
    method: 'PUT',
    autoFetch,
    queryParams,
    jwtToken,
    data,
  })
}

export function usePatch<T>({
  url,
  method = 'PATCH',
  autoFetch,
  queryParams,
  jwtToken,
  data,
}: BaseProps & PatchProps<T>): FetchReturn<any> {
  return useFetch({
    url,
    method,
    autoFetch,
    queryParams,
    jwtToken,
    data,
  })
}

export function useDelete({
  url,
  method = 'DELETE',
  autoFetch,
  queryParams,
  jwtToken,
  data,
}: BaseProps & DeleteProps): FetchReturn<any> {
  return useFetch({
    url,
    method,
    autoFetch,
    queryParams,
    jwtToken,
    data,
  })
}
