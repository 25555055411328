import { useCallback, useEffect, useRef } from 'react'
import { LuFile } from 'react-icons/lu'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'

import FileCard from '../../components/FileUpload/FileCard'
import { theme } from '../../constants/theme'
import React from 'react'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'

const ACCEPTED_EXTENSION = '.jpg, .jpeg, .pdf'
const MAX_FILE_SIZE = 3 * 1024 * 1024 // 3 MB

type Prop = {
  initialFiles?: AttachmentMetaData[]
  files: File[]
  onChange: ({
    files,
    uploadedFilesMetadata,
  }: {
    files: File[]
    uploadedFilesMetadata: AttachmentMetaData[]
  }) => void
  setError: (isError: boolean) => void
  disabled?: boolean
}

function FileUpload({
  initialFiles,
  files,
  onChange,
  setError,
  disabled,
}: Prop) {
  const fileInputBreakPoint = useMediaQuery(theme.breakpoints.up('md'))
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const isFileSizeExceeded = useCallback(() => {
    const newFilesSize = files.reduce((acc, file) => file.size + acc, 0)
    const initialFilesSize = initialFiles
      ? initialFiles.reduce((acc, file) => file.fileSize + acc, 0) * 0.001
      : 0
    const isExceeded = newFilesSize + initialFilesSize >= MAX_FILE_SIZE
    return isExceeded
  }, [files, initialFiles])

  useEffect(() => {
    setError(isFileSizeExceeded())
  }, [isFileSizeExceeded, setError])

  // Below is from https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
  const fileDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    if (disabled) return

    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault()
    const newFiles: File[] = []

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      ;[...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          const file = item.getAsFile()
          if (file) {
            newFiles.push(file)
          }
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s), in case the browser
      // doesn't support the above interface
      newFiles.push(...e.dataTransfer.files)
    }

    processFiles(newFiles)
  }

  const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      processFiles(Array.from(e.target.files))
    }
  }

  const handleDeleteFile = (index: number) => {
    onChange({
      files: files.filter((file, i) => i !== index),
      uploadedFilesMetadata: initialFiles ? initialFiles : [],
    })
  }

  const handleDeleteInitialFiles = (index: number) => {
    if (initialFiles) {
      onChange({
        files: files,
        uploadedFilesMetadata: initialFiles.filter((file, i) => i !== index),
      })
    }
  }

  const processFiles = (newFiles: File[]) => {
    // Reset file input to allow re-selecting the same file
    if (fileInputRef !== null && fileInputRef.current !== null) {
      fileInputRef.current.value = ''
    }

    // Filter unsupported files
    const filteredFiles = newFiles.filter((file) =>
      ACCEPTED_EXTENSION.includes(
        file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
      )
    )

    if (filteredFiles.length < newFiles.length) {
      alert('ไม่สามารถเลือกไฟล์ดังกล่าวได้')
    }

    onChange({
      files: [...files, ...filteredFiles],
      uploadedFilesMetadata: initialFiles ? initialFiles : [],
    })
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap={{ xs: 'wrap', md: 'nowrap' }}
      gap='20px'
    >
      <Box
        display='flex'
        flexDirection='column'
        width={files.length > 0 && fileInputBreakPoint ? '50%' : '100%'}
        minWidth='50%'
        gap='10px'
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '20px',
            outline: '2px dashed black',
            width: '100%',
            height: '100%',
          }}
          onDrop={fileDropHandler}
          onDragOver={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <label
            htmlFor='file-upload'
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              cursor: disabled ? 'default' : 'pointer',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <input
              disabled={disabled}
              id='file-upload'
              type='file'
              accept={ACCEPTED_EXTENSION}
              multiple={true}
              onChange={handleAddFile}
              className='hidden'
              ref={fileInputRef}
            />
            <LuFile size={100} />
            <br />
            <Typography variant='h5'>กดเพื่ออัปโหลดเอกสารของคุณ</Typography>
            <Typography variant='body1' align='center'>
              เฉพาะไฟล์นามสกุล Jpeg และ PDF ที่มีขนาดแต่ละไฟล์ไม่เกิน 3 MB.
              เท่านั้น
            </Typography>
          </label>
        </div>
        {isFileSizeExceeded() && (
          <Typography variant='subtitle1' color='red'>
            ขออภัย การอัปโหลดผิดพลาด
          </Typography>
        )}
      </Box>
      <Stack
        gap='20px'
        flexDirection='row'
        flexWrap='wrap'
        alignItems='flex-start'
        alignContent='flex-start'
      >
        {initialFiles &&
          initialFiles.map((file, i) => (
            <FileCard
              key={file.fileName}
              id={i}
              fileName={file.fileName.slice(file.fileName.indexOf('_') + 1)}
              fileSize={file.fileSize}
              objectUrl={file.url}
              onDelete={handleDeleteInitialFiles}
              {...(disabled && { variant: 'VIEW' })}
            />
          ))}
        {files.map((file, i) => (
          <FileCard
            key={file.name}
            id={i}
            fileName={file.name}
            fileSize={file.size}
            objectUrl={URL.createObjectURL(file)}
            onDelete={handleDeleteFile}
            {...(disabled && { variant: 'VIEW' })}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default FileUpload
