import {
  Box,
  Card,
  Typography,
  Container,
  Tabs,
  Stack,
  Grid,
  TableRow,
  TableCell,
  useMediaQuery,
  Tab,
} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import {
  ActivityPlans,
  BudgetPlans,
  Detail,
  FundingDetail,
  ImagesUrl,
  Overview,
} from '../models/campaign'

import Payment from '../components/PaymentSection'

import formatDate from '../utils/formatDate'
import { useEffect, useState } from 'react'
import { useGet } from '../hooks/useFetch'
import { CampaignProgressDetail } from '../models/CampaignProgress/CampaignProgressDetail'
import ResponsiveAppBar from '../components/Admin/ResponsiveAppBar'
import { COLOR } from '../constants/color-constant'
import { TabContext } from '@mui/lab'
import CampaignProgressDisplay from '../components/Campaign/CampaignProgress/CampaignProgressDisplay'
import PersonCard from '../components/PersonCard'

import { useUser } from '../services/UserContext'
import { UserData } from '../models/UserTypes'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

import ActivityPlansTable from '../components/Campaign/ActivityPlansTable'
import NowDonationCard from '../components/Campaign/DonationCard'
import AdminCardtoEdit from '../components/Admin/Campaign/AdminCardToEdit'
import BudgetPlansTable from '../components/Campaign/BudgetPlansTable'
import { getAreaHolisticTranslation } from '../utils/getThaiTranslation'
import CircularProgress from '@mui/material/CircularProgress'
interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3, px: 5 }}>{children}</Box>}
    </div>
  )
}

export default function CampaignPage() {
  const location = useLocation()
  const userData: UserData | null = useUser().userData
  const [isAdminView, setIsAdminView] = useState<boolean>(false)
  const params = useParams()
  const campaignId = params.campaignId
  const [currentTimestamp, setCurrentTimestamp] = useState(0)
  const [openPaymentSection, setOpenPaymentSection] = useState<boolean>(true)
  const isLargeScreen = useMediaQuery('(min-width:900px)')
  useEffect(() => {
    if (
      userData?.userrole.userRole === 'ADMIN' ||
      userData?.userrole.userRole === 'SUPER_ADMIN'
    ) {
      setIsAdminView(true)
    } else {
      setIsAdminView(false)
    }
  }, [userData])
  useEffect(() => {
    setCurrentTimestamp(Date.now())

    const handleHashChange = () => {
      if (location.hash) {
        setOpenPaymentSection(true)
        const id = location.hash.substring(1)
        const anchor = document.getElementById(id)
        setTimeout(() => {
          anchor?.scrollIntoView({ behavior: 'smooth' })
        }, 500)
      } else {
        setOpenPaymentSection(false)
      }
    }

    handleHashChange()
  }, [location])

  const [overview] = useGet<Overview>({
    url: `/campaigns/${campaignId}/overview`,
    autoFetch: true,
  })
  const [detail] = useGet<Detail>({
    url: `/campaigns/${campaignId}/detail`,
    autoFetch: true,
  })
  const [fundingDetail] = useGet<FundingDetail>({
    url: `/campaigns/${campaignId}/funding-detail`,
    autoFetch: true,
  })
  const [images] = useGet<ImagesUrl>({
    url: `/campaigns/${campaignId}/images`,
    autoFetch: true,
  })
  const [activityPlans] = useGet<ActivityPlans[]>({
    url: `/campaigns/${campaignId}/activity-plans`,
    autoFetch: true,
  })
  const [budgetPlans] = useGet<BudgetPlans[]>({
    url: `/campaigns/${campaignId}/budget-plans`,
    autoFetch: true,
  })

  const [camProgress] = useGet<CampaignProgressDetail[]>({
    url: `/campaigns/${campaignId}/progress`,
    autoFetch: true,
  })

  const [tabPage, setTabPage] = useState('0')

  const handleTabPageChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabPage(newValue)
  }
  if (!detail) {
    return (
      <>
        <div className='flex h-screen flex-grow items-center justify-center'>
          <CircularProgress />
        </div>
      </>
    )
  }
  return (
    <>
      <div className='flex min-h-screen flex-col'>
        {isAdminView ? <ResponsiveAppBar /> : <Navbar />}
        <Container maxWidth='xl' sx={{ mt: 5 }}>
          <Box width={'100%'}>
            {!isLargeScreen && isAdminView && campaignId && (
              <AdminCardtoEdit
                campaignId={campaignId}
                camProgressCount={camProgress?.reduce(
                  (count, item) => count + (item.index ? 1 : 0),
                  0
                )}
              />
            )}
            <Stack
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'stretch',
                justifyContent: 'space-around',
              }}
            >
              <Box
                sx={{
                  px: '4%',
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '100%', md: '60%', lg: '70%' },
                }}
              >
                <Typography
                  sx={{ mt: 2 }}
                  variant='h1'
                  color={COLOR.SECOUNDARY_FIRST_1}
                >
                  {detail?.name}
                </Typography>
                <Typography color={COLOR.PRIMARY_1} variant='h6'>
                  {detail?.areaHolistic
                    .map((area) => getAreaHolisticTranslation(area))
                    .join(', ')}
                </Typography>
                <Typography variant='body1' sx={{ mt: 1 }}>
                  ขั้นตอนการทำงาน:
                </Typography>
                <Typography variant='body1' sx={{ mb: 5, px: 5 }}>
                  {overview?.workProcess}
                </Typography>

                <img
                  loading='lazy'
                  draggable={false}
                  alt='1'
                  className='img-hover h-[500px] object-cover'
                  src={detail?.coverImageUrl}
                />
                <Typography sx={{ mt: 5, mx: 1 }} variant='subtitle1'>
                  {/* <pre style={{ fontFamily: 'inherit' }}> */}
                  {detail?.description}
                  {/* </pre> */}
                </Typography>
                <Typography
                  sx={{ m: 5 }}
                  variant='h4'
                  color={COLOR.SECOUNDARY_FIRST_1}
                >
                  {detail?.quote}
                </Typography>
                <Typography variant='h6'>
                  ระยะเวลาของโครงการ: {formatDate(detail?.startDate ?? 0)} ถึง{' '}
                  {formatDate(detail?.endDate ?? 0)}
                </Typography>
                <Typography variant='h6'>
                  การพัฒนาเด็ก:{' '}
                  {detail?.areaHolistic
                    .map((area) => getAreaHolisticTranslation(area))
                    .join(', ')}
                </Typography>
              </Box>

              <Box
                sx={{
                  width: { xs: '100%', md: '40%', lg: '30%' },
                  p: '20px',
                  mt: 5,
                  gap: '20px',
                }}
              >
                {isLargeScreen && isAdminView && campaignId && (
                  <AdminCardtoEdit
                    campaignId={campaignId}
                    camProgressCount={camProgress?.reduce(
                      (count, item) => count + (item.index ? 1 : 0),
                      0
                    )}
                  />
                )}
                <NowDonationCard
                  fundingDetail={fundingDetail}
                  currentTimestamp={currentTimestamp}
                  isAdminView={isAdminView}
                />
              </Box>
            </Stack>
          </Box>
          <Container maxWidth='xl' sx={{ mt: 5 }}>
            <Box sx={{ width: '100%' }}>
              <TabContext value={tabPage}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tabPage}
                    onChange={handleTabPageChange}
                    aria-label='basic tabs example'
                    centered
                    sx={{ display: 'flex' }}
                  >
                    <Tab
                      sx={{ width: '20%', fontSize: '14px' }}
                      label='ภาพรวม'
                      value={'0'}
                    />
                    <Tab
                      sx={{ width: '20%', fontSize: '14px' }}
                      label='รูปภาพ'
                      value={'1'}
                    />
                    <Tab
                      sx={{ width: '20%', fontSize: '14px' }}
                      label='แผนงานพร้อมช่วงเวลาดำเนินงาน'
                      value={'2'}
                    />
                    <Tab
                      sx={{ width: '20%', fontSize: '14px' }}
                      label='แผนการเงิน'
                      value={'3'}
                    />
                    <Tab
                      sx={{ width: '20%', fontSize: '14px' }}
                      label='ความคืบหน้า'
                      value={'4'}
                    />
                  </Tabs>
                </Box>
                <Card
                  sx={{
                    // minHeight: '800px',
                    backgroundColor: COLOR.BACKGROUD_MAIN,
                  }}
                >
                  <CustomTabPanel value={tabPage} index={'0'}>
                    <Box className='overView'>
                      <Typography variant='h4' color={COLOR.SECOUNDARY_FIRST_1}>
                        ปัญหา
                      </Typography>

                      <Typography sx={{ px: 5 }} variant='body1'>
                        {overview?.background}
                      </Typography>
                      <Typography variant='h4' color={COLOR.SECOUNDARY_FIRST_1}>
                        ขั้นตอนการทำงาน
                      </Typography>
                      <Typography sx={{ px: 5 }} variant='body1'>
                        {overview?.workProcess}
                      </Typography>
                      <Typography variant='h4' color={COLOR.SECOUNDARY_FIRST_1}>
                        กลุ่มเป้าหมาย
                      </Typography>
                      {overview?.targetGroup.map((target, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align='left'
                            sx={{ width: '50%', flexWrap: 'wrap' }}
                          >
                            <Typography variant='body1'>{`${target.age}  `}</Typography>
                          </TableCell>
                          <TableCell
                            align='left'
                            sx={{ width: '30%', flexWrap: 'wrap' }}
                          >
                            <Typography variant='body1'>{` ${
                              target.femaleCount + target.maleCount
                            } คน`}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabPage} index={'1'}>
                    <Box className='Image'>
                      <div>
                        <Typography variant='h4'>รูปภาพ:</Typography>

                        <Grid
                          container
                          spacing={{ xs: 2, md: 3 }}
                          columns={{ xs: 2, sm: 8, md: 12 }}
                        >
                          {images?.imagesUrl.map((imageUrl, index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                              <Card>
                                <img
                                  className='img-hover  '
                                  key={index}
                                  src={imageUrl}
                                  alt={`Image ${index}`}
                                />
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabPage} index={'2'}>
                    <Box className='activity detail'>
                      <ActivityPlansTable
                        data={activityPlans}
                        isLargeScreen={isLargeScreen}
                      />
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabPage} index={'3'}>
                    <Box className='funding detail'>
                      <BudgetPlansTable
                        data={budgetPlans}
                        isLargeScreen={isLargeScreen}
                      />
                    </Box>
                  </CustomTabPanel>
                  <CustomTabPanel value={tabPage} index={'4'}>
                    <Box className='progress'>
                      <CampaignProgressDisplay
                        data={camProgress}
                        detailContact={detail}
                      />
                    </Box>
                  </CustomTabPanel>
                </Card>
              </TabContext>
            </Box>

            <Box sx={{ width: '100%', my: '20px' }}>
              <Typography variant='h2' color={COLOR.PRIMARY_1}>
                ผู้ประสานงาน:
              </Typography>

              <Grid
                container
                spacing={{ xs: 2, md: 5 }}
                columns={{ xs: 2, md: 8, lg: 12 }}
              >
                {detail?.contactPerson.map((person, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <Box sx={{ width: '100%' }}>
                      <PersonCard
                        name={person.firstName + ' ' + person.lastName}
                        phone={person.phoneNumber ?? '-'}
                        email={person.email ?? '-'}
                      ></PersonCard>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Container>

        <div id={'JOIN'}>
          {openPaymentSection && (
            <div className='flex min-h-screen flex-col'>
              <Payment
                campaignName={detail ? detail.name : ''}
                campaignId={campaignId!}
                isFunding={
                  fundingDetail
                    ? fundingDetail.endDate - currentTimestamp >= 0
                    : true
                }
              />
            </div>
          )}
        </div>
      </div>
      {isAdminView ? null : <Footer />}
    </>
  )
}
