import { CircularProgress } from '@mui/material'

function ImageLoading({ progress }: { progress: number }) {
  return (
    <div className='relative flex aspect-square items-center justify-center bg-zinc-300'>
      <CircularProgress
        className='absolute'
        sx={{ color: '#8e8e8e' }}
        variant='determinate'
        size={48}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant='determinate'
        size={48}
        thickness={4}
        value={progress}
      />
    </div>
  )
}

export default ImageLoading
