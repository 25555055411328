/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { AttachmentMetaData } from '../../../models/AttachmentMetaData'
import { grey } from '@mui/material/colors'
import { TypeOf, z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { createContext, useEffect, useRef, useState } from 'react'
import { Detail } from '../../../models/campaign'
import {
  useDelete,
  useGet,
  usePatch,
  usePost,
  usePut,
} from '../../../hooks/useFetch'
import { GetCampaignProposalData } from '../../../models/CampaignProposal/GetCampaignProposalData'
import { COLOR } from '../../../constants/color-constant'
import { Delete } from '@mui/icons-material'
import TextareaWithCharLength from '../../Textarea/TextareaWithCharLength'
import { ReactComponent as AreaEducation } from '../../../assets/icons/area_education.svg'
import { ReactComponent as AreaChild } from '../../../assets/icons/area_child.svg'
import { ReactComponent as AreaInfra } from '../../../assets/icons/area_infra.svg'
import { ReactComponent as AreaYouth } from '../../../assets/icons/area_youth.svg'
import { ReactComponent as AreaWell } from '../../../assets/icons/area_well.svg'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ImageUpload from '../../ImageUpload/ImageUpload'
import AuthService from '../../../services/auth.service'
import { CampaignProgressFormModel } from '../../../models/CampaignProgress/CampaignProgressFormModel'
import ModalWithButton from '../../Modal/ModalWithButton'
import ModalNotification from '../../Modal/ModalNotification'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../../../services/UserContext'
import { Role, UserData } from '../../../models/UserTypes'
import {
  generateViewCampaignAdminRoute,
  generateViewCampaignRoute,
} from '../../../utils/routeGenerator'
import AdminCommentSection from '../../ChurchProposal/AdminCommentSection'
import ModalFillReason from '../../Modal/ModalFillReason'
import axios from 'axios'

//text field max length len
const MAX_LENGTH = 512

export const ProgrssFormContext = createContext<any>([])

const CampaignProgressUpdateFormSchema = z.object({
  progressDetails: z.array(
    z
      .object({
        startDate: z.custom<Dayjs>(),
        endDate: z.custom<Dayjs>(),
        activity: z.string().min(1, { message: '*ต้องกรอก' }),
        outcome: z.string().min(1, { message: '*ต้องกรอก' }),
        expenses: z.number().refine((n) => n > 0),
      })
      .refine(
        (data) => {
          if (!data.startDate || !data.endDate) return true
          return data.endDate.isAfter(data.startDate)
        },
        {
          message: '* วันที่สิ้นสุด ต้องมาทีหลัง วันที่เริ่มต้น',
          path: ['endDate'],
        }
      )
  ),
})

type CampaignProgressUpdateFormInput = TypeOf<
  typeof CampaignProgressUpdateFormSchema
>

interface CampaignProgressUpdateFormProps {
  type: 'CREATE' | 'EDIT' | 'EDIT-PROGRESS'
  status?: string
  id: string
  initialData?: any
  progressIndex?: string
  uploadedImagesMetadata?: AttachmentMetaData[]
  onClickSent?: () => void
}

function getHolistic(holistic: string) {
  if (holistic === 'EDUCATION') {
    return (
      <Stack flexDirection={'row'} gap={'8px'} justifyItems={'center'}>
        <AreaEducation width='30px' height='30px' color={'#0079FF'} />
        <Typography variant='h6' color={'primary'}>
          {holistic}
        </Typography>
      </Stack>
    )
  } else if (holistic === 'CHILD_SAFEGUARDING') {
    return (
      <Stack flexDirection={'row'} gap={'8px'}>
        <AreaChild width='30px' height='30px' />
        <Typography variant='h6'>{holistic}</Typography>
      </Stack>
    )
  } else if (holistic === 'YOUTH_LEADERSHIP') {
    return (
      <Stack flexDirection={'row'} gap={'8px'}>
        <AreaYouth width='30px' height='30px' />
        <Typography variant='h6'>{holistic}</Typography>
      </Stack>
    )
  } else if (holistic === 'INFRASTRUCTURE') {
    return (
      <Stack flexDirection={'row'} gap={'8px'}>
        <AreaInfra width='30px' height='30px' />
        <Typography variant='h6'>{holistic}</Typography>
      </Stack>
    )
  } else {
    return (
      <Stack flexDirection={'row'} gap={'8px'}>
        <AreaWell width='30px' height='30px' />
        <Typography variant='h6'>{holistic}</Typography>
      </Stack>
    )
  }
}

function CampaignProgressForm({
  type,
  status,
  id,
  initialData,
  progressIndex,
  uploadedImagesMetadata: initialFiles,
}: CampaignProgressUpdateFormProps) {
  const MAX_PROGRESS_DETAILS = 20
  const token = AuthService.getTokenUser()
  const navigate = useNavigate()
  const [dataForm, setDataForm] = useState<CampaignProgressFormModel>()
  const [openModalConfirmCreate, setOpenModalConfirmCreate] =
    useState<boolean>(false)
  const [openModalCreateSuccess, setOpenModalCreateSuccess] =
    useState<boolean>(false)
  const [openModalSaveSuccess, setOpenModalSaveSuccess] =
    useState<boolean>(false)
  const [openModalConfirmEdit, setOpenModalConfirmEdit] =
    useState<boolean>(false)
  const [openModalEditSuccess, setOpenModalEditSuccess] =
    useState<boolean>(false)
  const [openModalSaveAdminSuccess, setOpenModalSaveAdminSuccess] =
    useState<boolean>(false)
  const [openModalConfirmAdmin, setOpenModalConfirmAdmin] =
    useState<boolean>(false)
  const [openModalConfirmAdminSuccess, setOpenModalConfirmAdminSuccess] =
    useState<boolean>(false)
  const [openModalFixAdmin, setOpenModalFixAdmin] = useState<boolean>(false)
  const [openModalFixAdminSuccess, setOpenModalFixAdminSuccess] =
    useState<boolean>(false)
  const [requestFixProgressUpdateLoading, setRequestFixProgressUpdateLoading] =
    useState<boolean>(false)

  // edit campaign progress
  const [openModalSaveEditProgressAdmin, setOpenModalSaveEditProgressAdmin] =
    useState<boolean>(false)
  const [
    openModalSaveEditProgressAdminSuccess,
    setOpenModalSaveEditProgressAdminSuccess,
  ] = useState<boolean>(false)
  const [
    openModalDeleteEditProgressAdmin,
    setOpenModalDeleteEditProgressAdmin,
  ] = useState<boolean>(false)
  const [
    openModalDeleteEditProgressAdminSuccess,
    setOpenModalDeleteEditProgressAdminSuccess,
  ] = useState<boolean>(false)
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState<boolean>(false)
  const [editType, setEditType] = useState<string>('')

  const userData: UserData | null = useUser().userData
  const [churchId, setChurchId] = useState('')
  const [userRole, setUserRole] = useState<Role>('GUEST')
  const [typeSubmit, setTypeSubmit] = useState('')
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  useEffect(() => {
    if (userData) {
      const role = userData.userrole.userRole
      setUserRole(role)
      if (role === 'CHURCH' && userData.userrole.churchId) {
        setChurchId(userData.userrole.churchId)
      }
    }
  }, [userData])

  const submitHandler = (values: CampaignProgressUpdateFormInput) => {
    if (typeSubmit === 'CREATE') {
      setOpenModalConfirmCreate(true)
    }

    if (typeSubmit === 'SAVE') {
      setEditType('SAVE')
      prepareDate()
    }

    if (typeSubmit === 'RESUBMIT') {
      setOpenModalConfirmEdit(true)
    }

    if (typeSubmit === 'ADMIN_SAVE') {
      setEditType('ADMIN_SAVE')
      prepareDate()
    }

    if (typeSubmit === 'ADMIN_CONFIRM') {
      setOpenModalConfirmAdmin(true)
    }

    if (typeSubmit === 'ADMIN_FIX') {
      setOpenModalFixAdmin(true)
    }

    if (typeSubmit === 'EDIT_SAVE') {
      setOpenModalSaveEditProgressAdmin(true)
    }

    if (typeSubmit === 'EDIT_DELETE') {
      setOpenModalDeleteEditProgressAdmin(true)
    }
  }

  const prepareDate = async () => {
    const imageUrls = images.map((img) => img.url)

    const combineData = {
      ...getValues(),
      imageUrls: [...imageUrls, ...uploadedImages.map((img) => img.url)],
      progressDetails: getValues().progressDetails.map((p) => ({
        ...p,
        startDate: p.startDate.valueOf(),
        endDate: p.endDate.valueOf(),
      })),
    }

    setDataForm(combineData)
  }

  // create campiagn request
  const [
    createProgressUpdate,
    createProgressUpdateLoading,
    createProgressUpdateReq,
    createProgressUpdateStatusCode,
  ] = usePost<any>({
    url: `/campaigns/${id}/propose-update`,
    autoFetch: false,
    data: dataForm,
    jwtToken: token!,
  })

  useEffect(() => {
    if (createProgressUpdateLoading) {
      setOpenModalConfirmCreate(false)
    }
  }, [createProgressUpdateLoading])

  // save campiagn request
  const [
    editProgressUpdate,
    editProgressUpdateLoading,
    editProgressUpdateReq,
    editProgressUpdateStatusCode,
  ] = usePut<any>({
    url: `/campaign-requests/${id}/user-save-campaign-update`,
    autoFetch: false,
    data: dataForm,
    jwtToken: token!,
  })

  // resubmit campiagn request
  const [
    resubmitProgressUpdate,
    resubmitProgressUpdateLoading,
    resubmitProgressUpdateReq,
    resubmitProgressUpdateStatusCode,
  ] = usePost<any>({
    url: `/campaign-requests/${id}/resubmit-campaign-update-request`,
    autoFetch: false,
    jwtToken: token!,
    data: dataForm,
  })

  // save campaign progress by admin
  const [
    saveProgressUpdateAdmin,
    saveProgressUpdateAdminLoading,
    saveProgressUpdateAdminReq,
    saveProgressUpdateAdminStatusCode,
  ] = usePut<any>({
    url: `/campaign-requests/${id}/admin-save-campaign-update`,
    autoFetch: false,
    data: dataForm,
    jwtToken: token!,
  })

  // confirm campiagn progress by admin
  const [
    confirmProgressAdminUpdate,
    confirmProgressUpdateAdminLoading,
    confirmProgressUpdateAdminReq,
    confirmProgressUpdateAdminStatusCode,
  ] = usePatch<any>({
    url: `/campaign-requests/${id}/approve`,
    autoFetch: false,
    jwtToken: token!,
  })

  // save edit specific campaign progress by admin
  const [
    saveEditProgressAdmin,
    saveEditProgressAdminLoading,
    saveEditProgressAdminReq,
    saveEditProgressAdminStatusCode,
  ] = usePut<any>({
    url: `/campaigns/${id}/progress/${progressIndex}`,
    autoFetch: false,
    data: dataForm,
    jwtToken: token!,
  })

  // delete specific campaign progress by admin
  const [
    deleteProgressAdmin,
    deleteProgressAdminLoading,
    deleteProgressAdminReq,
    deleteProgressAdminStatusCode,
  ] = useDelete({
    url: `/campaigns/${id}/progress/${progressIndex}`,
    autoFetch: false,
    jwtToken: token!,
  })

  // request fix campaign progress by admin
  const handleClickConfirmNeedfix = async (data: string) => {
    setOpenModalFixAdmin(false)
    const comment = {
      comment: data,
    }
    try {
      setRequestFixProgressUpdateLoading(true)
      const response = await axios.patch(
        `${API_URL}/campaign-requests/${id}/request-fix`,
        comment,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )
      // Handle a successful response (e.g., display a success message)
      setOpenModalFixAdmin(false)
      setOpenModalFixAdminSuccess(true)
      setRequestFixProgressUpdateLoading(false)
    } catch (error) {
      // Handle errors (e.g., display an error message)
      setRequestFixProgressUpdateLoading(false)
      setOpenErrorSnackBar(true)
    }
  }

  useEffect(() => {
    if (resubmitProgressUpdate) {
      setOpenModalConfirmEdit(false)
    }
  }, [resubmitProgressUpdate])

  useEffect(() => {
    if (dataForm) {
      if (!errors || type === 'CREATE') {
        createProgressUpdateReq()
      }

      if (!errors || type === 'EDIT') {
        if (editType === 'SAVE') {
          editProgressUpdateReq()
        } else if (editType === 'ADMIN_SAVE' || editType === 'ADMIN_CONFIRM') {
          saveProgressUpdateAdminReq()
        } else {
          resubmitProgressUpdateReq()
        }
      }

      if (!errors || type === 'EDIT-PROGRESS') {
        saveEditProgressAdminReq()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm, editType])

  useEffect(() => {
    if (
      createProgressUpdateStatusCode === -1 &&
      resubmitProgressUpdateStatusCode === -1 &&
      editProgressUpdateStatusCode === -1 &&
      confirmProgressUpdateAdminStatusCode === -1 &&
      saveProgressUpdateAdminStatusCode === -1 &&
      deleteProgressAdminStatusCode === -1 &&
      saveEditProgressAdminStatusCode === -1
    ) {
      return
    }

    if (createProgressUpdateStatusCode === 200) {
      setOpenModalConfirmCreate(false)
      setOpenModalCreateSuccess(true)
    } else if (resubmitProgressUpdateStatusCode === 200) {
      setOpenModalConfirmEdit(false)
      setOpenModalEditSuccess(true)
    } else if (editProgressUpdateStatusCode === 200) {
      setOpenModalSaveSuccess(true)
    } else if (confirmProgressUpdateAdminStatusCode === 200) {
      setOpenModalConfirmAdmin(false)
      setOpenModalConfirmAdminSuccess(true)
    } else if (saveProgressUpdateAdminStatusCode === 200) {
      if (editType === 'ADMIN_CONFIRM') {
        confirmProgressUpdateAdminReq()
      } else {
        setOpenModalSaveAdminSuccess(true)
      }
    } else if (deleteProgressAdminStatusCode === 200) {
      setOpenModalDeleteEditProgressAdminSuccess(true)
    } else if (saveEditProgressAdminStatusCode === 200) {
      setOpenModalSaveEditProgressAdminSuccess(true)
    } else {
      setOpenErrorSnackBar(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createProgressUpdateStatusCode,
    resubmitProgressUpdateStatusCode,
    editProgressUpdateStatusCode,
    saveProgressUpdateAdminStatusCode,
    confirmProgressUpdateAdminStatusCode,
    deleteProgressAdminStatusCode,
    saveEditProgressAdminStatusCode,
    editType,
  ])

  // Form control
  const {
    register,
    control,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<CampaignProgressUpdateFormInput>({
    resolver: zodResolver(CampaignProgressUpdateFormSchema),
    defaultValues: initialData
      ? initialData
      : {
          progressDetails: [
            {
              startDate: dayjs(),
              endDate: dayjs().add(1, 'day'),
              activity: '',
              outcome: '',
              expenses: 0,
            },
          ],
        },
  })

  // progressDEtail control
  const {
    fields: progressDetails,
    append: addProgressDetails,
    remove: removeProgressDetails,
  } = useFieldArray({
    control,
    name: 'progressDetails',
  })

  //get capmaign detail

  useEffect(() => {
    if (type === 'CREATE' || type === 'EDIT-PROGRESS') {
      getDetail()
    } else {
      getreqDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  const [getCampaignDeatil, setGetCampaignDeatil] = useState<boolean>(false)
  const [campaignName, setCampaignName] = useState<string>()
  const [campaignId, setCampaignId] = useState<string>()
  const [campaignHolistic, setCampaignHolistic] = useState<string[]>()
  const [adminComment, setAdminComment] = useState<string>()

  const [detail, getDetailLoading, getDetail, getDetailStatusCode] =
    useGet<Detail>({
      url: `/campaigns/${id}/detail`,
      autoFetch: false,
    })

  const [reqDetail, getreqDetailLoading, getreqDetail, getreqDetailStatusCode] =
    useGet<GetCampaignProposalData>({
      url: `/campaign-requests/${id}`,
      jwtToken: token!,
      autoFetch: false,
    })

  useEffect(() => {
    setCampaignName(detail?.name)
    setCampaignHolistic(detail?.areaHolistic)
    setCampaignId(id)
    setGetCampaignDeatil(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, getCampaignDeatil])

  useEffect(() => {
    setCampaignName(reqDetail?.name)
    setCampaignHolistic(reqDetail?.campaignRequestAreaHolistics)
    setAdminComment(reqDetail?.adminComment)
    setCampaignId(reqDetail?.campaignId)
    setGetCampaignDeatil(true)
  }, [reqDetail, getCampaignDeatil])

  // upload images
  const [uploadedImages, setUploadedImages] = useState<AttachmentMetaData[]>([])
  const [images, setImages] = useState<AttachmentMetaData[]>([])
  const fileRef = useRef<any>(null)

  useEffect(() => {
    setUploadedImages(initialFiles || [])
  }, [initialFiles])

  const handleImageChange = (images: AttachmentMetaData[]) => {
    setImages(images)
  }

  const numberOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const lastChar = value.charAt(value.length - 1) // Get the last typed character
    const isValidChar = /^[0-9]*$/.test(lastChar) // Check if the last character is a number
    if (!isValidChar) {
      event.target.value = value.slice(0, -1) // Remove the last character if it's not a number
    }
  }

  return (
    <ProgrssFormContext.Provider value={[openErrorSnackBar]}>
      <Stack
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        paddingTop={'10px'}
      >
        {adminComment && <AdminCommentSection comment={adminComment} />}
        <Stack
          flexDirection='column'
          border={1}
          borderColor={grey[400]}
          borderRadius={'20px'}
          width={'100%'}
          padding={3}
          marginY={'24px'}
          bgcolor={'#FFFFFF'}
        >
          {resubmitProgressUpdateLoading ||
          createProgressUpdateLoading ||
          editProgressUpdateLoading ||
          saveProgressUpdateAdminLoading ||
          confirmProgressUpdateAdminLoading ||
          requestFixProgressUpdateLoading ||
          deleteProgressAdminLoading ||
          saveEditProgressAdminLoading ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyItems={'center'}
              alignItems={'center'}
              flexShrink={0}
              paddingY={'20px'}
            >
              <CircularProgress color='primary' size={40} />
            </Box>
          ) : (
            <Box component='form' onSubmit={handleSubmit(submitHandler)}>
              <Stack gap='30px'>
                <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
                  <Box
                    style={{
                      width: '100%',
                    }}
                    flexDirection='column'
                    textAlign={'left'}
                  >
                    {!getCampaignDeatil ? (
                      <>
                        <Skeleton variant='text' sx={{ fontSize: '44px' }} />
                        <Skeleton variant='text' sx={{ fontSize: '30px' }} />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant='h3'
                          color={COLOR.SECOUNDARY_FIRST_1}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => navigate('/campaigns/' + campaignId)}
                        >
                          {campaignName}
                        </Typography>
                        <Stack flexDirection={'row'} gap={'16px'}>
                          {campaignHolistic?.map((v, i) => getHolistic(v))}
                        </Stack>
                      </>
                    )}
                  </Box>
                </Stack>
                <Divider />
                <Stack flexDirection={'column'} flexWrap='wrap' gap='16px'>
                  <Box
                    style={{
                      width: '300px',
                    }}
                    flexDirection='column'
                    textAlign={'left'}
                  >
                    <Typography variant='h3'>กิจกรรมความคืบหน้า</Typography>
                    <Typography variant='h6' color={grey[600]}>
                      เพิ่มได้สูงสุด 20 กิจกรรม
                    </Typography>
                  </Box>
                  <Stack
                    flexGrow='1'
                    minWidth={{ xs: '200px', sm: '400px' }}
                    textAlign={'left'}
                    gap={'24px'}
                  >
                    {progressDetails.map((a, i) => (
                      <Stack
                        key={a.id}
                        display='flex'
                        flexDirection={'column'}
                        gap={'8px'}
                      >
                        <Stack flexDirection={'row'}>
                          <Typography variant='h5' sx={{ marginRight: '24px' }}>
                            {' '}
                            กิจกรรมที่ {i + 1}
                          </Typography>
                          {progressDetails.length > 1 && (
                            <Button
                              variant='outlined'
                              color='error'
                              sx={{ minWidth: '0px' }}
                              onClick={() => removeProgressDetails(i)}
                            >
                              <Delete sx={{ width: '22px' }} />
                              <Typography>ลบกิจกรรมนี้</Typography>
                            </Button>
                          )}
                        </Stack>

                        <Stack
                          flexDirection={{
                            sm: 'column',
                            md: 'row',
                            lg: 'row',
                            xl: 'row',
                          }}
                          justifyContent={'space-between'}
                        >
                          <Stack
                            flexDirection={'column'}
                            gap={'px'}
                            width={{
                              sm: '50%',
                              md: '25%',
                              lg: '25%',
                              xl: '25%',
                            }}
                          >
                            <Typography variant='h5'>
                              <span style={{ color: '#0079FF' }}>* </span>
                              วันและเวลาที่ดำเนินการ
                            </Typography>
                            <Stack flexDirection={'column'} gap={'8px'}>
                              <Typography variant='h6'>
                                วัน และเวลาที่เริ่มดำเนินการ
                              </Typography>
                              <Controller
                                name={`progressDetails.${i}.startDate`}
                                control={control}
                                defaultValue={dayjs()}
                                render={({
                                  field: { onChange, value, ...restField },
                                  fieldState: { error },
                                }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale='th'
                                  >
                                    <DateTimePicker
                                      label='วัน และเวลาที่เริ่มดำเนินการ'
                                      value={dayjs(value)}
                                      {...restField}
                                      onChange={(value) => {
                                        onChange(value)
                                      }}
                                      slotProps={{
                                        textField: {
                                          error: Boolean(error),
                                          size: 'small',
                                        },
                                      }}
                                      disabled={false}
                                      sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: '#212121',
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </Stack>
                            <Box height={'8px'}></Box>
                            <Stack flexDirection={'column'} gap={'8px'}>
                              <Typography variant='h6'>
                                วัน และเวลาที่สิ้นสุดการดำเนินการ
                              </Typography>
                              <Controller
                                name={`progressDetails.${i}.endDate`}
                                defaultValue={dayjs()}
                                control={control}
                                render={({
                                  field: { onChange, value, ...restField },
                                  fieldState: { error },
                                }) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale='th'
                                  >
                                    <DateTimePicker
                                      label='วัน และเวลาที่สิ้นสุดการดำเนินการ'
                                      value={dayjs(value)}
                                      {...restField}
                                      ref={
                                        register(`progressDetails.${i}.endDate`)
                                          .ref
                                      }
                                      onChange={(value) => {
                                        onChange(value)
                                      }}
                                      slotProps={{
                                        textField: {
                                          error: Boolean(error),
                                          size: 'small',
                                        },
                                      }}
                                      disabled={false}
                                      sx={{
                                        '& .MuiInputBase-input.Mui-disabled': {
                                          WebkitTextFillColor: '#212121',
                                        },
                                      }}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </Stack>
                            {errors.progressDetails?.[i]?.endDate && (
                              <Typography
                                variant='body1'
                                color={COLOR.BUTTON_RED}
                              >
                                {errors.progressDetails?.[i]?.endDate?.message}
                              </Typography>
                            )}
                          </Stack>
                          <Stack
                            flexDirection={'column'}
                            width={{
                              sm: '100%',
                              md: '25%',
                              lg: '25%',
                              xl: '25%',
                            }}
                            gap={'4px'}
                          >
                            <Typography variant='h5'>
                              <span style={{ color: '#0079FF' }}>* </span>{' '}
                              การดำเนินการ/กิจกรรม
                            </Typography>
                            <TextareaWithCharLength
                              name={`progressDetails.${i}.activity`}
                              minRows={5}
                              maxLength={512}
                              placeholder='การดำเนินการ'
                              control={control}
                              error={errors.progressDetails?.[i]?.activity}
                              onChangeValue={() =>
                                clearErrors(`progressDetails.${i}.activity`)
                              }
                            />
                          </Stack>

                          <Stack
                            flexDirection={'column'}
                            width={{
                              sm: '100%',
                              md: '25%',
                              lg: '25%',
                              xl: '25%',
                            }}
                            gap={'4px'}
                          >
                            <Typography variant='h5'>
                              <span style={{ color: '#0079FF' }}>* </span>{' '}
                              ผลที่เกิดขึ้น
                            </Typography>
                            <TextareaWithCharLength
                              name={`progressDetails.${i}.outcome`}
                              minRows={5}
                              maxLength={MAX_LENGTH}
                              placeholder='ผลที่เกิดขึ้น'
                              control={control}
                              error={errors.progressDetails?.[i]?.outcome}
                              onChangeValue={() =>
                                clearErrors(`progressDetails.${i}.outcome`)
                              }
                            />
                          </Stack>
                          <Stack
                            flexDirection={'column'}
                            width={{
                              sm: '50%',
                              md: '15%',
                              lg: '15%',
                              xl: '15%',
                            }}
                            gap={'4px'}
                          >
                            <Typography variant='h5'>
                              <span style={{ color: '#0079FF' }}>* </span>{' '}
                              ค่าใช้จ่าย (บาท)
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth={true}
                              type='text'
                              inputProps={{
                                pattern: '[0-9]*',
                                inputMode: 'numeric',
                                min: 0,
                              }}
                              {...register(`progressDetails.${i}.expenses`, {
                                valueAsNumber: true,
                              })}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                numberOnly(event)
                                clearErrors(`progressDetails.${i}.expenses`)
                              }}
                              error={Boolean(
                                errors.progressDetails?.[i]?.expenses
                              )}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    ))}

                    {progressDetails.length < MAX_PROGRESS_DETAILS && (
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        sx={{ maxWidth: '220px', alignSelf: 'end' }}
                        onClick={() =>
                          addProgressDetails({
                            startDate: dayjs(),
                            endDate: dayjs().add(1, 'day'),
                            activity: '',
                            outcome: '',
                            expenses: 0,
                          })
                        }
                      >
                        เพิ่มกิจกรรม
                      </Button>
                    )}
                  </Stack>{' '}
                </Stack>
                <Divider />
                <Stack
                  flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
                  flexWrap='wrap'
                  gap='16px'
                >
                  <Box
                    style={{
                      width: '300px',
                    }}
                    flexDirection='column'
                    textAlign={'left'}
                  >
                    <Typography variant='h3'>รูปภาพความคืบหน้า</Typography>
                  </Box>
                  <Box
                    height={'100%'}
                    minWidth={{ xs: '200px', sm: '400px' }}
                    ref={fileRef}
                    flexGrow={1}
                  >
                    <ImageUpload
                      label='อัปโหลดรูป'
                      files={images}
                      multiple={true}
                      onChange={handleImageChange}
                      adminView={false}
                      endpoint={'/campaign-requests/upload-image'}
                      type={'image'}
                    />
                  </Box>
                </Stack>
                <Divider />
                <Stack>
                  {type === 'CREATE' ? (
                    <Button
                      variant='outlined'
                      fullWidth={false}
                      color={'primary'}
                      sx={{ maxWidth: '250px', alignSelf: 'end' }}
                      onClick={() => setTypeSubmit('CREATE')}
                      type='submit'
                    >
                      ส่งรายงานความคืบหน้า
                    </Button>
                  ) : (userRole === 'ADMIN' || userRole === 'SUPER_ADMIN') &&
                    status === 'PENDING' ? (
                    <Stack
                      flexDirection={'row'}
                      gap={'16px'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'primary'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => {
                          setTypeSubmit('ADMIN_SAVE')
                        }}
                        type='submit'
                      >
                        บันทึก
                      </Button>
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'primary'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => setTypeSubmit('ADMIN_CONFIRM')}
                        type='submit'
                      >
                        ยืนยันข้อมูล
                      </Button>
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'error'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => setTypeSubmit('ADMIN_FIX')}
                        type='submit'
                      >
                        ขอให้แก้ไขข้อมูล
                      </Button>
                    </Stack>
                  ) : userRole !== 'ADMIN' &&
                    userRole !== 'SUPER_ADMIN' &&
                    status === 'REQUEST_FIX' ? (
                    <Stack
                      flexDirection={'row'}
                      gap={'16px'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'primary'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => {
                          setTypeSubmit('SAVE')
                        }}
                        type='submit'
                      >
                        บันทึก
                      </Button>
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'primary'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => {
                          setTypeSubmit('RESUBMIT')
                        }}
                        type='submit'
                      >
                        ยืนยันข้อมูล
                      </Button>
                    </Stack>
                  ) : type === 'EDIT-PROGRESS' ? (
                    <Stack
                      flexDirection={'row'}
                      gap={'16px'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'primary'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => {
                          setTypeSubmit('EDIT_SAVE')
                        }}
                        type='submit'
                      >
                        ยืนยันข้อมูล
                      </Button>
                      <Button
                        variant='outlined'
                        fullWidth={false}
                        color={'error'}
                        sx={{ maxWidth: '250px', alignSelf: 'end' }}
                        onClick={() => {
                          setTypeSubmit('EDIT_DELETE')
                        }}
                        type='submit'
                      >
                        ลบกิจกรรม
                      </Button>
                    </Stack>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </Box>
          )}
        </Stack>

        {/* user modal */}
        <ModalWithButton
          id={'create-comfirm'}
          type={'DEFAULT'}
          title={'ยืนยันการส่งรายงานความคืบหน้าโครงการ'}
          description={
            'คุณแน่ใจหรือไม่ว่าข้อมูลความคืบหน้าของโครงการนี้ถูกต้อง? หลังจากยืนยันการส่งรายงานความคืบหน้าแล้ว ระบบจะทำการพิจารณาข้อมูลทั้งหมดอีกครั้ง'
          }
          confirmBtnText={'ยืนยัน'}
          cancleBtnText={'ยกเลิก'}
          open={openModalConfirmCreate}
          onClose={() => setOpenModalConfirmCreate(false)}
          handleClickConfirm={prepareDate}
        />

        <ModalNotification
          id={'create-success'}
          type={'DEFAULT'}
          title={'ส่งรายงานความคืบหน้าโครงการสำเร็จ'}
          description={'ระบบจะทำการตรวจสอบข้อมูล และแจ้งผลท่านผ่านทางอีเมล'}
          open={openModalCreateSuccess}
          onClose={() => {
            setOpenModalCreateSuccess(false)
            navigate(generateViewCampaignRoute(id))
          }}
          SvgIcon={undefined}
        />

        <ModalWithButton
          id={'resubmit-comfirm'}
          type={'DEFAULT'}
          title={'ยืนยันการแก้ไขรายงานความคืบหน้าโครงการ'}
          description={
            'คุณแน่ใจหรือไม่ว่า ข้อมูลความคืบหน้าของโครงการนี้ถูกต้อง? หลังจากยืนยันการส่งรายงานความคืบหน้าแล้ว ระบบจะทำการพิจารณาข้อมูลทั้งหมดอีกครั้ง'
          }
          confirmBtnText={'ยืนยัน'}
          cancleBtnText={'ยกเลิก'}
          open={openModalConfirmEdit}
          onClose={() => setOpenModalConfirmEdit(false)}
          handleClickConfirm={() => {
            setEditType('RESUBMIT')
            setOpenModalConfirmEdit(false)
            prepareDate()
          }}
        />

        <ModalNotification
          id={'resubmit-success'}
          type={'DEFAULT'}
          title={'ส่งการแก้ไขรายงานความคืบหน้าโครงการสำเร็จ'}
          description={'ระบบจะทำการตรวจสอบข้อมูล และแจ้งผลท่านผ่านทางอีเมล'}
          open={openModalEditSuccess}
          onClose={() => {
            setOpenModalEditSuccess(false)
            navigate('/churches/' + churchId + '/manage/campaigns')
          }}
          SvgIcon={undefined}
        />

        <ModalNotification
          id={'save-success'}
          type={'DEFAULT'}
          title={'บันทึกการแก้ไขความคืบหน้าโครงการสำเร็จ'}
          description={
            'หากต้องการส่งข้อมูลให้กับระบบพิจารณา กรุณากดปุ่ม “ยืนยันข้อมูล”'
          }
          open={openModalSaveSuccess}
          onClose={() => {
            setOpenModalSaveSuccess(false)
            window.location.reload()
          }}
          SvgIcon={undefined}
        />

        {/* admin modal */}

        <ModalNotification
          id={'save-admin-success'}
          type={'DEFAULT'}
          title={'บันทึกความคืบหน้าโครงการสำเร็จ'}
          description={
            'หากต้องการยืนยันข้อมูลความคืบหน้า กรุณากดปุ่ม “ยืนยันข้อมูล”'
          }
          open={openModalSaveAdminSuccess}
          onClose={() => {
            setOpenModalSaveAdminSuccess(false)
            window.location.reload()
          }}
          SvgIcon={undefined}
        />

        <ModalWithButton
          id={'comfirm-admin'}
          type={'DEFAULT'}
          title={'ยืนยันการสร้างรายงานความคืบหน้าโครงการ'}
          description={
            'คุณแน่ใจหรือไม่ว่า ข้อมูลความคืบหน้าของโครงการนี้ถูกต้อง? หลังจากยืนยันการรายงานความคืบหน้าโครงการแล้ว ข้อมูลนี้จะแสดงบนรายละเอียดโครงการ'
          }
          confirmBtnText={'ยืนยัน'}
          cancleBtnText={'ยกเลิก'}
          open={openModalConfirmAdmin}
          onClose={() => setOpenModalConfirmAdmin(false)}
          handleClickConfirm={() => {
            setEditType('ADMIN_CONFIRM')
            setOpenModalConfirmAdmin(false)
            prepareDate()
          }}
        />

        <ModalNotification
          id={'confirm-admin-success'}
          type={'DEFAULT'}
          title={'ยืนยันการรายงานความคืบหน้าโครงการสำเร็จ'}
          confirmText={'กลับไปที่หน้ารวมคำขอโครงการ'}
          open={openModalConfirmAdminSuccess}
          onClose={() => {
            setOpenModalConfirmAdminSuccess(false)
            navigate('/admin/campaigns/request')
          }}
          SvgIcon={undefined}
        />

        <ModalFillReason
          id={'request-fix-admin'}
          type={'ERROR'}
          title={'แจ้งเหตุผลที่ต้องแก้ไข'}
          description={
            'แจ้งเหตุผลที่ความคืบหน้าโครงการนี้ต้องได้รับการแก้ไขโดยละเอียด เพื่อให้โบสถ์สามารถแก้ไขได้อย่างถูกต้อง'
          }
          open={openModalFixAdmin}
          confirmText={'ยืนยัน'}
          onClickConfirm={handleClickConfirmNeedfix}
          onClose={() => {
            setOpenModalFixAdmin(false)
          }}
          disableConfirmBtn={false}
        />

        <ModalNotification
          id={'request-fix-admin-success'}
          type={'ERROR'}
          title={'ส่งเหตุผลที่ต้องแก้ไขสำเร็จ'}
          confirmText={'กลับไปที่หน้ารวมคำขอโครงการ'}
          open={openModalFixAdminSuccess}
          onClose={() => {
            setOpenModalFixAdminSuccess(false)
            navigate('/admin/campaigns/request')
          }}
          SvgIcon={undefined}
        />

        <ModalWithButton
          id={'edit-specific-save'}
          type={'DEFAULT'}
          title={
            'ยืนยันการแก้ไขรายงานความคืบหน้าโครงการครั้งที่ ' + progressIndex
          }
          description={
            'คุณแน่ใจหรือไม่ว่า ข้อมูลความคืบหน้าของโครงการนี้ถูกต้อง? หลังจากยืนยันการรายงานความคืบหน้าโครงการแล้ว ข้อมูลนี้จะแสดงบนรายละเอียดโครงการ'
          }
          confirmBtnText={'ยืนยัน'}
          cancleBtnText={'ยกเลิก'}
          open={openModalSaveEditProgressAdmin}
          onClose={() => setOpenModalSaveEditProgressAdmin(false)}
          handleClickConfirm={() => {
            setEditType('ADMIN_SAVE_SPECIFIC')
            setOpenModalSaveEditProgressAdmin(false)
            prepareDate()
          }}
        />

        <ModalNotification
          id={'edit-specific-save-success'}
          type={'DEFAULT'}
          title={
            `แก้ไขรายงานความคืบหน้าโครงการครั้งที่ ` + progressIndex + ` สำเร็จ`
          }
          confirmText={'กลับไปที่หน้าโครงการ'}
          open={openModalSaveEditProgressAdminSuccess}
          onClose={() => {
            setOpenModalSaveEditProgressAdminSuccess(false)
            navigate(generateViewCampaignAdminRoute(id!))
          }}
          SvgIcon={undefined}
        />

        <ModalWithButton
          id={'delete-specific-save'}
          type={'ERROR'}
          title={'ยืนยันการลบรายงานความคืบหน้าโครงการครั้งที่ ' + progressIndex}
          description={
            `คุณแน่ใจหรือไม่ที่จะลบรายงานความคืบหน้าโครงการครั้งที่ ` +
            progressIndex +
            ` นี้? การกระทำนี้ไม่สามารถย้อนกลับได้`
          }
          confirmBtnText={'ยืนยัน'}
          cancleBtnText={'ยกเลิก'}
          open={openModalDeleteEditProgressAdmin}
          onClose={() => setOpenModalDeleteEditProgressAdmin(false)}
          handleClickConfirm={() => {
            setEditType('ADMIN_DELETE_SPECIFIC')
            setOpenModalDeleteEditProgressAdmin(false)
            deleteProgressAdminReq()
          }}
        />

        <ModalNotification
          id={'edit-delete-success'}
          type={'DEFAULT'}
          title={
            `ลบรายงานความคืบหน้าโครงการครั้งที่ ` + progressIndex + ` สำเร็จ`
          }
          confirmText={'กลับไปที่หน้าโครงการ'}
          open={openModalDeleteEditProgressAdminSuccess}
          onClose={() => {
            setOpenModalDeleteEditProgressAdminSuccess(false)
            navigate(generateViewCampaignAdminRoute(id!))
          }}
          SvgIcon={undefined}
        />

        <Snackbar
          open={openErrorSnackBar}
          autoHideDuration={6000}
          onClose={() => setOpenErrorSnackBar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClick={() => setOpenErrorSnackBar(false)}
            severity='error'
            sx={{ width: '100%' }}
          >
            เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง
          </Alert>
        </Snackbar>
      </Stack>
    </ProgrssFormContext.Provider>
  )
}

export default CampaignProgressForm
