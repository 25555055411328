export default function formatCompactNumber(number: number) {
  if (number < 1_000) {
    return number?.toLocaleString()
  } else if (number < 1_000_000) {
    // For numbers between 1000 and 999,999, format with K suffix
    return (
      (number / 1_000)?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }) + ' K'
    )
  } else {
    // For numbers 1,000,000 and above, format with M suffix
    return (
      (number / 1_000_000)?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }) + ' M'
    )
  }
}
