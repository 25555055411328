import {
  Box,
  CircularProgress,
  Container,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
  styled,
} from '@mui/material'
import { theme } from '../../constants/theme'
import StatusBar from '../../components/Admin/StatusBar'
import RequestList from '../../components/Admin/RequestList'
import { useEffect, useState } from 'react'
import { COLOR } from '../../constants/color-constant'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import ChurchList from '../../components/Admin/ChurchList'
import { Role, UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import { useGet } from '../../hooks/useFetch'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import AuthService from '../../services/auth.service'
import { Navigate } from 'react-router-dom'
import { AllChurch } from '../../models/admin/AllChurch'
import AdminDeleteModal from '../../components/Admin/modal/AdminDeleteModal'
import { PlatformAllEarning } from '../../models/admin/PlatformEarning'
import PlatformEarningList from '../../components/Admin/PlatformEarningList'

export default function AllEarningPage() {
  const [page, setPage] = useState(1)

  const token = AuthService.getTokenUser()
  const tokenChecked = useAuthMiddleware()
  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const [platformEarning, loading, refresh] = useGet<PlatformAllEarning>({
    url: '/confirm-earnings/list',
    ...(token && { jwtToken: token }),
    autoFetch: true,
    queryParams: {
      page: page,
    },
  })

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
    paddingLeft: '16px',
  })

  function handleReload() {
    refresh()
  }

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h3' paddingY={3}>
                  Confirm Earning
                </Typography>
                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '90%',
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHeadCell>
                            <Typography variant='h6' color={'text.primary'}>
                              Church
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell align='center'>
                            <Typography variant='h6' color={'text.primary'}>
                              Total
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell>
                            <Typography variant='h6' color={'text.primary'}>
                              Status
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell align='center'>
                            <Typography variant='h6' color={'text.primary'}>
                              Record
                            </Typography>
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      {!loading && (
                        <TableBody>
                          {platformEarning?.confirmEarnings.map((req) => (
                            <PlatformEarningList
                              key={req.churchId}
                              churchId={req.churchId}
                              churchName={req.churchName}
                              totalAndStatus={req.totalAndStatus}
                            ></PlatformEarningList>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {loading && (
                    <Box paddingY={2}>
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}
                  <Pagination
                    count={platformEarning?.totalPages}
                    page={page}
                    onChange={handleChange}
                    sx={{ paddingTop: '5px' }}
                  />
                </Box>
              </Box>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </ThemeProvider>
      )
    }
  }
}
