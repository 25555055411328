export const CHURCH_QUESTION = {
  question1: {
    choice1: '1-50',
    choice2: '51-100',
    choice3: '101-150',
    choice4: '151-300',
    choice5: '> 300',
  },
  question2: {
    choice1: 'Facebook',
    choice2: 'Twitter',
    choice3: 'Youtube',
    choice4: 'Email',
    choice5: 'Other',
  },
}
