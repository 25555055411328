import { useRef, useState } from 'react'
import { Stack, Typography, Divider, Button, Box } from '@mui/material'
import 'dayjs/locale/th'
import { TypeOf, z } from 'zod'
import {
  Control,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form'
import { AttachmentMetaData } from '../../../models/AttachmentMetaData'
import TextareaWithCharLength from '../../Textarea/TextareaWithCharLength'
import ImageUpload from '../../ImageUpload/ImageUpload'
import { COLOR } from '../../../constants/color-constant'

export const camEditDesQuotCoverFormSchema = z.object({
  description: z.string(),
  quote: z.string(),
  coverImageUrl: z.string(),
})

export type CamEditDesQuotCoverFormInput = TypeOf<
  typeof camEditDesQuotCoverFormSchema
>

interface CamEditDesQuotCoverFormProps {
  coverImage: AttachmentMetaData | null
  onCoverImageChange: (image: AttachmentMetaData) => void
  register: UseFormRegister<CamEditDesQuotCoverFormInput>
  control: Control<CamEditDesQuotCoverFormInput>
  errors: FieldErrors<CamEditDesQuotCoverFormInput>
  onSubmit: UseFormHandleSubmit<CamEditDesQuotCoverFormInput>
  onSubmissionPass: () => void
  onBack: () => void
  viewOnly: boolean
}

function CampaignDesQuotCoverForm({
  control,
  coverImage,
  onCoverImageChange,
  onSubmit,
  onSubmissionPass,
  onBack,
  viewOnly: adminView,
}: CamEditDesQuotCoverFormProps) {
  const fileRef = useRef<any>(null)
  const [coverImageMissing, setCoverImageMissing] = useState(false)

  const onSubmitHandler = (data: CamEditDesQuotCoverFormInput) => {
    if (validateCoverImage()) {
      onSubmissionPass()
    }
  }

  const validateCoverImage = () => {
    if (!coverImage) {
      fileRef.current?.scrollIntoView()
      setCoverImageMissing(true)
      return false
    }

    setCoverImageMissing(false)
    return true
  }

  return (
    <Box
      width='100%'
      component='form'
      id='campaign-proposal-form-2'
      onSubmit={onSubmit(onSubmitHandler)}
    >
      <Stack gap={'40px'}>
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <Typography variant='h3'>Campaign Description</Typography>
          </div>
          <Stack flexGrow='1' minWidth={{ xs: '200px', sm: '400px' }}>
            <TextareaWithCharLength
              name='description'
              minRows={4}
              maxLength={2000}
              placeholder='description'
              control={control}
              adminView={adminView}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <Typography variant='h3'>Quote</Typography>
          </div>
          <Stack flexGrow='1' minWidth={{ xs: '200px', sm: '400px' }}>
            <TextareaWithCharLength
              name='quote'
              minRows={4}
              maxLength={140}
              placeholder='quote'
              control={control}
              adminView={adminView}
            />
          </Stack>
        </Stack>
        <Divider />
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <span>
              <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
                *{' '}
              </Typography>
              <Typography display='inline' variant='h3'>
                Cover Image
              </Typography>
            </span>
          </div>
          <Stack flexGrow='1' minWidth='220px' ref={fileRef}>
            <ImageUpload
              label='อัปโหลดรูปหน้าปก'
              multiple={false}
              files={coverImage ? [coverImage] : []}
              onChange={(files) => {
                if (!files[0]) {
                  setCoverImageMissing(true)
                }

                setCoverImageMissing(false)
                onCoverImageChange(files[0])
              }}
              adminView={adminView}
              endpoint='/campaign-requests/upload-cover-image'
              type={'file'}
            />
            {coverImageMissing && (
              <Typography variant='body1' color='error'>
                กรุณาเลือกรูปภาพหน้าปก
              </Typography>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack justifyContent='space-between' flexDirection='row'>
          <Button variant='outlined' fullWidth={false} onClick={() => onBack()}>
            <Typography variant='h6'>{`< กลับ`}</Typography>
          </Button>

          <Button
            variant='outlined'
            fullWidth={false}
            type='submit'
            color='secondary'
          >
            <Typography variant='h6'>ส่งคำขอ</Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default CampaignDesQuotCoverForm
