import React, { useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { COLOR } from '../constants/color-constant'
import { common } from '@mui/material/colors'
import axios from 'axios'

export interface churchInfo {
  churchId: string
  churchName: string
  churchProvince: string
}

interface SearchChurchProps {
  setSelectingChurch: (church: churchInfo | null) => void
  setOpenSearchNotFoundChurch: (notFound: boolean) => void
  excludedChurches?: string[]
}

const SearchChurch = ({
  setSelectingChurch,
  setOpenSearchNotFoundChurch,
  excludedChurches,
}: SearchChurchProps) => {
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  const [openSearchChurchBox, setOpenSearchChurchBox] = useState<boolean>(false)
  const [churchByProvinceList, setChurchProvinceList] = useState<churchInfo[]>(
    []
  )
  const [loadingChurchList, setLoadingChurchList] = useState(false)
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState<boolean>(false)

  const availableChurches = useMemo(() => {
    if (excludedChurches && excludedChurches.length > 0) {
      const available = churchByProvinceList.filter(
        (c) => !excludedChurches?.includes(c.churchId)
      )
      return available
    } else {
      return churchByProvinceList
    }
  }, [excludedChurches, churchByProvinceList])

  useEffect(() => {
    setOpenSearchNotFoundChurch(availableChurches.length === 0)
  }, [availableChurches.length, openSearchChurchBox])

  const handleSelectingProvince = async (selectingProvince: string) => {
    if (selectingProvince === 'NO_PROVINCE') {
      setOpenSearchChurchBox(false)
      setOpenSearchNotFoundChurch(false)
      setSelectingChurch(null)
    } else {
      try {
        setLoadingChurchList(true)
        setOpenSearchNotFoundChurch(false)
        setOpenSearchChurchBox(false)
        setSelectingChurch(null)
        const response = await axios.get(`${API_URL}/churches/short`, {
          headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
          params: {
            province: selectingProvince,
          },
        })
        setLoadingChurchList(false)
        setChurchProvinceList(response.data)
        if (response.data.length === 0) {
          setOpenSearchNotFoundChurch(true)
        } else {
          setOpenSearchChurchBox(true)
        }
      } catch (error) {
        // Handle errors (e.g., display an error message)
        // console.error('Error fetching item:', error)
        setOpenErrorSnackBar(true)
      }
    }
  }

  return (
    <>
      <Stack display={'flex'} flexDirection={{ md: 'row' }} gap={'8px'}>
        <Autocomplete
          disablePortal
          blurOnSelect
          id='search-province-auto-box'
          size='small'
          options={ThaiProvince}
          sx={{
            width: { md: '16vw', xs: '60vw' },
            '& fieldset': { borderRadius: '8px' },
          }}
          onChange={(event, value) => {
            value
              ? handleSelectingProvince(value.name_th)
              : handleSelectingProvince('NO_PROVINCE')
          }}
          loading={loadingChurchList}
          getOptionLabel={(option) => option.name_th}
          isOptionEqualToValue={(option, value) =>
            option.name_th === value.name_th
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label='ระบุพื้นที่'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingChurchList ? (
                      <CircularProgress color='primary' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.name_th, inputValue, {
              insideWords: true,
            })
            const parts = parse(option.name_th, matches)

            return (
              <li {...props}>
                <div>
                  {parts.map((part: any, index: number) => (
                    <span
                      key={index}
                      style={{
                        color: part.highlight ? COLOR.PRIMARY_1 : common.black,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            )
          }}
        />
        {availableChurches.length > 0 &&
          openSearchChurchBox &&
          churchByProvinceList &&
          !loadingChurchList && (
            <Autocomplete
              disablePortal
              id='search-church-auto-box'
              size='small'
              options={availableChurches}
              value={null}
              blurOnSelect={true}
              sx={{
                width: { md: '16vw', xs: '60vw' },
                '& fieldset': { borderRadius: '8px' },
              }}
              onChange={(event, value) => {
                setSelectingChurch(value!)
              }}
              getOptionLabel={(option) => option.churchName}
              renderInput={(params) => (
                <TextField {...params} label='รายชื่อคริสตจักร' />
              )}
            />
          )}
      </Stack>
      <Snackbar
        open={openErrorSnackBar}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnackBar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {/* TODO: malke this error message to common msg */}
        <Alert
          onClose={() => setOpenErrorSnackBar(false)}
          severity='error'
          sx={{ width: '100%' }}
        >
          เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง
        </Alert>
      </Snackbar>
    </>
  )
}

export default SearchChurch

const ThaiProvince = [
  {
    id: 1,
    name_th: 'กรุงเทพฯ',
    name_en: 'Bangkok',
    geography_id: 2,
  },
  {
    id: 2,
    name_th: 'สมุทรปราการ',
    name_en: 'Samut Prakan',
    geography_id: 2,
  },
  {
    id: 3,
    name_th: 'นนทบุรี',
    name_en: 'Nonthaburi',
    geography_id: 2,
  },
  {
    id: 4,
    name_th: 'ปทุมธานี',
    name_en: 'Pathum Thani',
    geography_id: 2,
  },
  {
    id: 5,
    name_th: 'พระนครศรีอยุธยา',
    name_en: 'Phra Nakhon Si Ayutthaya',
    geography_id: 2,
  },
  {
    id: 6,
    name_th: 'อ่างทอง',
    name_en: 'Ang Thong',
    geography_id: 2,
  },
  {
    id: 7,
    name_th: 'ลพบุรี',
    name_en: 'Loburi',
    geography_id: 2,
  },
  {
    id: 8,
    name_th: 'สิงห์บุรี',
    name_en: 'Sing Buri',
    geography_id: 2,
  },
  {
    id: 9,
    name_th: 'ชัยนาท',
    name_en: 'Chai Nat',
    geography_id: 2,
  },
  {
    id: 10,
    name_th: 'สระบุรี',
    name_en: 'Saraburi',
    geography_id: 2,
  },
  {
    id: 11,
    name_th: 'ชลบุรี',
    name_en: 'Chon Buri',
    geography_id: 5,
  },
  {
    id: 12,
    name_th: 'ระยอง',
    name_en: 'Rayong',
    geography_id: 5,
  },
  {
    id: 13,
    name_th: 'จันทบุรี',
    name_en: 'Chanthaburi',
    geography_id: 5,
  },
  {
    id: 14,
    name_th: 'ตราด',
    name_en: 'Trat',
    geography_id: 5,
  },
  {
    id: 15,
    name_th: 'ฉะเชิงเทรา',
    name_en: 'Chachoengsao',
    geography_id: 5,
  },
  {
    id: 16,
    name_th: 'ปราจีนบุรี',
    name_en: 'Prachin Buri',
    geography_id: 5,
  },
  {
    id: 17,
    name_th: 'นครนายก',
    name_en: 'Nakhon Nayok',
    geography_id: 2,
  },
  {
    id: 18,
    name_th: 'สระแก้ว',
    name_en: 'Sa Kaeo',
    geography_id: 5,
  },
  {
    id: 19,
    name_th: 'นครราชสีมา',
    name_en: 'Nakhon Ratchasima',
    geography_id: 3,
  },
  {
    id: 20,
    name_th: 'บุรีรัมย์',
    name_en: 'Buri Ram',
    geography_id: 3,
  },
  {
    id: 21,
    name_th: 'สุรินทร์',
    name_en: 'Surin',
    geography_id: 3,
  },
  {
    id: 22,
    name_th: 'ศรีสะเกษ',
    name_en: 'Si Sa Ket',
    geography_id: 3,
  },
  {
    id: 23,
    name_th: 'อุบลราชธานี',
    name_en: 'Ubon Ratchathani',
    geography_id: 3,
  },
  {
    id: 24,
    name_th: 'ยโสธร',
    name_en: 'Yasothon',
    geography_id: 3,
  },
  {
    id: 25,
    name_th: 'ชัยภูมิ',
    name_en: 'Chaiyaphum',
    geography_id: 3,
  },
  {
    id: 26,
    name_th: 'อำนาจเจริญ',
    name_en: 'Amnat Charoen',
    geography_id: 3,
  },
  {
    id: 27,
    name_th: 'หนองบัวลำภู',
    name_en: 'Nong Bua Lam Phu',
    geography_id: 3,
  },
  {
    id: 28,
    name_th: 'ขอนแก่น',
    name_en: 'Khon Kaen',
    geography_id: 3,
  },
  {
    id: 29,
    name_th: 'อุดรธานี',
    name_en: 'Udon Thani',
    geography_id: 3,
  },
  {
    id: 30,
    name_th: 'เลย',
    name_en: 'Loei',
    geography_id: 3,
  },
  {
    id: 31,
    name_th: 'หนองคาย',
    name_en: 'Nong Khai',
    geography_id: 3,
  },
  {
    id: 32,
    name_th: 'มหาสารคาม',
    name_en: 'Maha Sarakham',
    geography_id: 3,
  },
  {
    id: 33,
    name_th: 'ร้อยเอ็ด',
    name_en: 'Roi Et',
    geography_id: 3,
  },
  {
    id: 34,
    name_th: 'กาฬสินธุ์',
    name_en: 'Kalasin',
    geography_id: 3,
  },
  {
    id: 35,
    name_th: 'สกลนคร',
    name_en: 'Sakon Nakhon',
    geography_id: 3,
  },
  {
    id: 36,
    name_th: 'นครพนม',
    name_en: 'Nakhon Phanom',
    geography_id: 3,
  },
  {
    id: 37,
    name_th: 'มุกดาหาร',
    name_en: 'Mukdahan',
    geography_id: 3,
  },
  {
    id: 38,
    name_th: 'เชียงใหม่',
    name_en: 'Chiang Mai',
    geography_id: 1,
  },
  {
    id: 39,
    name_th: 'ลำพูน',
    name_en: 'Lamphun',
    geography_id: 1,
  },
  {
    id: 40,
    name_th: 'ลำปาง',
    name_en: 'Lampang',
    geography_id: 1,
  },
  {
    id: 41,
    name_th: 'อุตรดิตถ์',
    name_en: 'Uttaradit',
    geography_id: 1,
  },
  {
    id: 42,
    name_th: 'แพร่',
    name_en: 'Phrae',
    geography_id: 1,
  },
  {
    id: 43,
    name_th: 'น่าน',
    name_en: 'Nan',
    geography_id: 1,
  },
  {
    id: 44,
    name_th: 'พะเยา',
    name_en: 'Phayao',
    geography_id: 1,
  },
  {
    id: 45,
    name_th: 'เชียงราย',
    name_en: 'Chiang Rai',
    geography_id: 1,
  },
  {
    id: 46,
    name_th: 'แม่ฮ่องสอน',
    name_en: 'Mae Hong Son',
    geography_id: 1,
  },
  {
    id: 47,
    name_th: 'นครสวรรค์',
    name_en: 'Nakhon Sawan',
    geography_id: 2,
  },
  {
    id: 48,
    name_th: 'อุทัยธานี',
    name_en: 'Uthai Thani',
    geography_id: 2,
  },
  {
    id: 49,
    name_th: 'กำแพงเพชร',
    name_en: 'Kamphaeng Phet',
    geography_id: 2,
  },
  {
    id: 50,
    name_th: 'ตาก',
    name_en: 'Tak',
    geography_id: 4,
  },
  {
    id: 51,
    name_th: 'สุโขทัย',
    name_en: 'Sukhothai',
    geography_id: 2,
  },
  {
    id: 52,
    name_th: 'พิษณุโลก',
    name_en: 'Phitsanulok',
    geography_id: 2,
  },
  {
    id: 53,
    name_th: 'พิจิตร',
    name_en: 'Phichit',
    geography_id: 2,
  },
  {
    id: 54,
    name_th: 'เพชรบูรณ์',
    name_en: 'Phetchabun',
    geography_id: 2,
  },
  {
    id: 55,
    name_th: 'ราชบุรี',
    name_en: 'Ratchaburi',
    geography_id: 4,
  },
  {
    id: 56,
    name_th: 'กาญจนบุรี',
    name_en: 'Kanchanaburi',
    geography_id: 4,
  },
  {
    id: 57,
    name_th: 'สุพรรณบุรี',
    name_en: 'Suphan Buri',
    geography_id: 2,
  },
  {
    id: 58,
    name_th: 'นครปฐม',
    name_en: 'Nakhon Pathom',
    geography_id: 2,
  },
  {
    id: 59,
    name_th: 'สมุทรสาคร',
    name_en: 'Samut Sakhon',
    geography_id: 2,
  },
  {
    id: 60,
    name_th: 'สมุทรสงคราม',
    name_en: 'Samut Songkhram',
    geography_id: 2,
  },
  {
    id: 61,
    name_th: 'เพชรบุรี',
    name_en: 'Phetchaburi',
    geography_id: 4,
  },
  {
    id: 62,
    name_th: 'ประจวบคีรีขันธ์',
    name_en: 'Prachuap Khiri Khan',
    geography_id: 4,
  },
  {
    id: 63,
    name_th: 'นครศรีธรรมราช',
    name_en: 'Nakhon Si Thammarat',
    geography_id: 6,
  },
  {
    id: 64,
    name_th: 'กระบี่',
    name_en: 'Krabi',
    geography_id: 6,
  },
  {
    id: 65,
    name_th: 'พังงา',
    name_en: 'Phangnga',
    geography_id: 6,
  },
  {
    id: 66,
    name_th: 'ภูเก็ต',
    name_en: 'Phuket',
    geography_id: 6,
  },
  {
    id: 67,
    name_th: 'สุราษฎร์ธานี',
    name_en: 'Surat Thani',
    geography_id: 6,
  },
  {
    id: 68,
    name_th: 'ระนอง',
    name_en: 'Ranong',
    geography_id: 6,
  },
  {
    id: 69,
    name_th: 'ชุมพร',
    name_en: 'Chumphon',
    geography_id: 6,
  },
  {
    id: 70,
    name_th: 'สงขลา',
    name_en: 'Songkhla',
    geography_id: 6,
  },
  {
    id: 71,
    name_th: 'สตูล',
    name_en: 'Satun',
    geography_id: 6,
  },
  {
    id: 72,
    name_th: 'ตรัง',
    name_en: 'Trang',
    geography_id: 6,
  },
  {
    id: 73,
    name_th: 'พัทลุง',
    name_en: 'Phatthalung',
    geography_id: 6,
  },
  {
    id: 74,
    name_th: 'ปัตตานี',
    name_en: 'Pattani',
    geography_id: 6,
  },
  {
    id: 75,
    name_th: 'ยะลา',
    name_en: 'Yala',
    geography_id: 6,
  },
  {
    id: 76,
    name_th: 'นราธิวาส',
    name_en: 'Narathiwat',
    geography_id: 6,
  },
  {
    id: 77,
    name_th: 'บึงกาฬ',
    name_en: 'buogkan',
    geography_id: 3,
  },
]
