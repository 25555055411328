import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useContext, useEffect } from 'react'
import NAMJAIBAG from '../assets/images/namjaiBag.png'
import { Delete, Edit } from '@mui/icons-material'
import { COLOR } from '../constants/color-constant'
import axios from 'axios'
import AuthService from '../services/auth.service'
import { NamjaiListContext } from '../pages/NamjaiBag'
import { error } from 'console'
import { useNavigate } from 'react-router-dom'

interface NamjaiBagListProps {
  namjaiBagId: string
  campaignId: string
  campaignName: string
  campaignAmount: number
  donationAmount: number
  onDelete: () => void
  onEdit: () => void
}

export default function NamjaiBagList(props: NamjaiBagListProps) {
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

  const token = AuthService.getTokenUser()
  const navigate = useNavigate()

  const {
    namjaiBagId,
    campaignId,
    campaignName,
    campaignAmount,
    donationAmount,
    onDelete,
    onEdit,
  } = props

  const [
    namjaiBagListStatus,
    setNamjaiBagListStatus,
    overallAmountError,
    setOverAllAmountError,
  ] = useContext(NamjaiListContext)

  const [amountNumber, setAmountNumber] = useState<number>(0)
  const [amountError, setAmountError] = useState<boolean>(false)

  const handleDelete = async (namjaiBagId: string) => {
    setNamjaiBagListStatus('loading')
    try {
      const response = await axios.delete(
        `${API_URL}/namjai-bag/${namjaiBagId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )

      // Handle a successful response (e.g., display a success message)
      console.log('Item deleted successfully:', response.data)
      setNamjaiBagListStatus('ready')
      onDelete()
    } catch (error) {
      // Handle errors (e.g., display an error message)
      console.error('Error deleting item:', error)
    }
  }

  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (+event.target.value <= 0) {
      setAmountError(true)
      setOverAllAmountError(true)
    } else {
      setAmountNumber(+event.target.value)
      setAmountError(false)
      setOverAllAmountError(false)
    }
  }

  useEffect(() => {
    if (amountNumber === 0 && donationAmount === 0) setAmountError(true)
  }, [amountNumber])

  const handlePatch = async (namjaiBagId: string, amountNumber: number) => {
    const patchBody = {
      namjaiBagId: namjaiBagId,
      donationAmount: amountNumber,
    }

    if (!amountError) {
      setNamjaiBagListStatus('loading')
      try {
        const response = await axios.patch(
          `${API_URL}/namjai-bag/item`,
          patchBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              'X-API-KEY': `${API_KEY}`,
            },
          }
        )

        // Handle a successful response (e.g., display a success message)
        console.log('Item edited successfully:', response.data)
        setNamjaiBagListStatus('ready')
        onEdit()
      } catch (error) {
        // Handle errors (e.g., display an error message)
        console.error('Error editing item:', error)
      }
    }
  }

  return (
    <>
      <Box
        id='namjai-bag-list'
        display={'flex'}
        padding={'12px'}
        alignItems={{ xs: 'self-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={'space-between'}
        alignSelf={'stretch'}
      >
        <Box
          id='namjai-bag-list-info'
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={{ xs: 'column', md: 'row' }}
          flex={'1 0 0'}
        >
          <Box
            id='namjai-bag-list-pic-name'
            display={'flex'}
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <img
              id='namjai-bag-list-pic'
              width={'196px'}
              height={'112px'}
              src={NAMJAIBAG}
            ></img>
            <Box
              id='namjai-bag-list-name'
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              maxWidth={{ xs: '250px', md: '400px' }}
              minWidth={'250px'}
              padding={'0 12px'}
            >
              <Button
                variant='text'
                disabled={namjaiBagListStatus === 'loading'}
                onClick={() => navigate(`/campaigns/${campaignId}`)}
              >
                <Typography variant='h4' color={COLOR.PRIMARY_1} align='left'>
                  {campaignName}
                </Typography>
              </Button>

              <Typography variant='h6'>
                ต้องการ {campaignAmount?.toLocaleString('en-US')} บาท
                เพื่อถึงเป้าหมาย
              </Typography>
            </Box>
          </Box>
          <Box
            id='namjai-bag-list-edit-area'
            display={'flex'}
            alignItems={'center'}
            gap={'8px'}
          >
            <Edit fontSize='small' />
            <FormControl>
              <TextField
                id='namjai-bag-list-donation-amount'
                disabled={namjaiBagListStatus === 'loading'}
                variant='standard'
                error={amountError}
                defaultValue={donationAmount?.toLocaleString()}
                type='number'
                onKeyDown={(ev) => {
                  if (ev.key === 'Enter') {
                    // Do code here
                    ev.preventDefault()
                    handlePatch(namjaiBagId, amountNumber)
                  }
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleEdit(event)
                }}
                onBlur={() => handlePatch(namjaiBagId, amountNumber)}
                helperText={
                  amountError || donationAmount === 0
                    ? 'จำนวนบริจาคต้องมากกว่า 0'
                    : null
                }
                sx={{ maxWidth: '250px', position: 'relative' }}
              />
              {/* <FormHelperText>must be more than 0</FormHelperText> */}
            </FormControl>
            <Typography variant='h6'>บาท</Typography>
            <Button
              color='primary'
              variant='outlined'
              sx={{
                minWidth: { xs: '20vw', md: '10vw' },
                maxWidth: { xs: '24vw', md: '10vw' },
              }}
              disabled={namjaiBagListStatus === 'loading' || amountError}
              onClick={() => navigate(`/campaigns/${campaignId}`)}
            >
              <Typography variant='h6'>บริจาค</Typography>
            </Button>
            <Button
              color='error'
              sx={{ padding: 0, minWidth: '16px' }}
              disabled={namjaiBagListStatus === 'loading'}
              onClick={() => handleDelete(namjaiBagId)}
              startIcon={
                <Delete
                  color={
                    namjaiBagListStatus === 'loading' ? 'disabled' : 'error'
                  }
                />
              }
            ></Button>
          </Box>
        </Box>
      </Box>
      <Divider variant='fullWidth' sx={{ width: '100%' }} />
    </>
  )
}
