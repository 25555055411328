import {
  Box,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  ThemeProvider,
  Typography,
  styled,
} from '@mui/material'
import { theme } from '../constants/theme'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { COLOR } from '../constants/color-constant'
import useMediaQuery from '@mui/material/useMediaQuery'
import { padding } from '@mui/system'
import { grey } from '@mui/material/colors'

type PersonProps = {
  name: string
  phone: string
  email: string
}

export default function PersonCard(props: PersonProps) {
  const matches = useMediaQuery('(min-width:400px)')
  const { name, phone, email } = props

  const CardContentNoPadding = styled(CardContent)(() => ({
    paddingTop: 8,
    paddingLeft: 8,
    paddingRight: 8,
    ':last-child': { paddingBottom: 8 },
  }))

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.10)',
          borderRadius: '20px',
          overflow: 'auto',
          border: '0.50px #C7C7C7 solid',
          paddingY: '4px',
          paddingRight: '16px',
          paddingLeft: '16px',
        }}
        width={'100%'}
      >
        {matches && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '0 0 auto',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccountCircleIcon
                sx={{
                  height: 80,
                  width: 80,
                  color: COLOR.SECOUNDARY_SECOUND_3,
                }}
              ></AccountCircleIcon>
            </Box>
          </Box>
        )}

        <CardContentNoPadding sx={{ flex: '0 0 auto' }}>
          <Typography variant='subtitle1' color={'text.primary'}>
            {name}
          </Typography>
          <Typography variant='body2' color={grey[600]}>
            เบอร์โทรศัพท์ : {phone}
          </Typography>
          <Typography variant='body2' color={grey[600]}>
            อีเมล : {email}
          </Typography>
        </CardContentNoPadding>
      </Box>
    </ThemeProvider>
  )
}
