import { useEffect } from 'react'
import { Stack, Typography } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import CampaignProposal from '../../components/CampaignProposal/CampaignProposal'
import { useUser } from '../../services/UserContext'
import { ROUTE } from '../../constants/route-constant'
import {
  CREATE_CAMPAIGN,
  getFromLocalStorage,
} from '../../services/LocalStorageService'
import transformCampaignProposalData from '../../services/TransformCampaignProposalData'

function CreateCampaignProposalPage() {
  const navigate = useNavigate()
  const userContext = useUser()

  useEffect(() => {
    const role = userContext?.userData?.userrole
    if (role) {
      if (role.userRole !== 'CHURCH') {
        navigate(ROUTE.WANT_TO_SUBMIT_CAMPAIGN)
      }
    } else {
      navigate(ROUTE.WANT_TO_SUBMIT_CAMPAIGN)
    }

    window.scrollTo(0, 0)
  }, [navigate, userContext?.userData?.userrole])

  const initialData = getFromLocalStorage(CREATE_CAMPAIGN)
    ? transformCampaignProposalData(getFromLocalStorage(CREATE_CAMPAIGN))
    : undefined

  console.log('initialData', initialData)

  return (
    <>
      <ToastContainer />
      <Stack flexDirection='column' minHeight={'100vh'}>
        <Navbar />
        <Typography alignSelf={'center'} variant='h1' sx={{ margin: '20px' }}>
          เสนอโครงการ
        </Typography>
        <div className='flex min-h-screen flex-col'>
          <div className='flex-1'>
            <CampaignProposal type='CREATE' initialData={initialData} />
          </div>
          <Footer />
        </div>
      </Stack>
    </>
  )
}

export default CreateCampaignProposalPage
