import { useEffect, useRef } from 'react'
import isDeepEqual from 'fast-deep-equal/react'
import {
  LocalStorageKey,
  saveToLocalStorage,
} from '../services/LocalStorageService'

const usePeriodicSave = (
  keyName: LocalStorageKey,
  getData: () => any,
  interval: number = 5000
) => {
  const saveInterval = useRef<NodeJS.Timeout | null>(null)
  const previousDataRef = useRef<any>(null)

  useEffect(() => {
    saveInterval.current = setInterval(() => {
      const currentData = getData()

      // Compare current data with the previously saved data
      if (!isDeepEqual(previousDataRef.current, currentData)) {
        saveToLocalStorage(keyName, currentData)
        previousDataRef.current = currentData // Update the reference with the latest data
      }
    }, interval)

    return () => {
      if (saveInterval.current) {
        clearInterval(saveInterval.current)
      }
    }
  }, [getData, interval, keyName])
}

export default usePeriodicSave
