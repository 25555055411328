import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import { COLOR } from '../../constants/color-constant'
import { common, grey } from '@mui/material/colors'
import { ADMIN_ROUTE } from '../../constants/admin-route-constant'
import { ROUTE } from '../../constants/route-constant'
import { LogoutOutlined } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Divider, Tooltip, alpha, styled } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/icons/Admin/NamjaiAdmin.svg'
import AuthService from '../../services/auth.service'
import { useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Role, UserData } from '../../models/UserTypes'

function ResponsiveAppBar() {
  const location = useLocation()
  const navigate = useNavigate()
  const [userRole, setUserRole] = useState<Role>()

  const isChurch =
    location.pathname == ADMIN_ROUTE.CHURCH_REQUEST
      ? true
      : location.pathname == ADMIN_ROUTE.ALL_CHURCH
      ? true
      : false

  const isCampaign =
    location.pathname == ADMIN_ROUTE.CAMPAIGN_REQUEST
      ? true
      : location.pathname == ADMIN_ROUTE.ALL_CAMPAIGN
      ? true
      : false

  const [anchorElCamp, setAnchorElCamp] = useState<null | HTMLElement>(null)
  const openCamp = Boolean(anchorElCamp)
  const handleClickCamp = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCamp(event.currentTarget)
  }
  const handleCloseCamp = () => {
    setAnchorElCamp(null)
  }

  const [anchorElChurch, setAnchorElChurch] = useState<null | HTMLElement>(null)
  const openChurch = Boolean(anchorElChurch)
  const handleClickChurch = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElChurch(event.currentTarget)
  }
  const handleCloseChurch = () => {
    setAnchorElChurch(null)
  }

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorElMenu)
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorElMenu(null)
  }

  const [anchorElAllMenu, setAnchorElAllMenu] = useState<null | HTMLElement>(
    null
  )
  const openlAllMenu = Boolean(anchorElAllMenu)
  const handleClickAllMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAllMenu(event.currentTarget)
  }
  const handleCloseAllMenu = () => {
    setAnchorElAllMenu(null)
  }

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
    },
  }))

  const handleLogout = () => {
    AuthService.logout()
    setUserRole('GUEST')
    navigate('/')
    window.location.reload()
  }

  return (
    <AppBar
      position='static'
      elevation={0}
      style={{
        backgroundColor: common.white,
        borderBottom: `1px solid ${grey[400]}`,
      }}
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box
            onClick={() => navigate(ADMIN_ROUTE.DASHBOARD)}
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'flex' },
              mr: 1,
              cursor: 'pointer',
            }}
          >
            <Logo></Logo>
          </Box>

          {/* Desktop screen */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
            }}
            justifyContent='flex-end'
          >
            <div className='flex items-center gap-8'>
              <Box
                className={
                  location.pathname != ADMIN_ROUTE.DASHBOARD
                    ? ' hidden items-center  gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                    : ' hidden items-center  gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                }
                onClick={() => navigate(ADMIN_ROUTE.DASHBOARD)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant='subtitle1'>Dashboard</Typography>
              </Box>

              <Box
                className={
                  isChurch
                    ? 'hidden items-center  gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                    : '  hidden items-center  gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                }
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                }}
                onClick={handleClickChurch}
              >
                <Typography variant='subtitle1'>Church</Typography>
                <KeyboardArrowDownIcon />
              </Box>

              <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorElChurch}
                open={openChurch}
                onClose={handleCloseChurch}
              >
                <MenuItem onClick={() => navigate(ADMIN_ROUTE.CHURCH_REQUEST)}>
                  <div
                    className={
                      location.pathname != ADMIN_ROUTE.CHURCH_REQUEST
                        ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                        : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant='subtitle1'>Church Requests</Typography>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => navigate(ADMIN_ROUTE.ALL_CHURCH)}>
                  <div
                    className={
                      location.pathname != ADMIN_ROUTE.ALL_CHURCH
                        ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                        : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant='subtitle1'>Churchs</Typography>
                  </div>
                </MenuItem>
              </StyledMenu>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  cursor: 'pointer',
                }}
                onClick={handleClickCamp}
                className={
                  isCampaign
                    ? 'hidden items-center  gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                    : '  hidden items-center  gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                }
              >
                <Typography variant='subtitle1'>Campaign</Typography>
                <KeyboardArrowDownIcon />
              </Box>

              <StyledMenu
                id='demo-customized-menu'
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorElCamp}
                open={openCamp}
                onClose={handleCloseCamp}
              >
                <MenuItem
                  onClick={() => navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)}
                >
                  <div
                    className={
                      location.pathname != ADMIN_ROUTE.CAMPAIGN_REQUEST
                        ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                        : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant='subtitle1'>
                      Campaign Requests
                    </Typography>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => navigate(ADMIN_ROUTE.ALL_CAMPAIGN)}>
                  <div
                    className={
                      location.pathname != ADMIN_ROUTE.ALL_CAMPAIGN
                        ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                        : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <Typography variant='subtitle1'>Campaigns</Typography>
                  </div>
                </MenuItem>
              </StyledMenu>

              <Box
                className={
                  location.pathname != ADMIN_ROUTE.ALL_EARNING
                    ? ' hidden items-center  gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                    : ' hidden items-center  gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                }
                onClick={() => navigate(ADMIN_ROUTE.ALL_EARNING)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant='subtitle1'>Earning</Typography>
              </Box>

              <Box
                className={
                  location.pathname != ADMIN_ROUTE.ADMIN_MANAGEMENT
                    ? ' hidden items-center  gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                    : ' hidden items-center  gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                }
                onClick={() => navigate(ADMIN_ROUTE.ADMIN_MANAGEMENT)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant='subtitle1'>Admin</Typography>
              </Box>

              <Box
                justifyContent='flex-end'
                sx={{ flexGrow: 0, cursor: 'pointer' }}
              >
                <Tooltip title='Open settings'>
                  <IconButton onClick={handleClickMenu} sx={{ p: 0 }}>
                    <MenuIcon sx={{ fontSize: '26px' }} />
                  </IconButton>
                </Tooltip>

                <StyledMenu
                  id='demo-customized-menu'
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorElMenu}
                  open={openMenu}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={() => navigate('/')}>
                    <Typography variant='subtitle1'> Go To Namjai</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography variant='subtitle1'>Logout</Typography>
                  </MenuItem>
                </StyledMenu>
              </Box>
            </div>
          </Box>

          {/* Mobile screen */}
          <Box
            sx={{ flexGrow: 0 }}
            display={{ md: 'none' }}
            justifyContent='flex-end'
          >
            <Tooltip title='Open settings'>
              <IconButton onClick={handleClickAllMenu} sx={{ p: 0 }}>
                <MenuIcon sx={{ fontSize: '26px' }} />
              </IconButton>
            </Tooltip>
            <StyledMenu
              id='demo-customized-menu'
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorElAllMenu}
              open={openlAllMenu}
              onClose={handleCloseAllMenu}
            >
              <MenuItem onClick={() => navigate(ADMIN_ROUTE.DASHBOARD)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.DASHBOARD
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Dashboard</Typography>
                </div>
              </MenuItem>

              <MenuItem onClick={() => navigate(ADMIN_ROUTE.CHURCH_REQUEST)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.CHURCH_REQUEST
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Church Requests</Typography>
                </div>
              </MenuItem>
              <MenuItem onClick={() => navigate(ADMIN_ROUTE.ALL_CHURCH)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.ALL_CHURCH
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Churchs</Typography>
                </div>
              </MenuItem>
              <MenuItem onClick={() => navigate(ADMIN_ROUTE.CAMPAIGN_REQUEST)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.CAMPAIGN_REQUEST
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Campaign Requests</Typography>
                </div>
              </MenuItem>
              <MenuItem onClick={() => navigate(ADMIN_ROUTE.ALL_CAMPAIGN)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.ALL_CAMPAIGN
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Campaigns</Typography>
                </div>
              </MenuItem>
              <MenuItem onClick={() => navigate(ADMIN_ROUTE.ALL_EARNING)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.ALL_EARNING
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Earning</Typography>
                </div>
              </MenuItem>
              <MenuItem onClick={() => navigate(ADMIN_ROUTE.ADMIN_MANAGEMENT)}>
                <div
                  className={
                    location.pathname != ADMIN_ROUTE.ADMIN_MANAGEMENT
                      ? 'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                      : 'xs:block flex items-center gap-2 text-[#0079FF] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Admin</Typography>
                </div>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => navigate('/')}>
                <div
                  className={
                    'xs:block flex items-center gap-2 text-[#212121] hover:text-[#0079FF] sm:block'
                  }
                >
                  <Typography variant='subtitle1'>Go To Namjai</Typography>
                </div>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <div className='xs:block sm:block'>
                  <Typography
                    variant='subtitle1'
                    className={
                      'flex items-center gap-2 text-[#212121] hover:text-[#0079FF]'
                    }
                  >
                    Logout
                  </Typography>
                </div>
              </MenuItem>
            </StyledMenu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar

function setUserRole(arg0: string) {
  throw new Error('Function not implemented.')
}
