import { Box, Card, Typography, Divider, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { COLOR } from '../../../constants/color-constant'
import { generateEditCampaignProgressAdminRoute } from '../../../utils/routeGenerator'
export default function AdminCardtoEdit(props: {
  campaignId: string
  camProgressCount: number | undefined
}) {
  const campaignId = props.campaignId
  const camProgressCount = props.camProgressCount
  const navigate = useNavigate()
  const borderColor = COLOR.PRIMARY_2

  return (
    <Card
      sx={{
        borderRadius: '20px',
        minHeight: '150px',
        p: '25px',
        alignItems: 'center',
        boxShadow: 3,
        marginBottom: '20px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Typography align='center' variant='h6' color='text' sx={{ mt: 1 }}>
          สำหรับแอดมิน
        </Typography>
        <Button
          variant='outlined'
          onClick={() => {
            navigate(`/admin/campaigns/${campaignId}/edit`)
          }}
        >
          <Typography variant='h6' color='text'>
            แก้ไขแคมเปญ
          </Typography>
        </Button>
        <Divider sx={{ padding: '4px' }} />
        {/* Check if camProgressCount is defined and greater than 0 */}
        {camProgressCount !== undefined && camProgressCount > 0 && (
          <>
            <Typography
              align='center'
              variant='h5'
              color={COLOR.PRIMARY_2}
              sx={{ mt: 1 }}
            >
              แก้ไขความคืบหน้า
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '10px',
              }}
            >
              {Array.from(
                { length: camProgressCount },
                (_, index) => index + 1
              ).map((page) => (
                <Button
                  style={{
                    borderColor: borderColor,
                    color: borderColor,
                  }}
                  onClick={() => {
                    navigate(
                      generateEditCampaignProgressAdminRoute(
                        campaignId,
                        '' + page
                      )
                    )
                  }}
                  variant='outlined'
                >
                  ครั้งที่ {page}
                </Button>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Card>
  )
}
