import {
  Container,
  Box,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  ThemeProvider,
} from '@mui/material'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import { theme } from '../constants/theme'
import { Link } from 'react-router-dom'

export default function PrivacyPolicy() {
  document.body.style.background = '#FBFDFF'

  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Container
        sx={{ paddingX: '48px', paddingY: '24px', background: '#ffffff' }}
      >
        <Stack gap={'10px'} justifyContent={'center'} alignItems={'center'}>
          <Stack textAlign={'center'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4'>
              นโยบายคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)
            </Typography>
          </Stack>
          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h6'>
              &emsp;&emsp; น้ำใจ ดำเนินงานภายใต้มูลนิธิดรุณาทร (“น้ำใจ”)
              ตระหนักถึงความสำคัญในการรักษาความเป็นส่วนตัวและความปลอดภัยของข้อมูลส่วนบุคคลของท่าน
              (“ท่าน”) ซึ่งเป็นผู้ใช้งานเว็บไซต์ (namjaisociety.org)
              รวมถึงเว็บไซต์ ฟีเจอร์ เทคโนโลยี เนื้อหา และผลิตภัณฑ์ต่างๆ
              ที่เกี่ยวข้อง (ซึ่งต่อไปนี้จะเรียกรวมกันว่า “แพลตฟอร์ม”) ดังนั้น
              น้ำใจจึงได้ประกาศนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้นเพื่อให้ท่านได้รับทราบถึงวิธีการที่น้ำใจเก็บรวบรวม
              ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
              ตลอดจนสิทธิตามกฎหมายของท่านที่เกี่ยวข้องกับข้อมูลส่วนบุคคล
              ซึ่งเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
              ตลอดจนกฎหมาย
              และกฎเกณฑ์ที่เกี่ยวข้องทั้งที่บังคับใช้อยู่ในปัจจุบันและที่ได้มีการแก้ไข/เพิ่มเติม
              (หากมี)
              <br />
              &emsp;&emsp; การใช้บริการแพลตฟอร์มของน้ำใจ
              และ/หรือสมัครสมาชิกบนแพลตฟอร์มของน้ำใจ ท่าน และ/หรือบิดามารดา
              ผู้ปกครอง หรือ ผู้แทนโดยชอบธรรมของท่าน (ในกรณีที่ท่านมีอายุต่ำกว่า
              20 ปี)
              ต้องรับทราบถึงแนวทางปฏิบัติและวิธีการซึ่งระบุไว้ในนโยบายความเป็นส่วนตัวฉบับนี้
              <br />
              &emsp;&emsp;
              แพลตฟอร์มของน้ำใจอาจมีลิงค์ที่เชื่อมต่อไปยังหรือเชื่อมต่อมาจากเว็บไซต์ต่างๆของบุคคลภายนอก
              หากท่านกดตามลิงค์ไปยังเว็บไซต์ของบุคคลภายนอกดังกล่าว
              น้ำใจขอแจ้งให้ท่านทราบว่าการประมวลผลข้อมูลส่วนบุคคลของท่านจะเป็นไปตามนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นทั้งสิ้น
              ด้วยเหตุนี้
              น้ำใจขอแนะนำให้ท่านอ่านและทำความเข้าใจนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นเมื่อท่านเข้าใช้แพลตฟอร์มนั้นๆ
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-semi-bold' }}>
              การเก็บข้อมูลส่วนบุคคลของท่านโดยน้ำใจ
            </Typography>

            <Typography variant='h6'>
              ข้อมูลส่วนบุคคลที่น้ำใจเก็บรวบรวมจากท่าน ได้แก่ ข้อมูลใดๆ
              ซึ่งสามารถใช้ในการระบุตัวตนของท่านได้ไม่ว่าโดยตรงหรือโดยอ้อม
              ดังต่อไปนี้
            </Typography>

            <Typography variant='h6'>
              <ol style={{ paddingLeft: '50px', listStyleType: 'decimal' }}>
                <li>
                  <span style={{ fontWeight: 'bold' }}>ข้อมูลประจำตัว</span>{' '}
                  ได้แก่ ชื่อ-นามสกุล เลขบัตรประจำตัวประชาชน เบอร์โทรศัพท์ อีเมล
                  ที่อยู่
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>
                    ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                  </span>{' '}
                  ได้แก่ วันเวลาในการใช้งาน อุปกรณ์การเข้าใช้งาน
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>ข้อมูลการเงิน</span>{' '}
                  ได้แก่ จำนวนเงินในประวัติการบริจาค วันและเวลาที่ทำรายการ
                  วิธีการชำระเงิน
                  หลักฐานการชำระเงินโดยการโอนเงินผ่านแอปพลิเคชันธนาคาร
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>
                    ข้อมูลการติดต่อสื่อสาร
                  </span>{' '}
                  รวมถึง
                  รูปแบบการติดต่อสื่อสารที่ท่านประสงค์ให้เราใช้เพื่อส่งข้อมูลการปรับปรุงแพลตฟอร์มและบริการและการสนับสนุนลูกค้า
                  (customer support) เช่น อีเมล หรือ Facebook Chat
                  และหมายเลขโทรศัพท์ที่ใช้ในการติดต่อกับฝ่ายบริการลูกค้าของน้ำใจ
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>
                    ข้อมูลความต้องการและข้อคิดเห็นของท่านต่อแพลตฟอร์มของน้ำใจ
                  </span>{' '}
                  ตลอดจนบันทึกการโต้ตอบและการสื่อสารระหว่างน้ำใจกับท่าน
                  ไม่ว่าจะในรูปแบบหรือวิธีใดๆ ก็ตาม เช่น โทรศัพท์ อีเมล
                  ข้อความสนทนาและการสื่อสารบนแพลตฟอร์ม หรือสื่อสังคมออนไลน์ต่างๆ
                  ซึ่งรวมถึงข้อมูลที่ท่านให้แก่น้ำใจเมื่อท่านเข้าร่วมกิจกรรมแคมเปญส่งเสริมการขาย
                  หรือการสำรวจใดๆ ที่น้ำใจเป็นผู้จัดขึ้น
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>ข้อมูลทางเทคนิค</span>{' '}
                  เช่น หมายเลขประจำเครื่องคอมพิวเตอร์ (IP Address)
                  ซึ่งใช้ในการเชื่อมต่อคอมพิวเตอร์ของท่านกับอินเตอร์เน็ต
                  ข้อมูลที่ใช้ในการเข้าระบบ ประเภทและเวอร์ชั่นของบราวเซอร์ที่ใช้
                  ข้อมูลคุกกี้ การตั้งค่าเขตเวลา
                  ประเภทและเวอร์ชั่นของส่วนขยายในบราวเซอร์ (browser plug-in)
                  ระบบปฏิบัติการและแพลตฟอร์ม
                </li>
                <li>
                  <span style={{ fontWeight: 'bold' }}>
                    ข้อมูลเกี่ยวกับการเยี่ยมชมของท่าน
                  </span>{' '}
                  เช่น รายการของตัวระบุทรัพยากรสากล หรือ URLs
                  ทั้งหมดที่เชื่อมมายังแพลตฟอร์ม ผ่านแพลตฟอร์ม
                  และจากแพลตฟอร์มของน้ำใจ (รวมถึงวันที่และเวลา)
                  ผลิตภัณฑ์ที่ท่านกำลังดูหรือมองหา เวลาตอบสนองของหน้าเพจ
                  ปัญหาการดาวน์โหลด ระยะเวลาที่เยี่ยมชมแต่ละหน้าเพจ
                  ข้อมูลการปฏิสัมพันธ์บนหน้าเพจ (เช่น การเลื่อน การคลิ๊ก
                  หรือการเคลื่อนที่ของเมาส์) วิธีที่ใช้ในการออกจากหน้าเพจ
                </li>
              </ol>
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              แหล่งที่มาของข้อมูลส่วนบุคคล
            </Typography>

            <Typography variant='h6'>
              <ol style={{ listStyleType: 'decimal', paddingLeft: '18px' }}>
                <li>
                  <Typography variant='h6'>
                    น้ำใจอาจได้รับข้อมูลส่วนบุคคลของท่านจาก 3 ช่องทาง ดังนี้
                  </Typography>
                  <Typography variant='h6'>
                    <ol
                      style={{
                        paddingLeft: '50px',
                        listStyleType: 'decimal',
                      }}
                    >
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          ปฏิสัมพันธ์โดยตรง{' '}
                        </span>{' '}
                        ท่านอาจให้ข้อมูลประจำตัว
                        ผ่านทางการทำรายการบริจาค/ลงทะเบียน/สมัครสมาชิกบนแพลตฟอร์ม
                        ค้นหาผลิตภัณฑ์/บริการของน้ำใจตามสื่อสังคมออนไลน์ต่างๆ
                        เข้าร่วมพูดคุย/แสดงความคิดเห็นกับน้ำใจผ่านทางโทรศัพท์
                        อีเมล แพลตฟอร์ม หรือสื่อสังคมออนไลน์ต่างๆ
                        รวมถึงเมื่อท่านเข้าร่วมกิจกรรม แคมเปญส่งเสริมการขาย
                        หรือการสำรวจใดๆ ที่น้ำใจเป็นผู้จัดขึ้น
                      </li>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          เทคโนโลยีหรือการโต้ตอบอัตโนมัติ
                        </span>{' '}
                        เมื่อคุณโต้ตอบกับแอปพลิเคชั่นของน้ำใจ
                        น้ำใจอาจรวบรวมข้อมูลทางเทคนิคเกี่ยวกับอุปกรณ์ของคุณโดยอัตโนมัติ
                        น้ำใจรวบรวมข้อมูลส่วนบุคคลนี้โดยใช้ คุ้กกี้
                        และเทคโนโลยีที่คล้ายคลึงกันอื่นๆ
                      </li>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>
                          ข้อมูลที่น้ำใจได้รับจากแหล่งข้อมูลอื่น{' '}
                        </span>{' '}
                        น้ำใจอาจได้รับข้อมูลส่วนบุคคลของท่าน
                        มาจากบุคคลที่มีความเกี่ยวเนื่องกับท่าน (เช่น ครอบครัว
                        เพื่อน และผู้แนะนำ) ตลอดจนบุคคลภายนอก (เช่น
                        คู่ค้าทางธุรกิจ ผู้ให้บริการเว็บไซต์ที่ท่านเข้าเยี่ยมชม
                        ผู้ให้บริการแอปพลิเคชัน/แพลตฟอร์มอื่นที่ท่านเป็นสมาชิกหรือเข้าใช้งาน
                        ผู้รับเหมาช่วงงานบริการทางเทคนิค ผู้ให้บริการรับชำระเงิน
                        ผู้ให้บริการด้านการโฆษณา ผู้ให้บริการวิเคราะห์ข้อมูล
                        และผู้ให้บริการสืบค้นข้อมูล เป็นต้น) (“คู่ค้าของน้ำใจ”)
                        ซึ่งรวมถึง กรณีดังต่อไปนี้
                        <ol
                          style={{
                            paddingLeft: '50px',
                            listStyleType: 'decimal',
                          }}
                        >
                          <li>
                            เมื่อท่านเข้าเยี่ยมชมหรือใช้เว็บไซต์และแอปพลิเคชันของบุคคลภายนอกที่มีความเกี่ยวข้องกับน้ำใจ
                          </li>
                          <li>
                            เมื่อท่านใช้หรือร้องขอการใช้บริการกับผู้ให้บริการภายนอกที่เกี่ยวข้องกับน้ำใจ
                            เช่น
                            สมัครใช้บริการชำระเงินที่น้ำใจมีนิติสัมพันธ์ด้วย
                          </li>
                          <li>
                            เมื่อท่านเชื่อมต่อบัญชีผู้ใช้งานของท่านกับเว็บไซต์หรือแอปพลิเคชันของบุคคลภายนอก
                            ซึ่งน้ำใจอาจออกรหัสภายในโดยอัตโนมัติเพื่อใช้เชื่อมต่อและแบ่งปันข้อมูลของท่านกับเว็บไซต์หรือแอปพลิเคชันดังกล่าว
                          </li>
                          <li>
                            นอกจากนี้
                            ท่านอาจเข้าสู่แพลตฟอร์มของน้ำใจผ่านระบบล็อคอินของสื่อสังคมออนไลน์ต่างๆ
                            เช่น Facebook Google หรือ Apple
                            โดยไม่ต้องกรอกข้อมูลของท่านบนแพลตฟอร์ม ในกรณีนี้
                            ทางน้ำใจถือว่าท่านได้ตกลงให้น้ำใจเข้าถึงและจัดเก็บข้อมูลสาธารณะของท่านที่อยู่ในสื่อสังคมออนไลน์ดังกล่าว
                            รวมไปถึงข้อมูลอื่นๆที่ปรากฏขึ้นระหว่างการใช้ระบบล็อคอินผ่านสื่อสังคมออนไลน์นั้นเพื่อการใช้บริการแพลตฟอร์มของน้ำใจต่อไป
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </Typography>
                </li>

                <li>
                  <Typography variant='h6'>
                    ในกรณีที่น้ำใจได้รับข้อมูลส่วนบุคคลของท่านจากแหล่งอื่นที่ไม่ใช่จากท่านโดยตรง
                    น้ำใจจะแจ้งให้ท่านทราบถึงการเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่นข้างต้นให้ท่านทราบภายใน
                    30 วันนับแต่วันที่น้ำใจได้เก็บรวบรวมข้อมูลส่วนบุคคลข้างต้น
                    และน้ำใจจะตรวจสอบให้แน่ใจว่าน้ำใจได้รับความยินยอมให้เก็บรวบรวม
                    ใช้ และประมวลผลข้อมูลส่วนบุคคลจากท่าน
                    เว้นแต่จะน้ำใจสามารถอาศัยฐานทางกฎหมายที่เกี่ยวข้องอื่นอันเป็นข้อยกเว้นของความยินยอมได้
                  </Typography>
                </li>
              </ol>
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-semi-bold' }}>
              วัตถุประสงค์ในการเก็บ รวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล
            </Typography>

            <ol
              style={{
                listStyleType: 'decimal',
                paddingLeft: '18px',
                width: '100%',
              }}
            >
              <Typography variant='h6'>
                <li>
                  น้ำใจจะเก็บรวบรวม ใช้
                  หรือเปิดเผยข้อมูลของท่านเฉพาะกรณีที่จำเป็นเท่านั้น
                  ซึ่งรวมถึงกรณีเพื่อการดำเนินการตามภาระหน้าที่ตามกฎหมายของน้ำใจ
                  การปฏิบัติตามสัญญาระหว่างท่านกับน้ำใจ
                  เพื่อประโยชน์โดยชอบด้วยกฎหมายของน้ำใจ
                  รวมถึงการดำเนินการตามความยินยอมของท่าน และ/หรือ
                  ภายใต้ฐานทางกฎหมายอื่นๆ
                  แล้วแต่กรณีโดยการใช้ข้อมูลส่วนบุคคลของท่านตามแต่ละวัตถุประสงค์นั้นมีรายละเอียดดังต่อไปนี้
                  <Box sx={{ overflow: 'auto' }}>
                    <TableContainer
                      sx={{
                        borderRadius: '8px',
                        border: 1,
                        borderColor: '#C7C7C7',
                        marginY: '10px',
                        display: 'block',
                        minWidth: '700px',
                      }}
                    >
                      <Table size='small'>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#cfe2f3' }}>
                            <TableCell
                              align='right'
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Typography variant='h6' textAlign={'left'}>
                                วัตถุประสงค์ / กิจกรรม
                              </Typography>
                            </TableCell>
                            <TableCell
                              align='right'
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Typography variant='h6' textAlign={'left'}>
                                ประเภทของข้อมูล
                              </Typography>
                            </TableCell>
                            <TableCell align='right' width={'35%'}>
                              <Typography variant='h6' textAlign={'left'}>
                                อำนาจตามกฎหมายในการประมวลผล
                                รวมถึงประโยชน์โดยชอบด้วยกฎหมาย
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ verticalAlign: 'top' }}>
                            <TableCell
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      การยืนยันตัวตนหรือระบุตัวตนของท่านในเวลาที่ท่านเข้าใช้แพลตฟอร์มของน้ำใจเพื่อปฏิบัติหน้าที่ของน้ำใจในการให้ข้อมูล
                                      จัดหาเนื้อหา ผลิตภัณฑ์ โครงการ
                                      และบริการแก่ท่านนอกจากนี้
                                      ยังช่วยให้น้ำใจสามารถแยกแยะตัวตนของท่านจากผู้ใช้แพลตฟอร์มท่านอื่น
                                      ๆ
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อติดตามหรือบันทึกการใช้งานของท่านบนแพลตฟอร์ม
                                      ตลอดจนจัดทำรายงานที่เกี่ยวข้องต่างๆ
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อดำเนินการตามคำขอของท่านก่อนการเข้าทำสัญญากับน้ำใจ
                                      และพิจารณาอนุมัติเกี่ยวกับการให้บริการ
                                      รวมถึงส่งมอบผลิตภัณฑ์
                                      และ/หรือบริการของน้ำใจแก่ท่าน
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลประจำตัว
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการติดต่อสื่อสาร
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการเงิน
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลทางเทคนิค
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell width={'35%'}>
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                                      (สำหรับการความปลอดภัยของเครือข่ายและเพื่อป้องกันการฉ้อโกง)
                                      เป็นการปฏิบัติตามกฎหมายของน้ำใจ
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อการปฏิบัติตามสัญญาที่ท่านได้ทำไว้กับน้ำใจ
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              verticalAlign: 'top',
                              backgroundColor: '#cfe2f3',
                            }}
                          >
                            <TableCell
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ปรับปรุงการให้บริการ
                                      น้ำใจอาจใช้ข้อมูลของท่านเพื่อวัตถุประสงค์ในการปรับปรุงการให้บริการ
                                      เช่น
                                      การตรวจสอบประวัติเพื่อการจัดหาปรับปรุงผลิตภัณฑ์และบริการ
                                      การรวบรวมข้อมูลของท่านเพื่อมาสร้างสถิติ
                                      เพื่อประโยชน์สูงสุดในการตอบสนองความต้องการของท่าน
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลประจำตัว
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการเงิน
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการติดต่อสื่อสาร
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลความต้องการและข้อคิดเห็น
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลทางเทคนิค
                                    </Typography>
                                  </ListItem>{' '}
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลเกี่ยวกับการเยี่ยมชมของท่าน
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell width={'35%'}>
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อพัฒนาบริการของน้ำใจ
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ verticalAlign: 'top' }}>
                            <TableCell
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      การสื่อสารเกี่ยวกับภาระและนโยบาย
                                      น้ำใจอาจใช้ข้อมูลของท่านเพื่อสื่อสารกับท่านเกี่ยวกับการภาระผูกพันของท่านต่อน้ำใจและการเปลี่ยนแปลงใดๆ
                                      ในข้อกำหนดเงื่อนไข
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการติดต่อสื่อสาร
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell width={'35%'}>
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                                      (เพื่อการสื่อสารกับท่านเกี่ยวกับภาระผูกพันของท่านต่อน้ำใจการเปลี่ยนแปลงใดๆ
                                      ในนโยบาย) เป็นการปฏิบัติตามกฎหมายของน้ำใจ{' '}
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              verticalAlign: 'top',
                              backgroundColor: '#cfe2f3',
                            }}
                          >
                            <TableCell
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      การโต้ตอบแก้ไขปัญหาเพื่อแจ้งให้ท่านทราบหรือแก้ไขปัญหาที่อาจเกิดขึ้นในบริการของน้ำใจ
                                      เพื่อจัดการการทำงานแบบโต้ตอบ (Interactive)
                                      ของแพลตฟอร์ม
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อบริหารจัดการความสัมพันธ์ระหว่างน้ำใจและท่าน
                                      (เช่น ดูแลลูกค้า ประเมินความพึงพอใจ
                                      จัดการข้อร้องเรียน){' '}
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการเงิน
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการติดต่อสื่อสาร
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลความต้องการและข้อคิดเห็น
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลทางเทคนิค
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลเกี่ยวกับการเยี่ยมชมของท่าน
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell width={'35%'}>
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                                      (ช่วยท่านแก้ไขปัญหา)
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ verticalAlign: 'top' }}>
                            <TableCell
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ปกป้องธุรกิจและแพลตฟอร์ม
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อจัดการและปกป้องธุรกิจและแพลตฟอร์มของน้ำใจ
                                      รวมถึงการตรวจสอบการละเมิดข้อกำหนดและเงื่อนไขในการใช้แพลตฟอร์มของน้ำใจและการแก้ไขปัญหาการวิเคราะห์ข้อมูลการทดสอบการบำรุงรักษาระบบการสนับสนุนการรายงานและการโฮสต์ข้อมูล
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลประจำตัว
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการเงิน
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการติดต่อสื่อสาร
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลทางเทคนิค
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลเกี่ยวกับการเยี่ยมชมของท่าน
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell width={'35%'}>
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                                      (สำหรับการให้บริการของน้ำใจ
                                      การให้บริการด้านการบริหารและไอทีความปลอดภัยของเครือข่ายและเพื่อป้องกันการฉ้อโกง)
                                      เป็นการปฏิบัติตามกฎหมายของน้ำใจ
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              verticalAlign: 'top',
                              backgroundColor: '#cfe2f3',
                            }}
                          >
                            <TableCell
                              width={'35%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อการปฏิบัติตามกฎหมายของน้ำใจ{' '}
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell
                              width={'30%'}
                              sx={{
                                borderRight: '1px solid #C7C7C7',
                              }}
                            >
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลประจำตัว
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการเงิน
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลการติดต่อสื่อสาร
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลทางเทคนิค
                                    </Typography>
                                  </ListItem>
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      ข้อมูลเกี่ยวกับการเยี่ยมชมของท่าน
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                            <TableCell width={'35%'}>
                              <Stack>
                                <List
                                  sx={{
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                  }}
                                  disablePadding
                                >
                                  <ListItem
                                    sx={{ display: 'list-item' }}
                                    disablePadding
                                  >
                                    <Typography variant='h6' display={'inline'}>
                                      เพื่อเป็นการปฏิบัติตามกฎหมายของน้ำใจเมื่อมีเหตุจำเป็น
                                    </Typography>
                                  </ListItem>
                                </List>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </li>
              </Typography>
              <Typography variant='h6'>
                <li>
                  โดยน้ำใจ อาจจะแบ่งปันข้อมูลของท่านกับ
                  <ol
                    style={{
                      paddingLeft: '50px',
                      listStyleType: 'decimal',
                    }}
                  >
                    <li>
                      <Typography variant='h6'>
                        โดยน้ำใจ อาจจะแบ่งปันข้อมูลของท่านกับหน่วยงานของรัฐ เช่น
                        การส่งข้อมูลการบริจาคให้สรรพากรเพื่ออำนวยความสะดวกของท่าน
                        และ/หรือ
                        หน่วยงานที่มีหน้าที่กำกับดูแลธุรกิจของน้ำใจโดยน้ำใจ
                        <Box sx={{ overflow: 'auto' }}>
                          <TableContainer
                            sx={{
                              borderRadius: '8px',
                              border: 1,
                              borderColor: '#C7C7C7',
                              display: 'block',
                              minWidth: '700px',
                              marginY: '10px',
                            }}
                          >
                            <Table size='small'>
                              <TableHead>
                                <TableRow sx={{ backgroundColor: '#cfe2f3' }}>
                                  <TableCell
                                    align='right'
                                    width={'35%'}
                                    sx={{
                                      borderRight: '1px solid #C7C7C7',
                                    }}
                                  >
                                    <Typography variant='h6' textAlign={'left'}>
                                      วัตถุประสงค์ / กิจกรรม
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    align='right'
                                    width={'30%'}
                                    sx={{
                                      borderRight: '1px solid #C7C7C7',
                                    }}
                                  >
                                    <Typography variant='h6' textAlign={'left'}>
                                      ประเภทของข้อมูล
                                    </Typography>
                                  </TableCell>
                                  <TableCell align='right' width={'35%'}>
                                    <Typography variant='h6' textAlign={'left'}>
                                      อำนาจตามกฎหมายในการประมวลผล
                                      รวมถึงประโยชน์โดยชอบด้วยกฎหมาย
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow sx={{ verticalAlign: 'top' }}>
                                  <TableCell
                                    width={'35%'}
                                    sx={{
                                      borderRight: '1px solid #C7C7C7',
                                    }}
                                  >
                                    <Stack>
                                      <List
                                        sx={{
                                          listStyleType: 'disc',
                                          listStylePosition: 'inside',
                                        }}
                                        disablePadding
                                      >
                                        <ListItem
                                          sx={{ display: 'list-item' }}
                                          disablePadding
                                        >
                                          <Typography
                                            variant='h6'
                                            display={'inline'}
                                          >
                                            เพื่อส่งข้อมูลการบริจาคให้สรรพากร
                                            โดยเป็นการเพิ่มความสะดวกให้ผู้บริจาค
                                            ไม่ต้องเก็บใบเสร็จเอง
                                            และเวลายื่นภาษีผู้บริจาคไม่ต้องกรอกเอง
                                          </Typography>
                                        </ListItem>
                                      </List>
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    width={'30%'}
                                    sx={{
                                      borderRight: '1px solid #C7C7C7',
                                    }}
                                  >
                                    <Stack>
                                      <List
                                        sx={{
                                          listStyleType: 'disc',
                                          listStylePosition: 'inside',
                                        }}
                                        disablePadding
                                      >
                                        <ListItem
                                          sx={{ display: 'list-item' }}
                                          disablePadding
                                        >
                                          <Typography
                                            variant='h6'
                                            display={'inline'}
                                          >
                                            ชื่อ นามสกุล,
                                            หมายเลขประจำตัวผู้เสียภาษี และ
                                            จำนวนเงินบริจาค
                                          </Typography>
                                        </ListItem>
                                      </List>
                                    </Stack>
                                  </TableCell>
                                  <TableCell width={'35%'}>
                                    <Stack>
                                      <List
                                        sx={{
                                          listStyleType: 'disc',
                                          listStylePosition: 'inside',
                                        }}
                                        disablePadding
                                      >
                                        <ListItem
                                          sx={{ display: 'list-item' }}
                                          disablePadding
                                        >
                                          <Typography
                                            variant='h6'
                                            display={'inline'}
                                          >
                                            เพื่อประโยชน์โดยชอบด้วยกฎหมาย
                                            ตามความต้องการของผู้บริจาค
                                          </Typography>
                                        </ListItem>
                                      </List>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        คู่ค้าของน้ำใจ ตัวแทน หรือองค์กรอื่น (เช่น
                        ผู้ตรวจสอบบัญชีอิสระ ผู้ให้บริการจัดเก็บข้อมูลและเอกสาร
                        ผู้ประมวลผลข้อมูลส่วนบุคคล
                        และผู้ให้บริการแพลตฟอร์มอื่นที่มีนิติสัมพันธ์กับน้ำใจ
                        และ/หรือท่าน)
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        ในกรณีของการฟื้นฟูกิจการ การควบรวมกิจการ
                        การโอนธุรกิจไม่ว่าทั้งหมดหรือบางส่วน การขาย การซื้อ
                        การดำเนินกิจการร่วมค้า การมอบ การโอน
                        หรือการจำหน่ายทั้งหมดหรือบางส่วนของธุรกิจ ทรัพย์สิน หุ้น
                        หรือธุรกรรมอื่นที่คล้ายกัน
                        น้ำใจย่อมมีความจำเป็นที่จะต้องเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลที่สามที่ได้รับการโอนหรือที่ประสงค์จะรับโอนสิทธิของดังกล่าวของน้ำใจ{' '}
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        ศาล หน่วยงาน
                        หรือบุคคลใดๆที่น้ำใจถูกกำหนดหรือได้รับอนุญาตให้เปิดเผยข้อมูลส่วนบุคคลตามกฎหมาย
                        กฎระเบียบ หรือคำสั่ง
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        ผู้ให้บริการวิเคราะห์ข้อมูลและโปรแกรมค้นหา (Search
                        Engines)
                        ที่ช่วยน้ำใจในการพัฒนาและเพิ่มประสิทธิภาพของแพลตฟอร์มของน้ำใจ
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        ผู้ให้บริการด้านสื่อสังคมออนไลน์ที่ได้มาตรฐาน
                        หรือน้ำใจโฆษณาภายนอกโดยมีวัตถุประสงค์เพื่อแสดงข้อความให้แก่ท่านและผู้ใช้บริการรายอื่นเกี่ยวกับแพลตฟอร์มของน้ำใจ
                        โดยอาจมีการใช้ข้อมูลประวัติกิจกรรมออนไลน์ของท่านเพื่อจัดสรรโฆษณาที่ท่านอาจสนใจ
                        ทั้งนี้
                        น้ำใจจะไม่เปิดเผยข้อมูลที่ทำให้สามารถระบุตัวตนได้
                        แต่น้ำใจจะให้ข้อมูลในภาพรวมเกี่ยวกับผู้ใช้ของน้ำใจแก่ผู้ให้บริการ/น้ำใจดังกล่าว
                        (ยกตัวอย่างเช่น ข้อมูลเกี่ยวกับผู้ชาย 500
                        คนซึ่งมีอายุต่ำกว่า 30 ปี
                        ซึ่งได้เข้าใช้ลิงค์โฆษณาในช่วงวันใดวันหนึ่ง)
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        ผู้ให้บริการอื่นๆของน้ำใจ ซึ่งรวมถึง แต่ไม่จำกัดเพียง
                        (1) ผู้ให้บริการอินเทอร์เน็ต (2)
                        ผู้ให้บริการด้านเทคโนโลยีสารสนเทศ (3)
                        ผู้ให้บริการด้านการชำระเงินและระบบชำระเงิน (4)
                        ผู้ให้บริการด้านการตลาด สื่อโฆษณา การออกแบบ
                        การสร้างสรรค์ และการสื่อสาร
                        รวมถึงการจัดกิจกรรมที่เกี่ยวข้องต่างๆ (5)
                        ผู้ให้บริการด้านโทรคมนาคมและการสื่อสาร (6)
                        ผู้ให้บริการด้านการจัดเก็บข้อมูลและบริการคลาวด์ไม่ว่าในประเทศหรือต่างประเทศ
                        (7) ผู้ให้บริการด้านการพิมพ์ (8) ทนายความ/
                        ที่ปรึกษาทางกฎหมาย/ ผู้ตรวจสอบบัญชี
                        และ/หรือผู้ประกอบวิชาชีพอื่นๆที่ช่วยเหลือในการดำเนินธุรกิจของน้ำใจ
                        (9) ผู้ให้บริการด้านการจัดเก็บและ/หรือทำลายเอกสาร
                        เป็นต้น
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='h6'>
                        ผู้รับมอบอำนาจ ผู้รับมอบอำนาจช่วง ตัวแทน
                        หรือผู้แทนโดยชอบธรรมของท่านที่มีอำนาจตามกฎหมายโดยชอบ
                      </Typography>
                    </li>
                  </ol>
                </li>
              </Typography>

              <Typography variant='h6'>
                <li>
                  ทั้งนี้
                  บุคคลหรือหน่วยงานภายนอกที่เราอาจจะแบ่งปันข้อมูลของท่านนั้นจะเก็บ
                  รวบรวม ใช้
                  หรือเปิดเผยข้อมูลส่วนบุคคลของท่านภายในขอบเขตตามกฎหมายหรือที่ได้รับอนุญาตเท่านั้น
                </li>
              </Typography>
            </ol>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-semi-bold' }}>
              การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ
            </Typography>

            <Typography variant='h6'>
              เนื่องจากน้ำใจดำเนินงานภายใต้มูลนิธิดรุณาดร หรือ Compassion
              ซึ่งมีเครือข่ายที่อยู่ต่างประเทศ ดังนั้น
              น้ำใจอาจมีความจำเป็นต้องส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศเพื่อการจัดเก็บและประมวลผล
              ในกรณีดังกล่าว
              น้ำใจจะใช้ความพยายามอย่างดีที่สุดในการส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังประเทศปลายทางที่มีความน่าเชื่อถือ
              และมีมาตรการรักษาความปลอดภัยที่เทียบเท่าตามที่กฎหมายภายในประเทศได้กำหนดเอาไว้
              เว้นแต่
            </Typography>

            <Typography variant='h6'>
              <ol style={{ paddingLeft: '50px', listStyleType: 'decimal' }}>
                <li>เป็นการปฏิบัติตามกฎหมาย</li>
                <li>
                  ได้แจ้งให้ท่านทราบถึงมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอของประเทศปลายทางและได้รับความยินยอมจากท่าน
                </li>
                <li>
                  ปฏิบัติตามสัญญาที่ท่านทำไว้กับน้ำใจ
                  หรือตามคำขอของท่านก่อนการเข้าทำสัญญา
                </li>
                <li>
                  ปฏิบัติตามสัญญาระหว่างน้ำใจกับบุคคล/นิติบุคคลอื่นเพื่อประโยชน์ของท่าน
                </li>
                <li>
                  ป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                  หรือสุขภาพของท่านหรือของบุคคลอื่น
                  เมื่อท่านไม่สามารถให้ความยินยอมในขณะนั้นได้ หรือ
                </li>
                <li>ดำเนินภารกิจเพื่อประโยชน์สาธารณะที่สำคัญ</li>
              </ol>
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-semi-bold' }}>
              การจัดเก็บและการรักษาความปลอดภัยของข้อมูลส่วนบุคคล
            </Typography>

            <Typography variant='h6'>
              <ol style={{ listStyleType: 'decimal', paddingLeft: '18px' }}>
                <li>
                  น้ำใจได้ใช้มาตรการรักษาความปลอดภัย
                  ทั้งมาตรการในการบริหารจัดการ (Organizational Measure)
                  และมาตรการเชิงเทคนิค (Technical Measure)
                  ที่เหมาะสมเพื่อจัดเก็บรักษาข้อมูลส่วนบุคคลของท่านให้ปลอดภัย
                  ยกตัวอย่างเช่น
                  การใช้มาตรการเข้ารหัสข้อมูลเพื่อป้องกันการเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต
                  นอกจากนี้ พนักงาน ลูกจ้าง
                  และผู้ให้บริการภายนอกของน้ำใจมีหน้าที่ต้องรักษาความลับของข้อมูลส่วนบุคคลของเจ้าของข้อมูล
                  และต้องปฏิบัติตามมาตรฐานและนโยบายการรักษาความปลอดภัยของข้อมูลที่เคร่งครัดเมื่อมีการใช้
                  ส่ง โอน หรือประมวลผลใดๆกับข้อมูลส่วนบุคคลของท่าน
                </li>
                <li>
                  ในกรณีที่น้ำใจหรือท่านได้กำหนดรหัสผ่านเพื่อการเข้าใช้บริการแพลตฟอร์ม
                  ท่านจะต้องรับผิดชอบในการรักษารหัสผ่านดังกล่าวไว้เป็นความลับเพื่อป้องกันไม่ให้บุคคลอื่นเข้าถึงข้อมูลส่วนบุคคลของท่านโดยมิชอบ{' '}
                </li>
                <li>
                  น้ำใจขอแจ้งให้ท่านทราบว่าการส่งข้อมูลผ่านระบบอินเตอร์เน็ตไม่มีความปลอดภัย
                  100% อย่างไรก็ตาม
                  น้ำใจจะพยายามอย่างดีที่สุดเพื่อคุ้มครองข้อมูลส่วนบุคคลของท่าน
                  น้ำใจไม่สามารถรับประกันความปลอดภัยของข้อมูลที่ส่งมายังแพลตฟอร์มของน้ำใจ
                  และท่านจะต้องรับความเสี่ยงในการส่งข้อมูลในแต่ละครั้ง
                </li>
              </ol>
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-semi-bold' }}>
              ระยะเวลาในการจัดเก็บข้อมูลส่วนบุคคล
            </Typography>

            <Typography variant='h6'>
              น้ำใจจะจัดเก็บบรรดาข้อมูลส่วนบุคคลของท่านตลอดระยะเวลาที่ท่านยังคงเป็นผู้ใช้ของแพลตฟอร์มของน้ำใจเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้อง
              นอกจากนี้น้ำใจอาจมีความจำเป็นต้องเก็บข้อมูลส่วนบุคคลของท่านตามอายุความ
              หรือระยะเวลาที่กฎหมายที่เกี่ยวข้องกำหนด โดยมีรายละเอียด ดังนี้
            </Typography>

            <Box sx={{ overflow: 'auto' }}>
              <TableContainer
                sx={{
                  borderRadius: '8px',
                  border: 2,
                  borderColor: '#C7C7C7',
                  display: 'block',
                  minWidth: '600px',
                  marginY: '10px',
                }}
              >
                <Table size='small'>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#cfe2f3' }}>
                      <TableCell
                        align='right'
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' textAlign={'left'}>
                          ลำดับ
                        </Typography>
                      </TableCell>
                      <TableCell
                        align='right'
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' textAlign={'left'}>
                          ประเภท / รายการข้อมูลส่วนบุคคล
                        </Typography>
                      </TableCell>
                      <TableCell align='right' width={'40%'}>
                        <Typography variant='h6' textAlign={'left'}>
                          ระยะเวลาประมวลผล
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ verticalAlign: 'top' }}>
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          1
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลประจำตัว
                        </Typography>
                      </TableCell>
                      <TableCell width={'40%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 10 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ verticalAlign: 'top', backgroundColor: '#cfe2f3' }}
                    >
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          2
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลการใช้งานแพลตฟอร์มล่าสุด
                        </Typography>
                      </TableCell>
                      <TableCell width={'40%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 5 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ verticalAlign: 'top' }}>
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          3
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลการเงิน
                        </Typography>
                      </TableCell>
                      <TableCell width={'4%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 10 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ verticalAlign: 'top', backgroundColor: '#cfe2f3' }}
                    >
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          4
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลการติดต่อสื่อสาร
                        </Typography>
                      </TableCell>
                      <TableCell width={'40%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 5 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ verticalAlign: 'top' }}>
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          5
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลความต้องการและข้อคิดเห็น
                        </Typography>
                      </TableCell>
                      <TableCell width={'40%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 5 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ verticalAlign: 'top', backgroundColor: '#cfe2f3' }}
                    >
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          6
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลทางเทคนิค
                        </Typography>
                      </TableCell>
                      <TableCell width={'40%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 5 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ verticalAlign: 'top' }}>
                      <TableCell
                        width={'15%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          7
                        </Typography>
                      </TableCell>
                      <TableCell
                        width={'45%'}
                        sx={{
                          borderRight: '1px solid #C7C7C7',
                        }}
                      >
                        <Typography variant='h6' display={'inline'}>
                          ข้อมูลเกี่ยวกับการเยี่ยมชมของท่าน
                        </Typography>
                      </TableCell>
                      <TableCell width={'40%'}>
                        <Typography variant='h6' display={'inline'}>
                          ประมาณ 5 ปี
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Typography variant='h6'>
              ทั้งนี้ น้ำใจจะลบหรือทำลายข้อมูลส่วนบุคคล
              หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุถึงตัวตนของท่านได้เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              สิทธิของท่าน
            </Typography>
            <Typography variant='h6'>
              <ol style={{ listStyleType: 'decimal', paddingLeft: '18px' }}>
                <li>
                  ท่านสามารถขอใช้สิทธิต่างๆอันเกี่ยวกับข้อมูลส่วนบุคคลของท่านได้ตามข้อกำหนดของกฎหมาย
                  และนโยบาย/หลักเกณฑ์ที่กำหนดไว้ในขณะนี้หรือที่จะมีการแก้ไขเพิ่มเติมในอนาคต
                  และในกรณีท่านมีอายุไม่ครบ 20 ปีบริบูรณ์
                  หรือถูกจำกัดความสามารถในการทำนิติกรรมตามกฎหมาย
                  ท่านสามารถขอใช้สิทธิโดยให้บิดาและมารดา ผู้ใช้อำนาจปกครอง
                  หรือมีผู้อำนาจกระทำการแทนเป็นผู้แจ้งความประสงค์
                  <ol
                    style={{
                      paddingLeft: '50px',
                      listStyleType: 'decimal',
                    }}
                  >
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการเข้าถึงและขอรับสำเนาข้อมูล{' '}
                      </span>{' '}
                      :
                      ท่านมีสิทธิเข้าถึงและได้รับสำเนาข้อมูลส่วนบุคคลของท่านที่น้ำใจเก็บรักษาไว้
                      และการขอให้เปิดเผยถึงการได้มาซึ่งข้อมูลส่วนบุคคลดังกล่าวที่ตนไม่ได้ให้ความยินยอม{' '}
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการขอแก้ไขข้อมูล{' '}
                      </span>{' '}
                      : ท่านมีสิทธิขอให้ทำการแก้ไข
                      หรือปรับปรุงข้อมูลส่วนบุคคลของท่านที่ไม่เป็นปัจจุบัน
                      ไม่ถูกต้อง หรือไม่ครบถ้วน
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการขอให้ลบหรือทำลายข้อมูล{' '}
                      </span>{' '}
                      : ท่านมีสิทธิขอให้ลบหรือทำลายข้อมูลของท่าน
                      หรือทำให้ข้อมูลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการขอให้ระงับการใช้ข้อมูล{' '}
                      </span>{' '}
                      :
                      ท่านมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลได้เมื่อน้ำใจอยู่ในระหว่างตรวจสอบตามคำร้องขอแก้ไขข้อมูลส่วนบุคคล/ขอคัดค้านของท่าน
                      หรือกรณีอื่นใดที่ท่านขอให้น้ำใจระงับการใช้ข้อมูลส่วนบุคคลแทนการลบหรือทำลายข้อมูลส่วนบุคคลที่ไม่มีความจำเป็นอีกต่อไป
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการคัดค้านประมวลผลข้อมูล{' '}
                      </span>{' '}
                      : ท่านมีสิทธิคัดค้านการเก็บรวบรวม ใช้
                      หรือเปิดเผยข้อมูลส่วนบุคคลของท่านในกรณีที่น้ำใจดำเนินการภายใต้ฐานประโยชน์โดยชอบด้วยกฎหมาย
                      หรือเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง
                      หรือการศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์ หรือสถิติ
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการโอนย้ายข้อมูล{' '}
                      </span>{' '}
                      :
                      ท่านมีสิทธิขอรับข้อมูลส่วนบุคคลของท่านในกรณีที่น้ำใจได้จัดทำข้อมูลนั้นให้อยู่ในรูปแบบที่สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติ
                      และสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลนั้นได้ด้วยวิธีการอัตโนมัติ
                      รวมทั้งมีสิทธิขอให้น้ำใจส่งหรือโอนข้อมูลส่วนบุคคลของท่านไปยังบุคคลภายนอก
                      และมีสิทธิขอรับข้อมูลส่วนบุคคลที่น้ำใจได้ส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง
                      เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการขอถอนความยินยอม{' '}
                      </span>{' '}
                      : หากท่านได้ให้ความยินยอมแก่น้ำใจเพื่อเก็บรวบรวม ใช้
                      และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
                      (ไม่ว่าจะเป็นความยินยอมที่ท่านให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น)
                      ท่านมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับน้ำใจตามรูปแบบที่น้ำใจกำหนด
                      เว้นแต่มีข้อจำกัดสิทธินั้นโดยกฎหมายหรือมีสัญญาที่ให้ประโยชน์แก่ท่านอยู่
                      ทั้งนี้การถอนความยินยอมของท่านไม่ส่งผลกระทบต่อการเก็บรวบรวม
                      ใช้
                      หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่น้ำใจได้ดำเนินการไปแล้วบนฐานความยินยอมนั้น
                      และในบางกรณีการถอนความยินยอมของท่านดังกล่าวอาจส่งผลกระทบต่อท่านในการใช้แพลตฟอร์มของน้ำใจ
                      เช่น ไม่ได้รับการแจ้งสิทธิประโยชน์ โปรโมชั่น
                      ข่าวสารหรือข้อเสนอใหม่ๆ
                      ไม่ได้รับผลิตภัณฑ์หรือบริการที่ดียิ่งขึ้นและสอดคล้องกับความต้องการของท่าน
                      เป็นต้น ดังนั้นหลังจากที่ได้รับแจ้งการถอนความยินยอมของท่าน
                      น้ำใจจะแจ้งให้ท่านทราบถึงผลที่อาจเกิดขึ้นจากการถอนความยินยอมดังกล่าวเพื่อให้ท่านสามารถตัดสินใจได้ว่าท่านยังคงประสงค์ที่จะถอนความยินยอมดังกล่าวอีกหรือไม่
                    </li>
                    <li>
                      <span style={{ fontWeight: 'bold' }}>
                        สิทธิในการร้องเรียน{' '}
                      </span>{' '}
                      : ท่านมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
                      (เช่น คณะกรรมการ/สำนักงานคุ้มครองข้อมูลส่วนบุคคล)
                      หากท่านเชื่อว่าการเก็บรวบรวม ใช้
                      และ/หรือเปิดเผยข้อมูลส่วนบุคคลของท่านเป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
                    </li>
                  </ol>
                </li>

                <li>
                  ทั้งนี้
                  ทางน้ำใจขอเรียนชี้แจงว่าการใช้สิทธิต่างๆของท่านอาจถูกจำกัดภายใต้กฎหมายที่เกี่ยวข้อง
                  และมีบางกรณีที่มีเหตุจำเป็นที่น้ำใจอาจปฏิเสธหรือไม่สามารถดำเนินการตามคำขอใช้สิทธิของท่านได้
                  เช่น ต้องปฏิบัติตามกฎหมายหรือคำสั่งศาล เพื่อประโยชน์สาธารณะ
                  การใช้สิทธิอาจละเมิดสิทธิหรือเสรีภาพของบุคคลอื่น เป็นต้น
                  หากน้ำใจปฏิเสธ คำขอข้างต้น
                  น้ำใจจะแจ้งเหตุผลของการปฏิเสธให้ท่านทราบด้วย
                </li>
              </ol>
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              การร้องขอใช้สิทธิของท่าน
            </Typography>
            <Typography variant='h6' sx={{ fontWeight: 'semi-bold' }}>
              การร้องขอใดๆเพื่อการใช้สิทธิของท่านตามที่กล่าวข้างต้นจะต้องกระทำเป็นลายลักษณ์อักษรตามรูปแบบที่น้ำใจได้กำหนดไว้
              โดยทางน้ำใจตระนักว่าการถอนความยินยอมของท่านต้องกระทำได้โดยง่ายเช่นเดียวกับการให้ความยินยอม
              และน้ำใจจะใช้ความพยายามอย่างดีที่สุดที่จะดำเนินการหรือชี้แจงภายใน
              30 วัน หรือไม่เกินตามระยะเวลาที่กฎหมายกำหนด
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              นโยบายคุกกี้
            </Typography>
            <Typography variant='h6'>
              <ol style={{ listStyleType: 'decimal', paddingLeft: '18px' }}>
                <li>
                  เมื่อท่านใช้แพลตฟอร์ม
                  น้ำใจอาจใส่คุกกี้จำนวนมากไว้ในบราวเซอร์ของท่าน คุกกี้
                  หมายถึงไฟล์ดิจิตอลขนาดเล็กซึ่งประกอบด้วยตัวอักษรและตัวเลขซึ่งน้ำใจจัดเก็บไว้บนบราวเซอร์หรือฮาร์ดไดร์ฟของคอมพิวเตอร์ของท่านเมื่อได้รับความยินยอมจากท่าน
                  คุกกี้ประกอบด้วยข้อมูลซึ่งได้ส่งเข้ามาในฮาร์ดดิสก์ของคอมพิวเตอร์ของท่าน
                </li>

                <li>
                  วัตถุประสงค์ของการใช้คุกกี้มีดังต่อไปนี้ (1)
                  เพื่อเปิดการใช้ฟังก์ชั่นต่างๆ (2) เพื่อทำการวิเคราะห์ (3)
                  เพื่อบันทึกความชอบของท่าน และ (4)
                  เพื่อให้สามารถส่งโฆษณาและโฆษณาที่อิงตามพฤติกรรม
                  คุกกี้บางประเภทอาจใช้เมื่อท่านต้องการใช้งานในลักษณะใดลักษณะหนึ่ง
                  หรือเลือกความชอบอย่างใดอย่างหนึ่ง
                  ในขณะที่จะมีการใช้คุกกี้บางประเภทตลอดระยะเวลาโดยเป็นไปตามนโยบายความเป็นส่วนตัวฉบับนี้
                </li>

                <li>
                  เหตุผลที่น้ำใจใช้คุกกี้
                  <ol
                    style={{
                      paddingLeft: '50px',
                      listStyleType: 'decimal',
                    }}
                  >
                    <li>
                      คุกกี้มีความจำเป็นสำหรับการทำงานของแพลตฟอร์ม ซึ่งรวมถึง
                      ยกตัวอย่างเช่น
                      คุกกี้ทำให้ท่านสามารถเข้าถึงพื้นที่ที่จำกัดบนแพลตฟอร์มของน้ำใจ
                      ใช้ตะกร้าสินค้า หรือใช้บริการเรียกชำระเงินอิเล็กทรอนิกส์
                    </li>
                    <li>
                      คุกกี้ทำให้น้ำใจสามารถจดจำและนับจำนวนผู้เยี่ยมชมและเห็นลักษณะที่ผู้เยี่ยมชมใช้แพลตฟอร์มของน้ำใจ
                      ข้อมูลดังกล่าวทำให้น้ำใจสามารถพัฒนาการทำงานของแพลตฟอร์ม
                      ยกตัวอย่างเช่น
                      โดยทำให้แน่ใจว่าผู้ใช้จะสามารถเจอสิ่งที่มองหาได้โดยง่าย
                    </li>
                    <li>
                      คุกกี้ใช้เพื่อจดจำท่านเมื่อท่านกลับมาใช้แพลตฟอร์มของน้ำใจอีกครั้ง
                      ซึ่งข้อมูลดังกล่าวทำให้น้ำใจสามารถจัดเนื้อหาที่เหมาะสมกับท่านโดยเฉพาะ
                      ทักทายท่านด้วยชื่อ และจำความชอบของท่านได้ (ยกตัวอย่างเช่น
                      ภาษา และภูมิภาคที่ท่านเลือก){' '}
                    </li>
                    <li>
                      คุกกี้บันทึกการเยี่ยมชมของท่านบนแพลตฟอร์มของน้ำใจ
                      หน้าที่ท่านเยี่ยมชม และลิงค์ซึ่งท่านเคยกดตาม
                      น้ำใจจะใช้ข้อมูลดังกล่าวเพื่อทำให้แพลตฟอร์มของน้ำใจ
                      รวมถึงโฆษณาบนแพลตฟอร์มนั้นสอดคล้องกับความสนใจของท่านมากขึ้น
                      ทั้งนี้
                      น้ำใจอาจแบ่งปันข้อมูลดังกล่าวกับบุคคลที่สามเพื่อวัตถุประสงค์นี้ด้วย
                    </li>
                  </ol>
                </li>

                <li>
                  น้ำใจขอแจ้งให้ท่านทราบว่าบุคคลที่สาม (รวมถึง ยกตัวอย่างเช่น
                  เครือข่ายโฆษณา
                  และผู้ให้บริการภายนอกที่ให้บริการวิเคราะห์การเข้าเยี่ยมชมเว็บไซต์
                  เป็นต้น) อาจใช้คุกกี้ดังกล่าวด้วยเช่นกัน
                  ซึ่งน้ำใจไม่มีอำนาจควบคุมการใช้คุกกี้ดังกล่าว
                  คุกกี้ดังกล่าวมักจะทำให้แพลตฟอร์มของน้ำใจและโฆษณาที่แสดงบนแพลตฟอร์มของน้ำใจมีความเกี่ยวข้องกับความสนใจของคุณมากขึ้น
                  และทำให้สามารถพัฒนาการทำงานของแพลตฟอร์มของน้ำใจได้
                </li>

                <li>
                  ท่านสามารถลบคุกกี้โดยใช้ฟังก์ชั่นลบข้อมูลบนแอปพลิเคชันในโทรศัพท์มือถือหรือเว็บบราวเซอร์ของท่านซึ่งจะทำให้ท่านสามารถปฏิเสธการติดต่อคุกกี้ทั้งหมดหรือบางส่วนได้
                  อย่างไรก็ตาม
                  ท่านอาจไม่สามารถเข้าใช้แพลตฟอร์มของน้ำใจได้บางส่วน
                </li>
              </ol>
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              สื่อทางการตลาด
            </Typography>
            <Typography variant='h6' sx={{ fontWeight: 'semi-bold' }}>
              น้ำใจและคู่ค้าของน้ำใจอาจส่งข้อมูลทางการตลาด โฆษณา
              และข้อมูลเกี่ยวกับการส่งเสริมการขายผ่านการแจ้งเตือนในแอปพลิเคชัน
              ไปรษณีย์ โทรศัพท์ บริการข้อความสั้น (sms) อีเมล
              และ/หรือแอปพลิเคชันสำหรับส่งข้อความอื่นๆ (“สื่อทางการตลาด”)
              ให้แก่ท่าน และ/หรือ บิดามารดา ผู้ปกครอง หรือตัวแทนผู้ปกครอง
              ในกรณีที่ท่านตกลงสมัครเป็นสมาชิกในรายชื่อผู้ติดต่อ
              และ/หรือยินยอมรับสื่อทางการตลาดและสื่อเพื่อการส่งเสริมการขายต่างๆจากน้ำใจ
              ท่านสามารถเลือกที่จะไม่รับการส่งสื่อทางการตลาดดังกล่าวได้ตามรูปแบบและช่องทางที่น้ำใจกำหนดไว้ไม่ว่าในเวลาใดๆ
              น้ำใจขอแจ้งให้ท่านทราบว่าหากท่านเลือกที่จะไม่รับข้อมูลดังกล่าว
              น้ำใจจะยังคงสามารถส่งข้อความที่ไม่เกี่ยวข้องกับการส่งเสริมการขาย
              เช่น ใบเสร็จรับเงินหรือข้อมูลเกี่ยวกับบัญชีของท่านได้ต่อไป
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว
            </Typography>
            <Typography variant='h6' sx={{ fontWeight: 'semi-bold' }}>
              ในกรณีที่น้ำใจได้ทำการแก้ไข/
              เปลี่ยนแปลงใดๆกับนโยบายความเป็นส่วนตัวของน้ำใจ
              น้ำใจจะเผยแพร่การเปลี่ยนแปลงดังกล่าวบนหน้าเพจนี้
              กรุณากลับเข้ามาอ่านหน้าเพจนี้เป็นระยะเพื่อให้ทราบถึงการปรับปรุงหรือการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวของน้ำใจ
              สำหรับกรณีที่เป็นการเปลี่ยนแปลงในสาระสำคัญ
              น้ำใจจะแจ้งให้ท่านทราบถึงการเปลี่ยนแปลงดังกล่าวทางหน้าเว็บเพจหรือแอปพลิเคชัน
            </Typography>
          </Stack>

          <Stack textAlign={'left'} paddingY={'16px'} width={'100%'}>
            <Typography variant='h4' sx={{ fontWeight: 'semi-bold' }}>
              ติดต่อน้ำใจ
            </Typography>
            <Typography variant='h6' sx={{ fontWeight: 'semi-bold' }}>
              หากท่านมีคำถามเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้
              หรือท่านต้องการขอใช้สิทธิของท่าน
              ตลอดจนส่งข้อร้องเรียนใดๆเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของท่านที่น้ำใจดำเนินการ
              กรุณาติดต่อมาที่
              <br />
              มูลนิธิดรุณาทร 57/7 ถ.ทุ่งโฮเต็ล ซอย 3/1
              <br />
              ต.วัดเกต อ.เมือง จ.เชียงใหม่ 50000 โทร 266426
              <br />
              เบอร์โทรศัพท์ : <Link to='tel:053266426'>053 266 426 - 9</Link>
              <br />
              E-mail :
              <Link
                to='mailto:cithpr@gmail.com'
                onClick={(e) => {
                  window.location.href = 'mailto:cithpr@gmail.com'
                  e.preventDefault()
                }}
              >
                {' '}
                namjai@darunatorn.com
              </Link>
            </Typography>
          </Stack>

          <Typography variant='subtitle2' alignItems={'center'}>
            นโยบายคุ้มครองข้อมูลส่วนบุคคลจะมีผลบังคับใช้ 22 กรกฎาคม 2023
          </Typography>
        </Stack>
      </Container>

      <Footer />
    </ThemeProvider>
  )
}
