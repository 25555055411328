import { Box, Button, Stack, ThemeProvider, Typography } from '@mui/material'
import { theme } from '../constants/theme'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { COLOR } from '../constants/color-constant'
import { common, grey } from '@mui/material/colors'
import { churchMemberPosition } from '../constants/churchMemberType'
import { useMemo } from 'react'
import { Delete, DeleteOutline } from '@mui/icons-material'

export type MemberCardVaraint =
  | 'VIEW'
  | 'EDIT-CP'
  | 'EDIT-MCP'
  | 'DELETED'
  | 'REMOVABLE'
  | 'ADMIN-VIEW'

interface ChurchMemberProps {
  isMe?: boolean
  position: churchMemberPosition
  firstName: string
  lastName: string
  variant: MemberCardVaraint
  handleClickPromoteMember?: () => void
  handleClickDemoteMember?: () => void
  handleClickDeleteMember?: () => void
}

export const ChurchMemberCard = (props: ChurchMemberProps) => {
  const {
    position,
    firstName,
    lastName,
    handleClickPromoteMember,
    handleClickDemoteMember,
    variant,
    handleClickDeleteMember,
  } = props

  const memberPosition = useMemo<string>(() => {
    if (position === 'PASTOR') {
      return 'Pastor'
    } else if (position === 'MCP') {
      return 'ผู้ประสานงานหลัก'
    } else if (position === 'CP') {
      return 'เจ้าหน้าที่'
    } else {
      return ''
    }
  }, [position])

  if (variant === 'DELETED') {
    return null
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Stack
          display={'flex'}
          alignItems={'left'}
          borderRadius={'16px'}
          border={'0.50px #C7C7C7 solid'}
          padding={'8px 16px 8px 16px'}
          bgcolor={common.white}
          justifyContent={'space-between'}
          gap={'8px'}
          width={{ xs: '100%', sm: '320px', md: '350px' }}
          maxWidth={{ xs: '300px', sm: '100%' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Stack
            display={'flex'}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent={'flex-start'}
            gap={'8px'}
            width={'100%'}
            minWidth={0}
          >
            <AccountCircleIcon
              sx={{
                height: 40,
                width: 40,
                color: COLOR.SECOUNDARY_SECOUND_3,
              }}
            ></AccountCircleIcon>
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
              <Stack
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                width={{ xs: '100%', sm: '280px', md: '220px' }}
                minWidth={0}
              >
                <Typography variant='body1' color={COLOR.SECOUNDARY_FIRST_1}>
                  {memberPosition}
                </Typography>
                <Typography
                  variant='body2'
                  color={grey[600]}
                  noWrap
                  textOverflow={'ellipsis'}
                >
                  {firstName + ' ' + lastName}
                </Typography>
              </Stack>
              {(variant === 'REMOVABLE' || variant === 'ADMIN-VIEW') && (
                <Button
                  color={'error'}
                  disabled={variant === 'ADMIN-VIEW'}
                  onClick={handleClickDeleteMember}
                >
                  <Delete
                    fontSize='small'
                    sx={{ width: '22px', height: '22px' }}
                  />
                </Button>
              )}
            </Stack>

            <Box width='8px'></Box>
          </Stack>
          {variant === 'EDIT-MCP' && (
            <Stack
              display={'flex'}
              flexDirection={'row'}
              width={'auto'}
              alignItems={'center'}
            >
              <Button
                variant='outlined'
                sx={{ padding: '0', height: '32px' }}
                onClick={handleClickPromoteMember}
              >
                เลื่อนขั้น
              </Button>
            </Stack>
          )}
          {variant === 'EDIT-CP' && (
            <Stack
              display={'flex'}
              flexDirection={'row'}
              width={'auto'}
              alignItems={'center'}
            >
              <Button
                variant='outlined'
                sx={{ padding: '0', height: '32px' }}
                onClick={handleClickPromoteMember}
              >
                เลื่อนขั้น
              </Button>
              <Button
                color='error'
                sx={{ minWidth: '0px' }}
                onClick={handleClickDeleteMember}
              >
                <Delete fontSize='small' />
              </Button>
            </Stack>
          )}
        </Stack>
      </ThemeProvider>
    )
  }
}
