import {
  Box,
  Popover,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from '@mui/material'

import { grey } from '@mui/material/colors'
import StatusBar from './StatusBar'

import { ReactComponent as Fund } from '../../assets/icons/Fundforadmin.svg'

import {
  generateViewCampaignAdminRoute,
  generateViewChurchAdminRoute,
} from '../../utils/routeGenerator'
import { useNavigate } from 'react-router-dom'
import ProgressBarAdmin from './ProgressBarAdmin'
import { HolderChurch } from '../../models/admin/AllCampaign'
import { useState } from 'react'

type CampaignListProps = {
  campaignId: string
  campaignName: string
  holder: HolderChurch[]
  status: string
  targetBudget: number
  currentDonationAmount: number
}

export default function CampaignListAdmin(props: CampaignListProps) {
  const fundIcon = useMediaQuery('(min-width:1000px)')
  const mobile = useMediaQuery('(min-width:900px)')

  const {
    campaignId,
    campaignName,
    holder,
    status,
    targetBudget,
    currentDonationAmount,
  } = props
  const navigate = useNavigate()
  const path = generateViewCampaignAdminRoute(campaignId)

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: '209px',
    },
  })

  return (
    <TableRow key={props.campaignId}>
      <TableCell sx={{ width: 500, flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            flexWrap: 'no wrap',
            alignItems: mobile ? 'center' : 'left',
            gap: '10px',
          }}
        >
          {fundIcon && (
            <Box width={'40px'} marginRight={'8px'}>
              <Fund></Fund>
            </Box>
          )}
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'Column',
              flexWrap: 'wrap',
              alignItems: mobile ? 'center' : 'left',
            }}
          >
            <Typography
              variant={mobile ? 'subtitle1' : 'h6'}
              color={'text.primary'}
              onClick={() => navigate(path)}
              sx={{
                cursor: 'pointer',
                color: mobile ? 'text.primary' : '#0079FF',
              }}
            >
              {campaignName}
            </Typography>
            {!mobile && (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Column',
                    gap: '8px',
                    alignItems: 'left',
                  }}
                >
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'left',
                    }}
                  >
                    <Box width={{ xs: '40vw', md: '30vw' }}>
                      <Typography variant='body2' color={grey[800]}>
                        Campaign Owner
                      </Typography>
                    </Box>
                    <Box>
                      {holder.map((owner, i) => (
                        <Typography
                          key={i}
                          variant='body1'
                          color={'text.primary'}
                          onClick={() =>
                            navigate(
                              generateViewChurchAdminRoute(owner.churchId)
                            )
                          }
                          sx={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexWrap: 'nowrap',
                          }}
                        >
                          {owner.churchName}
                        </Typography>
                      ))}
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'left',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box width={{ xs: '40vw', md: '30vw' }}>
                      <Typography variant='body2' color={grey[800]}>
                        Status
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1' color={'text.primary'}>
                        <StatusBar variant={status}></StatusBar>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'left',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box width={{ xs: '40vw', md: '30vw' }}>
                      <Typography variant='body2' color={grey[800]}>
                        Fund
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='body1' color={'text.primary'}>
                        <ProgressBarAdmin
                          amount={currentDonationAmount}
                          goal={targetBudget}
                        ></ProgressBarAdmin>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'left',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box width={{ xs: '40vw', md: '30vw' }}>
                      <Typography variant='body2' color={grey[800]}>
                        Recipe
                      </Typography>
                    </Box>
                    <Box sx={{ cursor: 'pointer' }}>
                      <StyledTooltip
                        title={
                          <Typography variant='body2' textAlign={'center'}>
                            Download all donation record for this campaign.
                          </Typography>
                        }
                        arrow
                        placement='bottom'
                      >
                        <Typography
                          variant='body1'
                          color={'#0079FF'}
                          sx={{ textDecoration: 'underline' }}
                        >
                          Download
                        </Typography>
                      </StyledTooltip>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Stack>
        </Stack>
      </TableCell>
      {mobile && (
        <>
          <TableCell align='left' sx={{ width: 300, flexWrap: 'wrap' }}>
            {holder.map((owner) => (
              <Typography
                variant='body1'
                color={'text.primary'}
                onClick={() =>
                  navigate(generateViewChurchAdminRoute(owner.churchId))
                }
                sx={{ cursor: 'pointer' }}
              >
                {owner.churchName}
              </Typography>
            ))}
          </TableCell>
          <TableCell align='left' sx={{ width: 160, flexWrap: 'wrap' }}>
            <StatusBar variant={status}></StatusBar>
          </TableCell>
          <TableCell align='left' sx={{ width: 200, flexWrap: 'wrap' }}>
            <ProgressBarAdmin
              amount={currentDonationAmount}
              goal={targetBudget}
            ></ProgressBarAdmin>
          </TableCell>
          <TableCell
            align='right'
            sx={{ width: 100, flexWrap: 'wrap', cursor: 'pointer' }}
          >
            <StyledTooltip
              title={
                <Typography variant='body2' textAlign={'center'}>
                  Download all donation record for this campaign.
                </Typography>
              }
              arrow
              placement='bottom'
            >
              <Typography
                variant='body1'
                color={'#0079FF'}
                sx={{ textDecoration: 'underline' }}
              >
                Download
              </Typography>
            </StyledTooltip>
          </TableCell>
        </>
      )}
    </TableRow>
  )
}
