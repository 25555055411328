import React, { useState, useEffect } from 'react'
import { Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { useNavigate } from 'react-router-dom'
import { grey } from '@mui/material/colors'
import { BsBagHeart, BsPersonFill, BsChevronDown } from 'react-icons/bs'
import { MdLogin } from 'react-icons/md'
import { Search } from '@mui/icons-material'

import { ROUTE } from '../../constants/route-constant'
import { COLOR } from '../../constants/color-constant'

import NamjaiLogo from '../../assets/icons/logo.svg'

import NavbarUserPopover from './NavbarUserPopover'
import AuthService from '../../services/auth.service'
import { useUser } from '../../services/UserContext'
import { Role, UserData } from '../../models/UserTypes'

function Navbar() {
  const navigate = useNavigate()
  const userData: UserData | null = useUser().userData

  const [popoverAnchor, setPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null)
  const [userRole, setUserRole] = useState<Role>('GUEST')
  const [churchId, setChurchId] = useState('')

  const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      userRole === 'USER' ||
      userRole === 'CHURCH' ||
      userRole === 'ADMIN' ||
      userRole === 'SUPER_ADMIN'
    ) {
      setPopoverAnchor(event.currentTarget)
    } else {
      navigate(ROUTE.LOGIN)
    }
  }
  useEffect(() => {
    if (userData) {
      const role = userData.userrole.userRole
      setUserRole(role)
      if (role === 'CHURCH' && userData.userrole.churchId) {
        setChurchId(userData.userrole.churchId)
      }
    }
  }, [userData])

  const handlePopoverClose = () => {
    setPopoverAnchor(null)
  }
  const handleLogout = () => {
    AuthService.logout()
    setUserRole('GUEST')
    navigate('/')
    window.location.reload()
  }
  return (
    <div
      style={{
        backgroundColor: COLOR.BACKGROUD_MAIN,
        borderBottom: `1px solid ${grey[400]}`,
      }}
    >
      <Container>
        <div
          className='mx-auto flex flex-row
        items-center justify-between gap-2 py-5'
        >
          <Link to='/'>
            <ReactSVG src={NamjaiLogo} />
          </Link>
          <div className='flex items-center gap-8 md:gap-10'>
            <Link
              to={ROUTE.ALL_CAMPAIGN}
              className='flex items-center gap-2 text-[#153E90] 
                hover:text-[#0079FF]'
            >
              <Search fontSize='large' />
              <div className='hidden sm:block'>
                <Typography variant='h6'>โครงการทั้งหมด</Typography>
              </div>
            </Link>
            <div
              className={`border border-l-[${grey[400]}] self-stretch`}
            ></div>
            {userRole !== 'GUEST' && (
              <Link
                to={ROUTE.NAMJAI_BAG}
                className='text-[#153E90] hover:text-[#0079FF]'
              >
                <BsBagHeart size={24} />
              </Link>
            )}
            <button
              className='flex flex-row items-center gap-2 rounded-full border 
               border-[#153E90] px-2 py-1 text-[#153E90] 
               hover:border-[#0079FF] hover:text-[#0079FF]'
              onClick={handleUserMenuClick}
            >
              {userRole === 'GUEST' ? (
                <>
                  <Typography variant='h6'>เข้าสู่ระบบ</Typography>
                  <MdLogin size={24} />
                </>
              ) : (
                <>
                  <BsPersonFill size={16} />
                  <BsChevronDown size={16} />
                </>
              )}
            </button>
            {userRole !== 'GUEST' ? (
              <NavbarUserPopover
                open={Boolean(popoverAnchor)}
                username={
                  userData?.userdetail.firstName +
                  ' ' +
                  userData?.userdetail.lastName
                }
                userRole={userRole}
                churchId={churchId}
                popoverAnchor={popoverAnchor}
                handlePopoverClose={handlePopoverClose}
                handleLogout={handleLogout}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Navbar
