import { Typography } from '@mui/material'
import { COLOR } from '../../constants/color-constant'

type StatusProps = {
  variant: string
}

export default function StatusBar(props: StatusProps) {
  if (props.variant === 'PENDING') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px]'
        style={{ backgroundColor: '#DEEEFF' }}
      >
        <Typography variant='h6' color={'#0079FF'}>
          Pending
        </Typography>
      </div>
    )
  } else if (props.variant === 'DENY' || props.variant === 'REJECTED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          Deny
        </Typography>
      </div>
    )
  } else if (props.variant === 'APPROVED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#DEFFD1' }}
      >
        <Typography variant='h6' color={'#34CC25'}>
          Approve
        </Typography>
      </div>
    )
  } else if (props.variant === 'FUNDING') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFF2D0' }}
      >
        <Typography variant='h6' color={'#FF8600'}>
          Funding
        </Typography>
      </div>
    )
  } else if (props.variant === 'FUNDED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#DEFFD1' }}
      >
        <Typography variant='h6' color={'#34CC25'}>
          Funded
        </Typography>
      </div>
    )
  } else if (props.variant === 'NOT_FUNDED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          Not funded
        </Typography>
      </div>
    )
  } else if (props.variant === 'ON_GOING') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFF2D0' }}
      >
        <Typography variant='h6' color={'#FF8600'}>
          On going
        </Typography>
      </div>
    )
  } else if (props.variant === 'COMPLETED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#F0F0F0' }}
      >
        <Typography variant='h6' color={'#808080'}>
          Completed
        </Typography>
      </div>
    )
  } else if (props.variant === 'ACTIVE') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#DEFFD1' }}
      >
        <Typography variant='h6' color={'#34CC25'}>
          ACTIVE
        </Typography>
      </div>
    )
  } else if (props.variant === 'INACTIVE') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          INACTIVE
        </Typography>
      </div>
    )
  } else if (props.variant === 'REVISE') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          Revise
        </Typography>
      </div>
    )
  } else if (props.variant === 'REQUEST_FIX') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          Request fix
        </Typography>
      </div>
    )
  } else if (props.variant === 'HOLDER_PENDING') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#DEEEFF' }}
      >
        <Typography variant='h6' color={'#0079FF'}>
          Holder Pending
        </Typography>
      </div>
    )
  } else if (props.variant === 'NEW_VERSION') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#000000' }}
      >
        <Typography variant='h6' color={'#FFFFFF'}>
          New Version
        </Typography>
      </div>
    )
  } else if (props.variant === 'UNCONFIRMED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#F0F0F0' }}
      >
        <Typography variant='h6' color={'#808080'}>
          Unconfirmed
        </Typography>
      </div>
    )
  } else if (props.variant === 'CONFIRMED') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#DEFFD1' }}
      >
        <Typography variant='h6' color={'#34CC25'}>
          Confirmed
        </Typography>
      </div>
    )
  } else if (props.variant === 'ISSUE') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          Issue
        </Typography>
      </div>
    )
  } else {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#000000' }}
      >
        <Typography variant='h6' color={'#FFFFFF'}>
          {props.variant}
        </Typography>
      </div>
    )
  }
}
