import { useEffect, useState } from 'react'
import { Stack, TextField, Typography } from '@mui/material'

import { COLOR } from '../../constants/color-constant'

interface TextareaWithCharLengthProps {
  minRows: number
  maxLength: number
  placeholder: string
  control: any // from react-hook-form
  name: string // from react-hook-form
  onChangeValue?: () => void
  error?: any
  adminView?: boolean
}

// TextareaWithCharLength is a textarea with a character counter to be used with react-hook-form
function TextareaWithCharLength({
  maxLength,
  minRows,
  placeholder,
  control,
  name,
  onChangeValue,
  error,
  adminView,
}: TextareaWithCharLengthProps) {
  const [charLen, setCharLen] = useState(0)

  // update charLen when the value of the form changes
  // load the initial value of charLen when the component is mounted
  useEffect(() => {
    setCharLen(control._formValues[name]?.length || 0)
  }, [control._formValues, name])

  const updateCharLen = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCharLen(e.target.value.length)
  }

  return (
    <>
      {adminView ? (
        <TextField
          {...control.register(name)}
          disabled
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#212121',
            },
          }}
        ></TextField>
      ) : (
        // styling is in App.css
        <textarea
          placeholder={placeholder}
          rows={minRows}
          maxLength={maxLength}
          {...control.register(name)}
          onChange={error ? onChangeValue : updateCharLen}
          className={error ? 'campaign-progress-update-textarea' : ''}
        />
      )}

      <Stack
        flexDirection={'row'}
        justifyContent={error ? 'space-between' : 'flex-end'}
      >
        {error ? (
          <Typography color={COLOR.BUTTON_RED}>*ต้องกรอก</Typography>
        ) : (
          <></>
        )}
        <Typography
          variant='body1'
          color={charLen >= maxLength ? COLOR.BUTTON_RED : COLOR.GRAY_600}
          alignSelf={'flex-end'}
        >
          {charLen}/{maxLength}
        </Typography>
      </Stack>
    </>
  )
}

export default TextareaWithCharLength
