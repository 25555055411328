import { ReactComponent as area_all } from '../assets/icons/area_all.svg'
import { ReactComponent as area_education } from '../assets/icons/area_education.svg'
import { ReactComponent as area_child } from '../assets/icons/area_child.svg'
import { ReactComponent as area_infra } from '../assets/icons/area_infra.svg'
import { ReactComponent as area_youth } from '../assets/icons/area_youth.svg'
import { ReactComponent as area_well } from '../assets/icons/area_well.svg'

import { Grid, Box, Typography, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import { COLOR } from '../constants/color-constant'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import '../css/service.css'

interface AreaHoslisticProps {
  state: number[]
  onAreaHolisticChange_hook: React.Dispatch<React.SetStateAction<number[]>>
  listsAreaName: string[]
  multipleSelect: boolean
}

export default function AreaHolistic(props: AreaHoslisticProps) {
  const { state, onAreaHolisticChange_hook, listsAreaName, multipleSelect } =
    props
  const areaTips = [
    'พันธกิจของคอมแพสชั่นให้ความสำคัญและมุ่งเน้นที่ การพัฒนาแบบองค์รวม มากกว่า การสงเคราะห์เพียงอย่างเดียว ครอบคลุมตั้งแต่วัยเริ่มต้นของชีวิตไปจนถึงวัยรุ่น',
    'การศึกษา: เด็กและเยาวชนจะได้รับการสนับสนุนสิ่งที่จำเป็นในการศึกษา (ทั้งในระบบและนอกระบบ) และการพัฒนาทักษะเพื่อโอกาสในการประกอบอาชีพ',
    'การคุ้มครองเด็ก: ความปลอดภัยของเด็กเป็นสิ่งสำคัญ: การอบรมเกี่ยวกับการปกป้องคุ้มครองเด็ก, ป้องกันเด็กจากสิ่งแวดล้อมที่ไม่ปลอดภัย, การใช้ความรุนแรง และการค้ามนุษย์',
    'สาธารณูปโภค: จัดเตรียมสภาพแวดล้อม อาคาร สถานที่ ที่เอื้อต่อการพัฒนาเด็กและเยาวชน เช่น ห้องเรียน  โซลาร์เซลล์ สนามเด็กเล่น',
    'เยาวชนผู้นำ: เตรียมเด็กและเยาวชน ผ่านกิจกรรมที่ช่วยพัฒนาคุณลักษณะนิสัย จิตสำนึกเพื่อสังคม และความเป็นผู้นำ  เพื่อสร้างการเปลี่ยนแปลงเชิงบวกสู่ชุมชน',
    'สุขภาวะ: ส่งเสริมสุขภาวะทางกายและจิตใจในเด็กและเยาวชน  เช่น มีน้ำดื่มสะอาด มีห้องน้ำถูกสุขอนามัย เข้าถึงการรักษาพยาบาล รักษาการเจ็บป่วยเรื้อรัง การเยียวยาจิตใจ',
  ]
  const handleSelectOneOrMulti = (index: number) => {
    onAreaHolisticChange_hook((prevIndices) => {
      if (multipleSelect) {
        if (index === 0) {
          return [0]
        } else {
          if (prevIndices.includes(index)) {
            const newIndices = prevIndices.filter((i) => i !== index)
            if (newIndices.length === 0) {
              return [0]
            }
            return newIndices
          } else {
            return [...prevIndices.filter((i) => i !== 0), index]
          }
        }
      } else {
        return [index]
      }
    })
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      py={5}
      sx={{
        overflowX: { xs: 'scroll', lg: 'hidden' },
      }}
    >
      {[
        area_all,
        area_education,
        area_child,
        area_infra,
        area_youth,
        area_well,
      ].map((Svg, index) => (
        <Box key={index} sx={{ maxWidth: '50%', marginTop: '15px' }}>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            sx={{ width: '220px' }}
          >
            <Button
              variant='outlined'
              title={listsAreaName[index]}
              sx={{
                ':hover': {
                  ...(state.includes(index)
                    ? {
                        bgcolor: COLOR.PRIMARY_2,
                      }
                    : { bgcolor: 'transparent' }),
                },

                border: 3,
                ...(state.includes(index)
                  ? {
                      borderColor: COLOR.PRIMARY_2,
                      backgroundColor: COLOR.PRIMARY_2,
                    }
                  : { borderColor: COLOR.DIVIDER_MAIN }),
                width: 'fit-content',
                height: 'fit-content',
                borderRadius: '50%',
                padding: '10px',
              }}
              onClick={() => handleSelectOneOrMulti(index)}
            >
              <div
                style={{
                  width: '52px',
                  height: '52px',
                  position: 'relative',
                }}
              >
                <Svg
                  style={{
                    position: 'absolute',
                    top: '50%',
                    height: 48,
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                  }}
                  color={state.includes(index) ? 'white' : COLOR.DIVIDER_MAIN}
                />
              </div>
            </Button>
            <div className='no-highlight '>
              <Tooltip title={areaTips[index]} enterTouchDelay={10}>
                <Typography
                  // align='center'
                  variant='h6'
                  sx={{
                    ...(state.includes(index)
                      ? { color: COLOR.PRIMARY_2 }
                      : { color: COLOR.DIVIDER_MAIN }),
                  }}
                >
                  {listsAreaName[index]}

                  <InfoOutlinedIcon />
                </Typography>
              </Tooltip>
            </div>
          </Grid>
        </Box>
      ))}
    </Box>
  )
}
