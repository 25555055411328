import { Stack, Typography, Tooltip } from '@mui/material'
import { COLOR } from '../constants/color-constant'

import { ReactComponent as AreaEducation } from '../assets/icons/area_education.svg'
import { ReactComponent as AreaChild } from '../assets/icons/area_child.svg'
import { ReactComponent as AreaInfra } from '../assets/icons/area_infra.svg'
import { ReactComponent as AreaYouth } from '../assets/icons/area_youth.svg'
import { ReactComponent as AreaWell } from '../assets/icons/area_well.svg'

type SelectButtonProps = {
  children?: React.ReactNode
  isSelected: boolean
  setSelected: (isSelected: boolean) => void
  adminView?: boolean
}

function Button({
  isSelected,
  setSelected,
  children,
  adminView,
}: SelectButtonProps) {
  return (
    <button
      type='button'
      className={`group rounded-[4px] border-[1px] px-[8px] py-[4px] ${
        isSelected
          ? 'border-[#0079FF] bg-[#0079FF]'
          : 'hover:border-[#0079FF] hover:bg-[#f5faff]'
      }`}
      onClick={() => {
        setSelected(!isSelected)
      }}
      disabled={adminView}
    >
      <div className='flex flex-row items-center gap-[4px]'>{children}</div>
    </button>
  )
}

type SmallAreaHolisticSelectorProps = {
  selected: boolean[]
  setSelected: (i: number, isSelected: boolean) => void
  adminView?: boolean
}

const areaList = [
  {
    name: 'การศึกษา',
    tip: 'การศึกษา: เด็กและเยาวชนจะได้รับการสนับสนุนสิ่งที่จำเป็นในการศึกษา (ทั้งในระบบและนอกระบบ) และการพัฒนาทักษะเพื่อโอกาสในการประกอบอาชีพ',
    svg: AreaEducation,
  },
  {
    name: 'การคุ้มครองเด็ก',
    tip: 'การคุ้มครองเด็ก: ความปลอดภัยของเด็กเป็นสิ่งสำคัญ: การอบรมเกี่ยวกับการปกป้องคุ้มครองเด็ก, ป้องกันเด็กจากสิ่งแวดล้อมที่ไม่ปลอดภัย, การใช้ความรุนแรง และการค้ามนุษย์',
    svg: AreaChild,
  },
  {
    name: 'สาธารณูปโภค',
    tip: 'สาธารณูปโภค: จัดเตรียมสภาพแวดล้อม อาคาร สถานที่ ที่เอื้อต่อการพัฒนาเด็กและเยาวชน เช่น ห้องเรียน  โซลาร์เซลล์ สนามเด็กเล่น',
    svg: AreaInfra,
  },
  {
    name: 'เยาวชนผู้นำ',
    tip: 'เยาวชนผู้นำ: เตรียมเด็กและเยาวชน ผ่านกิจกรรมที่ช่วยพัฒนาคุณลักษณะนิสัย จิตสำนึกเพื่อสังคม และความเป็นผู้นำ  เพื่อสร้างการเปลี่ยนแปลงเชิงบวกสู่ชุมชน',
    svg: AreaYouth,
  },
  {
    name: 'สุขภาวะ',
    tip: 'สุขภาวะ: ส่งเสริมสุขภาวะทางกายและจิตใจในเด็กและเยาวชน  เช่น มีน้ำดื่มสะอาด มีห้องน้ำถูกสุขอนามัย เข้าถึงการรักษาพยาบาล รักษาการเจ็บป่วยเรื้อรัง การเยียวยาจิตใจ',
    svg: AreaWell,
  },
]

function SmallAreaHolisticSelector({
  selected,
  setSelected,
  adminView,
}: SmallAreaHolisticSelectorProps) {
  return (
    <Stack
      justifyContent='space-between'
      flexDirection='row'
      flexWrap='wrap'
      width='100%'
      gap='8px'
    >
      {areaList.map((area, i) => (
        <Tooltip key={i} title={area.tip}>
          <div>
            <Button
              isSelected={selected[i]}
              setSelected={(isSelected) => setSelected(i, isSelected)}
              adminView={adminView}
            >
              <area.svg
                width='40px'
                height='40px'
                color={selected[i] ? 'white' : COLOR.GRAY_600}
              />
              <Typography variant='h6' color={selected[i] ? 'white' : ''}>
                {area.name}
              </Typography>
            </Button>
          </div>
        </Tooltip>
      ))}
    </Stack>
  )
}

export default SmallAreaHolisticSelector
