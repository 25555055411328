import { Box, TextField, Typography } from '@mui/material'
import { common } from '@mui/material/colors'
import React from 'react'
import { COLOR } from '../../constants/color-constant'

interface DonationAmountProps {
  amount?: number
  selected: boolean
}

export default function DonationAmount(props: DonationAmountProps) {
  const { amount, selected } = props
  return (
    <Box
      display={'flex'}
      width={'auto'}
      minWidth={'160px'}
      height={'100px'}
      borderRadius={'14px'}
      border={'1px solid #C1C1C1'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background: selected ? COLOR.PRIMARY_2 : 'white',
        cursor: 'pointer',
      }}
      boxShadow={'0px 4px 10px 5px rgba(0, 0, 0, 0.10)'}
    >
      <Typography variant='h3' color={selected ? 'white' : 'gray'}>
        {amount?.toLocaleString()} ฿
      </Typography>
    </Box>
  )
}
