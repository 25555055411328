import { ThemeProvider } from '@emotion/react'
import { useUser } from '../../services/UserContext'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import { useEffect, useState } from 'react'
import { Role, UserData } from '../../models/UserTypes'
import AuthService from '../../services/auth.service'
import { theme } from '../../constants/theme'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { ADMIN_ROUTE } from '../../constants/admin-route-constant'
import { useGet } from '../../hooks/useFetch'
import { DashboardStat } from '../../models/admin/DashboardStat'
import formatCompactNumber from '../../utils/formatNumber'

export default function Dashboard() {
  const userData: UserData | null = useUser().userData
  const token = AuthService.getTokenUser()
  const [userRole, setUserRole] = useState<Role>()
  const tokenChecked = useAuthMiddleware()
  const navigator = useNavigate()
  const mobile = useMediaQuery('(min-width:600px)')
  document.body.style.background = '#FBFDFF'

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const [stats, statsLoading] = useGet<DashboardStat>({
    url: '/admins/dashboard',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  const [recentlyEnded, recentlyEndedLoading] = useGet<Number>({
    url: '/campaigns/recently-ended/count',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  const [nearlyFunded, nearlyFundedLoading] = useGet<Number>({
    url: '/campaigns/nearly-funded/count',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  const [oneWeekEnded, oneWeekEndedLoading] = useGet<Number>({
    url: '/campaigns/less-than-one-week-until-end/count',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  const [lastWeekFunded, lastWeekFundedLoading] = useGet<Number>({
    url: '/campaigns/funded-within-last-week/count',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div style={{ backgroundColor: '#FBFDFF' }}>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                textAlign={'center'}
                gap={{ xs: '24px', sm: '24px', md: '24px' }}
                marginY={'24px'}
              >
                <Stack
                  sx={{
                    width: '90%',
                    paddingTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'top',
                    textAlign: 'center',
                    padding: 0,
                  }}
                  flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
                  gap={{ xs: '24px', sm: '24px' }}
                >
                  {/* Information section */}
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '24px',
                      justifyContent: 'space-between',
                    }}
                    width={{ xs: '100%', sm: '100%', md: '49%' }}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                  >
                    {/* Accounting section */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                        borderColor: theme.palette.grey[200],
                        backgroundColor: 'white',
                        textAlign: 'left',
                        justifyContent: 'space-between',
                        paddingX: '20px',
                        paddingY: '12px',
                      }}
                      height={{ xs: 'fit-content', sm: '200px' }}
                      width={{ xs: '100%', sm: '49%', md: '49%' }}
                      gap={{ xs: '16px' }}
                    >
                      <Box
                        justifyContent={'space-between'}
                        alignItems={{ xs: 'center' }}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <Typography variant='h4' color={'text.primary'}>
                          Accounting
                        </Typography>
                      </Box>
                      <Box sx={{ alignItems: 'flex-end' }}>
                        <h6
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '8px',
                          }}
                          color={grey[600]}
                        >
                          Total income
                        </h6>
                        <Box height={'16px'}></Box>
                        <h3
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '28px',
                            lineHeight: '24px',
                            color: '#0079FF',
                          }}
                        >
                          ฿{' '}
                          {statsLoading
                            ? '0'
                            : formatCompactNumber(stats?.totalIncome!)}
                        </h3>
                      </Box>
                    </Box>
                    {/* Admin section */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                        borderColor: theme.palette.grey[200],
                        backgroundColor: 'white',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        paddingX: '20px',
                        paddingY: '12px',
                      }}
                      height={{ xs: 'fit-content', sm: '200px' }}
                      width={{ xs: '100%', sm: '49%', md: '49%' }}
                      gap={{ xs: '16px' }}
                    >
                      <Box
                        justifyContent={'space-between'}
                        alignItems={{ xs: 'center' }}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <Typography variant='h4' color={'text.primary'}>
                          Admin
                        </Typography>
                        <Box
                          sx={{
                            flexDirection: 'row',
                            borderRadius: '50px',
                            background: ' rgb(0 121 255 / .1)',
                            paddingLeft: '8px',
                            paddingRight: '8px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            ':hover': {
                              background: ' rgb(0 121 255 / .3)',
                            },
                            width: 'fit-content',
                            justifyItems: 'center',
                            alignItems: 'center',
                            height: 'fit-content',
                            cursor: 'pointer',
                          }}
                          display={{
                            xs: 'flex',
                            sm: 'none',
                            md: 'none',
                            lg: 'none',
                          }}
                          onClick={() =>
                            navigator(ADMIN_ROUTE.ADMIN_MANAGEMENT)
                          }
                        >
                          <Typography variant='body2' color={'primary'}>
                            Manage
                          </Typography>
                          <Box>
                            <ArrowForwardIcon
                              sx={{ marginLeft: '4px', fontSize: '24px' }}
                              color={'primary'}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <h3
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '28px',
                            lineHeight: '24px',
                            color: '#212121',
                          }}
                        >
                          {statsLoading
                            ? '0'
                            : stats?.activeAdminCount?.toLocaleString()}
                        </h3>
                        <Typography
                          variant='h6'
                          color={grey[600]}
                          sx={{ justifyContent: 'end' }}
                        >
                          Total admin
                        </Typography>

                        <Box
                          sx={{
                            flexDirection: 'row',
                            borderRadius: '50px',
                            background: ' rgb(0 121 255 / .1)',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            ':hover': {
                              background: ' rgb(0 121 255 / .3)',
                            },
                            width: 'fit-content',
                            justifyItems: 'center',
                            alignItems: 'center',
                            marginTop: '4px',
                            cursor: 'pointer',
                          }}
                          display={{
                            xs: 'none',
                            sm: 'flex',
                            md: 'flex',
                            lg: 'flex',
                          }}
                          onClick={() =>
                            navigator(ADMIN_ROUTE.ADMIN_MANAGEMENT)
                          }
                        >
                          <Typography variant='body2' color={'primary'}>
                            Manage Admins
                          </Typography>
                          <Box>
                            <ArrowForwardIcon
                              sx={{ marginLeft: '4px', fontSize: '24px' }}
                              color={'primary'}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Church section */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                      borderRadius: '16px',
                      boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                      borderColor: theme.palette.grey[200],
                      backgroundColor: 'white',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      paddingX: '20px',
                      paddingY: '12px',
                    }}
                    width={{ xs: '100%', sm: '100%', md: '49%' }}
                    height={{ xs: 'fit-content', sm: '200px' }}
                  >
                    <Typography variant='h4' color={'text.primary'}>
                      Church
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                      width={'100%'}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      alignItems={{ xs: 'start', sm: 'flex-end' }}
                      marginTop={{ xs: '16px' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        width={{
                          xs: '100%',
                          sm: '67%',
                          md: '60%',
                          lg: '67%',
                        }}
                        alignItems={{
                          xs: 'start',
                          sm: 'center',
                          md: 'start',
                          lg: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                          width={'100%'}
                          justifyContent={{
                            xs: 'start',
                            sm: 'space-around',
                            md: 'start',
                            lg: 'space-around',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            width={{
                              xs: '100%',
                              sm: '50%',
                              md: '100%',
                              lg: '50%',
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: 'Mitr',
                                fontWeight: 400,
                                fontSize: '28px',
                                lineHeight: '24px',
                                color: '#212121',
                              }}
                            >
                              {statsLoading
                                ? '0'
                                : stats?.church.pendingRequestCount?.toLocaleString()}
                            </h3>
                            <Typography
                              variant='h6'
                              color={grey[600]}
                              sx={{ justifyContent: 'end' }}
                            >
                              Pending requests
                            </Typography>
                          </Box>

                          <Box
                            display={{
                              xs: 'none',
                              sm: 'flex',
                              md: 'none',
                              lg: 'flex',
                            }}
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              variant='inset'
                              sx={{ borderRightWidth: 2, marginLeft: '0px' }}
                            />
                          </Box>

                          <Box
                            sx={{
                              flexDirection: 'column',
                              paddingLeft: '16px',
                            }}
                            width={'50%'}
                            display={{
                              xs: 'none',
                              sm: 'flex',
                              md: 'none',
                              lg: 'flex',
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: 'Mitr',
                                fontWeight: 400,
                                fontSize: '28px',
                                lineHeight: '24px',
                                color: '#212121',
                              }}
                            >
                              {statsLoading
                                ? '0'
                                : stats?.church.revisionNeededCount.toLocaleString()}
                            </h3>
                            <Typography
                              variant='h6'
                              color={grey[600]}
                              sx={{ justifyContent: 'end' }}
                            >
                              Revision needed
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '50px',
                            background: ' rgb(0 121 255 / .1)',

                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            ':hover': {
                              background: ' rgb(0 121 255 / .3)',
                            },
                            width: 'fit-content',
                            justifyItems: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginTop: '4px',
                          }}
                          onClick={() => navigator(ADMIN_ROUTE.CHURCH_REQUEST)}
                        >
                          <Typography variant='body2' color={'primary'}>
                            View Church Requests
                          </Typography>
                          <Box>
                            <ArrowForwardIcon
                              sx={{ marginLeft: '4px', fontSize: '24px' }}
                              color={'primary'}
                            />
                          </Box>
                        </Box>
                      </Box>

                      <Divider
                        orientation={mobile ? 'vertical' : 'horizontal'}
                        sx={{
                          borderRightWidth: 2,
                          lineHeight: 2,
                          marginY: mobile ? '0px' : '16px',
                          marginX: '0px',
                        }}
                        flexItem
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        width={{
                          xs: '100%',
                          sm: '34%',
                          md: '50%',
                          lg: '34%',
                        }}
                        paddingLeft={{ xs: '0px', sm: '24px' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          width={'100%'}
                        >
                          <h3
                            style={{
                              fontFamily: 'Mitr',
                              fontWeight: 400,
                              fontSize: '28px',
                              lineHeight: '24px',
                              color: '#212121',
                            }}
                          >
                            {statsLoading
                              ? '0'
                              : stats?.church.activeChurchCount?.toLocaleString()}
                          </h3>
                          <Typography
                            variant='h6'
                            color={grey[600]}
                            sx={{ justifyContent: 'end' }}
                          >
                            Active churches
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '50px',
                            background: ' rgb(0 121 255 / .1)',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            ':hover': {
                              background: ' rgb(0 121 255 / .3)',
                            },
                            width: 'fit-content',
                            justifyItems: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginTop: '4px',
                          }}
                          onClick={() => navigator(ADMIN_ROUTE.ALL_CHURCH)}
                        >
                          <Typography variant='body2' color={'primary'}>
                            View Churches
                          </Typography>
                          <Box>
                            <ArrowForwardIcon
                              sx={{ marginLeft: '4px', fontSize: '24px' }}
                              color={'primary'}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    width: '90%',
                    paddingTop: '30px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'top',
                    textAlign: 'center',
                    padding: 0,
                  }}
                  flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
                  gap={{ xs: '24px', sm: '24px' }}
                >
                  {/* Campaign section */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left',
                      borderRadius: '16px',
                      boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                      borderColor: theme.palette.grey[200],
                      backgroundColor: 'white',
                      alignItems: 'left',
                      justifyContent: 'space-between',
                      paddingX: '20px',
                      paddingY: '12px',
                    }}
                    width={{ xs: '100%', sm: '100%', md: '49%' }}
                    height={{ xs: 'fit-content', sm: '200px' }}
                  >
                    <Typography variant='h4' color={'text.primary'}>
                      Campaigns
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                      width={'100%'}
                      flexDirection={{ xs: 'column', sm: 'row' }}
                      alignItems={{ xs: 'start', sm: 'flex-end' }}
                      marginTop={{ xs: '16px' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        width={{
                          xs: '100%',
                          sm: '67%',
                          md: '60%',
                          lg: '67%',
                        }}
                        alignItems={{
                          xs: 'start',
                          sm: 'center',
                          md: 'start',
                          lg: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                          width={'100%'}
                          justifyContent={{
                            xs: 'start',
                            sm: 'space-around',
                            md: 'start',
                            lg: 'space-around',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                            width={{
                              xs: '100%',
                              sm: '50%',
                              md: '100%',
                              lg: '50%',
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: 'Mitr',
                                fontWeight: 400,
                                fontSize: '28px',
                                lineHeight: '24px',
                                color: '#212121',
                              }}
                            >
                              {statsLoading
                                ? '0'
                                : stats?.campaign.pendingRequestCount?.toLocaleString()}
                            </h3>
                            <Typography
                              variant='h6'
                              color={grey[600]}
                              sx={{ justifyContent: 'end' }}
                            >
                              Pending requests
                            </Typography>
                          </Box>

                          <Box
                            display={{
                              xs: 'none',
                              sm: 'flex',
                              md: 'none',
                              lg: 'flex',
                            }}
                          >
                            <Divider
                              orientation='vertical'
                              flexItem
                              variant='inset'
                              sx={{ borderRightWidth: 2, marginLeft: '0px' }}
                            />
                          </Box>

                          <Box
                            sx={{
                              flexDirection: 'column',
                              paddingLeft: '16px',
                            }}
                            width={'50%'}
                            display={{
                              xs: 'none',
                              sm: 'flex',
                              md: 'none',
                              lg: 'flex',
                            }}
                          >
                            <h3
                              style={{
                                fontFamily: 'Mitr',
                                fontWeight: 400,
                                fontSize: '28px',
                                lineHeight: '24px',
                                color: '#212121',
                              }}
                            >
                              {statsLoading
                                ? '0'
                                : stats?.campaign.successfulRequestCount?.toLocaleString()}
                            </h3>
                            <Typography
                              variant='h6'
                              color={grey[600]}
                              sx={{ justifyContent: 'end' }}
                            >
                              Successful requests
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '50px',
                            background: ' rgb(0 121 255 / .1)',

                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            ':hover': {
                              background: ' rgb(0 121 255 / .3)',
                            },
                            width: 'fit-content',
                            justifyItems: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginTop: '4px',
                          }}
                          onClick={() =>
                            navigator(ADMIN_ROUTE.CAMPAIGN_REQUEST)
                          }
                        >
                          <Typography variant='body2' color={'primary'}>
                            View Campaign Requests
                          </Typography>
                          <Box>
                            <ArrowForwardIcon
                              sx={{ marginLeft: '4px', fontSize: '24px' }}
                              color={'primary'}
                            />
                          </Box>
                        </Box>
                      </Box>

                      <Divider
                        orientation={mobile ? 'vertical' : 'horizontal'}
                        sx={{
                          borderRightWidth: 2,
                          lineHeight: 2,
                          marginY: mobile ? '0px' : '16px',
                          marginX: '0px',
                        }}
                        flexItem
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        width={{
                          xs: '100%',
                          sm: '34%',
                          md: '50%',
                          lg: '34%',
                        }}
                        paddingLeft={{ xs: '0px', sm: '24px' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                          width={'100%'}
                        >
                          <h3
                            style={{
                              fontFamily: 'Mitr',
                              fontWeight: 400,
                              fontSize: '28px',
                              lineHeight: '24px',
                              color: '#212121',
                            }}
                          >
                            {statsLoading
                              ? '0'
                              : stats?.campaign.activeCampaignCount?.toLocaleString()}
                          </h3>
                          <Typography
                            variant='h6'
                            color={grey[600]}
                            sx={{ justifyContent: 'end' }}
                          >
                            Active campaigns
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderRadius: '50px',
                            background: ' rgb(0 121 255 / .1)',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            ':hover': {
                              background: ' rgb(0 121 255 / .3)',
                            },
                            width: 'fit-content',
                            justifyItems: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            marginTop: '4px',
                          }}
                          onClick={() => navigator(ADMIN_ROUTE.ALL_CAMPAIGN)}
                        >
                          <Typography variant='body2' color={'primary'}>
                            View Campaigns
                          </Typography>
                          <Box>
                            <ArrowForwardIcon
                              sx={{ marginLeft: '4px', fontSize: '24px' }}
                              color={'primary'}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {/* Else section */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    width={{ xs: '100%', sm: '100%', md: '49%' }}
                    gap={{ xs: '24px', sm: '24px', md: '16px' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                        borderColor: theme.palette.grey[200],
                        backgroundColor: 'white',
                        paddingX: '20px',
                        paddingY: '12px',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ flexDirection: 'column', textAlign: 'left' }}>
                        <h5
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#212121',
                          }}
                        >
                          {nearlyFundedLoading
                            ? '0'
                            : nearlyFunded?.valueOf()?.toLocaleString()}
                        </h5>
                        <Typography variant='body1' color={grey[600]}>
                          95% funded campaigns
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'fit-content',
                          justifyItems: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          navigator(ADMIN_ROUTE.DASHBOARD_CAMPAIGN, {
                            state: {
                              filter: 'nearly-funded',
                              text: '95% Funded Campaigns',
                            },
                          })
                        }
                      >
                        <Typography variant='h6' color={'primary'}>
                          View
                        </Typography>
                        <Box>
                          <ArrowForwardIcon
                            sx={{ marginLeft: '8px', fontSize: '24px' }}
                            color={'primary'}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                        borderColor: theme.palette.grey[200],
                        backgroundColor: 'white',
                        paddingX: '20px',
                        paddingY: '12px',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ flexDirection: 'column', textAlign: 'left' }}>
                        <h5
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#212121',
                          }}
                        >
                          {lastWeekFundedLoading
                            ? '0'
                            : lastWeekFunded?.valueOf()?.toLocaleString()}
                        </h5>
                        <Typography variant='body1' color={grey[600]}>
                          100% funded campaigns in last 7 days
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'fit-content',
                          justifyItems: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          navigator(ADMIN_ROUTE.DASHBOARD_CAMPAIGN, {
                            state: {
                              filter: 'funded-within-last-week',
                              text: '100% Funded Campaigns In Last 7 Days',
                            },
                          })
                        }
                      >
                        <Typography variant='h6' color={'primary'}>
                          View
                        </Typography>
                        <Box>
                          <ArrowForwardIcon
                            sx={{ marginLeft: '8px', fontSize: '24px' }}
                            color={'primary'}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                        borderColor: theme.palette.grey[200],
                        backgroundColor: 'white',
                        paddingX: '20px',
                        paddingY: '12px',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ flexDirection: 'column', textAlign: 'left' }}>
                        <h5
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#212121',
                          }}
                        >
                          {oneWeekEndedLoading
                            ? '0'
                            : oneWeekEnded?.valueOf()?.toLocaleString()}
                        </h5>
                        <Typography variant='body1' color={grey[600]}>
                          less than 7 remaining days campaigns
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'fit-content',
                          justifyItems: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          navigator(ADMIN_ROUTE.DASHBOARD_CAMPAIGN, {
                            state: {
                              filter: 'less-than-one-week-until-end',
                              text: 'Less Than 7 Remaining Days Campaigns',
                            },
                          })
                        }
                      >
                        <Typography variant='h6' color={'primary'}>
                          View
                        </Typography>
                        <Box>
                          <ArrowForwardIcon
                            sx={{ marginLeft: '8px', fontSize: '24px' }}
                            color={'primary'}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderRadius: '16px',
                        boxShadow: '0px 0px 10px 0px rgba(0 0 0 / 0.10)',
                        borderColor: theme.palette.grey[200],
                        backgroundColor: 'white',
                        paddingX: '20px',
                        paddingY: '12px',
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ flexDirection: 'column', textAlign: 'left' }}>
                        <h5
                          style={{
                            fontFamily: 'Mitr',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            color: '#212121',
                          }}
                        >
                          {recentlyEndedLoading
                            ? '0'
                            : recentlyEnded?.valueOf()?.toLocaleString()}
                        </h5>
                        <Typography variant='body1' color={grey[600]}>
                          campaigns ended in the last 7 days
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: 'fit-content',
                          justifyItems: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          navigator(ADMIN_ROUTE.DASHBOARD_CAMPAIGN, {
                            state: {
                              filter: 'recently-ended',
                              text: 'Campaigns Ended In The Last 7 Days',
                            },
                          })
                        }
                      >
                        <Typography variant='h6' color={'primary'}>
                          View
                        </Typography>
                        <Box>
                          <ArrowForwardIcon
                            sx={{ marginLeft: '8px', fontSize: '24px' }}
                            color={'primary'}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </ThemeProvider>
      )
    }
  }
}
