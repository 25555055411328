import React, { useEffect, useMemo, useState } from 'react'
import { ModalType } from '../../constants/modalType'
import { CloseRounded } from '@mui/icons-material'
import {
  Backdrop,
  Modal,
  Box,
  Typography,
  Button,
  TextField,
} from '@mui/material'
import { common, grey } from '@mui/material/colors'

interface ModalProps {
  id: string
  type: ModalType
  title: string
  description: string
  confirmText?: string
  open: boolean
  onClickConfirm: (reason: string) => void
  onClose: () => void
  engVer?: boolean
  disableConfirmBtn: boolean
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
}

export default function ModalFillReason(props: ModalProps) {
  const {
    id,
    type,
    title,
    description,
    confirmText,
    open,
    onClickConfirm,
    onClose,
    engVer,
    disableConfirmBtn,
  } = props

  const [input, setInput] = useState<string>('')

  const color: any = useMemo(() => {
    if (type === 'DEFAULT') {
      return 'primary'
    } else if (type === 'ERROR') {
      return 'error'
    } else if (type === 'SUCCESS') {
      return 'secondary'
    }
  }, [type])

  function handleTextFieldChange(input: string): void {
    setInput(input.trim())
  }

  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Modal
        open={open}
        onClose={() => {
          setInput('')
          onClose()
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyItems={'center'}
          alignItems={'center'}
          flexShrink={0}
          sx={ModalStyle}
          gap={'8px'}
          width={{ xs: '80vw', md: '640px' }}
        >
          <Box
            display={'flex      '}
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            alignSelf={'stretch'}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
            >
              <Typography id='modal-modal-title' variant='h5' color={color}>
                {title}
              </Typography>
              <Typography
                id='modal-modal-description'
                variant='subtitle1'
                color={grey[600]}
              >
                {description}
              </Typography>
            </Box>
            <Button // close button
              onClick={onClose}
              color='inherit'
              size='small'
              sx={{
                minWidth: '16px',
                position: 'absolute',
                right: '0px',
                top: '0px',
                p: '16px',
              }}
            >
              <CloseRounded sx={{ color: grey[400] }} fontSize='small' />
            </Button>
          </Box>

          <textarea
            onChange={(e) => handleTextFieldChange(e.target.value)}
            required
            autoFocus
            className='modal-fill-reason-textarea'
          />

          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            alignSelf={'stretch'}
          >
            <Typography
              variant='h6'
              color='error'
              visibility={input === '' ? 'visible' : 'hidden'}
            >
              {engVer ? 'Must be filled in' : '*ต้องกรอก'}
            </Typography>
            <Button
              variant='outlined'
              color='error'
              disabled={input === '' || disableConfirmBtn}
              onClick={() => onClickConfirm(input)}
              sx={{ minWidth: '7vw', padding: '4px' }}
            >
              <Typography variant='h6'>{confirmText}</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  )
}
