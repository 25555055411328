import { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { Link, Navigate, useParams } from 'react-router-dom'

import { useAuthMiddleware } from '../../services/AuthMiddleware'
import { useUser } from '../../services/UserContext'
import { Role, UserData } from '../../models/UserTypes'
import { generateChurchRoute } from '../../utils/routeGenerator'

import { theme } from '../../constants/theme'
import { COLOR } from '../../constants/color-constant'

import StatusBar from '../../components/Admin/StatusBar'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import ChurchWeeklyEarningList from '../../components/Admin/Earning/ChurchWeeklyEarningList'

const mockData = [
  {
    startDate: '21/01/2024',
    endDate: '28/01/2024',
    amount: 12120,
    status: 'UNCONFIRMED',
  },
  {
    startDate: '28/01/2024',
    endDate: '4/02/2024',
    amount: 10000,
    status: 'UNCONFIRMED',
  },
]

function ChurchWeeklyEarning() {
  const [page, setPage] = useState(1)

  const tokenChecked = useAuthMiddleware()
  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()

  const { churchId } = useParams()

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const loading = false

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
    paddingLeft: '16px',
  })

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: COLOR.BACKGROUD_MAIN,
                  gap: '24px',
                }}
              >
                <Typography variant='h3' paddingY={3}>
                  Confirm Earning
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    padding: '24px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '24px',
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '90%',
                  }}
                >
                  <Stack flexDirection={'row'} gap={'24px'}>
                    <Typography variant='h6'>status: </Typography>
                    <StatusBar variant={'UNCONFIRMED'}></StatusBar>
                  </Stack>
                  <Link to={generateChurchRoute(churchId!)}>
                    <Typography
                      component={'span'}
                      variant='h5'
                      sx={{
                        color: '#0079FF',
                        textDecoration: 'underline',
                      }}
                    >
                      Church Name
                    </Typography>
                  </Link>
                  <Typography variant='h5'>Total Earning: {} บาท</Typography>
                </Box>
                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '90%',
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHeadCell>
                            <Typography variant='h6' color={'text.primary'}>
                              Start Date
                            </Typography>
                          </StyledTableHeadCell>
                          <StyledTableHeadCell>
                            <Typography variant='h6' color={'text.primary'}>
                              End Date
                            </Typography>
                          </StyledTableHeadCell>
                          <StyledTableHeadCell>
                            <Typography variant='h6' color={'text.primary'}>
                              Amount (บาท)
                            </Typography>
                          </StyledTableHeadCell>
                          <StyledTableHeadCell>
                            <Typography variant='h6' color={'text.primary'}>
                              Status
                            </Typography>
                          </StyledTableHeadCell>
                        </TableRow>
                      </TableHead>
                      {!loading && (
                        <TableBody>
                          {mockData.map((row) => (
                            <ChurchWeeklyEarningList
                              key={row.startDate}
                              churchId={churchId!}
                              startDate={row.startDate}
                              endDate={row.endDate}
                              amount={row.amount}
                              status={row.status}
                            />
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {loading && (
                    <Box paddingY={2}>
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}
                  <Pagination
                    count={1}
                    page={page}
                    onChange={handleChange}
                    sx={{ paddingTop: '5px' }}
                  />
                </Box>
              </Box>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </>
      )
    }
  }
}

export default ChurchWeeklyEarning
