export const ROUTE = {
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  USER: '/user',

  CHURCH: '/churches/:churchId',
  MANAGE_CHURCH: '/churches/:churchId/manage',
  EDIT_CHURCH: '/churches/:churchId/edit',
  CHURCH_MANAGE_CAMPAIGN: '/churches/:churchId/manage/campaigns',
  CHURCH_RECEIPT: '/churches/:churchID/receipt/:receiptID',

  CREATE_CHURCH_PROPOSAL: '/register-church',
  EDIT_CHURCH_PROPOSAL: '/churches-request/:churchId/edit',

  ALL_CAMPAIGN: '/campaigns',
  VIEW_CAMPAIGN: '/campaigns/:campaignId',
  EDIT_CAMPAIGN: '/campaigns/:campaignId/edit',
  CREATE_PROGRESS_UPDATE: '/campaigns/:campaignId/progress-update',
  EDIT_PROGRESS_UPDATE: '/campaign-request/:requestId/progress-update/edit',

  WANT_TO_SUBMIT_CAMPAIGN: '/campaign-request/want-to-submit',
  CREATE_CAMPAIGN_PROPOSAL: '/campaign-request',
  EDIT_CAMPAIGN_PROPOSAL: '/campaign-request/:campaignId/edit',

  NAMJAI_BAG: '/namjai-bag',
  FAQ: '/faq',
  ABOUT: '/about',
  THANKYOU: '/thank-you',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIES_POLICY: '/cookies-policy',

  NOT_FOUND: '/404',
  PERMISSION_DENIED: '/403',
}
