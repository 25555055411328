import {
  Stack,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import ProgressBar from './ProgressBar'
import { useEffect, useState } from 'react'
import { generateViewCampaignRoute } from '../utils/routeGenerator'
import { useNavigate } from 'react-router-dom'
import formatDate from '../utils/formatDate'

type CampaignListProps = {
  id: string
  name: string
  startDate: number
  endDate: number
  currentDonationAmount: number
  targetBudget: number
}

export default function CampaignList(props: CampaignListProps) {
  const matches = useMediaQuery('(min-width:680px)')
  const nowDate = Math.floor(Date.now())
  const navigator = useNavigate()

  const { id, name, startDate, endDate, currentDonationAmount, targetBudget } =
    props

  const endTime = props?.endDate
  const remaingdate = Math.floor((endTime - nowDate) / 1000 / 60 / 60 / 24)
  const path = generateViewCampaignRoute(id)
  const [selectedRow, setSelectedRow] = useState({})
  const navigate = useNavigate()

  return (
    <TableRow key={props.id} hover>
      <TableCell sx={{ width: 500, flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Column',
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <Typography
            variant='h5'
            color={'primary'}
            onClick={() => navigator(path)}
          >
            {name}
          </Typography>
          <Typography variant='body2' color={grey[600]}>
            {remaingdate} วัน จนถึง {formatDate(endDate)}
          </Typography>
        </Stack>
        {!matches && (
          <ProgressBar
            amount={currentDonationAmount}
            goal={targetBudget}
          ></ProgressBar>
        )}
      </TableCell>
      {matches && (
        <TableCell align='right' width={'350px'}>
          <ProgressBar
            amount={currentDonationAmount}
            goal={targetBudget}
          ></ProgressBar>
        </TableCell>
      )}
    </TableRow>
  )
}
