import axios from 'axios'
const API_URL = process.env.REACT_APP_BASE_API
const API_KEY = process.env.REACT_APP_XKEY_API ?? ''

const headers = {
  'x-api-key': API_KEY,
}

const acceptCooperativeInvitation = async (token: String) => {
  try {
    const response = await axios.get(
      `${API_URL}/campaign-requests/accept-cooperative-invitation?token=${token}`,
      {
        headers,
      }
    )

    if (response.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Error while acceptCooperativeInvitation  :', error)
    return false
  }
}

const churchesJoin = async (token: String) => {
  try {
    const response = await axios.get(
      `${API_URL}/churches/join?token=${token}`,
      {
        headers,
      }
    )

    if (response.status === 200) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error('Error while churchesJoin  :', error)
    return false
  }
}

const RedirectGetServices = {
  acceptCooperativeInvitation,
  churchesJoin,
}

export default RedirectGetServices
