import { useState } from 'react'
import { Delete } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'

interface ImagePreviewProps {
  index: number
  name: string
  imageUrl: string
  onDelete: (index: number) => void
  adminView: boolean
}

function ImagePreview({
  index,
  name,
  imageUrl,
  onDelete,
  adminView,
}: ImagePreviewProps) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div
      className={`relative block aspect-square h-full w-full ${
        !isLoaded && 'bg-zinc-300'
      }`}
    >
      <a href={imageUrl} target='_blank' rel='noreferrer'>
        <img
          src={imageUrl}
          alt={`${name} Preview`}
          width='100%'
          height='100%'
          style={{
            aspectRatio: '1/1',
            position: 'absolute',
            objectFit: 'cover',
          }}
          onLoad={() => setIsLoaded(true)}
        />
      </a>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        width={'auto'}
        alignItems={'center'}
      >
        <IconButton
          color='error'
          sx={{
            position: 'absolute',
            bottom: '8px',
            right: '8px',
          }}
          onClick={() => onDelete(index)}
          disabled={adminView}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '32px',
              height: '32px',
              backgroundColor: 'white',
              borderRadius: '50%',
            }}
          >
            <Delete sx={{ width: '22px', height: '22px' }} />
          </div>
        </IconButton>
      </Stack>
    </div>
  )
}

export default ImagePreview
