import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

type FormDirtyContextType = {
  isDirty: boolean
  setIsDirty: (isDirty: boolean) => void
}

const FormDirtyContext = createContext<FormDirtyContextType>({
  isDirty: false,
  setIsDirty: () => {},
})

type FormDirtyProviderProps = {
  children: ReactNode
}

export const FormDirtyProvider = ({ children }: FormDirtyProviderProps) => {
  const [isDirty, setIsDirty] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  return (
    <FormDirtyContext.Provider value={{ isDirty, setIsDirty }}>
      {children}
    </FormDirtyContext.Provider>
  )
}

export const useFormDirtyContext = () => {
  const context = useContext(FormDirtyContext)
  if (!context) {
    throw new Error(
      'useFormDirtyContext must be used within a FormDirtyProvider'
    )
  }
  return context
}
