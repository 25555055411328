import { Stack, Typography } from '@mui/material'
import { ToastContainer } from 'react-toastify'

import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import ChurchProposal from '../../components/ChurchProposal/ChurchProposal'
import { useGet } from '../../hooks/useFetch'
import { useParams } from 'react-router'
import AuthService from '../../services/auth.service'
import { GetChurchProposalFormData } from '../../models/ChurchProposalFormData'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { ROUTE } from '../../constants/route-constant'
import { generateEditChurchProposalRoute } from '../../utils/routeGenerator'

function EditChurchProposalPage() {
  const navigate = useNavigate()
  const params = useParams()
  const { churchId: requestId } = params
  const [churchProposalData, loading, getChurchData, statusCode] =
    useGet<GetChurchProposalFormData>({
      url: `/church-requests/${requestId}`,
      jwtToken: AuthService.getTokenUser() ?? '',
      autoFetch: true,
      autoNavigate: true,
    })

  useEffect(() => {
    if (statusCode === 401 || statusCode === 406) {
      navigate(ROUTE.PERMISSION_DENIED)
    } else if (!AuthService.getTokenUser()) {
      navigate(
        `${ROUTE.LOGIN}?redirectTo=${generateEditChurchProposalRoute(
          requestId!
        )}`
      )
    }
  }, [statusCode, navigate, requestId])

  if (loading) {
    return null
  }

  return (
    <>
      <ToastContainer />
      <Stack flexDirection='column' minHeight={'100vh'}>
        <Navbar />
        <Typography alignSelf={'center'} variant='h1' sx={{ margin: '20px' }}>
          Edit Church
        </Typography>
        <ChurchProposal
          type='EDIT'
          {...(churchProposalData ? { initialData: churchProposalData } : {})}
          requestId={requestId}
          status={churchProposalData?.churchRequestStatus}
          adminNote={churchProposalData?.adminComment}
        />
        <Footer />
      </Stack>
    </>
  )
}

export default EditChurchProposalPage
