import {
  Typography,
  Box,
  TextField,
  Divider,
  Button,
  useMediaQuery,
} from '@mui/material'
import React, { useEffect } from 'react'
import { COLOR } from '../../constants/color-constant'
import { DonorType, PaymentData } from '../../models/campaign'
import DonorTypeBox from './DonorTypeBox'
import {
  FieldErrors,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  useForm,
} from 'react-hook-form'
import { TypeOf, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'

interface ThankyouLetterForm {
  donorFirstName: string
  donorLastName: string
  donorPhoneNumber: string
  donorAddress: string
  donorTaxNumber: string
}

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
)

const numberRegrex = new RegExp(/^(\s*|\d+)$/)

export const ThankyouLetterFormSchema = z.object({
  donorFirstName: z.string().trim().nonempty({ message: '*ต้องกรอก' }),
  donorLastName: z.string().trim().nonempty({ message: '*ต้องกรอก' }),
  donorPhoneNumber: z.string().regex(phoneRegex, 'เบอร์โทรศัพท์ไม่ถูกต้อง'),
  donorAddress: z.string().trim().nonempty({ message: '*ต้องกรอก' }),
  donorTaxNumber: z.string().regex(numberRegrex, 'ตัวเลขเท่านั้น'),
})
export type ThankyouLetterFormInput = TypeOf<typeof ThankyouLetterFormSchema>

interface ThankYouLetterSectionProps {
  openPaymentSection: boolean
  setOpenPaymentSection: (open: boolean) => void
  initialData?: any
  toUploadData: PaymentData
  setToUploadData: React.Dispatch<React.SetStateAction<PaymentData>>
  handleSubmit: UseFormHandleSubmit<ThankyouLetterForm>
  register: UseFormRegister<ThankyouLetterForm>
  clearErrors: UseFormClearErrors<ThankyouLetterForm>
  errors: FieldErrors<ThankyouLetterFormInput>
}

export default function ThankYouLetterSection(
  props: ThankYouLetterSectionProps
) {
  const {
    openPaymentSection,
    setOpenPaymentSection,
    initialData,
    toUploadData,
    setToUploadData,
    handleSubmit,
    register,
    clearErrors,
    errors,
  } = props

  const matches = useMediaQuery('(min-width:890px)')

  const submitHandler = () => {
    setOpenPaymentSection(true)
  }

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={matches ? 'row' : 'column'}
        gap={'16px'}
      >
        <div
          onClick={() =>
            setToUploadData({ ...toUploadData, donorType: 'PERSONAL' })
          }
        >
          <DonorTypeBox
            donorType='PERSONAL'
            selectedDonorType={toUploadData.donorType}
          />
        </div>
        <div
          onClick={() =>
            setToUploadData({ ...toUploadData, donorType: 'JURISTIC' })
          }
        >
          <DonorTypeBox
            donorType='JURISTIC'
            selectedDonorType={toUploadData.donorType}
          />
        </div>
      </Box>

      <Typography variant='h4' alignSelf={'stretch'}>
        จดหมายขอบคุณ และ ใบลดหย่อนภาษี
      </Typography>
      <Box
        id='namjai-payment-section-form-area-thankyou-letter-form'
        display={'flex'}
        padding={'0 16px'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        gap={'12px'}
        alignSelf={'stretch'}
        component='form'
        onSubmit={handleSubmit(submitHandler)}
      >
        <Typography variant='h6' color={COLOR.SECOUNDARY_FIRST_1}>
          ข้อมูลของท่านจะนำไปใช้ในการออกจดหมายขอบคุณสำหรับน้ำใจของท่าน
          และใบลดหย่อนภาษี (หากมี)
        </Typography>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={'flex-start'}
          gap={'12px'}
          alignSelf={'stretch'}
        >
          <TextField
            id='thankyou-letter-name'
            label='ชื่อ'
            variant='outlined'
            size='small'
            fullWidth
            {...register('donorFirstName')}
            onChange={() => clearErrors('donorFirstName')}
            error={Boolean(errors.donorFirstName)}
          />
          <TextField
            id='thankyou-letter-surname'
            label='สกุล'
            variant='outlined'
            size='small'
            fullWidth
            {...register('donorLastName')}
            onChange={() => clearErrors('donorLastName')}
            error={Boolean(errors.donorFirstName)}
          />
        </Box>
        {/* TODO: Validate only valid phone number */}
        <TextField
          id='thankyou-letter-phone-number'
          label='เบอร์โทรศัพท์'
          variant='outlined'
          size='small'
          fullWidth
          {...register('donorPhoneNumber')}
          onChange={() => clearErrors('donorPhoneNumber')}
          error={Boolean(errors.donorPhoneNumber)}
        />
        <TextField
          id='thankyou-letter-phone-address'
          label='ที่อยู่'
          variant='outlined'
          size='small'
          fullWidth
          {...register('donorAddress')}
          onChange={() => clearErrors('donorAddress')}
          error={Boolean(errors.donorAddress)}
        />
        <Divider sx={{ width: '100%' }} />
        <Typography variant='h6' color={COLOR.SECOUNDARY_FIRST_1}>
          สำหรับผู้ที่ต้องการใบลดหย่อนภาษี
        </Typography>
        <TextField
          id='thankyou-letter-tax-deduction-no'
          label='เลขที่เสียภาษี'
          variant='outlined'
          size='small'
          fullWidth
          {...register('donorTaxNumber')}
          onChange={() => clearErrors('donorTaxNumber')}
          error={Boolean(errors.donorTaxNumber)}
        />
      </Box>
      {!openPaymentSection && (
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <Button
            variant='outlined'
            color='secondary'
            form='namjai-payment-section-form-area-thankyou-letter-form'
            type='submit'
          >
            <Typography variant='h6'>เข้าสู่การชำระเงิน</Typography>
          </Button>
        </Box>
      )}
    </>
  )
}
