const CREATE_CAMPAIGN = 'create_campaign'

export type LocalStorageKey = typeof CREATE_CAMPAIGN

const saveToLocalStorage = (key: LocalStorageKey, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getFromLocalStorage = (key: LocalStorageKey) => {
  const data = localStorage.getItem(key)

  if (!data) {
    return null
  }

  return JSON.parse(data)
}

const removeLocalStorage = (key: LocalStorageKey) => {
  localStorage.removeItem(key)
}

export {
  CREATE_CAMPAIGN,
  saveToLocalStorage,
  getFromLocalStorage,
  removeLocalStorage,
}
