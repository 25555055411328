import React, {
  useRef,
  useEffect,
  useState,
  Suspense,
  ReactElement,
} from 'react'

interface LazyLoadedComponentProps {
  Component: ReactElement
}

export const LazyLoadedComponent: React.FC<LazyLoadedComponentProps> = ({
  Component,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const compoundRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(entry.target)
        }
      })
    })

    if (compoundRef.current) {
      observer.observe(compoundRef.current)
    }

    return () => {
      if (compoundRef.current) {
        observer.unobserve(compoundRef.current)
      }
    }
  }, [])

  return (
    <div ref={compoundRef}>
      {isVisible && (
        <Suspense fallback={<div>Loading...</div>}>{Component}</Suspense>
      )}
    </div>
  )
}
