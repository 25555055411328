import { TableCell, TableRow, Tooltip, Typography } from '@mui/material'

import StatusBar from './StatusBar'
import { useNavigate } from 'react-router-dom'
import {
  generateViewChurchAdminRoute,
  generateViewReceiptAdminRoute,
  generateWeeklyEarningAdminRoute,
} from '../../utils/routeGenerator'
import formatCompactNumber from '../../utils/formatNumber'
import { PlatformConfirmEarnings } from '../../models/admin/PlatformEarning'

export default function PlatformEarningList(props: PlatformConfirmEarnings) {
  const { churchId, churchName, totalAndStatus } = props

  const navigate = useNavigate()

  return (
    <TableRow
      key={churchId}
      // onClick={() => navigate(generateWeeklyEarningAdminRoute(churchId))}
    >
      <TableCell align='left' sx={{ width: 200, flexWrap: 'wrap' }}>
        <Typography variant='h5'>{churchName}</Typography>
        <Typography
          variant='h6'
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => navigate(generateViewChurchAdminRoute(churchId!))}
        >
          {churchId}
        </Typography>
      </TableCell>

      <TableCell align='right' sx={{ width: 200, flexWrap: 'wrap' }}>
        <Typography variant='h5' align='right' color={'text.primary'}>
          {formatCompactNumber(totalAndStatus.total)} บาท
        </Typography>
      </TableCell>

      <TableCell align='left' sx={{ width: 100, flexWrap: 'wrap' }}>
        <StatusBar variant={totalAndStatus.status}></StatusBar>
      </TableCell>

      <TableCell align='center' sx={{ width: 200, flexWrap: 'wrap' }}>
        <Typography
          variant='h6'
          color={'primary'}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => navigate(generateWeeklyEarningAdminRoute(churchId))}
        >
          Earning record
        </Typography>
      </TableCell>
    </TableRow>
  )
}
