export const ADMIN_ROUTE = {
  LOGIN: '/admin/login',

  DASHBOARD: '/admin/dashboard',
  DASHBOARD_CAMPAIGN: '/admin/dashboard/campaign',

  ADMIN_MANAGEMENT: '/admin/management',

  ALL_CAMPAIGN: '/admin/campaigns',
  VIEW_CAMPAIGN: '/admin/campaigns/:campaignId',
  EDIT_CAMPAIGN: '/admin/campaigns/:campaignId/edit',
  EDIT_CAMPAIGN_PROGRESS:
    '/admin/campaigns/:campaignId/progress/:progressIndex',
  CAMPAIGN_REQUEST: '/admin/campaigns/request',
  CAMPAIGN_REQUEST_VIEW: '/admin/campaigns/request/:requestId',
  CAMPAIGN_UPDATE_REQUEST_VIEW: '/admin/campaigns/update-request/:requestId',

  CHURCH_EARNING: '/admin/earning/:churchId',
  CHURCH_RECEIPTS: '/admin/earning/:churchId/receipt',

  ALL_CHURCH: '/admin/churchs',
  VIEW_CHURCH: '/admin/churchs/:churchId',
  CHURCH_REQUEST: '/admin/churches/request',
  CHURCH_REQUEST_VIEW: '/admin/churches/request/:requestId',

  ALL_EARNING: '/admin/earning',
  WEEKLY_EARNING_LIST: '/admin/weekly-earning/:churchId',
  ALL_RECEIPT_LIST: '/admin/:churchId/receipt-list/:confirmEarningId',
}
