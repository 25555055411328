import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import AuthService from './auth.service'

const API_KEY = process.env.REACT_APP_XKEY_API || ''
const baseURL = process.env.REACT_APP_BASE_API || ''

type UploadFileAttribute = 'image' | 'file' | 'qr-code'

const uploadFile = async (
  url: string,
  file: File,
  attrib: UploadFileAttribute,
  handleUploadProgress?: (file: File, percent: number) => void
) => {
  const token = AuthService.getTokenUser()

  const data = new FormData()
  data.append(attrib, file)
  // console.log(token)
  const config: AxiosRequestConfig = {
    baseURL,
    url,
    method: 'POST',
    headers: {
      'x-api-key': API_KEY,
      Authorization: `Bearer ${token}`,
    },
    data,
    onUploadProgress: (progressEvent: AxiosProgressEvent) => {
      const percentCompleted = progressEvent.total
        ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
        : 0

      handleUploadProgress && handleUploadProgress(file, percentCompleted)
    },
  }

  return axios.request(config)
}

export default uploadFile
