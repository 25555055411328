import {
  Box,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  styled,
} from '@mui/material'
import { BudgetPlans } from '../../models/campaign'
import { grey } from '@mui/material/colors'

export default function BudgetPlansTable(props: {
  data: BudgetPlans[] | null
  isLargeScreen: boolean
}) {
  const data = props.data
  const isLargeScreen = props.isLargeScreen
  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
  })
  const StyledTableCell = styled(TableCell)({
    paddingTop: '8px',
    paddingBottom: '8px',
  })
  const columnsLabels = [
    { label: 'ลำดับที่', width: 20 },
    { label: 'หัวข้อ', width: 600 },
    { label: 'ลักษณะนาม' },
    { label: 'จำนวน' },
    { label: 'งบประมาณ' },
  ]

  interface TableColumn {
    label: string
    width?: number
  }

  const TableHeaderRow: React.FC<{ columns: TableColumn[] }> = ({
    columns,
  }) => (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <StyledTableHeadCell key={index} width={column.width}>
            <Typography variant='h6' color={'text.primary'}>
              {column.label}
            </Typography>
          </StyledTableHeadCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const TableBodyRow: React.FC<{
    plan: BudgetPlans
    isLargeScreen: boolean
    // onClick: () => void
  }> = ({
    plan,
    isLargeScreen,
    // onClick
  }) => (
    <TableRow
      key={plan.index}
      // onClick={onClick}
    >
      <StyledTableCell sx={{ width: 20, flexWrap: 'wrap', cursor: 'pointer' }}>
        {isLargeScreen && (
          <Typography variant='body2' color={'text.primary'}>
            {plan.index}
          </Typography>
        )}

        {!isLargeScreen && (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'Column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                No.
              </Typography>
              <Typography variant='body2' color={'text.primary'}>
                {plan.index}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                Topics
              </Typography>
              <Typography sx={{ px: 2 }} variant='body2' color={'text.primary'}>
                {plan.topic}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                Classifier
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {plan?.classifier}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                Amount
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {plan?.amount?.toLocaleString() ?? 0}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                Budget
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {plan?.budget?.toLocaleString() ?? 0}
              </Typography>
            </Box>
          </Stack>
        )}
      </StyledTableCell>
      {isLargeScreen && (
        <>
          <TableCell align='left' sx={{ width: 300, flexWrap: 'wrap' }}>
            <Typography variant='body1' color={'text.primary'}>
              {plan.topic}
            </Typography>
          </TableCell>
          <TableCell align='left' sx={{ width: 100, flexWrap: 'wrap' }}>
            <Typography variant='body1'>{plan?.classifier}</Typography>
          </TableCell>
          <TableCell align='left' sx={{ width: 100, flexWrap: 'wrap' }}>
            <Typography variant='body1'>
              {plan?.amount?.toLocaleString() ?? 0}
            </Typography>
          </TableCell>
          <TableCell align='left' sx={{ width: 100, flexWrap: 'wrap' }}>
            <Typography variant='body1'>
              {plan?.budget?.toLocaleString() ?? 0}
            </Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  )

  return (
    <Box
      display={'flex'}
      padding={'8px'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      textAlign={'center'}
      marginBottom={'12px'}
      sx={{
        // borderRadius: '8px',
        // border: '1px solid',
        // borderColor: theme.palette.grey[200],
        // backgroundColor: 'white',
        width: '100%',
      }}
    >
      <TableContainer component={Paper}>
        <Table>
          {isLargeScreen && <TableHeaderRow columns={columnsLabels} />}
          {!isLargeScreen && (
            <TableHeaderRow columns={[{ label: 'Budget detail' }]} />
          )}
          <TableBody>
            {data
              ?.sort((a, b) => a.index - b.index) // Sort the data by the "index" property
              .map((plan) => (
                <TableBodyRow
                  key={plan.index}
                  plan={plan}
                  isLargeScreen={isLargeScreen}
                  // onClick={() => {}}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
