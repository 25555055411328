import { PersonAddRounded, EditNoteRounded } from '@mui/icons-material'
import {
  Stack,
  Box,
  Typography,
  Button,
  Divider,
  useMediaQuery,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { useContext } from 'react'
import { theme } from '../constants/theme'
import { useUser } from '../services/UserContext'
import { ChurchMemberManagementContext } from '../pages/ChurchManagement/ChurchManagement'

interface ChurchManagementProfileProps {
  id: string
  name: string
  totalProject: number | undefined
  totalMember: number | undefined
  totalFund: number | undefined
  handleClickInvite: () => void
  handleClickEdit: () => void
}

export default function ChurchManagementProfile(
  props: ChurchManagementProfileProps
) {
  const {
    id,
    name,
    totalProject,
    totalMember,
    totalFund,
    handleClickEdit,
    handleClickInvite,
  } = props

  const userContext = useUser()
  const desktop = useMediaQuery('(min-width:600px)')

  const [userIds, setUserIds, checkIfNotContactPerson] = useContext(
    ChurchMemberManagementContext
  )

  return (
    <Stack
      direction={{ md: 'column', lg: 'row' }}
      display={'flex'}
      alignItems={{ md: 'left', lg: 'flex-start' }}
      gap={theme.spacing(5)}
    >
      {/* church profile */}
      <Box width={{ sm: '100vw', md: '640px' }}>
        <Typography variant='h1' sx={{ padding: theme.spacing(1) }}>
          {' '}
          {name}
        </Typography>
        <Box display={'flex'} sx={{ gap: theme.spacing(3) }}>
          <Button
            variant='outlined'
            endIcon={<PersonAddRounded />}
            onClick={handleClickInvite}
          >
            เพิ่มเจ้าหน้าที่คริสตจักร
          </Button>
          {checkIfNotContactPerson() && (
            <Button
              variant='outlined'
              endIcon={<EditNoteRounded />}
              onClick={handleClickEdit}
            >
              แก้ไขข้อมูล
            </Button>
          )}
        </Box>
      </Box>
      {/* church statics */}
      <Stack direction='column'>
        <Typography variant='h5' sx={{ padding: theme.spacing(1) }}>
          สถิติการระดมทุนของคริสตจักร
        </Typography>
        <Stack
          flexDirection={desktop ? 'row' : 'column'}
          alignItems={'center'}
          border={1}
          borderColor={grey[400]}
          // width={window.innerWidth < 500? '50vw' : '500px'}
          sx={{
            gap: theme.spacing(2),
            px: '50px',
            py: '10px',
            justifyContent: 'space-between',
            borderRadius: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: theme.spacing(0),
            }}
          >
            <Typography variant='h6' color={'primary'}>
              {totalProject}
            </Typography>
            <Typography variant='body2' color={grey[600]}>
              โครงการทั้งหมด
            </Typography>
          </Box>
          <Divider orientation={desktop ? 'vertical' : 'horizontal'} flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: theme.spacing(0),
            }}
          >
            <Typography variant='h6' color={'primary'}>
              {totalMember}
            </Typography>
            <Typography variant='body2' color={grey[600]}>
              จำนวนผู้มีน้ำใจ
            </Typography>
          </Box>
          <Divider orientation={desktop ? 'vertical' : 'horizontal'} flexItem />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              gap: theme.spacing(0),
            }}
          >
            <Typography variant='h6' color={'primary'}>
              {totalFund?.toLocaleString()}฿
            </Typography>
            <Typography variant='body2' color={grey[600]}>
              ยอดระดมทุนสะสม
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
