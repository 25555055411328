import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { BsBagHeart } from 'react-icons/bs'
import { ROUTE } from '../../constants/route-constant'
import DonationAmount from './DonationAmount'
import { useNavigate } from 'react-router-dom'
import DonationCustomAmount from './DonationCustomAmount'
import AuthService from '../../services/auth.service'
import { COLOR } from '../../constants/color-constant'
import { PaymentData } from '../../models/campaign'

interface SelectTotalProps {
  openPaymentSection: boolean
  setOpenThankYouLetterSection: (open: boolean) => void
  toUploadData: PaymentData
  setToUploadData: React.Dispatch<React.SetStateAction<PaymentData>>
  campaignId: string
  addToBag: () => Promise<void>
}
export default function SelectTotal(props: SelectTotalProps) {
  const navigate = useNavigate()
  const token = AuthService.getTokenUser()
  const matches = useMediaQuery('(min-width:890px)')

  const {
    setOpenThankYouLetterSection,
    toUploadData,
    setToUploadData,
    openPaymentSection,
    addToBag,
    campaignId,
  } = props

  const [customSelected, setCustomSelected] = useState<boolean>(false)
  const [openErrorMsg_Login, setOpenErrorMsg_Login] = useState<boolean>(false)
  const [openErrorMsg_NoTotal, setOpenErrorMsg_NoTotal] =
    useState<boolean>(false)
  const [errorSnackbar, setOpenErrorSnackBar] = useState<boolean>(false)

  const handleSelectPreAmount = (total: number) => {
    setToUploadData({ ...toUploadData, total: total })
    setCustomSelected(false)
  }

  const handleClickPay = () => {
    if (!toUploadData.total) {
      setOpenErrorMsg_NoTotal(true)
    } else if (!token) {
      setOpenErrorMsg_Login(true)
    } else {
      setOpenThankYouLetterSection(true)
      setOpenErrorMsg_NoTotal(false)
      setOpenErrorMsg_Login(false)
    }
  }

  const handleClickAddToBag = async () => {
    if (!toUploadData.total) {
      setOpenErrorMsg_NoTotal(true)
    } else if (!token) {
      setOpenErrorMsg_Login(true)
    } else {
      setOpenErrorMsg_NoTotal(false)
      setOpenErrorMsg_Login(false)
      addToBag()
    }
  }

  return (
    <Box
      id='namjai-payment-section-form-area-content'
      display={'flex'}
      flexDirection={'column'}
      // justifyContent={'center'}
      alignItems={'flex-start'}
      gap={'24px'}
      padding={'16px'}
      width={'100%'}
    >
      <Typography variant='h4'>จำนวนเงินที่จะบริจาค</Typography>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        width={'100%'}
        gap={'16px'}
        flexDirection={matches ? 'row' : 'column'}
      >
        <div onClick={() => handleSelectPreAmount(2000)}>
          <DonationAmount
            amount={2000}
            selected={toUploadData.total === 2000 && !customSelected}
          />
        </div>
        <div onClick={() => handleSelectPreAmount(1000)}>
          <DonationAmount
            amount={1000}
            selected={toUploadData.total === 1000 && !customSelected}
          />
        </div>
        <div onClick={() => handleSelectPreAmount(500)}>
          <DonationAmount
            amount={500}
            selected={toUploadData.total === 500 && !customSelected}
          />
        </div>
        <div onClick={() => setCustomSelected(true)}>
          <DonationCustomAmount
            selected={customSelected}
            toUploadData={toUploadData}
            setToUploadData={setToUploadData}
          />
        </div>
      </Box>

      <Box
        display={'flex'}
        alignSelf={'center'}
        gap={'24px'}
        paddingTop={'12px'}
        flexDirection={matches ? 'row' : 'column'}
      >
        <Button
          variant='outlined'
          color='secondary'
          onClick={() => handleClickPay()}
        >
          <Typography variant='h5'>ชำระเงินด้วย QR Code</Typography>
        </Button>
        <Button
          variant='outlined'
          color='primary'
          // disabled={namjaiBagListStatus === 'loading'}
          startIcon={<BsBagHeart size={20} />}
          onClick={() => handleClickAddToBag()}
        >
          <Typography variant='h6'>เพิ่มลง กระเป๋าน้ำใจ</Typography>
        </Button>
      </Box>
      {openErrorMsg_Login && (
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          <Typography align='center'>กรุณา </Typography>
          <a href={ROUTE.LOGIN}>
            <Typography align='center' color={COLOR.PRIMARY_1}>
              เข้าสู่ระบบ
            </Typography>
          </a>
          <Typography align='center'>
            ก่อนทำการชำระเงิน / เพิ่มโครงการลง กระเป๋าน้ำใจ
          </Typography>
        </Box>
      )}
      {openErrorMsg_NoTotal && (
        <Box display={'flex'} width={'100%'} justifyContent={'center'}>
          <Typography align='center'>
            กรุณาระบุจำนวนเงินที่จะบริจาคก่อนทำการชำระเงิน / เพิ่มโครงการลง
            กระเป๋าน้ำใจ
          </Typography>
        </Box>
      )}
    </Box>
  )
}
