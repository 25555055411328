import { CampaignProposalForm1Input } from '../components/CampaignProposal/CampaignProposalForm1'
import { CampaignProposalForm2Input } from '../components/CampaignProposal/CampaignProposalForm2'
import { CampaignProposalForm3Input } from '../components/CampaignProposal/CampaignProposalForm3'
import { churchInfo } from '../components/SearchChurch'
import {
  CampaignRequestContactPerson,
  CampaignRequestProjectHolder,
} from '../models/CampaignProposal/GetCampaignProposalData'
import trimFormValues from '../utils/trimFormValues'
import { BankAccount } from '../models/campaign'
import {
  AreaHolistic,
  CampaignProposalFormData,
} from '../models/CampaignProposal/CampaignProposalFormData'
import { UserShortDetail } from '../models/UserTypes'

export const AREA_HOLISTIC_ENUM: AreaHolistic[] = [
  'EDUCATION',
  'CHILD_SAFEGUARDING',
  'INFRASTRUCTURE',
  'YOUTH_LEADERSHIP',
  'WELL_BEING',
]

type CombinedCampaignDataInput = {
  form1: CampaignProposalForm1Input
  form2: CampaignProposalForm2Input
  form3: CampaignProposalForm3Input
  imageUrls: string[]
  holderChurches: churchInfo[] | CampaignRequestProjectHolder[]
  contactPersons: CampaignRequestContactPerson[] | UserShortDetail[]
  selectedAreas: boolean[]
  qrUrl: string
  bankAccount: BankAccount
}

export const combinedCampaignData = ({
  form1,
  form2,
  form3,
  imageUrls,
  holderChurches,
  contactPersons,
  selectedAreas,
  qrUrl,
  bankAccount,
}: CombinedCampaignDataInput): CampaignProposalFormData => {
  const data = {
    campaignName: form1.campaignName,
    fundingGoal: form1.fundingGoal,
    objective: form2.objective,
    background: form2.background,
    workingProcess: form2.workingProcess,
    expectedResult: form2.expectedResult,
    location: form2.location,
    childTarget: form2.childTarget,
    budgetPlan: form3.budgetPlan,
    image: imageUrls,
    bankAccount: {
      ...trimFormValues(bankAccount),
      qrCodeUrl: qrUrl,
    },
    holderChurchIds: holderChurches.map((church) => church.churchId),
    contactPersonId: contactPersons.map((person) => person.id),
    areaHolistic: AREA_HOLISTIC_ENUM.filter((area, i) => selectedAreas[i]),
    resourceType: [],
    campaignStartDate: form1.campaignStartDate.valueOf(),
    campaignEndDate: form1.campaignEndDate.valueOf(),
    donationStartDate: form1.donationStartDate.valueOf(),
    donationEndDate: form1.donationEndDate.valueOf(),
    activityPlan: form2.activityPlan.map((p) => ({
      ...p,
      startDate: p.startDate.valueOf(),
      endDate: p.endDate.valueOf(),
    })),
  }
  delete data['oldChurchHolder' as keyof typeof combinedCampaignData]
  delete data['oldContactPerson' as keyof typeof combinedCampaignData]

  return data
}
