import {
  Box,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'

import { grey } from '@mui/material/colors'

import StatusBar from './StatusBar'

import { ReactComponent as Fund } from '../../assets/icons/Fundforadmin.svg'

import {
  generateViewChurchRequestAdminRoute,
  generateViewCampaignRequestAdminRoute,
  generateViewChurchAdminRoute,
  generateViewCampaignUpdateRequestAdminRoute,
} from '../../utils/routeGenerator'
import { useNavigate } from 'react-router-dom'
import { HolderChurch } from '../../models/admin/CampaignRequest'

type RequestListProps = {
  variant: string
  requestId: string
  name: string
  date: number
  version?: number
  holder?: HolderChurch[]
  type: string
  status: string
}

export default function RequestList(props: RequestListProps) {
  const fundIcon = useMediaQuery('(min-width:900px)')
  const mobile = useMediaQuery('(min-width:600px)')

  const navigate = useNavigate()

  const { variant, requestId, name, date, version, holder, type, status } =
    props

  const reqDate = new Date(date)

  var path = generateViewCampaignRequestAdminRoute(requestId)
  if (variant === 'church') {
    path = generateViewChurchRequestAdminRoute(requestId)
  }

  if (variant === 'campaign' && type === 'UPDATE') {
    path = generateViewCampaignUpdateRequestAdminRoute(requestId)
  }

  const StyledTableCell = styled(TableCell)({
    paddingTop: '8px',
    paddingBottom: '8px',
  })

  const StyledTableCell2 = styled(TableCell)({
    paddingTop: '12px',
    paddingBottom: '12px',
  })

  return (
    <TableRow key={requestId}>
      <StyledTableCell2
        sx={{ width: 600, flexWrap: 'wrap', cursor: 'pointer' }}
        onClick={() => navigate(path)}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            flexWrap: 'no wrap',
            alignItems: 'left',
            gap: '8px',
          }}
        >
          {variant == 'campaign' && fundIcon && (
            <Box width={'40px'}>
              <Fund></Fund>
            </Box>
          )}
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'Column',
              flexWrap: 'wrap',
              alignItems: 'left',
            }}
          >
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'Column',
                columnGap: '4px',
                alignItems: 'left',
              }}
            >
              <Typography
                component={'span'}
                variant='body1'
                // color={'text.primary'}
                sx={{
                  cursor: 'pointer',
                  color: mobile ? 'text.primary' : '#0079FF',
                }}
              >
                <div onClick={() => navigate(path)}>{name}</div>
              </Typography>
              {mobile && (
                <Typography
                  variant='body2'
                  color={grey[400]}
                  sx={{ cursor: 'pointer' }}
                >
                  Request date
                  {' ' +
                    reqDate.getDate().toString() +
                    '/' +
                    (reqDate.getMonth() + 1) +
                    '/' +
                    reqDate.getFullYear()}
                </Typography>
              )}
            </Stack>

            {!mobile && variant == 'campaign' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'Column',
                  gap: '5px',
                  alignItems: 'left',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Request date
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {' ' +
                        reqDate.getDate().toString() +
                        '/' +
                        (reqDate.getMonth() + 1) +
                        '/' +
                        reqDate.getFullYear()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Holder Church
                    </Typography>
                  </Box>

                  <Box>
                    {holder?.map((church) => (
                      <Typography
                        variant='body2'
                        color={'text.primary'}
                        onClick={() =>
                          generateViewChurchAdminRoute(church.churchId)
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        {church.churchName}
                      </Typography>
                    ))}
                    <Typography
                      variant='body2'
                      color={'text.primary'}
                    ></Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Version
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {version}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Type
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {type}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Status
                    </Typography>
                  </Box>
                  <Box>
                    <StatusBar variant={status}></StatusBar>
                  </Box>
                </Box>
              </Box>
            )}
            {!mobile && variant == 'church' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'Column',
                  gap: '5px',
                  alignItems: 'left',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Request date
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {' ' +
                        reqDate.getDate().toString() +
                        '/' +
                        (reqDate.getMonth() + 1) +
                        '/' +
                        reqDate.getFullYear()}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Type
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2' color={'text.primary'}>
                      {type}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Row',
                    alignItems: 'left',
                  }}
                >
                  <Box width={'140px'}>
                    <Typography variant='body2' color={grey[800]}>
                      Status
                    </Typography>
                  </Box>
                  <Box>
                    <StatusBar variant={status}></StatusBar>
                  </Box>
                </Box>
              </Box>
            )}
          </Stack>
        </Stack>
      </StyledTableCell2>
      {variant == 'campaign' && mobile && (
        <>
          <StyledTableCell
            align='left'
            sx={{ paddingLeft: '8px', width: 200, flexWrap: 'wrap' }}
          >
            {holder?.map((church) => (
              <Typography
                variant='body1'
                color={'text.primary'}
                sx={{ cursor: 'pointer' }}
              >
                <div
                  onClick={() =>
                    navigate(generateViewChurchAdminRoute(church.churchId))
                  }
                >
                  {church.churchName}
                </div>
              </Typography>
            ))}
          </StyledTableCell>
          <TableCell
            align='right'
            sx={{ paddingLeft: '8px', flexWrap: 'wrap' }}
          >
            <Typography variant='body1' color={'text.primary'}>
              v{version}
            </Typography>
          </TableCell>
          <TableCell
            align='right'
            sx={{ paddingLeft: '8px', flexWrap: 'wrap' }}
          >
            <Typography variant='body1' color={'text.primary'}>
              {type}
            </Typography>
          </TableCell>
          <TableCell
            align='right'
            sx={{ paddingLeft: '8px', flexWrap: 'wrap' }}
          >
            <StatusBar variant={status}></StatusBar>
          </TableCell>
        </>
      )}

      {variant == 'church' && mobile && (
        <TableCell align='right' sx={{ width: 300, flexWrap: 'wrap' }}>
          <Typography variant='body1' color={'text.primary'}>
            {type}
          </Typography>
        </TableCell>
      )}

      {variant == 'church' && mobile && (
        <TableCell align='right' sx={{ width: 200, flexWrap: 'wrap' }}>
          <StatusBar variant={status}></StatusBar>
        </TableCell>
      )}
    </TableRow>
  )
}
