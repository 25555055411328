import {
  Backdrop,
  Box,
  Button,
  Divider,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { common, grey } from '@mui/material/colors'
import React, { useContext, useMemo, useState } from 'react'
import { ModalType } from '../../constants/modalType'
import { theme } from '../../constants/theme'
import { CloseRounded } from '@mui/icons-material'

interface ModalDeleteWithConfirmProps {
  id: string
  type: ModalType
  confirmText: string
  title?: string
  description?: React.ReactNode
  confirmBtnText?: string
  cancleBtnText?: string
  open: boolean
  onClose: () => void
  handleClickConfirm?: () => void
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
}

// MODAL WITH FILL TO CONFIRM DELETE
export default function ModalDeleteWithConfirm(
  props: ModalDeleteWithConfirmProps
) {
  const {
    id,
    type,
    title,
    confirmText,
    description,
    confirmBtnText,
    cancleBtnText,
    open,
    onClose,
    handleClickConfirm,
  } = props

  const color: any = useMemo(() => {
    if (type === 'DEFAULT') {
      return 'primary'
    } else if (type === 'ERROR') {
      return 'error'
    } else if (type === 'SUCCESS') {
      return 'secondary'
    }
  }, [type])

  const _churchName = (
    <Typography color={color} display={'span'}>
      {confirmText}
    </Typography>
  )

  const [validate, setValidate] = useState<boolean>(false)

  function ValidateInput(input: any): void {
    setValidate(input === confirmText)
  }

  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Modal open={open} onClose={onClose}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          sx={ModalStyle}
          width={{ xs: '80vw', md: '640px' }}
        >
          <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
            <Typography
              id='modal-modal-title'
              variant='h5'
              color={color}
              width={'100%'}
            >
              {title}
            </Typography>
            <Button // close button
              onClick={onClose}
              color='inherit'
              size='small'
              sx={{
                minWidth: '16px',
                position: 'absolute',
                right: '0px',
                top: '0px',
                p: '16px',
              }}
            >
              <CloseRounded sx={{ color: grey[400] }} fontSize='small' />
            </Button>
          </Box>
          <Divider
            variant='fullWidth'
            sx={{ color: grey[200], padding: '4px' }}
            flexItem
          />
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2 }}
            variant='subtitle1'
            color={grey[600]}
          >
            {description}
          </Typography>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            width={'100%'}
            gap={'4px'}
            paddingTop={theme.spacing(2)}
          >
            <Typography color={grey[600]}>พิมพ์</Typography>
            {_churchName}
            <Typography color={grey[600]}>เพื่อยืนยัน</Typography>
          </Stack>
          <TextField
            autoFocus
            error={validate ? false : true}
            id='outlined-basic'
            variant='outlined'
            fullWidth={true}
            size='small'
            placeholder={confirmText}
            onChange={(e) => ValidateInput(e.target.value)}
          />
          <Typography variant='subtitle1' color={grey[600]} sx={{ mt: 2 }}>
            หลังจากกดยืนยัน ระบบจะส่งคำร้องให้กับแอดมินของน้ำใจในการพิจารณาต่อไป
          </Typography>
          <Stack // buttons
            display={'flex'}
            flexDirection={{ sm: 'column', md: 'row' }}
            gap={theme.spacing(2)}
            width={'100%'}
            alignItems={'flex-end'}
            justifyContent={'flex-end'}
            paddingTop={theme.spacing(2)}
          >
            <Button // cancel btn
              sx={{ color: grey[600] }}
              color='inherit'
              variant='outlined'
              onClick={() => {
                onClose()
                setValidate(false)
              }}
            >
              <Typography variant='h6'>{cancleBtnText}</Typography>
            </Button>
            <Button // confitm btn
              disabled={validate ? false : true}
              color={color}
              variant='contained'
              onClick={() => {
                if (handleClickConfirm) handleClickConfirm()
                setValidate(false)
              }}
            >
              <Typography variant='h6'>{confirmBtnText}</Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Backdrop>
  )
}
