import {
  Grid,
  Box,
  Typography,
  TableCell,
  TableBody,
  styled,
  TableRow,
  Stack,
  FormControl,
  Select,
  MenuItem,
  useMediaQuery,
  Table,
  TableHead,
  TableContainer,
  Paper,
  Card,
} from '@mui/material'
import Button from '@mui/material/Button'
import {
  CampaignProgressDetail,
  CampaignProgressDetailList,
} from '../../../models/CampaignProgress/CampaignProgressDetail'
import { grey } from '@mui/material/colors'
import { useState } from 'react'
import { COLOR } from '../../../constants/color-constant'
import { useUser } from '../../../services/UserContext'
import { UserData } from '../../../models/UserTypes'
import { Detail } from '../../../models/campaign'
import formatDate from '../../../utils/formatDate'
import { useNavigate, useParams } from 'react-router-dom'
import { generateCampaignProgressUpdateRoute } from '../../../utils/routeGenerator'

export default function CampaignProgressDisplay(props: {
  data: CampaignProgressDetail[] | null
  detailContact: Detail | null
}) {
  const userData: UserData | null = useUser().userData
  const data = props.data
  const detailContact = props.detailContact
  const params = useParams()
  const navigate = useNavigate()

  const hasMatchingEmail = detailContact?.contactPerson?.some(
    (person) => person.email === userData?.userdetail.email
  )
  const isLargeScreen = useMediaQuery('(min-width:750px)')
  const StyledTableCell = styled(TableCell)({
    paddingTop: '8px',
    paddingBottom: '8px',
  })
  const [progressSelec, setProgressSelec] = useState(0)
  const columnsLabels = [
    { label: 'วันและเวลาที่ดำเนินการ', width: 300 },
    { label: 'การดำเนินการ/กิจกรรม', width: 600 },
    { label: 'ผลที่เกิดขึ้น' },
    { label: 'ค่าใช้จ่าย (บาท)' },
  ]

  const ProgressSelect = () => {
    return (
      <FormControl
        sx={{
          minWidth: 150,
          '&:focus-within': {
            boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
          },
        }}
        variant='standard'
        size='small'
      >
        <Select
          disableUnderline
          id='progress-select-small'
          variant='outlined'
          value={`ความคืบหน้าครั้งที่ ${progressSelec + 1}`}
          onChange={(event) => setProgressSelec(parseInt(event.target.value))}
          autoWidth
          renderValue={(selected) => (
            <Typography variant='h6'>{selected}</Typography>
          )}
        >
          {data
            ?.sort((a, b) => a.index - b.index)
            .map((progress) => (
              <MenuItem value={progress.index - 1}>
                ความคืบหน้าครั้งที่ {progress.index}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }
  interface TableColumn {
    label: string
    width?: number
  }
  const TableHeaderRow: React.FC<{ columns: TableColumn[] }> = ({
    columns,
  }) => (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell key={index} width={column.width}>
            <Typography variant='h6' color={'text.primary'}>
              {column.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
  const ProgressDetails: React.FC<{
    progress: CampaignProgressDetail
    isLargeScreen: boolean
  }> = ({ progress, isLargeScreen }) => (
    <>
      <TableBody>
        {progress.campaignProgressDetails.map((ProgressRow, index) => (
          <ProgressRowList
            key={index}
            progressDetail={ProgressRow}
            isLargeScreen={isLargeScreen}
          />
        ))}
      </TableBody>
    </>
  )
  const ProgressRowList: React.FC<{
    progressDetail: CampaignProgressDetailList
    isLargeScreen: boolean
    // onClick: () => void
  }> = ({ progressDetail, isLargeScreen }) => (
    <TableRow key={progressDetail.index}>
      <StyledTableCell sx={{ width: 300, flexWrap: 'wrap' }}>
        {isLargeScreen && (
          <Typography variant='body2' color={'text.primary'}>
            {formatDate(progressDetail.activityStartDate)} ถึง{' '}
            {formatDate(progressDetail.activityEndDate)}
          </Typography>
        )}

        {!isLargeScreen && (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'Column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                ลำดับ
              </Typography>
              <Typography sx={{ px: 2 }} variant='body2' color={'text.primary'}>
                {progressDetail.index}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                วันและเวลาที่ดำเนินการ
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {formatDate(progressDetail.activityStartDate)} ถึง{' '}
                {formatDate(progressDetail.activityEndDate)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                การดำเนินการ/กิจกรรม
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {progressDetail.activity}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
                flexWrap: 'wrap',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                ผลที่เกิดขึ้น
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {progressDetail.outcome}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Row',
                alignItems: 'left',
              }}
            >
              <Typography variant='body2' color={grey[800]}>
                ค่าใช้จ่าย (บาท)
              </Typography>
              <Typography sx={{ px: 2 }} variant='body1'>
                {progressDetail.expenses?.toLocaleString()}
              </Typography>
            </Box>
          </Stack>
        )}
      </StyledTableCell>
      {isLargeScreen && (
        <>
          <TableCell align='left' sx={{ width: 500, flexWrap: 'wrap' }}>
            <Typography variant='body1' color={'text.primary'}>
              {progressDetail.activity}
            </Typography>
          </TableCell>
          <TableCell align='left' sx={{ width: 500, flexWrap: 'wrap' }}>
            <Typography variant='body1'>{progressDetail.outcome}</Typography>
          </TableCell>
          <TableCell align='left' sx={{ width: 300, flexWrap: 'wrap' }}>
            <Typography variant='body1'>
              {progressDetail.expenses?.toLocaleString()}
            </Typography>
          </TableCell>
        </>
      )}
    </TableRow>
  )

  return (
    <Box display='flex' flexDirection='column' py={5}>
      <Box
        display='flex'
        flexDirection={isLargeScreen ? 'row' : 'column'}
        sx={{ paddingX: '8px' }}
      >
        <Typography variant='h4' color={COLOR.SECOUNDARY_FIRST_1}>
          รายงานความคืบหน้า
        </Typography>
        {hasMatchingEmail && (
          <Box paddingX={{ xs: '0px', sm: '16px' }}>
            <Button
              variant='outlined'
              style={{
                borderBlockWidth: '2px',
                borderRadius: '10px',
                borderColor: COLOR.PRIMARY_1,
              }}
              fullWidth
              onClick={() => {
                navigate(
                  generateCampaignProgressUpdateRoute(params.campaignId!)
                )
              }}
              sx={{ width: '100%', height: '42px' }}
            >
              <Typography variant='h6' color={'primary'}>
                +{'   '}ส่งรายงานความคืบหน้า
              </Typography>
            </Button>
          </Box>
        )}
      </Box>

      {data && data.length > 0 ? (
        <>
          <Box
            display={'flex'}
            padding={'8px'}
            flexDirection={'row'}
            gap={'10px'}
            flexWrap={'wrap'}
            marginBottom={'12px'}
            sx={{
              width: '100%',
            }}
          >
            <ProgressSelect key={'ProgressSelect'} />
            <TableContainer component={Paper}>
              <Table>
                {isLargeScreen && <TableHeaderRow columns={columnsLabels} />}
                {!isLargeScreen && (
                  <TableHeaderRow columns={[{ label: 'Progress detail' }]} />
                )}
                <ProgressDetails
                  progress={data[progressSelec]}
                  isLargeScreen={isLargeScreen}
                />
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ padding: '10px' }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 8, md: 12 }}
            >
              {data[progressSelec]?.imgUrl.map((pic, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Card>
                    <img
                      className='img-hover  '
                      key={index}
                      src={pic.url}
                      alt={pic.fileName}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Typography paddingX={'8px'} variant='h6' color={'text.primary'}>
          ไม่มีรายงานความคืบหน้า
        </Typography>
      )}
    </Box>
  )
}
