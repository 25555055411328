import { Button, Divider, Stack, Typography } from '@mui/material'
import { ROUTE } from '../../constants/route-constant'

import { ReactComponent as Facebook } from '../../assets/SocialIcons/Facebook.svg'
import { ReactComponent as Instagram } from '../../assets/SocialIcons/Instagram.svg'
import { ReactComponent as Youtube } from '../../assets/SocialIcons/Youtube.svg'
import { ReactComponent as Email } from '../../assets/SocialIcons/Email.svg'
import { Link } from 'react-router-dom'

const FACEBOOK_URL = 'https://www.facebook.com/namjaisociety'
const INSTAGRAM_URL = 'https://www.instagram.com/compassionthailand'
const YOUTUBE_URL = 'https://www.youtube.com/@compassionth'
const EMAIL_URL = 'mailto:namjai@darunatorn.com'

function Footer() {
  const ICON_SIZE = 36
  return (
    <footer>
      <div
        className='align-center bottom-0 flex h-auto 
          w-full flex-col-reverse content-center items-start justify-center gap-10 bg-[#005eb8] px-8 py-6 text-white sm:flex-row sm:items-center'
      >
        {/* Navigation Menu */}
        <div className='flex flex-col items-start gap-2 sm:items-center'>
          <Link to={ROUTE.CREATE_CAMPAIGN_PROPOSAL}>
            <Button
              variant='contained'
              sx={{
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                },
                borderRadius: '8px',
                padding: '2px 16px',
                boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.25)',
              }}
            >
              <Typography variant='h5' sx={{ color: '#005eb8' }}>
                สร้างโครงการ
              </Typography>
            </Button>
          </Link>
          <div
            className='flex flex-row flex-wrap gap-x-4 
              sm:justify-center'
          >
            <Link
              to={ROUTE.PRIVACY_POLICY}
              className='whitespace-nowrap text-lg hover:text-[#00DFA2]'
            >
              <Typography variant='h6'>นโยบายความเป็นส่วนตัว</Typography>
            </Link>
            <Link
              to={ROUTE.COOKIES_POLICY}
              className='whitespace-nowrap text-lg hover:text-[#00DFA2]'
            >
              <Typography variant='h6'>นโยบายการใช้คุกกี้</Typography>
            </Link>
          </div>
          <Typography variant='subtitle2'>Copyright ©2023</Typography>
        </div>
        <Divider
          className='hidden sm:block'
          sx={{ background: 'white' }}
          orientation='vertical'
          flexItem
        />
        <Divider
          className='block sm:hidden'
          sx={{ background: 'white' }}
          orientation='horizontal'
          flexItem
        />
        {/* Contact Us */}
        <div className='flex flex-col gap-1'>
          <Typography variant='h4'>ติดต่อเรา</Typography>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            gap={'16px'}
            alignItems={'center'}
          >
            <a href={FACEBOOK_URL} target='_blank' rel='noreferrer'>
              <Facebook
                color={'#FBFDFF'}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            </a>
            <a href={INSTAGRAM_URL} target='_blank' rel='noreferrer'>
              <Instagram
                color={'#FBFDFF'}
                width={ICON_SIZE}
                height={ICON_SIZE}
              />
            </a>
            <a href={YOUTUBE_URL} target='_blank' rel='noreferrer'>
              <Youtube color={'#FBFDFF'} width={ICON_SIZE} height={ICON_SIZE} />
            </a>
            <a href={EMAIL_URL} target='_blank' rel='noreferrer'>
              <Email color={'#FBFDFF'} width={ICON_SIZE} height={ICON_SIZE} />
            </a>
          </Stack>
        </div>
      </div>
    </footer>
  )
}

export default Footer
