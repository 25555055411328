import { Button, IconButton, Stack, Typography } from '@mui/material'
import {
  ArrowDownwardRounded,
  ArrowUpwardRounded,
  Delete,
} from '@mui/icons-material'
import { useContext, useState } from 'react'
import { ChurchMember } from '../models/UserTypes'
import { DeleteMemberListContext } from '../pages/ChurchManagement/ChurchMemberManagement'

interface ChurchMemberListProps {
  member: ChurchMember
  promoteable: boolean
  demoteable: boolean
  removeable: boolean
  open: boolean
  handleClickPromote: () => void
  handleClickDemote: () => void
  handleClickRemove: (member: ChurchMember) => void
  handleClickCancelRemove: (member: ChurchMember) => void
}

export default function ChurchMemberList(props: ChurchMemberListProps) {
  const {
    member,
    promoteable,
    demoteable,
    removeable,
    open,
    handleClickPromote,
    handleClickDemote,
    handleClickRemove,
    handleClickCancelRemove,
  } = props

  const toBeRemovedMembers = useContext(DeleteMemberListContext)
  const [isRemove, setIsRemove] = useState<boolean>(false)

  const toggleRemove = () => {
    setIsRemove(!isRemove)
  }

  function checkRemoved() {
    return (
      toBeRemovedMembers.filter((m) => m.email === member.email).length === 1
    )
  }

  return (
    <Stack
      key={`church-mgmt-list-${member.churchMemberRole}-${member.firstName}`}
      display={'flex'}
      flexDirection={{ sm: 'row' }}
      padding={'4px 8px'}
      justifyContent={'space-between'}
      bgcolor={open && checkRemoved() ? '#FFE6EB' : ''}
      alignItems={'center'}
    >
      {member && (
        <>
          <Stack
            flexDirection={{ md: 'column', lg: 'row' }}
            justifyContent={'space-evenly'}
            gap={{ lg: '24px' }}
            alignSelf={'flex-start'}
          >
            <Typography
              variant='h6'
              sx={{
                textDecoration: open && checkRemoved() ? 'line-through' : '',
                width: { lg: '24vw' },
              }}
            >
              {member.firstName + ' ' + member.lastName}
            </Typography>
            <Typography
              variant='h6'
              sx={{
                textDecoration: open && checkRemoved() ? 'line-through' : '',
              }}
            >
              {member.email}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} gap={'8px'} alignSelf={'flex-start'}>
            {promoteable && open && !checkRemoved() && (
              <Button
                variant='outlined'
                endIcon={<ArrowUpwardRounded />}
                color='primary'
                sx={{
                  width: '100px',
                  height: '50%',
                  padding: 1,
                }}
                onClick={() => handleClickPromote()}
              >
                <Typography variant='h6'>เลื่อนขั้น</Typography>
              </Button>
            )}

            {demoteable && open && !checkRemoved() && (
              <Button
                variant='outlined'
                endIcon={<ArrowDownwardRounded />}
                color='primary'
                sx={{ width: '90px', height: '70%', padding: 1 }}
                onClick={() => handleClickDemote()}
              >
                <Typography variant='h6'>ลดขั้น</Typography>
              </Button>
            )}

            {removeable && open && !checkRemoved() && (
              <IconButton
                aria-label='delete'
                color='error'
                onClick={() => {
                  toggleRemove()
                  handleClickRemove(member)
                }}
              >
                <Delete />
              </IconButton>
            )}

            {open && checkRemoved() && (
              <Button
                variant='outlined'
                color='error'
                onClick={() => {
                  toggleRemove()
                  handleClickCancelRemove(member)
                }}
              >
                ยกเลิกการลบ
              </Button>
            )}
          </Stack>
        </>
      )}
      {!member && (
        <Typography color='gray'>
          ไม่มีเจ้าหน้าที่คริสตจักรในตำแหน่งนี้
        </Typography>
      )}
    </Stack>
  )
}
