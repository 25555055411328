import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  TableCell,
  TableRow,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material'
import { theme } from '../constants/theme'
import { COLOR } from '../constants/color-constant'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

import { UserProfile as ProfileResponse, UserData } from '../models/UserTypes'
import { useUser } from '../services/UserContext'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { grey } from '@mui/material/colors'
import { BiEdit } from 'react-icons/bi'
import AuthService from '../services/auth.service'
import { RecentCampaigns } from '../models/campaign'
import { generateViewCampaignRoute } from '../utils/routeGenerator'
import { ReactComponent as ChurchOutline } from '../assets/icons/church_outline.svg'
import { ReactComponent as Fundforadmin } from '../assets/icons/Fundforadmin.svg'

import { generateChurchRoute } from '../utils/routeGenerator'
import { useGet, usePost } from '../hooks/useFetch'
import { useState } from 'react'
import NormalModal from '../components/Modal/NormalModal'
import CircularProgress from '@mui/material/CircularProgress'

function RecentTable({
  campaignId,
  campaignName,
  donationAmount,
  campaignResourceTypes,
}: RecentCampaigns) {
  const path = generateViewCampaignRoute(campaignId)
  const navigate = useNavigate()

  return (
    <TableRow key={campaignId}>
      <TableCell align='left' style={{ width: '40%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            {/* //TODO campaignResourceTypes */}
            <Fundforadmin />
          </Box>

          <Typography variant='h6' color='text'>
            {campaignName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell align='center' sx={{ width: { sm: '10%', md: '35%' } }}>
        <Typography variant='h6' color='text'>
          {donationAmount} บาท
        </Typography>
      </TableCell>
      <TableCell align='right' sx={{ width: { sm: '5%', md: '40%' } }}>
        <Typography
          variant='h6'
          color='primary'
          onClick={() => {
            navigate(path)
          }}
        >
          View
        </Typography>
      </TableCell>
    </TableRow>
  )
}

function UserProfile() {
  const navigate = useNavigate()
  const userData: UserData | null = useUser().userData

  const token = AuthService.getTokenUser()
  const [isResendValidationModal, setIsResendValidationModal] = useState(false)

  const [recentCampaign] = useGet<RecentCampaigns[]>({
    url: '/users/recently-donated-campaigns',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  const [userProfile, loadingUserProfile] = useGet<ProfileResponse>({
    url: '/users/profile',
    ...(token && { jwtToken: token }),
    autoFetch: true,
  })

  const [
    sendValidationRequestResp,
    sendValidationLoading,
    sendValidation,
    statusCode,
  ] = usePost<any>({
    url: '/auth/resend-verification',
    autoFetch: false,
    data: '',
    jwtToken: AuthService.getTokenUser() ?? '',
  })

  const matches = useMediaQuery(theme.breakpoints.not('md'))

  let churchId = ''
  let cardData: {
    label: string
    value: number | string
  }[] = []

  if (!loadingUserProfile && userProfile) {
    churchId = userProfile.churchShort?.churchId ?? ''
    cardData = [
      {
        label: 'ยอดบริจาคทั้งหมด',
        value: userProfile.fundingActivity.donatedFunds + ' ฿',
      },
      {
        label: 'จำนวนโครงการที่บริจาค',
        value: userProfile.fundingActivity.fundingProjectCount,
      },
      {
        label: 'จำนวนโครงการที่ดำเนินการเสร็จสิ้น',
        value: userProfile.fundingActivity.fundedProjectCount,
      },
    ]
  }

  if (loadingUserProfile) {
    return (
      <div className='flex h-screen flex-grow items-center justify-center'>
        <CircularProgress />
      </div>
    )
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div className='flex flex-col'>
        <Navbar />
        <NormalModal
          id={'resend-Validation-email'}
          type={'DEFAULT'}
          title={`กรุณายืนยันตัวตนของท่านผ่านอีเมล`}
          open={isResendValidationModal}
          onClose={() => {
            setIsResendValidationModal(false)
          }}
          confirmText='ส่งอีเมลยืนยันตัวตนอีกครั้ง'
          onClickConfirm={() => {
            sendValidation()
          }}
        />

        <Container
          disableGutters={matches}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Card variant='outlined' sx={{ my: 3, borderRadius: '16px', p: 3 }}>
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              justifyContent='space-between'
            >
              {/* ///////////////// */}
              <Stack
                direction={'row'}
                sx={{
                  alignItems: 'center',
                  paddingY: '4px',
                  marginRight: '13px',
                  margin: '3px',
                }}
              >
                <AccountCircleIcon
                  sx={{
                    height: 80,
                    width: 80,
                    color: COLOR.SECOUNDARY_SECOUND_3,
                  }}
                />
                <Box sx={{ margin: '5px' }}></Box>

                <Box>
                  <Typography
                    variant='h5'
                    color={'text.primary'}
                    sx={{ whiteSpace: 'normal', overflowWrap: 'break-word' }}
                  >
                    {userData?.userdetail?.firstName +
                      ' ' +
                      userData?.userdetail?.lastName}
                  </Typography>

                  <Typography>
                    {userProfile?.userDetail.verified ? (
                      <Typography variant='h6' color={COLOR.PRIMARY_2}>
                        ยีนยันตัวตนแล้ว
                      </Typography>
                    ) : (
                      <Typography variant='h6' color={COLOR.PRIMARY_4}>
                        ยังไม่ได้ยืนยันตัวตน
                      </Typography>
                    )}
                  </Typography>
                  <Typography variant='body2' color={grey[600]}>
                    เบอร์โทรศัพท์ : {userData?.userdetail?.phoneNumber}
                  </Typography>
                  <Typography variant='body2' color={grey[600]}>
                    อีเมล : {userData?.userdetail?.email}
                  </Typography>
                </Box>
              </Stack>

              {/* ///////////////// */}

              <Box>
                {/* Hide edit profile button */}
                {/* <Button
                  variant='outlined'
                  sx={{ m: 1, borderRadius: '16px', maxHeight: '45px' }}
                >
                  <Typography
                    variant='subtitle1'
                    color={COLOR.PRIMARY_1}
                    sx={{ margin: '3px' }}
                  >
                    แก้ไขข้อมูล
                  </Typography>
                  <BiEdit size={'30px'} />
                </Button> */}
                {!userProfile?.userDetail.verified && (
                  <Button
                    variant='outlined'
                    sx={{ m: 1, borderRadius: '16px' }}
                    onClick={() => {
                      setIsResendValidationModal(true)
                      console.log('Resend verification')
                    }}
                  >
                    <Typography color={COLOR.PRIMARY_1} sx={{ margin: '3px' }}>
                      ยืนยันตัวตนอีกครั้ง
                    </Typography>
                  </Button>
                )}
              </Box>
              {/* ///////////////// */}
            </Stack>
            {/* ////////////////////////////////// */}
            <Box sx={{ my: 3 }}>
              <Typography
                variant='h3'
                color={COLOR.BLACK_TEXT}
                sx={{ margin: '10px' }}
              >
                สรุปกิจกรรม
              </Typography>
              {/* ///////////////// */}
              <Box height={20}></Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='h6'
                  color={COLOR.BLACK_TEXT}
                  sx={{ margin: '5px' }}
                >
                  ประวัติการบริจาค
                </Typography>

                <Grid
                  width={'80%'}
                  container
                  columns={{ xs: 2, sm: 2, md: 12 }}
                >
                  {cardData.map((card, index) => (
                    <Grid item xs={3} sm={3} md={4} key={index}>
                      <Card variant='outlined'>
                        <Typography
                          align='center'
                          variant='h4'
                          color={COLOR.PRIMARY_1}
                          sx={{ margin: '5px' }}
                        >
                          {card.value}
                        </Typography>
                        <Typography
                          align='center'
                          variant='body2'
                          color={COLOR.GRAY_400}
                          sx={{ margin: '5px' }}
                        >
                          {card.label}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'Column',
              }}
              paddingBottom={{ xs: '16px', sm: '16px' }}
            >
              <Typography
                variant='h6'
                color={'text.primary'}
                sx={{ marginBottom: '16px' }}
              >
                โครงการที่บริจาคล่าสุด
              </Typography>

              <Box
                sx={{
                  width: '100%',
                  overflow: 'auto',
                  maxHeight: '50vh',
                  border: '1px solid #F0F0F0',
                }}
              >
                <TableContainer>
                  <Table>
                    <TableBody>
                      {recentCampaign && recentCampaign.length !== 0 ? (
                        recentCampaign.map((c) => (
                          <RecentTable
                            campaignId={c.campaignId}
                            campaignName={c.campaignName}
                            donationAmount={c.donationAmount}
                            campaignResourceTypes={c.campaignResourceTypes}
                            key={c.campaignId}
                          />
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            ไม่มีข้อมูลการบริจาคล่าสุด
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>

            <Typography
              variant='h6'
              color={'text.primary'}
              sx={{ marginBottom: '16px' }}
            >
              โบสถ์ของฉัน
            </Typography>
            {userProfile?.churchShort?.churchId ? (
              <Button onClick={() => navigate(generateChurchRoute(churchId))}>
                <Card
                  variant='outlined'
                  sx={{
                    borderRadius: '16px',
                    alignItems: 'center',
                    p: 3,
                    width: '300px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                  }}
                >
                  <ChurchOutline />
                  <Box>
                    <Typography
                      align='left'
                      variant='body1'
                      color={'text.secondary'}
                    >
                      โบสถ์
                    </Typography>
                    <Typography variant='body1' color={'text.secondary'}>
                      {userProfile?.churchShort?.churchName}
                    </Typography>
                  </Box>
                </Card>
              </Button>
            ) : (
              <Typography variant='body1' color={'text.secondary'}>
                ไม่ได้เป็นสมาชิกของโบสถ์
              </Typography>
            )}
          </Card>
        </Container>
      </div>
      <Footer />
    </Box>
  )
}

export default UserProfile
