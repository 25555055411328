import { useRef } from 'react'
import {
  Stack,
  Typography,
  Divider,
  Button,
  Box,
  TextField,
  InputAdornment,
  useMediaQuery,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/th'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Delete } from '@mui/icons-material'
import { TypeOf, z } from 'zod'
import dayjs, { Dayjs } from 'dayjs'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  useFieldArray,
} from 'react-hook-form'

import { COLOR } from '../../constants/color-constant'
import { theme } from '../../constants/theme'
import TextareaWithCharLength from '../Textarea/TextareaWithCharLength'
import ImageUpload from '../ImageUpload/ImageUpload'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'

export const campaignProposalForm2Schema = z.object({
  objective: z.string(),
  background: z.string(),
  workingProcess: z.string(),
  location: z.array(
    z.object({
      addressLine: z.string(),
      subDistrict: z.string(),
      district: z.string(),
      province: z.string(),
    })
  ),
  childTarget: z.array(
    z.object({
      age: z.string(),
      maleNumber: z.number(),
      femaleNumber: z.number(),
    })
  ),
  expectedResult: z.string(),
  activityPlan: z.array(
    z
      .object({
        detail: z.string(),
        startDate: z.custom<Dayjs>(),
        endDate: z.custom<Dayjs>(),
      })
      .refine(
        (data) => {
          if (!data.startDate || !data.endDate) return true
          return data.endDate.isAfter(data.startDate)
        },
        {
          message: '* วันที่สิ้นสุด ต้องมาทีหลัง วันที่เริ่มต้น',
          path: ['endDate'],
        }
      )
  ),
})

export type CampaignProposalForm2Input = TypeOf<
  typeof campaignProposalForm2Schema
>

interface CampaignProposalForm2Props {
  images: AttachmentMetaData[]
  onImagesChange: (images: AttachmentMetaData[]) => void
  register: UseFormRegister<CampaignProposalForm2Input>
  control: Control<CampaignProposalForm2Input>
  errors: FieldErrors<CampaignProposalForm2Input>
  onSubmit: UseFormHandleSubmit<CampaignProposalForm2Input>
  onSubmissionPass: () => void
  onBack: () => void
  viewOnly: boolean
}

const MAX_LOCATION = 15
const MAX_CHILD_TARGET = 15
const MAX_ACTIVITY_PLAN = 15

function CampaignProposalForm2({
  register,
  control,
  images,
  onImagesChange,
  errors,
  onSubmit,
  onSubmissionPass,
  onBack,
  viewOnly: adminView,
}: CampaignProposalForm2Props) {
  const screenLargerThanMd = useMediaQuery(theme.breakpoints.up('md'))
  const screenLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'))
  const fileRef = useRef<any>(null)

  const {
    fields: locationFields,
    append: addLocation,
    remove: removeLocation,
  } = useFieldArray({
    control,
    name: 'location',
  })

  const {
    fields: childTargets,
    append: addChildTarget,
    remove: removeChildTarget,
  } = useFieldArray({
    control,
    name: 'childTarget',
  })

  const {
    fields: activityPlans,
    append: addActivityPlan,
    remove: removeActivityPlan,
  } = useFieldArray({
    control,
    name: 'activityPlan',
  })

  const onSubmitHandler = () => {
    onSubmissionPass()
  }

  return (
    <Box
      width='100%'
      component='form'
      id='campaign-proposal-form-2'
      onSubmit={onSubmit(onSubmitHandler)}
    >
      <Stack gap={'40px'}>
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <Typography variant='h3'>จุดประสงค์</Typography>
          </div>
          <Stack flexGrow='1' minWidth={{ xs: '200px', sm: '400px' }}>
            <TextareaWithCharLength
              name='objective'
              minRows={4}
              maxLength={2000}
              placeholder='จุดประสงค์'
              control={control}
              adminView={adminView}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <Typography variant='h3'>ความเป็นมาของโครงการ</Typography>
          </div>
          <Stack flexGrow='1' minWidth={{ xs: '200px', sm: '400px' }}>
            <TextareaWithCharLength
              name='background'
              minRows={4}
              maxLength={2000}
              placeholder='ความเป็นมาของโครงการ'
              control={control}
              adminView={adminView}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <Typography variant='h3'>ขั้นตอนการทำงาน</Typography>
          </div>
          <Stack flexGrow='1' minWidth={{ xs: '200px', sm: '400px' }}>
            <TextareaWithCharLength
              name='workingProcess'
              minRows={4}
              maxLength={2000}
              placeholder='ขั้นตอนการทำงาน'
              control={control}
              adminView={adminView}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <Stack>
            <Typography variant='h3'>สถานที่</Typography>
            <Typography variant='subtitle1' color={COLOR.GRAY_600}>
              สูงสุด {MAX_LOCATION} สถานที่
            </Typography>
          </Stack>
          <Stack width={'100%'} gap={'16px'}>
            {locationFields.map((field, i) => (
              <div key={field.id}>
                <Stack flexDirection='row' justifyContent='space-between'>
                  <Typography variant='h5'>สถานที่ที่ {i + 1}</Typography>
                  {locationFields.length > 1 && (
                    <Button
                      color='error'
                      sx={{ minWidth: '0px' }}
                      onClick={() => removeLocation(i)}
                      disabled={adminView}
                    >
                      <Delete sx={{ width: '22px' }} />
                    </Button>
                  )}
                </Stack>
                <Stack marginLeft={{ lg: '60px' }} gap={'16px'}>
                  <Stack direction={'row'} flexWrap='wrap'>
                    <div style={{ minWidth: '100px' }}>
                      <Typography variant='h5'>ที่อยู่</Typography>
                    </div>
                    <Stack flexGrow='1' minWidth='0' gap='24px'>
                      <TextField
                        size='small'
                        fullWidth={true}
                        {...register(`location.${i}.addressLine`)}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={'row'} flexWrap='wrap' gap='16px'>
                    <Stack direction={'row'} flexGrow='1' minWidth='0'>
                      <div style={{ minWidth: '100px' }}>
                        <Typography variant='h5'>ตำบล</Typography>
                      </div>
                      <TextField
                        size='small'
                        fullWidth={true}
                        {...register(`location.${i}.subDistrict`)}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </Stack>
                    <Stack direction={'row'} flexGrow='1' minWidth='0'>
                      <div style={{ minWidth: '100px' }}>
                        <Typography variant='h5'>อำเภอ</Typography>
                      </div>
                      <TextField
                        size='small'
                        fullWidth={true}
                        {...register(`location.${i}.district`)}
                        disabled={adminView}
                        sx={{
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#212121',
                          },
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack direction={'row'}>
                    <div style={{ minWidth: '100px' }}>
                      <Typography variant='h5'>จังหวัด</Typography>
                    </div>
                    <TextField
                      size='small'
                      fullWidth={true}
                      {...register(`location.${i}.province`)}
                      disabled={adminView}
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: '#212121',
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </div>
            ))}
            {locationFields.length < MAX_LOCATION && (
              <Button
                variant='outlined'
                fullWidth={false}
                sx={{ maxWidth: '220px', alignSelf: 'end' }}
                onClick={() =>
                  addLocation({
                    addressLine: '',
                    subDistrict: '',
                    district: '',
                    province: '',
                  })
                }
                disabled={adminView}
              >
                เพิ่ม
              </Button>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <Stack>
            <div
              style={{
                width: '300px',
              }}
            >
              <Typography variant='h3'>กลุ่มเป้าหมาย</Typography>
            </div>
            <Typography variant='subtitle1' color={COLOR.GRAY_600}>
              สูงสุด {MAX_CHILD_TARGET} กลุ่มเป้าหมาย
            </Typography>
          </Stack>
          <Stack gap='16px'>
            {childTargets.map((t, i) => (
              <Stack
                key={t.id}
                direction={'row'}
                gap='22px'
                sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                justifyContent={'space-between'}
              >
                <Typography variant='h5' whiteSpace={'nowrap'}>
                  ลำดับที่ {i + 1}
                </Typography>
                {!screenLargerThanMd && childTargets.length - 1 > 0 && (
                  <Button
                    color='error'
                    sx={{ minWidth: '0px' }}
                    onClick={() => removeChildTarget(i)}
                    disabled={adminView}
                  >
                    <Delete fontSize='small' />
                  </Button>
                )}
                <TextField
                  size='small'
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        กลุ่มอายุ
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>ปี</InputAdornment>
                    ),
                  }}
                  {...register(`childTarget.${i}.age`)}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                ></TextField>
                <TextField
                  type='number'
                  size='small'
                  fullWidth={true}
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    min: 0,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        จำนวนเพศชาย
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>คน</InputAdornment>
                    ),
                  }}
                  {...register(`childTarget.${i}.maleNumber`, {
                    valueAsNumber: true,
                  })}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                ></TextField>
                <TextField
                  type='number'
                  size='small'
                  fullWidth={true}
                  inputProps={{
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    min: 0,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        จำนวนเพศหญิง
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>คน</InputAdornment>
                    ),
                  }}
                  {...register(`childTarget.${i}.femaleNumber`, {
                    valueAsNumber: true,
                  })}
                  disabled={adminView}
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#212121',
                    },
                  }}
                ></TextField>
                {screenLargerThanMd && childTargets.length - 1 > 0 && (
                  <Button
                    color='error'
                    sx={{ minWidth: '0px' }}
                    onClick={() => removeChildTarget(i)}
                    disabled={adminView}
                  >
                    <Delete sx={{ width: '22px' }} />
                  </Button>
                )}
              </Stack>
            ))}
            {childTargets.length < MAX_CHILD_TARGET && (
              <Button
                variant='outlined'
                fullWidth={false}
                sx={{ maxWidth: '220px', alignSelf: 'end' }}
                onClick={() =>
                  addChildTarget({
                    age: '',
                    maleNumber: 0,
                    femaleNumber: 0,
                  })
                }
                disabled={adminView}
              >
                เพิ่ม
              </Button>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <div
            style={{
              width: '300px',
            }}
          >
            <Typography variant='h3'>ผลที่คาดว่าจะได้รับ</Typography>
          </div>
          <Stack flexGrow='1' minWidth={{ xs: '200px', sm: '400px' }}>
            <TextareaWithCharLength
              name='expectedResult'
              minRows={4}
              maxLength={2000}
              placeholder='ผลที่คาดว่าจะได้รับ'
              control={control}
              adminView={adminView}
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <Stack>
            <div
              style={{
                width: '300px',
              }}
            >
              <Typography variant='h3'>แผนงานพร้อมช่วงเวลาดำเนินงาน</Typography>
            </div>
            <Typography variant='subtitle1' color={COLOR.GRAY_600}>
              สูงสุด {MAX_ACTIVITY_PLAN} แผน
            </Typography>
          </Stack>
          <Stack gap='16px' width={'100%'}>
            {activityPlans.map((a, i) => (
              <Box
                key={a.id}
                display='flex'
                gap='22px'
                sx={{ flexDirection: { xs: 'column', lg: 'row' } }}
              >
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant='h5' whiteSpace={'nowrap'}>
                    ลำดับที่ {i + 1}
                  </Typography>
                  {!screenLargerThanLg && activityPlans.length - 1 > 0 && (
                    <Button
                      color='error'
                      sx={{ minWidth: '0px' }}
                      onClick={() => removeActivityPlan(i)}
                      disabled={adminView}
                    >
                      <Delete sx={{ width: '22px' }} />
                    </Button>
                  )}
                </Stack>
                <Stack direction={'column'} gap='8px'>
                  <Stack direction={'row'} gap='16px'>
                    <Stack gap='8px'>
                      <Typography variant='h6'>วันที่เริ่มต้น</Typography>
                      <Controller
                        name={`activityPlan.${i}.startDate`}
                        control={control}
                        render={({
                          field: { onChange, value, ...restField },
                          fieldState: { error },
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale='th'
                          >
                            <DatePicker
                              label='วันที่เริ่มต้น'
                              value={value}
                              {...restField}
                              onChange={(value) => {
                                onChange(value)
                              }}
                              slotProps={{
                                textField: {
                                  error: Boolean(error),
                                  size: 'small',
                                },
                              }}
                              disabled={adminView}
                              sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                  WebkitTextFillColor: '#212121',
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Stack>
                    <Stack gap='8px'>
                      <Typography variant='h6'>วันที่สิ้นสุด</Typography>
                      <Controller
                        name={`activityPlan.${i}.endDate`}
                        control={control}
                        render={({
                          field: { onChange, value, ...restField },
                          fieldState: { error },
                        }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale='th'
                          >
                            <DatePicker
                              label='วันที่สิ้นสุด'
                              value={value}
                              {...restField}
                              ref={register(`activityPlan.${i}.endDate`).ref}
                              onChange={(value) => {
                                onChange(value)
                              }}
                              slotProps={{
                                textField: {
                                  error: Boolean(error),
                                  size: 'small',
                                },
                              }}
                              disabled={adminView}
                              sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                  WebkitTextFillColor: '#212121',
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Stack>
                  </Stack>
                  {errors.activityPlan && errors.activityPlan[i] && (
                    <Typography variant='body1' color={COLOR.BUTTON_RED}>
                      {errors.activityPlan[i]?.endDate?.message}
                    </Typography>
                  )}
                </Stack>
                <Stack flexGrow='1' minWidth='200px'>
                  <TextareaWithCharLength
                    name={`activityPlan.${i}.detail`}
                    minRows={4}
                    maxLength={500}
                    placeholder='รายละเอียด'
                    control={control}
                    adminView={adminView}
                  />
                </Stack>
                {screenLargerThanLg && activityPlans.length - 1 > 0 && (
                  <Button
                    color='error'
                    sx={{ minWidth: '0px' }}
                    onClick={() => removeActivityPlan(i)}
                    disabled={adminView}
                  >
                    <Delete sx={{ width: '22px' }} />
                  </Button>
                )}
              </Box>
            ))}
            {activityPlans.length < MAX_ACTIVITY_PLAN && (
              <Button
                variant='outlined'
                fullWidth={false}
                sx={{ maxWidth: '220px', alignSelf: 'end' }}
                onClick={() =>
                  addActivityPlan({
                    startDate: dayjs(),
                    endDate: dayjs().add(1, 'day'),
                    detail: '',
                  })
                }
                disabled={adminView}
              >
                เพิ่ม
              </Button>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack flexDirection='row' flexWrap='wrap' gap='16px'>
          <Stack>
            <div
              style={{
                width: '300px',
              }}
            >
              <Typography variant='h3'>รูปภาพ</Typography>
            </div>
          </Stack>
          <Stack flexGrow='1' minWidth='220px' ref={fileRef}>
            <ImageUpload
              label='อัปโหลดรูป'
              files={images}
              multiple={true}
              onChange={onImagesChange}
              adminView={adminView}
              endpoint='/campaign-requests/upload-image'
              type='image'
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack justifyContent='space-between' flexDirection='row'>
          <Button variant='outlined' fullWidth={false} onClick={() => onBack()}>
            <Typography variant='h6'>{`< กลับ`}</Typography>
          </Button>
          <Button variant='outlined' fullWidth={false} type='submit'>
            <Typography variant='h6'>{`ถัดไป >`}</Typography>
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}

export default CampaignProposalForm2
