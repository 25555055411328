import { Typography } from '@mui/material'

type AdminRoleBarProps = {
  variant: string
}

export default function AdminRoleBar(props: AdminRoleBarProps) {
  if (props.variant === 'ADMIN') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px]'
        style={{ backgroundColor: '#DEEEFF', width: '80px' }}
      >
        <Typography variant='h6' color={'#0079FF'}>
          Admin
        </Typography>
      </div>
    )
  } else if (props.variant === 'SUPER_ADMIN') {
    return (
      <div
        className='inline-flex flex-col items-center justify-center rounded-[15px] px-[14px] '
        style={{ backgroundColor: '#FFDEE5', width: '130px' }}
      >
        <Typography variant='h6' color={'#FF0038'}>
          Super Admin
        </Typography>
      </div>
    )
  } else {
    return null
  }
}
