function trimFormValues<T>(obj: T): T {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      if (typeof value === 'string') {
        ;(obj as any)[key] = value.trim()
      } else if (typeof value === 'object' && value !== null) {
        ;(obj as any)[key] = trimFormValues(value) // Recursively trim nested objects
      }
    }
  }
  return obj
}

export default trimFormValues
