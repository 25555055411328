import React from 'react'
import { theme } from '../constants/theme'
import { ThemeProvider } from '@emotion/react'
import { ChurchOutlined, Delete } from '@mui/icons-material'
import { Stack, Typography, Button } from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { COLOR } from '../constants/color-constant'
import { Link } from 'react-router-dom'

interface ChurchProfileCardProps {
  churchId: string
  churchName: string | null
  isViewable: boolean
  openInNewTab?: boolean
  onDelete?: (churchId: string) => void
}

export default function ChurchProfileCard(props: ChurchProfileCardProps) {
  const { churchId, churchName, isViewable, onDelete } = props

  return (
    <ThemeProvider theme={theme}>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        borderRadius={'20px'}
        border={'1px #C7C7C7 solid'}
        padding={'8px 16px 8px 16px'}
        bgcolor={common.white}
        justifyContent={'space-between'}
        boxShadow={'3px 3px 3px 0px rgba(0, 0, 0, 0.10)'}
        maxWidth={'360px'}
      >
        <Stack display={'flex'} flexDirection={'row'} gap={'8px'}>
          <ChurchOutlined
            sx={{
              height: 40,
              width: 40,
              color: COLOR.PRIMARY_1,
            }}
          ></ChurchOutlined>
          <Stack display={'flex'} flexDirection={'column'} textAlign={'left'}>
            <Typography variant='body1' color={COLOR.SECOUNDARY_FIRST_1}>
              โบสถ์
            </Typography>
            <Typography
              variant='body2'
              color={grey[600]}
              textOverflow={'ellipsis'}
            >
              {churchName}
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={'row'} gap={'8px'}>
          {isViewable && (
            <Link
              to={`/churches/${churchId}`}
              {...(props.openInNewTab && {
                target: '_blank',
                rel: 'noopener noreferrer',
              })}
            >
              <Button variant='text'>
                <Typography variant='h6' sx={{ textDecoration: 'underline' }}>
                  View
                </Typography>
              </Button>
            </Link>
          )}
          {onDelete && (
            <Button
              color='error'
              sx={{ minWidth: '0px' }}
              onClick={() => onDelete(churchId)}
            >
              <Delete fontSize='small' />
            </Button>
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  )
}
