import { ThemeProvider } from '@emotion/react'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Pagination,
  Stack,
  styled,
  Skeleton,
  Snackbar,
  Alert,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { theme } from '../../constants/theme'
import { UserData, Role } from '../../models/UserTypes'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import { useUser } from '../../services/UserContext'
import AuthService from '../../services/auth.service'
import StatusBar from '../../components/Admin/StatusBar'
import ReceiptList from '../../components/Admin/ReceiptList'
import { useGet } from '../../hooks/useFetch'
import {
  ConfirmEarningDetails,
  AllReceiptLists,
} from '../../models/admin/EarningStat'
import axios from 'axios'
import formatCompactNumber from '../../utils/formatNumber'

export default function AllReceiptList() {
  const token = AuthService.getTokenUser()
  const tokenChecked = useAuthMiddleware()
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''
  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()

  const [openErrorSnackBar, setOpenErrorSnackBar] = useState<boolean>(false)

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const StyledTableHeadCell = styled(TableCell)({
    paddingTop: 0,
    paddingBottom: 0,
  })

  const param = useParams()
  const confirmEarningId = param.confirmEarningId
  const churchId = param.churchId

  const [
    confirmEarningDetail,
    confirmEarningDetailLoading,
    confirmEarningDetailReq,
    confirmEarningDetailStatus,
  ] = useGet<ConfirmEarningDetails>({
    url: `/confirm-earnings/${confirmEarningId}`,
    jwtToken: token ? token : '',
    autoFetch: true,
  })

  const [page, setPage] = useState(1)
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const [startDate, setStartDate] = useState<number>()
  const [endDate, setEndDate] = useState<number>()
  const [status, setStatus] = useState<string>()
  const [total, setTotal] = useState<number>()

  const [receiptLists, receiptListsLoading, getReceiptListsReq] =
    useGet<AllReceiptLists>({
      url: `/receipts`,
      ...(token && { jwtToken: token }),
      autoFetch: false,
      queryParams: {
        startDate: startDate,
        endDate: endDate,
        churchId: churchId,
        page: page,
      },
    })

  useEffect(() => {
    if (confirmEarningDetailStatus === -1) return

    if (confirmEarningDetailStatus === 200) {
      setStartDate(confirmEarningDetail?.startDate)
      setEndDate(confirmEarningDetail?.endDate)
      setStatus(confirmEarningDetail?.status)
      setTotal(confirmEarningDetail?.total)
    }
  }, [confirmEarningDetailStatus, confirmEarningDetail])

  useEffect(() => {
    if (startDate && endDate) {
      getReceiptListsReq()
    }
  }, [startDate, endDate])

  const start = new Date(startDate!)
  const end = new Date(endDate!)

  const [saveAmountLoading, setSaveAmountLoading] = useState(false)
  const [saveStatusLoading, setSaveStatusLoading] = useState(false)

  const saveReceipt = (
    receiptId: string,
    newAmount: number,
    newStatus: string
  ) => {
    setSaveAmountLoading(true)
    setSaveStatusLoading(true)

    axios
      .patch<{ data: any }>(
        `${API_URL}/receipts/${receiptId}/change-status/admin`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )
      .then((response) => {
        // Handle successful response
        axios
          .patch<{ data: any }>(
            `${API_URL}/receipts/${receiptId}/change-amount`,
            { amount: newAmount },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'X-API-KEY': `${API_KEY}`,
              },
            }
          )
          .then((response) => {
            // Handle successful response
            setSaveAmountLoading(false)
          })
          .catch((error) => {
            // Handle error
            setOpenErrorSnackBar(true)
            console.error('Error patching data:')
          })
        setSaveStatusLoading(false)
      })
      .catch((error) => {
        // Handle error
        setOpenErrorSnackBar(true)
        console.error('Error patching data:')
      })
  }

  useEffect(() => {}, [])

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h1' paddingY={3}>
                  Weekly Receipt List
                </Typography>

                <Box
                  width={'95%'}
                  border={1}
                  bgcolor={'#FFFFFF'}
                  borderColor={'#C7C7C7'}
                  borderRadius={'40px'}
                  paddingX={'40px'}
                  paddingY={'20px'}
                >
                  <Stack flexDirection={'column'} width={'100%'} gap={'8px'}>
                    <Stack
                      flexDirection={{ sx: 'column', sm: 'column', md: 'row' }}
                      gap={{ sx: '8px', sm: '8px', md: '24px' }}
                    >
                      <Typography variant='h3'>Duration </Typography>
                      {confirmEarningDetailLoading ? (
                        <Skeleton
                          variant='rectangular'
                          width={'300px'}
                          height={'45px'}
                        />
                      ) : (
                        <Typography variant='h3'>
                          {start.getDate().toString() +
                            '/' +
                            (start.getMonth() + 1) +
                            '/' +
                            start.getFullYear()}{' '}
                          -{' '}
                          {' ' +
                            end.getDate().toString() +
                            '/' +
                            (end.getMonth() + 1) +
                            '/' +
                            end.getFullYear()}
                        </Typography>
                      )}
                      <Stack
                        flexDirection={'row'}
                        gap={'24px'}
                        alignItems={'center'}
                      >
                        <Typography variant='h5'> status: </Typography>
                        <StatusBar variant={status!}></StatusBar>
                      </Stack>
                    </Stack>
                    <Stack flexDirection={'row'} gap={'10px'}>
                      <Typography variant='h3'>Total Week Earning: </Typography>
                      <Typography variant='h3'>
                        {confirmEarningDetailLoading ? (
                          <Skeleton
                            variant='rectangular'
                            width={'120px'}
                            height={'45px'}
                          />
                        ) : (
                          <>{formatCompactNumber(total!)}</>
                        )}{' '}
                      </Typography>
                      <Typography variant='h3'>บาท</Typography>
                    </Stack>
                  </Stack>
                </Box>

                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  marginY={'20px'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '95%',
                  }}
                >
                  <TableContainer sx={{ alignItems: 'center' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHeadCell
                            sx={{ width: 110, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Date
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 100, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Time
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 170, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Donor
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 320, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Campaign name
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 250, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Amount
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ width: 120, flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Receipt
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='left'
                            sx={{ flexWrap: 'wrap' }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              Status
                            </Typography>
                          </StyledTableHeadCell>

                          <StyledTableHeadCell
                            align='right'
                            sx={{ width: 120, flexWrap: 'wrap' }}
                          ></StyledTableHeadCell>
                        </TableRow>
                      </TableHead>

                      {!receiptListsLoading &&
                        !saveAmountLoading &&
                        !saveStatusLoading && (
                          <TableBody>
                            {receiptLists?.receipts.map((req) => (
                              <ReceiptList
                                key={req.id}
                                receiptId={req.id}
                                dateTime={req.datetime}
                                donor={req.donorName}
                                campaignId={req.campaignId}
                                campaignName={req.campaignName}
                                amount={req.amount}
                                status={req.receiptStatus}
                                imgUrl={req.image.url}
                                saveReceipt={saveReceipt}
                              />
                            ))}
                          </TableBody>
                        )}
                    </Table>
                  </TableContainer>

                  {(receiptListsLoading ||
                    saveAmountLoading ||
                    saveStatusLoading) && (
                    <Box paddingY={2}>
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}

                  {!receiptListsLoading &&
                  !saveAmountLoading &&
                  !saveStatusLoading &&
                  receiptLists?.totalPages === 0 ? (
                    <Typography variant='h5' paddingY={'5px'}>
                      There have been no donations during this period.
                    </Typography>
                  ) : (
                    <Pagination
                      count={receiptLists?.totalPages}
                      page={page}
                      onChange={handleChange}
                      sx={{ paddingTop: '5px' }}
                    />
                  )}
                </Box>
              </Stack>
            </div>
          ) : (
            <Navigate to='/' />
          )}

          <Snackbar
            open={openErrorSnackBar}
            autoHideDuration={6000}
            onClose={() => setOpenErrorSnackBar(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert
              onClick={() => setOpenErrorSnackBar(false)}
              severity='error'
              sx={{ width: '100%' }}
            >
              เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง
            </Alert>
          </Snackbar>
        </ThemeProvider>
      )
    }
  }
}
