import {
  Box,
  CircularProgress,
  Container,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { theme } from '../../constants/theme'
import StatusBar from '../../components/Admin/StatusBar'
import RequestList from '../../components/Admin/RequestList'
import { useEffect, useMemo, useState } from 'react'
import { COLOR } from '../../constants/color-constant'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { useUser } from '../../services/UserContext'
import { Role, UserData } from '../../models/UserTypes'
import { Navigate } from 'react-router-dom'
import AuthService from '../../services/auth.service'
import { ChurchRequest } from '../../models/admin/ChurchRequest'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import { useGet } from '../../hooks/useFetch'
import { ChurchDetail } from '../../models/ChurchDetail'

export default function ChurchRequestList() {
  const matches = useMediaQuery('(min-width:880px)')
  const mobile = useMediaQuery('(min-width:600px)')

  const userData: UserData | null = useUser().userData
  const token = AuthService.getTokenUser()
  const [userRole, setUserRole] = useState<Role>()
  const [page, setPage] = useState(1)

  const tokenChecked = useAuthMiddleware()

  const [requestList, loading] = useGet<ChurchRequest>({
    url: '/church-requests/list',
    ...(token && { jwtToken: token }),
    autoFetch: true,
    queryParams: {
      page: page,
    },
  })

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
  })

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h3' paddingY={3}>
                  Church Requests
                </Typography>
                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '90%',
                  }}
                >
                  <TableContainer>
                    <Table>
                      {mobile && (
                        <TableHead>
                          <TableRow>
                            <StyledTableHeadCell>
                              <Typography variant='h6' color={'text.primary'}>
                                Church name
                              </Typography>
                            </StyledTableHeadCell>
                            <StyledTableHeadCell align='right'>
                              <Typography variant='h6' color={'text.primary'}>
                                Type
                              </Typography>
                            </StyledTableHeadCell>
                            <StyledTableHeadCell align='right'>
                              <Typography variant='h6' color={'text.primary'}>
                                Status
                              </Typography>
                            </StyledTableHeadCell>
                          </TableRow>
                        </TableHead>
                      )}
                      {!mobile && (
                        <TableHead>
                          <TableRow>
                            <StyledTableHeadCell>
                              <Typography variant='h6' color={'text.primary'}>
                                Request detail
                              </Typography>
                            </StyledTableHeadCell>
                          </TableRow>
                        </TableHead>
                      )}

                      {!loading && (
                        <TableBody>
                          {requestList?.churchRequests.map((req) => (
                            <RequestList
                              key={req.id}
                              variant={'church'}
                              requestId={req.id}
                              name={req.churchName}
                              type={req.churchRequestType}
                              status={req.churchRequestStatus}
                              date={req.lastModifiedDate}
                            ></RequestList>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {loading && (
                    <Box paddingY={2}>
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}
                  <Pagination
                    count={requestList?.totalPages}
                    page={page}
                    onChange={handleChange}
                    sx={{ paddingTop: '5px' }}
                  />
                </Box>
              </Box>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </ThemeProvider>
      )
    }
  }
}
