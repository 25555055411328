import { useState } from 'react'
import { Delete } from '@mui/icons-material'
import { Button, Link, Stack, Typography } from '@mui/material'

import { theme } from '../../constants/theme'
import ModalWithButton from '../Modal/ModalWithButton'

type Prop = {
  id: number
  fileName: string
  fileSize: number
  onDelete: (id: number) => void
  objectUrl: string
  variant?: 'VIEW'
}

function FileCard({
  id,
  fileName,
  fileSize,
  onDelete,
  variant,
  objectUrl,
}: Prop) {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

  return (
    <>
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        padding='10px 20px 10px 40px'
        border={`2px solid ${theme.palette.grey[200]}`}
        borderLeft='16px solid #00dfa2'
        width='250px'
        gap='20px'
      >
        <Stack flexGrow='0' minWidth='0'>
          <Link
            href={objectUrl}
            target='_blank'
            rel='noopener'
            underline='hover'
          >
            <Typography variant='h6' sx={{ overflowWrap: 'anywhere' }}>
              {fileName}
            </Typography>
          </Link>
          <Typography variant='body2'>
            {(fileSize / (1024 * 1024)).toFixed(1)} MB
          </Typography>
        </Stack>
        {variant !== 'VIEW' && (
          <Button
            sx={{ minWidth: '3vw', minHeight: '3vw' }}
            onClick={() => setOpenConfirmDelete(true)}
            color='error'
          >
            <Delete sx={{ width: '24px', height: '24px' }} />
          </Button>
        )}
      </Stack>
      <ModalWithButton
        id={`${id}`}
        type='ERROR'
        title='ยืนยันการลบไฟล์'
        description={`คุณต้องการที่จะลบไฟล์ ${fileName}?`}
        confirmBtnText='ต้องการลบ'
        cancleBtnText='ยกเลิกการลบ'
        open={openConfirmDelete}
        handleClickConfirm={() => {
          setOpenConfirmDelete(false)
          onDelete(id)
        }}
        onClose={() => setOpenConfirmDelete(false)}
      ></ModalWithButton>
    </>
  )
}

export default FileCard
