import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar/Navbar'
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { theme } from '../constants/theme'
import Footer from '../components/Footer/Footer'
import ChurchProfileCard from '../components/ChurchProfileCard'

import { Link, useNavigate } from 'react-router-dom'
import SearchChurch, { churchInfo } from '../components/SearchChurch'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useUser } from '../services/UserContext'
import { ROUTE } from '../constants/route-constant'

export default function WantToSubmitCampaign() {
  const breakpoint = useMediaQuery('(min-width:900px)')
  const navigate = useNavigate()
  const [selectingChurch, setSelectingChurch] = useState<churchInfo | null>(
    null
  )
  const [openSearchNotFoundChurch, setOpenSearchNotFoundChurch] =
    useState(false)

  const userContext = useUser()

  useEffect(() => {
    const role = userContext?.userData?.userrole
    if (role) {
      if (role.userRole === 'CHURCH') {
        navigate(ROUTE.CREATE_CAMPAIGN_PROPOSAL)
      }
    }

    window.scrollTo(0, 0)
  }, [navigate, userContext?.userData?.userrole])

  return (
    <>
      <div className='flex min-h-screen flex-col justify-between'>
        <Navbar />
        <Container>
          <Box
            display={'flex'}
            padding={'16px'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            textAlign={'center'}
            gap={'8px'}
            sx={{
              borderRadius: '8px',
              border: '1px solid',
              borderColor: theme.palette.grey[400],
            }}
          >
            <Stack>
              <Typography variant='h1' color='primary'>
                ต้องการเสนอโครงการระดมทุน ?
              </Typography>
              <Typography variant='h6'>
                เนื่องจากน้ำใจดำเนินงานพัฒนาเด็กแบบองค์รวมผ่านคริสตจักรท้องถิ่นในพื้นที่
              </Typography>
            </Stack>

            <Stack
              display={'flex'}
              alignContent={'space-between'}
              flexDirection={{ md: 'row', xs: 'column' }}
            >
              <Stack padding={'20px'} gap={'16px'} alignItems={'center'}>
                <Stack>
                  <Typography variant='h5'>ถ้าคุณคือคริสตจักร</Typography>
                  <Typography variant='h5'>กรุณาลงทะเบียน</Typography>
                </Stack>

                <Link to={ROUTE.CREATE_CHURCH_PROPOSAL}>
                  <Button
                    variant='outlined'
                    color='secondary'
                    sx={{ maxWidth: '200px' }}
                  >
                    ลงทะเบียนคริสตจักร
                  </Button>
                </Link>

                <Typography>
                  <Typography variant='subtitle1'>
                    ถ้าคุณคือ สมาชิกคริสตจักร
                  </Typography>
                  <Typography variant='subtitle1'>
                    กรุณาติดต่อคริสตจักรที่คุณเข้าร่วมเพื่อลงทะเบียน
                  </Typography>
                </Typography>
              </Stack>
              <Divider
                orientation={breakpoint ? 'vertical' : 'horizontal'}
                sx={{ height: breakpoint ? '36vh' : 1 }}
              />
              <Stack padding={'20px'} gap={'16px'} alignItems={'center'}>
                <Typography variant='h5'>
                  ถ้าคุณคือ ผู้มีน้ำใจ (ยังไม่ได้เป็นสมาชิกคริสตจักร)
                  ต้องการเสนอโครงการระดมทุน
                  สามารถค้นหาและติดต่อคริสตจักรท้องถิ่นที่อยู่ใกล้พื้นที่นั้น
                  เพื่อร่วมกันเสนอโครงการได้
                </Typography>
                <Stack>
                  <Typography variant='subtitle1'>
                    กรุณาระบุพื้นที่ที่คุณต้องการเสนอโครงการ
                    เพื่อพัฒนาเด็กแบบองค์รวม
                  </Typography>
                  <Typography variant='subtitle1'>
                    ระบบจะแสดงรายชื่อคริสตจักรที่อยู่ในพื้นที่ดังกล่าว
                    ที่คุณสามารถติดต่อมีส่วนร่วมได้
                  </Typography>
                </Stack>

                <SearchChurch
                  setSelectingChurch={(c) => setSelectingChurch(c)}
                  setOpenSearchNotFoundChurch={(isFound) =>
                    setOpenSearchNotFoundChurch(isFound)
                  }
                />
                {openSearchNotFoundChurch && (
                  <Typography
                    variant='subtitle1'
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row ' },
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    ไม่มีรายชื่อคริสตจักรที่อยู่ในพื้นที่ดังกล่าว{' '}
                    <a
                      href='https://docs.google.com/forms/d/e/1FAIpQLSdTD7SfZ-xiv9arWwBAHgWbAaxbZxp9EPZf0fV0Fdp0nD84ZQ/viewform'
                      target='_blank'
                      rel='noreferrer'
                      className='legend flex flex-row items-center font-extralight text-gray-500 no-underline'
                    >
                      กรณีไม่มีคริสตจักรในพื้นที่ <OpenInNewIcon />
                    </a>
                  </Typography>
                )}

                {selectingChurch && (
                  <ChurchProfileCard
                    churchId={selectingChurch.churchId}
                    churchName={selectingChurch.churchName}
                    isViewable={true}
                  />
                )}
              </Stack>
            </Stack>
          </Box>
        </Container>

        <Footer />
      </div>
    </>
  )
}
