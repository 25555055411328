import {
  Box,
  Button,
  CircularProgress,
  Container,
  List,
  Stack,
  Table,
  TableBody,
  TableContainer,
  ThemeProvider,
  Typography,
} from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import { useNavigate, useParams } from 'react-router-dom'
import { ChurchDetail } from '../../models/ChurchDetail'
import { useGet } from '../../hooks/useFetch'
import { generateManageChurchRoute } from '../../utils/routeGenerator'
import { UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import { theme } from '../../constants/theme'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import PersonCard from '../../components/PersonCard'
import CampaignList from '../../components/CampaignList'
import MemberList from '../../components/MemberList'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'

interface ChurchProfileProps {
  adminView?: boolean
  manualChurchId?: string
}

function AdminChurch(props: ChurchProfileProps) {
  const navigate = useNavigate()
  const params = useParams()
  const { adminView, manualChurchId } = props
  const churchID = manualChurchId ? manualChurchId : params.churchId

  const [churchDetail, loading] = useGet<ChurchDetail>({
    url: `/churches/${churchID}/church-profile`,
    autoFetch: true,
  })

  const manageChurchPath = generateManageChurchRoute(churchID!)

  const userData: UserData | null = useUser().userData

  const joinOn = new Date(churchDetail?.joinNamjai ?? 0)

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className='flex  flex-col'>
            <ResponsiveAppBar />
            <Box
              display={'flex'}
              justifyContent={'center'}
              padding={'10vw'}
              alignItems={'center'}
            >
              <CircularProgress />
            </Box>
          </div>
        </Box>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div className='flex  flex-col'>
            <ResponsiveAppBar />
            <Container>
              <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }}>
                <Box
                  sx={{
                    background: ' rgba(134,229,255,0.2)',
                  }}
                  width={{ xs: '100%', sm: '100%', md: '50%' }}
                  paddingX={{ xs: '16px', sm: '24px' }}
                  paddingTop={3}
                >
                  <Box
                    className='grid'
                    marginY={{
                      xs: '0.5',
                      sm: '0.5',
                      md: '10',
                    }}
                    marginX={{ xs: '0.5', sm: '0.5', md: '16' }}
                  >
                    <Box marginX={{ xs: '0px', sm: '0px', md: '8px' }}>
                      <h1
                        style={{
                          fontFamily: 'Mitr',
                          fontWeight: 400,
                          fontSize: '36px',
                          lineHeight: '32px',
                          letterSpacing: '-0.25px',
                        }}
                        color={'text.primary'}
                      >
                        {churchDetail?.churchName! ?? 'Church name'}
                      </h1>
                      <Box height={'10px'}></Box>
                      <Typography variant='h4' color={'#0081C9'}>
                        {churchDetail?.denomination ?? 'Denomination'}
                      </Typography>
                    </Box>

                    <Typography
                      variant='body1'
                      color={'text.primary'}
                      marginX={{ xs: '0px', sm: '0px', md: '8px' }}
                    >
                      เข้าร่วมสังคมน้ำใจในวันที่{' '}
                      {joinOn.getDate().toString() +
                        '/' +
                        (joinOn.getMonth() + 1) +
                        '/' +
                        joinOn.getFullYear()}
                    </Typography>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'Row',
                        columnGap: '4px',
                        marginX: '8px',
                        marginY: '4px',
                        alignItems: 'center',
                      }}
                    >
                      <LocationOnIcon
                        sx={{ height: '30px', width: '30px' }}
                      ></LocationOnIcon>
                      <Typography variant='body1' color={'text.primary'}>
                        {churchDetail?.address.addressLine! +
                          ' ' +
                          churchDetail?.address.subDistrict +
                          ' ' +
                          churchDetail?.address.district +
                          ' ' +
                          churchDetail?.address.province +
                          ' ' +
                          churchDetail?.address.postCode ?? '-'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'Row',
                        columnGap: '4px',
                        marginX: '8px',
                        marginBottom: '16px',
                        alignItems: 'center',
                      }}
                    >
                      <FacebookRoundedIcon
                        sx={{ height: '30px', width: '30px' }}
                      ></FacebookRoundedIcon>
                      <Typography variant='body1' color={'text.primary'}>
                        {churchDetail?.facebook ?? '-'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ verticalAlign: 'middle', alignItems: 'center' }}>
                  <Box
                    className='grid'
                    paddingX={{ xs: '8px', sm: '8px', md: '60px' }}
                    paddingY={{ xs: '16px', sm: '16px' }}
                  >
                    <Typography
                      variant='h5'
                      color={'text.primary'}
                      sx={{ marginBottom: '16px' }}
                    >
                      ศิษยาภิบาล
                    </Typography>
                    {churchDetail?.pastor ? (
                      <Box maxWidth={'500px'}>
                        <PersonCard
                          name={
                            churchDetail?.pastor.firstName +
                            ' ' +
                            churchDetail?.pastor.lastName
                          }
                          phone={churchDetail?.pastor.phoneNumber ?? '-'}
                          email={churchDetail?.pastor.email ?? '-'}
                        ></PersonCard>
                      </Box>
                    ) : (
                      <Typography variant='h6'>There are no pastor.</Typography>
                    )}
                  </Box>
                </Box>
              </Stack>

              <Box height={20}></Box>
              <Stack
                direction={{ xs: 'column', sm: 'column', md: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                sx={{ justifyContent: 'space-between' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Column',
                  }}
                  paddingX={{ xs: '16px', sm: '16px' }}
                  paddingBottom={{ xs: '16px', sm: '16px' }}
                >
                  <Typography
                    variant='h5'
                    color={'text.primary'}
                    sx={{ marginBottom: '16px' }}
                  >
                    โครงการ
                  </Typography>

                  <Box
                    sx={{
                      width: '100%',
                      overflow: 'auto',
                      maxHeight: 300,
                      border: '1px solid #F0F0F0',
                    }}
                  >
                    {churchDetail?.campaign.length === 0 && (
                      <Typography
                        variant='body2'
                        color={'text.primary'}
                        sx={{ padding: 2 }}
                      >
                        คริสตจักรนี้ยังไม่มีโครงการ
                      </Typography>
                    )}
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {churchDetail?.campaign.map((c) => (
                            <CampaignList
                              key={c.id}
                              id={c.id}
                              name={c.name}
                              startDate={c.startDate}
                              endDate={c.endDate}
                              currentDonationAmount={c.budget.amount}
                              targetBudget={c.budget.budget}
                            ></CampaignList>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'Column',
                  }}
                  paddingX={{ xs: '16px', sm: '16px' }}
                  paddingBottom={{ xs: '16px', sm: '16px' }}
                >
                  <Typography
                    variant='h5'
                    color={'text.primary'}
                    sx={{ marginBottom: '16px' }}
                  >
                    เจ้าหน้าที่คริสตจักร
                  </Typography>
                  <List
                    disablePadding
                    sx={{
                      width: '100%',
                      overflow: 'auto',
                      maxHeight: 300,
                      border: '1px solid #F0F0F0',
                    }}
                  >
                    {churchDetail?.mainContactPerson ? (
                      <MemberList
                        name={
                          churchDetail?.mainContactPerson.firstName +
                          ' ' +
                          churchDetail?.mainContactPerson.lastName
                        }
                      ></MemberList>
                    ) : (
                      <></>
                    )}
                    {churchDetail?.churchStaff.length !== 0 ? (
                      churchDetail?.churchStaff.map((m, i) => (
                        <MemberList
                          key={i}
                          name={m.firstName + ' ' + m.lastName}
                        ></MemberList>
                      ))
                    ) : (
                      <Typography variant='h6'>
                        คริสตจักรนี้ยังไม่มีเจ้าหน้าที่
                      </Typography>
                    )}
                  </List>
                </Box>
              </Stack>
              <Box height={20}></Box>
              {userData?.userrole.churchId === churchDetail?.id && (
                <Stack
                  className='float-right justify-end'
                  spacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                  paddingBottom={3}
                >
                  <Box paddingX={{ xs: '16px', sm: '16px' }}>
                    <Button
                      variant='outlined'
                      style={{
                        width: 200,
                        height: 40,

                        borderRadius: 8,
                        overflow: 'hidden',
                        border: '2px #0079FF solid',
                        justifyContent: 'center',
                        alignItems: 'center',

                        display: 'inline-flex',
                      }}
                      onClick={() => navigate(manageChurchPath)}
                    >
                      <Typography variant='body2' color={'primary'}>
                        แก้ไข
                      </Typography>
                    </Button>
                  </Box>
                </Stack>
              )}
            </Container>
          </div>
        </Box>
      </ThemeProvider>
    )
  }
}

export default AdminChurch
