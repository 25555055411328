export const COLOR = {
  PRIMARY_1: '#0079FF',
  PRIMARY_2: '#00DFA2',
  PRIMARY_3: '#F6FA70',
  PRIMARY_4: '#FF0060',

  SECOUNDARY_FIRST_1: '#153E90',
  SECOUNDARY_FIRST_2: '#0E49B5',
  SECOUNDARY_FIRST_3: '#54E346',
  SECOUNDARY_FIRST_4: '#FFFAA4',

  SECOUNDARY_SECOUND_1: '#0081C9',
  SECOUNDARY_SECOUND_2: '#5BC0F8',
  SECOUNDARY_SECOUND_3: '#86E5FF',
  SECOUNDARY_SECOUND_4: '#FFC93C',

  SECOUNDARY_THIRD_1: '#79E0EE',
  SECOUNDARY_THIRD_2: '#98EECC',
  SECOUNDARY_THIRD_3: '#D0F5BE',
  SECOUNDARY_THIRD_4: '$FBFFDC',

  BUTTON_RED: '#FF0038',
  BUTTON_BLUE: '#0079FF',
  BUTTON_DISABLE: '#B4B4B4',

  BACKGROUD_MAIN: '#FBFDFF',
  BACKGROUD_MAIN_2: '#D0F5BE',

  BACKGROUD_HOMEPAGE: 'rgba(134, 229, 255, 0.1)',
  DIVIDER_MAIN: '#C1C1C1',

  BLACK_TEXT: '#212121',
  GRAY_400: '#808080',
  GRAY_200: '#C7C7C7',
  GRAY_600: '#808080',
}
