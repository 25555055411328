import React, { useState } from 'react'
import AuthService from '../../services/auth.service'
import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useGet } from '../../hooks/useFetch'
import {
  ChurchTotalEarning,
  ChurchTotalEarningList,
} from '../../models/church/ChurchEarning'
import StatusBar from '../../components/Admin/StatusBar'

import EarningList from '../../components/Admin/EarningList'

interface ChurchConfirmEarningProps {
  churchID: string
}
export default function ChurchConfirmEarning(props: ChurchConfirmEarningProps) {
  const { churchID } = props

  const token = AuthService.getTokenUser()
  const mobile = useMediaQuery('(min-width:900px)')

  const [page, setPage] = useState(1)

  const StyledTableHeadCell = styled(TableCell)({
    paddingTop: 0,
    paddingBottom: 0,
  })

  const [
    totalEarning,
    loadingTotalEarning,
    fetchTotalEarning,
    statusCodeTotalEarning,
  ] = useGet<ChurchTotalEarning>({
    url: `/confirm-earnings/${churchID}/total`,
    jwtToken: token ? token : '',
    autoFetch: true,
  })

  const [
    totalEarningList,
    loadingTotalEarningList,
    fetchTotalEarningList,
    statusCodeTotalEarningList,
  ] = useGet<ChurchTotalEarningList>({
    url: `/confirm-earnings/${churchID}/list`,
    jwtToken: token ? token : '',
    autoFetch: true,
  })

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  if (statusCodeTotalEarning !== 200 && !loadingTotalEarning) {
    return (
      <Box
        display={'flex'}
        width={'100%'}
        justifyContent={'center'}
        height={'200px'}
      >
        <Typography variant='h6'>
          ไม่สามารถแสดงผลได้ โปรดติดต่อผู้ประสานงานหลัก
        </Typography>
      </Box>
    )
  } else if (loadingTotalEarning) {
    return (
      <Box
        display={'flex'}
        width={'100%'}
        justifyContent={'center'}
        height={'200px'}
      >
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          border={1}
          borderColor={grey[400]}
          borderRadius={'16px'}
          padding={'16px'}
          bgcolor={'white'}
        >
          <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
            <Typography variant='h6'>สถานะ: </Typography>

            <StatusBar variant={totalEarning?.status!} />
          </Box>
          <Typography variant='h4'>
            ยอดเงินรวม: {totalEarning?.total} บาท
          </Typography>
        </Box>

        <div>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            marginBottom={'12px'}
            marginTop={1}
            border={1}
            borderColor={grey[400]}
            borderRadius={'16px'}
            padding={'16px'}
            bgcolor={'white'}
          >
            <TableContainer>
              <Table>
                {mobile && (
                  <TableHead>
                    <TableRow>
                      <StyledTableHeadCell
                        sx={{ width: 400, flexWrap: 'wrap' }}
                      >
                        <Stack
                          sx={{
                            display: 'flex',
                            flexDirection: 'Row',
                            flexWrap: 'no wrap',
                            alignItems: 'center',
                          }}
                        >
                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'Column',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              วันที่เริ่ม
                            </Typography>
                          </Stack>
                        </Stack>
                      </StyledTableHeadCell>

                      <StyledTableHeadCell
                        align='left'
                        sx={{ width: 400, flexWrap: 'wrap' }}
                      >
                        <Typography variant='h6' color={'text.primary'}>
                          วันที่สิ้นสุด
                        </Typography>
                      </StyledTableHeadCell>

                      <StyledTableHeadCell
                        align='left'
                        sx={{ width: 240, flexWrap: 'wrap' }}
                      >
                        <Typography variant='h6' color={'text.primary'}>
                          ยอดเงิน (บาท)
                        </Typography>
                      </StyledTableHeadCell>

                      <StyledTableHeadCell
                        align='left'
                        sx={{ width: 140, flexWrap: 'wrap' }}
                      >
                        <Typography variant='h6' color={'text.primary'}>
                          สถานะ
                        </Typography>
                      </StyledTableHeadCell>

                      <StyledTableHeadCell
                        align='right'
                        sx={{ flexWrap: 'wrap' }}
                      >
                        <Typography
                          variant='h6'
                          color={'text.primary'}
                          style={{ visibility: 'hidden' }}
                        >
                          Donation
                        </Typography>
                      </StyledTableHeadCell>
                    </TableRow>
                  </TableHead>
                )}
                {!mobile && (
                  <TableHead>
                    <TableRow>
                      <StyledTableHeadCell
                        align='left'
                        sx={{ flexWrap: 'wrap' }}
                      >
                        <Typography variant='h6' color={'text.primary'}>
                          Campaign detail
                        </Typography>
                      </StyledTableHeadCell>
                    </TableRow>
                  </TableHead>
                )}

                {!loadingTotalEarningList && (
                  <TableBody>
                    {totalEarningList &&
                      totalEarningList.confirmEarnings.map((x) => (
                        <EarningList
                          churchId={churchID}
                          id={x.id}
                          startDate={+x.startDate}
                          endDate={+x.endDate}
                          total={x.total}
                          status={x.status}
                          varaint='CHURCH'
                        />
                      ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            {loadingTotalEarningList && (
              <Box paddingY={2}>
                <CircularProgress
                  color='primary'
                  disableShrink
                  size={60}
                  sx={{ margin: 'auto' }}
                />
              </Box>
            )}
            <Pagination
              count={totalEarningList?.totalPages ?? 1}
              page={page}
              onChange={handleChange}
              sx={{ paddingTop: '5px' }}
            />
          </Box>
        </div>
      </>
    )
  }
}
