import {
  Typography,
  Stack,
  Box,
  Divider,
  TextField,
  Grid,
  Button,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  FormControl,
  Input,
  FormGroup,
  RadioGroup,
  Radio,
  CircularProgress,
  Skeleton,
  List,
  ListItem,
} from '@mui/material'

import { ReactComponent as ChurchIcon } from '../../assets/Church.svg'

import { COLOR } from '../../constants/color-constant'
import { useGet } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { ChurchRequestData, FileData } from '../../models/admin/ChurchRequest'
import MemberSectionViewOnly from '../../components/Admin/MemberSectionViewOnly'
import { theme } from '../../constants/theme'
import FileCard from '../../components/FileUpload/FileCard'
import axios from 'axios'
import { CHURCH_QUESTION } from '../../constants/church-question'

interface AdminChurchProposalFormProps {
  requestData: ChurchRequestData | null
  loading: boolean
  page: number
  requestType: 'CREATE' | 'EDIT' | 'DELETE' | undefined
}

export default function AdminChurchProposalForm(
  props: AdminChurchProposalFormProps
) {
  const { page, requestData, loading, requestType } = props
  const NO_DATA = 'ไม่มีข้อมูล'

  const LoadingTextField = (
    <Skeleton variant='rounded' width={'20vw'} height={40} />
  )

  function checkAnswer(choice: string) {
    if (!requestData) {
      return false
    } else {
      return (
        requestData.formAnswers.filter(
          (formAns) => formAns.answer.toUpperCase() === choice.toUpperCase()
        ).length === 1
      )
    }
  }

  return (
    <Stack gap={'40px'}>
      <Stack
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        gap={'24px'}
      >
        {(page === 1 || page === 0) && (
          <Stack display={'flex'} flexDirection={'column'} gap={'24px'}>
            {/** Church Profile section */}
            <Stack
              flexDirection={{ sm: 'column', md: 'row' }}
              justifyContent='space-between'
              gap={'30px'}
            >
              {/** Church Profile with Church icon */}
              <Stack gap={'30px'} textAlign={'start'}>
                <span>
                  <Typography
                    display='inline'
                    variant='h3'
                    color={COLOR.PRIMARY_1}
                  >
                    *{' '}
                  </Typography>
                  <Typography display='inline' variant='h3'>
                    Church Profile
                  </Typography>
                </span>
                <Box display={{ xs: 'none', lg: 'block' }}>
                  <ChurchIcon />
                </Box>
              </Stack>

              {/** Church Profile Textfields */}
              <Stack
                maxWidth={{ xs: '100%', lg: '100%' }}
                component='form'
                id='church-profile-form'
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-churchName'
                        size='small'
                        label='Church Name'
                        fullWidth={true}
                        defaultValue={
                          requestData.churchName === ''
                            ? NO_DATA
                            : requestData.churchName
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid
                    item
                    display={{ xs: 'none', md: 'block' }}
                    md={6}
                  ></Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-denomination'
                        size='small'
                        label='Denomination'
                        fullWidth={true}
                        defaultValue={
                          requestData.denomination === ''
                            ? NO_DATA
                            : requestData.denomination
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid
                    item
                    display={{ xs: 'none', md: 'block' }}
                    md={6}
                  ></Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-address'
                        size='small'
                        label='Address'
                        fullWidth={true}
                        defaultValue={
                          requestData.address.addressLine === ''
                            ? NO_DATA
                            : requestData.address.addressLine
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-sub-district'
                        size='small'
                        label='Sub-District/Tambon'
                        fullWidth={true}
                        defaultValue={
                          requestData.address.subDistrict === ''
                            ? NO_DATA
                            : requestData.address.subDistrict
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-district'
                        size='small'
                        label='District/Amphoe'
                        fullWidth={true}
                        defaultValue={
                          requestData.address.district === ''
                            ? NO_DATA
                            : requestData.address.district
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-province'
                        size='small'
                        label='Province'
                        fullWidth={true}
                        defaultValue={
                          requestData.address.province === ''
                            ? NO_DATA
                            : requestData.address.province
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-postCode'
                        size='small'
                        label='Post Code'
                        fullWidth={true}
                        defaultValue={
                          requestData.address.postCode === null
                            ? NO_DATA
                            : requestData.address.postCode
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid
                    item
                    display={{ xs: 'none', md: 'block' }}
                    md={6}
                  ></Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-email'
                        size='small'
                        label='Email'
                        fullWidth={true}
                        defaultValue={
                          requestData.email === '' ? NO_DATA : requestData.email
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-phoneNumber'
                        size='small'
                        label='Phone Number'
                        fullWidth={true}
                        defaultValue={
                          requestData.phoneNumber === ''
                            ? NO_DATA
                            : requestData.phoneNumber
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-website'
                        size='small'
                        label='Website'
                        fullWidth={true}
                        defaultValue={
                          requestData.website === ''
                            ? NO_DATA
                            : requestData.website
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {requestData ? (
                      <TextField
                        id='outlined-read-only-facebook'
                        size='small'
                        label='Facebook'
                        fullWidth={true}
                        defaultValue={
                          requestData.facebook === ''
                            ? NO_DATA
                            : requestData.facebook
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    ) : (
                      LoadingTextField
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
            <Divider />
            {/** Church Member section */}

            {requestType === 'CREATE' && (
              <>
                <Stack
                  flexDirection='column'
                  justifyContent='space-between'
                  alignItems={'flex-start'}
                  gap={'30px'}
                >
                  <Stack width={'100%'} alignItems={'start'}>
                    <Typography variant='h3'>Church Member</Typography>
                    <List
                      sx={{
                        listStyleType: 'disc',
                        pl: 4,
                        '& .MuiListItem-root': {
                          display: 'list-item',
                          padding: '2px',
                        },
                        color: COLOR.GRAY_600,
                      }}
                    >
                      <ListItem>
                        <Typography variant='subtitle1'>
                          สามารถเพิ่มสูงสุดได้ 10 คน
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant='subtitle1'>
                          สามารถเพิ่มได้เฉพาะสมาชิกที่ยืนยันอีเมลแล้วเท่านั้น
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant='subtitle1'>
                          สมาชิก 1 คน สามารถเป็นได้เพียง 1 ตำแหน่งเท่านั้น
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant='subtitle1'>
                          สมาชิก 1 คน สามารถเป็นสมาชิกได้เพียง 1 โบสถ์เท่านั้น
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant='subtitle1'>
                          ค้นหาด้วยชื่อจริง หรืออีเมลของสมาชิก เช่น ปรียา,
                          preeya@namjai.com
                        </Typography>
                      </ListItem>
                    </List>
                  </Stack>

                  <Stack
                    marginLeft={{ lg: '60px' }}
                    gap='24px'
                    alignItems={'flex-start'}
                  >
                    {requestData ? (
                      <>
                        <MemberSectionViewOnly
                          members={
                            requestData.mainContactPerson
                              ? [requestData.mainContactPerson]
                              : []
                          }
                          title='ผู้ประสานงานหลัก'
                          position='MCP'
                          subtitle='สามารถเพิ่มสูงสุดได้ 1 คน'
                          isRequired={false}
                        />
                        <MemberSectionViewOnly
                          members={
                            requestData.churchStaff
                              ? requestData.churchStaff
                              : []
                          }
                          title='Church Staff'
                          position='CP'
                          subtitle=''
                          isRequired={false}
                        />
                      </>
                    ) : (
                      <>
                        <Stack gap={'30px'}>
                          <Skeleton
                            variant='rounded'
                            width={'50vw'}
                            height={60}
                          />
                          <Skeleton
                            variant='rounded'
                            width={'50vw'}
                            height={60}
                          />
                          <Skeleton
                            variant='rounded'
                            width={'50vw'}
                            height={60}
                          />
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
          </Stack>
        )}
        {(page === 2 || page === 0) && (
          <>
            <Stack
              display={'flex'}
              flexDirection={'column'}
              gap={'24px'}
              width={'100%'}
            >
              <Stack
                alignItems={'flex-start'}
                flexDirection={'row'}
                gap={'10px'}
              >
                <Typography
                  display='inline'
                  variant='h3'
                  color={COLOR.PRIMARY_1}
                >
                  *{' '}
                </Typography>
                <Typography display='inline' variant='h3'>
                  จำนวนสมาชิก
                </Typography>
              </Stack>

              <FormControl>
                <RadioGroup
                  aria-label='member-count'
                  name='member-count'
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack
                    flexDirection={{ sm: 'column', md: 'row' }}
                    width={'100%'}
                    justifyContent={'space-between'}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={checkAnswer(
                            CHURCH_QUESTION.question1.choice1
                          )}
                        />
                      }
                      value={CHURCH_QUESTION.question1.choice1}
                      label={CHURCH_QUESTION.question1.choice1}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={checkAnswer(
                            CHURCH_QUESTION.question1.choice2
                          )}
                        />
                      }
                      value={CHURCH_QUESTION.question1.choice2}
                      label={CHURCH_QUESTION.question1.choice2}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={checkAnswer(
                            CHURCH_QUESTION.question1.choice3
                          )}
                        />
                      }
                      value={CHURCH_QUESTION.question1.choice3}
                      label={CHURCH_QUESTION.question1.choice3}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={checkAnswer(
                            CHURCH_QUESTION.question1.choice4
                          )}
                        />
                      }
                      value={CHURCH_QUESTION.question1.choice4}
                      label={CHURCH_QUESTION.question1.choice4}
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={checkAnswer(
                            CHURCH_QUESTION.question1.choice5
                          )}
                        />
                      }
                      value={CHURCH_QUESTION.question1.choice5}
                      label={CHURCH_QUESTION.question1.choice5}
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Divider />
              <Stack
                flexDirection='column'
                flexWrap='wrap'
                gap={'20px'}
                alignItems={'flex-start'}
              >
                <Typography variant='h3'>
                  ท่านรู้จักน้ำใจจากช่องทางใด
                </Typography>
                <FormGroup
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Stack
                    flexDirection={{ sm: 'column', md: 'row' }}
                    width={'100%'}
                    justifyContent={'space-between'}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={CHURCH_QUESTION.question2.choice1}
                          checked={checkAnswer(
                            CHURCH_QUESTION.question2.choice1
                          )}
                        />
                      }
                      label={CHURCH_QUESTION.question2.choice1}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={CHURCH_QUESTION.question2.choice2}
                          checked={checkAnswer(
                            CHURCH_QUESTION.question2.choice2
                          )}
                        />
                      }
                      label={CHURCH_QUESTION.question2.choice2}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={CHURCH_QUESTION.question2.choice3}
                          checked={checkAnswer(
                            CHURCH_QUESTION.question2.choice3
                          )}
                        />
                      }
                      label={CHURCH_QUESTION.question2.choice3}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={CHURCH_QUESTION.question2.choice4}
                          checked={checkAnswer(
                            CHURCH_QUESTION.question2.choice4
                          )}
                        />
                      }
                      label={CHURCH_QUESTION.question2.choice4}
                    />
                    <FormControl variant='standard'>
                      <Input
                        placeholder='อื่นๆ'
                        name={CHURCH_QUESTION.question2.choice5}
                        id='other-channel'
                        value={''}
                        startAdornment={
                          <InputAdornment position='start'>
                            <Checkbox
                              name='OtherChecked'
                              inputProps={{ 'aria-label': 'controlled' }}
                              checked={checkAnswer(
                                CHURCH_QUESTION.question2.choice5
                              )}
                            />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Stack>
                </FormGroup>
              </Stack>
              <Divider />
              <Stack
                flexDirection='column'
                flexWrap='wrap'
                gap={'20px'}
                width={'100%'}
              >
                <Stack flexDirection={'row'} gap={'10px'}>
                  <Typography
                    display='inline'
                    variant='h3'
                    color={COLOR.PRIMARY_1}
                  >
                    *{' '}
                  </Typography>
                  <Typography display='inline' variant='h3'>
                    เอกสาร
                  </Typography>
                </Stack>

                <Stack flexDirection={'column'} gap={'8px'} textAlign={'left'}>
                  <Typography variant='subtitle1' color={COLOR.GRAY_600}>
                    - รายชื่อเอกสารที่ต้องการ (รอ confirm)
                  </Typography>
                  <Typography variant='subtitle1' color={COLOR.GRAY_600}>
                    - รายชื่อเอกสารที่ต้องการ (รอ confirm)
                  </Typography>
                  <Stack
                    gap='20px'
                    flexDirection='row'
                    flexWrap='wrap'
                    alignItems='flex-start'
                    alignContent='flex-start'
                  >
                    {requestData?.documents.map((f, i) => (
                      <FileCard
                        key={i}
                        id={i}
                        fileName={f.fileName.slice(f.fileName.indexOf('_') + 1)}
                        fileSize={f.fileSize}
                        onDelete={() => {}}
                        variant='VIEW'
                        objectUrl={f.url}
                      />
                    ))}
                  </Stack>
                </Stack>
                <Divider />
              </Stack>
            </Stack>

            <Divider />
          </>
        )}
      </Stack>
    </Stack>
  )
}
