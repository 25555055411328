import { Box, Container, ThemeProvider, Typography } from '@mui/material'
import { theme } from '../../constants/theme'

import dayjs from 'dayjs'
import { useGet } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import { CampaignProgressDetail } from '../../models/CampaignProgress/CampaignProgressDetail'
import CampaignProgressForm from '../../components/Campaign/CampaignProgress/CapaignProgressForm'
import { GetCampaignProposalData } from '../../models/CampaignProposal/GetCampaignProposalData'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { useParams } from 'react-router-dom'

interface progressDetail {
  startDate: any
  endDate: any
  activity: string
  outcome: string
  expenses: number
}

const transformData = (data: CampaignProgressDetail) => {
  const img: string[] = []
  data.imgUrl.map((v, i, a) => (img[i] = v.url))

  const detail: progressDetail[] = []
  data.campaignProgressDetails.forEach((v, i) => {
    const d: progressDetail = {
      startDate: dayjs(v.activityStartDate),
      endDate: dayjs(v.activityEndDate),
      activity: v.activity,
      outcome: v.outcome,
      expenses: v.expenses,
    }
    detail[i] = d
  })

  const transformed = {
    imageUrls: img,
    progressDetails: detail,
  }

  return transformed
}

export default function UpdateCampaignRequest() {
  const token = AuthService.getTokenUser()
  const param = useParams()
  const requestId = param.requestId

  const [progressDetail, getDeatailLoading, getDetail, getDetailStatusCode] =
    useGet<CampaignProgressDetail>({
      url: `/campaign-requests/${requestId}/progress`,
      jwtToken: token ? token : '',
      autoFetch: true,
    })

  const [reqDetail, getreqDetailLoading, getreqDetail, getreqDetailStatusCode] =
    useGet<GetCampaignProposalData>({
      url: `/campaign-requests/${requestId}`,
      jwtToken: token ? token : '',
      autoFetch: true,
    })

  if (getDeatailLoading) return null

  return (
    <ThemeProvider theme={theme}>
      {' '}
      <ResponsiveAppBar></ResponsiveAppBar>
      <Container>
        <Box>
          <Box textAlign={'center'}>
            <Typography variant='h2' paddingTop={'24px'}>
              ส่งรายงานความคืบหน้าโครงการ
            </Typography>
            <Box height={'20px'}></Box>
            <CampaignProgressForm
              type={'EDIT'}
              status={reqDetail?.campaignRequestStatus}
              id={requestId!}
              {...(progressDetail
                ? { initialData: transformData(progressDetail) }
                : {})}
              {...(progressDetail
                ? { uploadedImagesMetadata: progressDetail.imgUrl }
                : {})}
            />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  )
}
