import {
  Box,
  CircularProgress,
  Pagination,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Role, UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import AuthService from '../../services/auth.service'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { theme } from '../../constants/theme'
import { Navigate } from 'react-router-dom'
import CampaignList from '../../components/CampaignList'
import CampaignAdminList from '../../components/Admin/CampaignListAdmin'
import { ReactComponent as Fund } from '../../assets/icons/Fundforadmin.svg'
import { useGet } from '../../hooks/useFetch'
import { AllCampaign } from '../../models/admin/AllCampaign'
import CampaignListAdmin from '../../components/Admin/CampaignListAdmin'

export default function AllCampaignPage() {
  const matches = useMediaQuery('(min-width:880px)')
  const mobile = useMediaQuery('(min-width:900px)')
  const fundIcon = useMediaQuery('(min-width:1000px)')

  const userData: UserData | null = useUser().userData
  const token = AuthService.getTokenUser()
  const [userRole, setUserRole] = useState<Role>()
  const tokenChecked = useAuthMiddleware()

  const [fundingStatus, setFundingStatus] = useState('FUNDING')
  const [page, setPage] = useState(1)

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const [value, setValue] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    setPage(1)
  }

  const StyledTableHeadCell = styled(TableCell)({
    paddingTop: 0,
    paddingBottom: 0,
  })

  const [campaignList, loading] = useGet<AllCampaign>({
    url: '/campaigns/list',
    ...(token && { jwtToken: token }),
    autoFetch: true,
    queryParams: {
      page: page,
      fundingStatus: fundingStatus,
    },
  })

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h3' paddingTop={3}>
                  โครงการทั้งหมด
                </Typography>
                <Tabs value={value} onChange={handleTabChange} centered>
                  <Tab
                    label={<Typography variant='h6'>Funding</Typography>}
                    onClick={() => setFundingStatus('FUNDING')}
                  />
                  <Tab
                    label={<Typography variant='h6'>Done</Typography>}
                    onClick={() => setFundingStatus('DONE')}
                  />
                </Tabs>
                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  marginTop={1}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '90%',
                  }}
                >
                  <TableContainer>
                    <Table>
                      {mobile && (
                        <TableHead>
                          <TableRow>
                            <StyledTableHeadCell
                              sx={{ width: 500, flexWrap: 'wrap' }}
                            >
                              <Stack
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'Row',
                                  flexWrap: 'no wrap',
                                  alignItems: 'center',
                                }}
                              >
                                <Stack
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'Column',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography
                                    variant='h6'
                                    color={'text.primary'}
                                  >
                                    Campaign Name
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableHeadCell>

                            <StyledTableHeadCell
                              align='left'
                              sx={{ width: 200, flexWrap: 'wrap' }}
                            >
                              <Typography variant='h6' color={'text.primary'}>
                                Campaign owner
                              </Typography>
                            </StyledTableHeadCell>

                            <StyledTableHeadCell
                              align='left'
                              sx={{ width: 160, flexWrap: 'wrap' }}
                            >
                              <Typography variant='h6' color={'text.primary'}>
                                Status
                              </Typography>
                            </StyledTableHeadCell>

                            <StyledTableHeadCell
                              align='left'
                              sx={{ width: 200, flexWrap: 'wrap' }}
                            >
                              <Typography variant='h6' color={'text.primary'}>
                                Fund
                              </Typography>
                            </StyledTableHeadCell>

                            <StyledTableHeadCell
                              align='right'
                              sx={{ flexWrap: 'wrap' }}
                            >
                              <Typography
                                variant='h6'
                                color={'text.primary'}
                                style={{ visibility: 'hidden' }}
                              >
                                Donation
                              </Typography>
                            </StyledTableHeadCell>
                          </TableRow>
                        </TableHead>
                      )}
                      {!mobile && (
                        <TableHead>
                          <TableRow>
                            <StyledTableHeadCell
                              align='left'
                              sx={{ flexWrap: 'wrap' }}
                            >
                              <Typography variant='h6' color={'text.primary'}>
                                Campaign detail
                              </Typography>
                            </StyledTableHeadCell>
                          </TableRow>
                        </TableHead>
                      )}

                      {!loading && (
                        <TableBody>
                          {campaignList?.campaignPage.map((req) => (
                            <CampaignListAdmin
                              key={req.id}
                              campaignId={req.id}
                              campaignName={req.name}
                              holder={req.holderChurches}
                              status={
                                req.fundingStatus === 'NOT_FUNDED' ||
                                req.fundingStatus === 'FUNDING'
                                  ? req.fundingStatus
                                  : req.isOnGoing === false &&
                                    req.campaignStatus === 'ACTIVE'
                                  ? 'FUNDED'
                                  : req.isOnGoing === true &&
                                    req.campaignStatus === 'ACTIVE'
                                  ? 'ON_GOING'
                                  : 'COMPLETED'
                              }
                              targetBudget={req.targetBudget}
                              currentDonationAmount={req.currentDonationAmount}
                            ></CampaignListAdmin>
                          ))}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  {loading && (
                    <Box paddingY={2}>
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}
                  <Pagination
                    count={campaignList?.totalPages}
                    page={page}
                    onChange={handleChange}
                    sx={{ paddingTop: '5px' }}
                  />
                </Box>
              </Box>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </ThemeProvider>
      )
    }
  }
}
