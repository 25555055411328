import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ROUTE } from './constants/route-constant'
import Campaign from './pages/Campaign'
import LoginPage from './pages/LoginPage'
import SignupPage from './pages/SignupPage'
import ChurchManagement from './pages/ChurchManagement/ChurchManagement'
import { ThemeProvider } from '@mui/material'
import { theme } from './constants/theme'
import Church from './pages/Church'
import WantToSubmitCampaign from './pages/WantToSubmitCampaign'

import { UserProvider } from './services/UserContext'
import ChurchRequestDetail from './pages/Admin/ChurchRequestDetail'
import { ADMIN_ROUTE } from './constants/admin-route-constant'
import CampaignRequestList from './pages/Admin/CampaignRequestList'
import ChurchRequestList from './pages/Admin/ChurchRequestList'
import AllChurchPage from './pages/Admin/AllChurchsPage'
import UserProfile from './pages/UserProfile'
import Redirect from './pages/Redirect'
import AllSearch from './pages/AllSearch'
import ForgotPasswordPage from './pages/ForgotPasswordPage'
import CreateChurchProposalPage from './pages/CreateChurchProposal/CreateChurchProposalPage'
import CreateCampaignProposalPage from './pages/CreateCampaignProposal/CreateCampaignProposalPage'
import EditChurchProposalPage from './pages/EditChurchProposal/EditChurchProposalPage'
import NamjaiBag from './pages/NamjaiBag'
import PermissionDeny from './pages/PermissionDeny'
import ChurchCampaignManagement from './pages/ChurchManagement/ChurchCampaignManagement'
import AllCampaignPage from './pages/Admin/AllCampaignPage'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Dashboard from './pages/Admin/Dashboard'
import AdminManagementPage from './pages/Admin/AdminManagementPage'
import DashboardCampaign from './pages/Admin/DashboardCampaign'
import CampaignRequestDetail from './pages/Admin/CampaignRequestDetail'
import EditChurchForChurchMember from './pages/EditChurchForChurchMember'
import EditCampaignProposalPage from './pages/EditCampaignProposal/EditCampaignProposalPage'
import CreateCampaignProgressUpdate from './pages/CampaignProgressUpdate/CreateCampaignProgressUpdate'
import AdminChurch from './pages/Admin/AdminChurch'
import AdminCampaignEdit from './pages/Admin/AdminCampaignEdit'
import EditCampaignProgressUpdate from './pages/CampaignProgressUpdate/EditCampaignProgressUpdate'
import UpdateCampaignRequest from './pages/Admin/UpdateCampaignRequest'

import AboutNamjai from './pages/AboutNamjai'
import AllReceiptList from './pages/Admin/AllReceiptList'
import ChurchWeeklyEarning from './pages/Admin/ChurchWeeklyEarning'

import ThankyouPage from './pages/ThankyouPage'
import EditCampaignProgress from './pages/Admin/EditCampaignProgress'
import WeeklyEarningList from './pages/Admin/WeeklyEarningList'
import AllEarningPage from './pages/Admin/AllEarningPage'
import ScrollToTop from './pages/ScrollToTop'
import CookiePolicy from './pages/CookiePolicy'
import FAQ from './pages/FAQ'
import { FormDirtyProvider } from './services/FormDirtyContext'
import PrivacyPolicy from './pages/PrivacyPolicy'

const googleapikey = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''
const router = createBrowserRouter([
  {
    element: <ScrollToTop />,
    children: [
      {
        path: '/',
        element: <App />,
      },
      {
        path: '/redirect',
        element: <Redirect />,
      },
      // TODO: ADD ALL ELEMENT TO THE PATH
      {
        path: ROUTE.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTE.SIGNUP,
        element: <SignupPage />,
      },
      {
        path: ROUTE.FORGOT_PASSWORD,
        element: <ForgotPasswordPage />,
      },
      {
        path: ROUTE.USER,
        element: <UserProfile />,
      },
      {
        path: ROUTE.CHURCH,
        element: <Church />,
      },
      {
        path: ROUTE.MANAGE_CHURCH,
        element: <ChurchManagement />,
      },
      {
        path: ROUTE.CHURCH_MANAGE_CAMPAIGN,
        element: <ChurchCampaignManagement />,
      },
      {
        path: ROUTE.EDIT_CHURCH,
        element: <EditChurchForChurchMember />,
      },
      {
        path: ROUTE.CREATE_CHURCH_PROPOSAL,
        element: <CreateChurchProposalPage />,
      },
      {
        path: ROUTE.EDIT_CHURCH_PROPOSAL,
        element: <EditChurchProposalPage />,
      },
      {
        path: ROUTE.CREATE_CAMPAIGN_PROPOSAL,
        element: <CreateCampaignProposalPage />,
      },
      {
        path: ROUTE.EDIT_CAMPAIGN_PROPOSAL,
        element: <EditCampaignProposalPage />,
      },
      {
        path: ROUTE.ALL_CAMPAIGN,
        element: <AllSearch />,
      },
      {
        path: ROUTE.VIEW_CAMPAIGN,
        element: <Campaign />,
      },
      {
        path: ADMIN_ROUTE.CHURCH_EARNING,
        element: <ChurchWeeklyEarning />,
      },
      {
        path: ROUTE.EDIT_CAMPAIGN_PROPOSAL,
        element: <EditCampaignProposalPage />,
      },
      {
        path: ROUTE.CREATE_PROGRESS_UPDATE,
        element: <CreateCampaignProgressUpdate />,
      },
      {
        path: ROUTE.EDIT_PROGRESS_UPDATE,
        element: <EditCampaignProgressUpdate />,
      },
      {
        path: ROUTE.NAMJAI_BAG,
        element: <NamjaiBag />,
      },
      {
        path: ROUTE.FAQ,
        element: <FAQ />,
      },
      {
        path: ROUTE.ABOUT,
        element: <AboutNamjai />,
      },
      {
        path: ROUTE.THANKYOU,
        element: <ThankyouPage />,
      },
      {
        path: ROUTE.PRIVACY_POLICY,
        element: <PrivacyPolicy />,
      },
      {
        path: ROUTE.COOKIES_POLICY,
        element: <CookiePolicy />,
      },
      {
        path: ROUTE.WANT_TO_SUBMIT_CAMPAIGN,
        element: (
          <>
            <ThemeProvider theme={theme}>
              <WantToSubmitCampaign />
            </ThemeProvider>
          </>
        ),
      },
      {
        path: ADMIN_ROUTE.CHURCH_REQUEST,
        element: <ChurchRequestList />,
      },
      {
        path: ADMIN_ROUTE.DASHBOARD,
        element: <Dashboard></Dashboard>,
      },
      {
        path: ADMIN_ROUTE.DASHBOARD_CAMPAIGN,
        element: <DashboardCampaign></DashboardCampaign>,
      },
      {
        path: ADMIN_ROUTE.ALL_CAMPAIGN,
        element: <AllCampaignPage />,
      },
      {
        path: ADMIN_ROUTE.VIEW_CAMPAIGN,
        element: <Campaign />,
      },
      {
        path: ADMIN_ROUTE.EDIT_CAMPAIGN,
        element: <AdminCampaignEdit />,
      },
      {
        path: ADMIN_ROUTE.ALL_EARNING,
        element: <AllEarningPage />,
      },
      {
        path: ADMIN_ROUTE.ALL_CHURCH,
        element: <AllChurchPage />,
      },
      {
        path: ADMIN_ROUTE.VIEW_CHURCH,
        element: <AdminChurch />,
      },
      {
        path: ADMIN_ROUTE.EDIT_CAMPAIGN_PROGRESS,
        element: <EditCampaignProgress />,
      },
      {
        path: ADMIN_ROUTE.CAMPAIGN_REQUEST,
        element: <CampaignRequestList />,
      },
      {
        path: ADMIN_ROUTE.CAMPAIGN_REQUEST_VIEW,
        element: <CampaignRequestDetail></CampaignRequestDetail>,
      },

      {
        path: ADMIN_ROUTE.CAMPAIGN_UPDATE_REQUEST_VIEW,
        element: <UpdateCampaignRequest></UpdateCampaignRequest>,
      },
      {
        path: ADMIN_ROUTE.CHURCH_REQUEST,
        element: <ChurchRequestList />,
      },
      {
        path: ADMIN_ROUTE.CHURCH_REQUEST_VIEW,
        element: (
          <>
            <ThemeProvider theme={theme}>
              <ChurchRequestDetail />
            </ThemeProvider>
          </>
        ),
      },
      {
        path: ADMIN_ROUTE.ADMIN_MANAGEMENT,
        element: <AdminManagementPage />,
      },
      {
        path: ADMIN_ROUTE.ALL_RECEIPT_LIST,
        element: <AllReceiptList />,
      },
      {
        path: ADMIN_ROUTE.WEEKLY_EARNING_LIST,
        element: <WeeklyEarningList />,
      },
      {
        path: ROUTE.PERMISSION_DENIED,
        element: <PermissionDeny />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={googleapikey}>
        <UserProvider>
          <FormDirtyProvider>
            <RouterProvider router={router} />
          </FormDirtyProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
