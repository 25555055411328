import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Divider,
  CardActions,
  Typography,
  Grid,
  Box,
} from '@mui/material'
import ProgressBar from './ProgressBar'
import { COLOR } from '../constants/color-constant'
import account from '../assets/icons/account.svg'
import fvg from '../assets/icons/fav.svg'
import { CampaignCard, CampaignCards } from '../models/campaign'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { generateViewCampaignRoute } from '../utils/routeGenerator'
import formatDate from '../utils/formatDate'
import { useGet } from '../hooks/useFetch'
import { CheckCircleOutline } from '@mui/icons-material'
import AuthService from '../services/auth.service'
import { ROUTE } from '../constants/route-constant'

interface CampaignCardDetailProps {
  data: CampaignCard | null
  nowdate: number
  onAddtoBag_hook: React.Dispatch<React.SetStateAction<string>>
  isLogin: boolean
}
function CampaignCardDetail(props: CampaignCardDetailProps) {
  const { data, nowdate, onAddtoBag_hook, isLogin } = props
  const campaignId = props.data?.campaignId
  const navigate = useNavigate()
  const [isAddtoBag, setIsAddtoBag] = useState<boolean>(false)
  const endTime = data?.endDate ?? 0
  const remaingdate = Math.floor((endTime - nowdate) / 1000 / 60 / 60 / 24)
  const [donated] = useGet<number>({
    url: `/campaigns/${campaignId}/donated-user-count`,
    autoFetch: true,
  })
  const handleAddToCart = () => {
    if (isLogin) {
      setIsAddtoBag(true)
      onAddtoBag_hook(data?.campaignId ?? '0')
    } else {
      navigate(ROUTE.LOGIN)
    }
  }
  return (
    <Card sx={{ borderRadius: '20px' }}>
      <CardActionArea
        onClick={() =>
          navigate(generateViewCampaignRoute(data?.campaignId ?? '9999'))
        }
      >
        <CardMedia
          component='img'
          style={{ minHeight: '250px' }}
          image={data?.coverImageUrl}
          alt={`card_cover ${campaignId}`}
        />
        <CardContent>
          <Box sx={{ minHeight: '200px' }}>
            <Typography
              gutterBottom
              variant='h4'
              color={COLOR.PRIMARY_1}
              component='div'
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2, // Maximum number of lines
                WebkitBoxOrient: 'vertical',
                // maxHeight: '3.6em', // Adjust this to control the line height and ellipsis
              }}
            >
              {data?.campaignName ?? 'Campaign Name'}
            </Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4,
              }}
            >
              {data?.description ??
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua Loremipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolore magna aliqua dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt '}
            </Typography>
          </Box>
          <Divider />
          <Typography variant='subtitle1' color='text' sx={{ mt: 1 }}>
            เป้าหมาย
          </Typography>
          <ProgressBar
            amount={data?.currentDonationAmount ?? 0}
            goal={data?.targetBudget ?? 10}
          ></ProgressBar>
          <Grid container direction='row' justifyContent='space-between'>
            <Typography variant='body1' color='text'>
              {remaingdate > 0
                ? `${remaingdate} วัน จนถึง ${formatDate(endTime)}`
                : `ปิดรับการบริจาคเมื่อ ${formatDate(endTime)} `}
            </Typography>
            <Grid display={'flex'}>
              <img alt='account' src={account}></img>
              <Typography variant='body1' color='text'>
                {donated ?? ' 9,999'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions
        sx={{
          m: '5px',
          justifyContent: 'space-around',
        }}
      >
        <Button
          style={{
            borderBlockWidth: '2px',
            borderRadius: '10px',
            borderColor: COLOR.PRIMARY_2,
            padding: '10px',
          }}
          fullWidth
          variant='outlined'
          onClick={() =>
            navigate(
              generateViewCampaignRoute(data?.campaignId ?? '0') + '#JOIN'
            )
          }
          sx={{ width: '80%', height: '42px' }}
        >
          <Typography variant='h5' color={COLOR.PRIMARY_2}>
            ร่วมมอบน้ำใจ
          </Typography>
        </Button>
        <Button
          onClick={handleAddToCart}
          disabled={isAddtoBag}
          sx={{ height: '42px' }}
          style={{
            animation: isAddtoBag ? 'pop 1.5s ease-out' : 'none', // Apply animation if clicked is true
          }}
        >
          {isAddtoBag ? (
            <CheckCircleOutline
              color='secondary'
              sx={{
                fontSize: '50px',
              }}
            />
          ) : (
            <img alt='fvg' src={fvg} />
          )}
        </Button>
      </CardActions>
    </Card>
  )
}

interface SectionCampaignCardProps {
  data: CampaignCards | null
  onAddtoBag_hook: React.Dispatch<React.SetStateAction<string>>
}
export default function SectionCampaignCard(props: SectionCampaignCardProps) {
  const data = props.data
  const onAddtoBag_hook = props.onAddtoBag_hook
  const token = AuthService.getTokenUser()
  const [currentTimestamp, setCurrentTimestamp] = useState(0)
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (data && data.campaignCards && data.campaignCards.length > 0) {
      setCurrentTimestamp(Date.now())
      if (token) {
        setIsLogin(true)
      }
    }
  }, [data, token])

  function NoCampaignsFoundComponent() {
    return (
      <Box
        sx={{
          width: '100%',
          minHeight: '300px',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h5' color={COLOR.PRIMARY_4}>
          ไม่พบ Campaign ที่ค้นหา
        </Typography>
      </Box>
    )
  }

  if (!data) {
    return null
  }

  if (data.campaignCards && data.campaignCards.length > 0) {
    return (
      <Box sx={{ width: '100%', minHeight: '300px', flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, md: 8, lg: 12 }}
        >
          {data.campaignCards.map((carddata, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <CampaignCardDetail
                data={carddata}
                nowdate={currentTimestamp}
                onAddtoBag_hook={onAddtoBag_hook}
                isLogin={isLogin}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  }

  //   no campaigns found
  return <NoCampaignsFoundComponent />
}
