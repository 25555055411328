import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  circularProgressClasses,
  linearProgressClasses,
  styled,
} from '@mui/material'
import { blue, red } from '@mui/material/colors'
import { COLOR } from '../../constants/color-constant'

type ProgressProps = {
  amount: number
  goal: number
}

const ProgressBarAdmin: React.FunctionComponent<ProgressProps> = (props) => {
  const { amount, goal } = props

  const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#F0F0F0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundImage: `linear-gradient(90deg, #08B3E5 39.7%, #02D2B6 47.58%, #00DFA2  65.81%)`,
    },
  }))

  const percent = (amount / goal) * 100

  return (
    <Box width={{ xs: '35vw', md: '200px' }}>
      <div className='grid gap-0'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            columnGap: '4px',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='body1' color={COLOR.BLACK_TEXT}>
            {amount}฿
          </Typography>
          <Typography variant='body1' color={COLOR.BLACK_TEXT}>
            {((amount / goal) * 100).toFixed(2)}%
          </Typography>
        </Box>
        <BorderLinearProgress
          variant='determinate'
          value={percent > 100 ? 100 : percent}
        />
      </div>
    </Box>
  )
}

export default ProgressBarAdmin
