import {
  Backdrop,
  Box,
  Button,
  Divider,
  FormControl,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { common, grey } from '@mui/material/colors'
import React, { useContext, useMemo, useState } from 'react'

import { CloseRounded } from '@mui/icons-material'
import { ModalType } from '../../../constants/modalType'
import { theme } from '../../../constants/theme'

interface ModalDeleteWithConfirmProps {
  id: string
  type: ModalType
  confirmText: string
  title?: string
  description?: string
  confirmBtnText?: string
  cancleBtnText?: string
  open: boolean
  onClose: () => void
  handleClickConfirm?: () => void
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
}

// MODAL WITH FILL TO CONFIRM DELETE
export default function AdminDeleteModal(props: ModalDeleteWithConfirmProps) {
  const breakPoint = useMediaQuery('(min-width:600px)')

  const {
    id,
    type,
    title,
    confirmText,
    description,
    confirmBtnText,
    cancleBtnText,
    open,
    onClose,
    handleClickConfirm,
  } = props

  const color: any = useMemo(() => {
    if (type === 'DEFAULT') {
      return 'primary'
    } else if (type === 'ERROR') {
      return 'error'
    } else if (type === 'SUCCESS') {
      return 'secondary'
    }
  }, [type])

  const [validate, setValidate] = useState<boolean>(false)

  function ValidateInput(input: any): void {
    setValidate(input === confirmText)
  }

  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      // onClick={onClose}
    >
      <Modal open={open} onClose={onClose}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          alignSelf={'stretch'}
          sx={ModalStyle}
          width={{ xs: '80vw', md: '640px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'flex-end'}
            alignItems={'flex-end'}
            flex={'1 0 0'}
          >
            {/* title & description section */}
            <Typography
              id='modal-modal-title'
              variant='h5'
              color={color}
              width={'100%'}
            >
              {title}
            </Typography>
            <Divider
              variant='fullWidth'
              sx={{ color: grey[200], padding: '4px' }}
              flexItem
            />
            <Box height={theme.spacing(1)} />

            <Typography
              id='modal-modal-description'
              variant='subtitle1'
              color={grey[600]}
              width={'100%'}
            >
              {description}
            </Typography>
            <Box height={theme.spacing(1)} />
            <Typography color={grey[600]} variant='subtitle1' width={'100%'}>
              Type <span style={{ color: '#FF0038' }}>{confirmText}</span> to
              confirm
            </Typography>
            <Box height={theme.spacing(1)} />

            <TextField
              autoFocus
              error={validate ? false : true}
              id='outlined-basic'
              variant='outlined'
              fullWidth={true}
              size='small'
              placeholder={confirmText}
              onChange={(e) => ValidateInput(e.target.value)}
            />

            <Box height={theme.spacing(2)} />
            <Stack // buttons
              display={'flex'}
              flexDirection={'row'}
              gap={theme.spacing(2)}
              alignItems={{ sx: 'stretch', md: 'flex-end' }}
              justifyContent={'flex-end'}
              width={'100%'}
              flex={1}
            >
              <Button // cancel btn
                sx={{ color: grey[600] }}
                color='inherit'
                variant='outlined'
                onClick={() => {
                  onClose()
                  setValidate(false)
                }}
                style={{ flex: breakPoint ? 'none' : 1 }}
              >
                <Typography variant='body1'>{cancleBtnText}</Typography>
              </Button>

              <Button // confitm btn
                disabled={validate ? false : true}
                color={color}
                variant='contained'
                onClick={() => {
                  if (handleClickConfirm) handleClickConfirm()
                  setValidate(false)
                }}
                style={{ flex: breakPoint ? 'none' : 1 }}
              >
                {!breakPoint && (
                  <Typography variant='body1'>Confirm</Typography>
                )}
                {breakPoint && (
                  <Typography variant='body1'>{confirmBtnText}</Typography>
                )}
              </Button>
            </Stack>
          </Box>
          <Box // close button
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              margin: '16px',
            }}
          >
            <CloseRounded
              sx={{ color: grey[400], cursor: 'pointer', fontSize: '20px' }}
            />
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  )
}
