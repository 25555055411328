import { forwardRef, useMemo } from 'react'
import {
  Typography,
  Stack,
  Box,
  Divider,
  TextField,
  Grid,
  Button,
  Autocomplete,
  List,
  ListItem,
} from '@mui/material'
import { TypeOf, z } from 'zod'

import { ReactComponent as ChurchIcon } from '../../assets/Church.svg'

import MemberSection from '../MemberSection'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { common } from '@mui/material/colors'

import { COLOR } from '../../constants/color-constant'
import { UserShortDetail } from '../../models/UserTypes'
import { SubmitChurchProposalFormData } from '../../models/ChurchProposalFormData'
import ThaiProvince from '../../constants/thai-province'
import appendHttpsIfMissing from '../../utils/appendHttps'

export const churchProfileSchema = z.object({
  churchName: z.string().trim().min(1),
  denomination: z.string().trim().min(1),
  address: z.object({
    addressLine: z.string().trim().min(1),
    subDistrict: z.string().trim().min(1),
    district: z.string().trim().min(1),
    province: z.string().trim().min(1),
    postCode: z
      .string()
      .trim()
      .refine((value) => /^[0-9]{5}$/.test(value)),
  }),
  email: z.preprocess(
    (arg) => (arg === '' ? undefined : arg),
    z.string().trim().email().optional()
  ),
  phoneNumber: z
    .string()
    .trim()
    .refine((value) => !value || /^[0-9]{9,}$/.test(value))
    .optional(),
  website: z.preprocess(
    (arg) => (arg === '' ? undefined : arg),
    z
      .string()
      .trim()
      .transform(appendHttpsIfMissing)
      .pipe(z.string().url())
      .optional()
  ),
  facebook: z.preprocess(
    (arg) => (arg === '' ? undefined : arg),
    z.string().trim().min(1).optional()
  ),
})

export type ChurchProfileInput = TypeOf<typeof churchProfileSchema>

export type ChurchMember = {
  mcp: null | UserShortDetail
  staffs: UserShortDetail[]
}

interface ChurchProposalForm1Props {
  viewOnly: boolean
  churchProfileErrors: FieldErrors<SubmitChurchProposalFormData>
  register: UseFormRegister<ChurchProfileInput>
  control: Control<ChurchProfileInput>
  members: ChurchMember
  onMemberChange: (newVal: ChurchMember) => void
  memberError: boolean
  onSubmit: any
}

const MAX_MEMBERS = 10

const ChurchProposalForm1 = forwardRef<
  HTMLDivElement,
  ChurchProposalForm1Props
>(function ChurchProposalForm1(
  {
    viewOnly,
    members,
    onMemberChange,
    memberError,
    churchProfileErrors: errors,
    register,
    control,
    onSubmit,
  },
  ref
) {
  const { mcp, staffs } = members

  const assignedMembersID = useMemo(() => {
    return [mcp, ...staffs].map((user) => (user ? user.id : ''))
  }, [mcp, staffs])

  const handleAddMcp = (newMcp: UserShortDetail) => {
    onMemberChange({
      ...members,
      mcp: newMcp,
    })
  }

  const handleAddStaff = (newStaff: UserShortDetail) => {
    onMemberChange({
      ...members,
      staffs: staffs.concat(newStaff),
    })
  }

  const handleDeleteMcp = () => {
    onMemberChange({
      ...members,
      mcp: null,
    })
  }

  const handleDeleteStaff = (deletedStaff: UserShortDetail) => {
    onMemberChange({
      ...members,
      staffs: staffs.filter((s) => s !== deletedStaff),
    })
  }

  const countAvailableStaffs = () => {
    let remaining = MAX_MEMBERS
    if (mcp) remaining--
    return remaining
  }

  return (
    <Stack gap={'40px'}>
      <Stack
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        gap={'24px'}
      >
        <Stack>
          <span>
            <Typography display='inline' variant='h3' color={COLOR.PRIMARY_1}>
              *{' '}
            </Typography>
            <Typography display='inline' variant='h3'>
              ข้อมูลโบสถ์
            </Typography>
          </span>
          <Box
            display={{ xs: 'none', lg: 'block' }}
            marginTop='auto'
            marginBottom='auto'
          >
            <ChurchIcon />
          </Box>
        </Stack>
        <Box
          maxWidth={{ xs: '100%', lg: '75%' }}
          onSubmit={onSubmit}
          component='form'
          id='church-profile-form'
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id='churchName'
                size='small'
                label='ชื่อโบสถ์'
                variant='outlined'
                error={Boolean(errors['churchName'])}
                fullWidth={true}
                {...register('churchName')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item display={{ xs: 'none', md: 'block' }} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='denomination'
                size='small'
                label='สังกัด'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors['denomination'])}
                {...register('denomination')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item display={{ xs: 'none', md: 'block' }} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='address'
                size='small'
                label='ที่อยู่'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors.address?.addressLine)}
                {...register('address.addressLine')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='sub-district'
                size='small'
                label='ตำบล/แขวง'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors.address?.subDistrict)}
                {...register('address.subDistrict')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='district'
                size='small'
                label='อำเภอ/เขต'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors.address?.district)}
                {...register('address.district')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name='address.province'
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    ref={register('address.province').ref}
                    disabled={viewOnly}
                    disablePortal
                    blurOnSelect
                    id='province'
                    size='small'
                    options={ThaiProvince}
                    value={ThaiProvince.find((p) => p.name_th === field.value)}
                    onChange={(event, value) => {
                      field.onChange(value ? value.name_th : '')
                    }}
                    fullWidth={true}
                    getOptionLabel={(option) => option.name_th}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='จังหวัด'
                        error={Boolean(errors.address?.province)}
                      />
                    )}
                    renderOption={(props, option, { inputValue }) => {
                      const matches = match(option.name_th, inputValue, {
                        insideWords: true,
                      })
                      const parts = parse(option.name_th, matches)

                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part: any, index: number) => (
                              <span
                                key={index}
                                style={{
                                  color: part.highlight
                                    ? COLOR.PRIMARY_1
                                    : common.black,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='postCode'
                size='small'
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  maxLength: 5,
                  onInput: (e) => {
                    // Allow only numeric characters
                    e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ''
                    )
                  },
                }}
                label='รหัสไปรษณีย์'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors.address?.postCode)}
                {...register('address.postCode')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item display={{ xs: 'none', md: 'block' }} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='email'
                size='small'
                inputProps={{ inputMode: 'email' }}
                label='อีเมล'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors['email'])}
                {...register('email')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='phoneNumber'
                size='small'
                inputProps={{
                  inputMode: 'tel',
                  maxLength: 10,
                  onInput: (e) => {
                    // Allow only numeric characters
                    e.currentTarget.value = e.currentTarget.value.replace(
                      /[^0-9]/g,
                      ''
                    )
                  },
                }}
                label='เบอร์โทรศัพท์'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors['phoneNumber'])}
                {...register('phoneNumber')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='website'
                size='small'
                inputProps={{ inputMode: 'url' }}
                label='เว็บไซต์'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors['website'])}
                {...register('website')}
                disabled={viewOnly}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id='facebook'
                size='small'
                label='เฟสบุ๊คเพจ'
                variant='outlined'
                fullWidth={true}
                error={Boolean(errors['facebook'])}
                {...register('facebook')}
                disabled={viewOnly}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Divider />
      <div ref={ref} className='flex flex-col'>
        <Typography variant='h3'>เจ้าหน้าที่คริสตจักร</Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 4,
            '& .MuiListItem-root': {
              display: 'list-item',
              padding: '2px',
            },
            color: COLOR.GRAY_600,
          }}
        >
          <ListItem>
            <Typography variant='subtitle1'>
              สามารถเพิ่มสูงสุดได้ 10 คน
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='subtitle1'>
              สามารถเพิ่มได้เฉพาะสมาชิกที่ยืนยันอีเมลแล้วเท่านั้น
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='subtitle1'>
              สมาชิก 1 คน สามารถเป็นได้เพียง 1 ตำแหน่งเท่านั้น
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='subtitle1'>
              สมาชิก 1 คน สามารถเป็นสมาชิกได้เพียง 1 โบสถ์เท่านั้น
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='subtitle1'>
              ค้นหาด้วยชื่อจริง หรืออีเมลของสมาชิก เช่น ปรียา, preeya@namjai.com
            </Typography>
          </ListItem>
        </List>
        {memberError && (
          <Typography variant='subtitle1' color='error'>
            * กรุณาเลือกผู้ประสานงานหลัก
          </Typography>
        )}
      </div>
      <Stack marginLeft={{ lg: '60px' }} gap='24px'>
        <MemberSection
          disabled={viewOnly}
          showSideTitle={true}
          members={mcp ? [mcp] : []}
          addMember={handleAddMcp}
          deleteMember={handleDeleteMcp}
          title='ผู้ประสานงานหลัก'
          position='MCP'
          maxMembers={1}
          subtitle='สามารถเพิ่มสูงสุดได้ 1 คน'
          excludedMembersID={assignedMembersID}
          isRequired={true}
          customNoOptionsText='ไม่พบผู้ใช้หรือท่านได้เลือกคนนี้ไปแล้ว'
        />
        <MemberSection
          disabled={viewOnly}
          showSideTitle={true}
          members={staffs}
          addMember={handleAddStaff}
          deleteMember={handleDeleteStaff}
          title='เจ้าหน้าที่'
          position='CP'
          subtitle=''
          maxMembers={countAvailableStaffs()}
          excludedMembersID={assignedMembersID}
          isRequired={false}
          customNoOptionsText='ไม่พบผู้ใช้หรือท่านได้เลือกคนนี้ไปแล้ว'
        />
      </Stack>
      <Divider />
      <Stack flexDirection={'row-reverse'}>
        <Button
          variant='outlined'
          type='submit'
          fullWidth={false}
          form='church-profile-form'
        >
          <Typography variant='h6'>{`ถัดไป >`}</Typography>
        </Button>
      </Stack>
    </Stack>
  )
})

export default ChurchProposalForm1
