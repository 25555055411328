import { Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { COLOR } from '../../constants/color-constant'

interface CountUpProps {
  targetNumber: number
  duration?: number
  textVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'body1'
    | 'body2'
}

const useCountAnimation = (targetNumber: number, duration: number = 3000) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    let start: number
    const step = (timestamp: number) => {
      if (!start) start = timestamp
      const progress = timestamp - start
      const percentage = Math.min(progress / duration, 1)
      const newCount = Math.floor(targetNumber * percentage)
      setCount(newCount)
      if (progress < duration) {
        requestAnimationFrame(step)
      }
    }
    requestAnimationFrame(step)
  }, [targetNumber, duration])

  return count
}

const CountUp: React.FC<CountUpProps> = ({
  targetNumber,
  duration,
  textVariant,
}) => {
  const count = useCountAnimation(targetNumber, duration)
  const formattedCount = count?.toLocaleString()
  return (
    <Typography variant={textVariant || 'h3'} color={COLOR.PRIMARY_1}>
      {formattedCount}
    </Typography>
  )
}

export default CountUp
