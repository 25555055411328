import { Fragment } from 'react'
import { Box, Grid, Skeleton, Card, Divider } from '@mui/material'

const CardSkeleton = ({ cards }: { cards: number }) => {
  return (
    <Box sx={{ width: '100%', minHeight: '300px', flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, md: 8, lg: 12 }}
      >
        {Array(cards)
          .fill(0)
          .map((_, i) => (
            <Grid item xs={2} sm={4} md={4} key={i}>
              <Card sx={{ borderRadius: '20px' }} key={i}>
                <Skeleton variant='rectangular' width='100%' height={250} />
                <div style={{ padding: '16px' }}>
                  <Skeleton
                    animation='wave'
                    height={40}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation='wave' height={24} />
                  <Skeleton animation='wave' height={24} />
                  <Skeleton animation='wave' height={24} />
                  <Skeleton
                    animation='wave'
                    height={24}
                    width='80%'
                    style={{ marginBottom: 58 }}
                  />
                  <Divider />
                  <Skeleton animation='wave' height={122} />
                </div>
                <div style={{ padding: '8px' }}>
                  <Skeleton animation='wave' height={55} />
                </div>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default CardSkeleton
