import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { theme } from '../constants/theme'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import about_report64 from '../assets/images/about_report64.jpeg'
import about_banner from '../assets/images/aboutNamjaiPage/about_banner.jpg'
import compassion from '../assets/images/aboutNamjaiPage/compassion.jpg'
import lineQR from '../assets/images/aboutNamjaiPage/about_lineQR.jpg'
import slide1 from '../assets/images/aboutNamjaiPage/slides/about_slide1.jpg'
import slide2 from '../assets/images/aboutNamjaiPage/slides/about_slide2.jpg'
import slide3 from '../assets/images/aboutNamjaiPage/slides/about_slide3.jpg'
import slide4 from '../assets/images/aboutNamjaiPage/slides/about_slide4.jpg'
import slide5 from '../assets/images/aboutNamjaiPage/slides/about_slide5.jpg'
import slide6 from '../assets/images/aboutNamjaiPage/slides/about_slide6.jpg'
import slide7 from '../assets/images/aboutNamjaiPage/slides/about_slide7.jpg'
import slide8 from '../assets/images/aboutNamjaiPage/slides/about_slide8.jpg'
import slide9 from '../assets/images/aboutNamjaiPage/slides/about_slide9.jpg'
import slide10 from '../assets/images/aboutNamjaiPage/slides/about_slide10.jpg'
import slide11 from '../assets/images/aboutNamjaiPage/slides/about_slide11.jpg'
import slide12 from '../assets/images/aboutNamjaiPage/slides/about_slide12.jpg'

import { ReactComponent as People } from '../../src/assets/icons/children.svg'
import { ReactComponent as Logo } from '../../src/assets/icons/logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

function AboutNamjai() {
  document.body.style.background = '#FBFDFF'

  const breakPoint = useMediaQuery(theme.breakpoints.up('md'))
  const breakPointMobile = useMediaQuery(theme.breakpoints.up('sm'))

  const [numberImage, setNumberImage] = useState(3)

  useEffect(() => {
    if (!breakPointMobile) {
      setNumberImage(1)
    } else if (!breakPoint) {
      setNumberImage(2)
    } else {
      setNumberImage(3)
    }
  }, [breakPointMobile, breakPoint])

  const navigate = useNavigate()
  const imagePaths = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
    slide12,
  ]

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const showNextSet = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + numberImage) % imagePaths.length
    )
  }

  const showPreviousSet = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - numberImage + imagePaths.length) % imagePaths.length
    )
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      showNextSet()
    }, 3000)

    return () => clearInterval(intervalId)
  }, [numberImage, imagePaths.length])

  return (
    <ThemeProvider theme={theme}>
      <Navbar></Navbar>
      <Container
        sx={{ paddingX: '48px', paddingY: '24px', background: '#ffffff' }}
      >
        <Stack gap={'80px'} justifyContent={'center'} alignItems={'center'}>
          <Stack width={'100%'} textAlign={'center'} gap={'16px'}>
            <Typography variant='h1' color={'primary'}>
              น้ำใจโปรเจ็ค (Namjai Project)
            </Typography>

            <Typography variant='h5'>
              จากประสบการณ์การทำงานด้านการพัฒนาเด็กและเยาวชนแบบองค์รวม
              ทำให้มูลนิธิดรุณาทรเห็นโอกาสความร่วมมืออย่างกว้างขวางในสังคม
              ทั้งระดับบุคคลและองค์กร
              ในการช่วยกันปกป้องดูแลเด็กและเยาวชนให้เติบโตอย่างปลอดภัย
              ส่งเสริมให้ค้นพบศักยภาพในตนเอง นำไปสู่การพึ่งพาตนเองอย่างยั่งยืน
              <br />
              <br />
              เราพบว่า มีเด็กและเยาวชนจำนวนมากที่ยังต้องการโอกาสในการพัฒนา
              รวมทั้งโอกาสในการได้รับความช่วยเหลือในเรื่องที่จำเป็นสำหรับชีวิต
            </Typography>
            <Typography variant='h5' display='inline'>
              <span>
                <Typography color={'primary'} variant='h2' display='inline'>
                  “น้ำใจ”{' '}
                </Typography>
              </span>
              จึงเกิดขึ้น เพื่อระดมทรัพยากร สู่การสนับสนุน
              พัฒนาเด็กและเยาวชนแบบองค์รวม <br />
              เพื่อให้เด็กและเยาวชนได้รับ
              <span>
                <Typography color={'primary'} variant='h2' display='inline'>
                  {' '}
                  “โอกาสที่ดี”{' '}
                </Typography>
              </span>
              และ
              <span>
                <Typography color={'primary'} variant='h2' display='inline'>
                  {' '}
                  “มีช่วงเวลาที่ดีในชีวิต”
                </Typography>
              </span>
            </Typography>
          </Stack>

          <Stack width={'100%'} textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography variant='h3' paddingBottom={'10px'}>
              ตัวอย่างโครงการต่าง ๆ ที่มูลนิธิเคยให้ความช่วยเหลือในช่วง 2
              ปีที่ผ่านมา
            </Typography>

            <TableContainer
              sx={{ borderRadius: '8px', border: 1, borderColor: '#C7C7C7' }}
            >
              <Table size='small'>
                <TableHead sx={{ paddingX: '40px' }}>
                  <TableRow>
                    <TableCell align='right' width={'60%'}></TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        จำนวนโครงการ
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        จำนวนผู้รับประโยชน์
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      backgroundColor: '#cadeff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          ช่วยเหลือกรณีภัยพิบัติ
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                ถุงยังชีพสำหรับครอบครัวที่ได้รับผลกระทบจากโควิด
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                ช่วยเหลือเด็กอพยพกรณีความไม่สงบบริเวณชายแดนไทยพม่า
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        12
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        25,727
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#e7f0ff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          การศึกษา
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                ทุนการศึกษาให้เยาวชนได้เรียนต่อระดับอุดมศึกษา
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        2
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        259
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#cadeff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          สุขภาพ
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                จัดหาอาหารเสริมโภชนาการสำหรับเด็กที่ขาดสารอาหารเป็นระยะเวลา
                                12 เดือน
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        2
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        2,911
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#e7f0ff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          การสร้างรายได้
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        6
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        2,686
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#cadeff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          สาธารณูปโภค
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                สร้างอาคารกิจกรรม
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                สร้างศูนย์ฝึกอาชีพ
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                ติดตั้งโซลาร์เซล
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        6
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        5,696
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#e7f0ff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          การศึกษานอกระบบ
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                สร้างสนามเด็กเล่น
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                สนับสนุนอุปกรณ์มีเดียให้เยาวชน
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        3
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        4,099
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: '#cadeff',
                      borderBottom: '2px solid white',
                    }}
                  >
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          การให้ความรู้ผู้ปกครอง
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                อบรมวินัยเชิงบวกให้พ่อแม่ที่มีความเครียดจากโควิด
                                ป้องกันความรุนแรงในครอบครัว
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        1
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        206
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: '#e7f0ff' }}>
                    <TableCell
                      align='left'
                      width={'60%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Stack>
                        <Typography color={'primary'} variant='h5'>
                          น้ำเพื่อการอุปโภคและบริโภค
                        </Typography>
                        <Stack>
                          <List
                            sx={{
                              listStyleType: 'disc',
                              listStylePosition: 'inside',
                            }}
                            disablePadding
                          >
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                ปรับปรุงระบบน้ำสะอาด
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                จัดซื้อเครื่องกรองน้ำ
                              </Typography>
                            </ListItem>
                            <ListItem
                              sx={{ display: 'list-item' }}
                              disablePadding
                            >
                              <Typography variant='h5' display={'inline'}>
                                สร้างส้วมที่ถูกสุขลักษณะ
                              </Typography>
                            </ListItem>
                          </List>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align='right'
                      width={'20%'}
                      sx={{ borderRight: '2px solid white' }}
                    >
                      <Typography variant='h5' textAlign={'center'}>
                        21
                      </Typography>
                    </TableCell>
                    <TableCell align='right' width={'20%'}>
                      <Typography variant='h5' textAlign={'center'}>
                        14,958
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>

          <Stack justifyContent={'center'} alignItems={'center'}>
            <Box
              component='img'
              loading='lazy'
              draggable={false}
              alt='1'
              width={{ sx: '100%', sm: '600px', md: '800px' }}
              src={about_report64}
            />
          </Stack>

          <Stack width={'100%'} textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography variant='h2'> การดำเนินงาน น้ำใจโปรเจ็ค </Typography>
            <Typography variant='h5'>
              น้ำใจ ดำเนินงานภายใต้มูลนิธิดรุณาทร <br />
              ร่วมกับคริสตจักร องค์กร
              อาสาสมัครเพื่อนำเสนอโครงการที่จะช่วยตอบสนองต่อ
              <br />
              ความจำเป็นสำหรับเด็ก ๆ ที่กำลังเผชิญความยากลำบาก รวมทั้ง
              สร้างโอกาสที่ดีเพื่อการพัฒนาชีวิต ให้แก่เด็ก ๆ
            </Typography>
            <Stack
              flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
              paddingY={'40px'}
            >
              <Stack
                width={{ xs: '100%', sm: '50%', md: '50%' }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <People color={'#FBFDFF'} height={'100px'} width={'250px'} />
                <Typography variant='h6' paddingTop={'16px'}>
                  {' '}
                  น้ำใจอีเวนต์{' '}
                </Typography>
                <Typography variant='h6'>
                  {' '}
                  ออกพบปะชุมชนเพื่อส่งมอบน้ำใจ{' '}
                </Typography>
              </Stack>
              <Divider
                orientation='vertical'
                variant='middle'
                flexItem
                sx={{ borderRightWidth: 2 }}
              />

              <Divider sx={{ borderRightWidth: 2 }} />
              <Stack
                width={{ xs: '100%', sm: '50%', md: '50%' }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Logo color={'#FBFDFF'} height={'100px'} width={'250px'} />
                <Typography variant='h6' paddingTop={'16px'}>
                  {' '}
                  น้ำใจเว็บไซต์{' '}
                </Typography>
                <Link to={`/`}>
                  <Typography
                    variant='h6'
                    sx={{ cursor: 'pointer' }}
                    color={'primary'}
                  >
                    {' '}
                    namjaisociety.org{' '}
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          </Stack>

          <Stack width={'100%'} textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography variant='h5'>
              คริสตจักรที่สนใจ สามารถเตรียมโครงการ (proposal) นำเสนอเข้ามายัง
              <span>
                <Typography color={'primary'} variant='h5' display='inline'>
                  {' '}
                  “น้ำใจ”{' '}
                </Typography>
              </span>
              ได้
            </Typography>

            {!breakPointMobile ? (
              <>
                <Box
                  sx={{
                    position: 'relative',
                    background: ' rgba(0, 121, 255, 0.8)',

                    borderRadius: '16px',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    backgroundImage: `url(${slide2})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: `center center`,
                    display: 'block',
                    overflow: 'hidden',
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      background: ' rgba(0, 121, 255, 0.7)',
                      padding: '32px',
                      borderRadius: '5px',
                      display: 'flex',
                      gap: '8px',

                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant='h5' color={'#ffffff'}>
                      ร่วมกันเป็นส่วนหนึ่งของ “โอกาสที่ดี” และ
                      “ช่วงเวลาที่ดีในชีวิต” ของเด็ก ๆ
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Link to={`/campaign-request`}>
                        <Box
                          sx={{
                            borderRadius: '5px',
                            bgcolor: '#ffffff',
                            paddingX: '16px',
                            cursor: 'pointer',
                            display: 'flex',
                          }}
                        >
                          <Typography variant='h5' color={'primary'}>
                            สร้างโครงการของคุณ
                          </Typography>
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : !breakPoint ? (
              <>
                <Box
                  sx={{
                    width: '100%',
                    height: '25vh',
                    overflow: 'hidden',
                    borderRadius: '16px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                    position: 'relative',
                    right: 0,
                    top: 0,
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      textAlign: 'right',
                      overflow: 'hidden',
                      display: 'flex,',
                      justifyContent: 'right',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      component='img'
                      loading='lazy'
                      draggable={false}
                      alt='1'
                      sx={{
                        overflow: 'hidden',
                        minHeight: '100%',
                        float: 'right',
                        maxWidth: '50%',
                      }}
                      src={slide3}
                    />
                  </Box>

                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      background:
                        'linear-gradient(90deg, #0079FF 50%, rgba(0, 121, 255, 0) 90%)',
                      display: 'inline-flex',
                      padding: '32px',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      zIndex: 1,
                      width: '100%',
                      height: '100%',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <Typography variant='h5' color={'#ffffff'}>
                      ร่วมกันเป็นส่วนหนึ่งของ “โอกาสที่ดี”
                      <br /> และ “ช่วงเวลาที่ดีในชีวิต” ของเด็ก ๆ
                    </Typography>
                    <Link to={`/campaign-request`}>
                      <Box
                        sx={{
                          borderRadius: '5px',
                          bgcolor: '#ffffff',
                          paddingX: '16px',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h5' color={'primary'}>
                          สร้างโครงการของคุณ
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '16px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      overflow: 'hidden',
                      display: 'flex,',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      component='img'
                      loading='lazy'
                      draggable={false}
                      alt='1'
                      sx={{
                        overflow: 'hidden',
                        minHeight: '100%',
                      }}
                      src={about_banner}
                    />
                  </Box>

                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      background:
                        'linear-gradient(90deg, #0079FF 30%, rgba(0, 121, 255, 0) 80%)',
                      display: 'inline-flex',
                      padding: '32px',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      zIndex: 1,
                      width: '100%',
                      height: '100%',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <Typography variant='h5' color={'#ffffff'}>
                      ร่วมกันเป็นส่วนหนึ่งของ “โอกาสที่ดี” และ
                      <br /> “ช่วงเวลาที่ดีในชีวิต” ของเด็ก ๆ
                    </Typography>

                    <Link to={`/campaign-request`}>
                      <Box
                        sx={{
                          borderRadius: '5px',
                          bgcolor: '#ffffff',
                          paddingX: '16px',
                          cursor: 'pointer',
                        }}
                      >
                        <Typography variant='h5' color={'primary'}>
                          สร้างโครงการของคุณ
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                </Box>
              </>
            )}
          </Stack>

          <div
            style={{ width: '50%', height: 0, border: '2px #0079FF solid' }}
          ></div>

          <Stack
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <Typography
              variant='h5'
              justifyContent={'center'}
              alignItems={'center'}
            >
              {' '}
              <span>
                <Typography color={'primary'} variant='h2' display='inline'>
                  {' '}
                  “น้ำใจ”{' '}
                </Typography>
              </span>{' '}
              มีจุดมุ่งหมายที่จะทำหน้าที่เป็นสะพานเชื่อมระหว่าง{' '}
            </Typography>
            <Typography
              variant='h5'
              justifyContent={'center'}
              alignItems={'center'}
            >
              {' '}
              <span>
                <Typography color={'primary'} variant='h2' display='inline'>
                  ผู้ให้น้ำใจ (ผู้บริจาค)
                </Typography>
              </span>{' '}
              และ{' '}
              <Typography color={'primary'} variant='h2' display='inline'>
                ผู้รับน้ำใจ (ผู้รับบริจาค)
              </Typography>
            </Typography>
          </Stack>

          <div
            style={{ width: '50%', height: 0, border: '2px #0079FF solid' }}
          ></div>

          <Stack width={'100%'} textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography variant='h2'>
              กลุ่มเป้าหมายผู้รับน้ำใจ คือ เด็กและเยาวชน
            </Typography>
            <Typography variant='h5'>
              เด็กและเยาวชนของน้ำใจ คือ บุคคลอายุตั้งแต่ 0-21 ปี
            </Typography>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              sx={{ position: 'relative' }}
            >
              <Stack
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  left: '5px',
                  top: '50%',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}
              >
                <ArrowBackIosIcon onClick={showPreviousSet} />
              </Stack>
              {imagePaths
                .slice(currentImageIndex, currentImageIndex + numberImage)
                .map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Slide ${currentImageIndex + index + 1}`}
                    style={{
                      maxWidth: `${99 / numberImage}%`,
                      overflow: 'hidden',
                    }}
                  />
                ))}

              <Stack
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: '5px',
                  top: '50%',
                  color: '#ffffff',
                  cursor: 'pointer',
                }}
              >
                <ArrowForwardIosIcon onClick={showNextSet} />
              </Stack>
            </Stack>
          </Stack>

          <Stack gap={'24px'} textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography variant='h2'>รู้จักมูลนิธิดรุณาทร</Typography>
            <Stack
              gap={'30px'}
              flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
            >
              <Stack
                width={{ xs: '100%', sm: '100%', md: '40%' }}
                flexDirection={'column'}
                textAlign={'center'}
                justifyContent={'space-around'}
                gap={'4px'}
              >
                <Box
                  component='img'
                  loading='lazy'
                  draggable={false}
                  alt='1'
                  sx={{
                    width: '100%', // Ensure it doesn't exceed the container width
                  }}
                  src={compassion}
                />{' '}
                <Link to={'http://www.compassionth.com'}>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      fontFamily: 'Mitr',
                      fontWeight: '400',
                      textDecoration: 'underline',
                      lineHeight: '32px',
                      color: '#005EB8',
                    }}
                  >
                    www.compassionth.com
                  </Typography>
                </Link>
              </Stack>
              <Stack width={{ xs: '100%', sm: '100%', md: '60%' }}>
                <Typography variant='h5'>
                  <span>
                    <Typography color={'#005EB8'} variant='h5' display='inline'>
                      มูลนิธิดรุณาทร
                    </Typography>
                  </span>{' '}
                  หรือ{' '}
                  <span>
                    <Typography color={'#005EB8'} variant='h5' display='inline'>
                      คอมแพสชั่น ประเทศไทย
                    </Typography>
                  </span>{' '}
                  <br /> เป็นองค์กรคริสเตียนไม่แสวงหากำไร
                  ดำเนินงานในประเทศไทยมาเป็นเวลา กว่า 50 ปี
                  จดทะเบียนเป็นมูลนิธิเมื่อวันที่ 9 พฤศจิกายน พ.ศ.2544
                  ใช้ชื่อว่า “มูลนิธิดรุณาทร” และ ได้รับการรับรองเป็นองค์กร
                  สาธารณประโยชน์เมื่อวันที่ 3 มกราคม พ.ศ.2557
                  ทำงานด้านการพัฒนาเด็กและเยาวชนแบบองค์รวม
                  ร่วมกับคริสตจักรท้องถิ่น และเครือข่ายการทำงานจากภาคส่วนต่าง ๆ
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            width={'100%'}
            gap={'32px'}
            textAlign={{ xs: 'center', sm: 'left' }}
          >
            <Typography variant='h2' textAlign={'center'}>
              สแกน QR Code เพิ่มน้ำใจเป็นเพื่อน
              <br />
              เพื่อติดตามข่าวสารการดำเนินงานของน้ำใจ
            </Typography>
            <Stack
              flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
              justifyContent={'center'}
              alignItems={'center'}
              gap={'32px'}
            >
              <Box
                component='img'
                loading='lazy'
                draggable={false}
                alt='1'
                sx={{
                  width: '300px',
                  height: '300px',
                }}
                src={lineQR}
              />
              <Stack flexDirection={'column'} gap={'16px'}>
                <Typography variant='h5'>
                  ติดต่อเรา
                  <br />
                  <br />
                  ที่อยู่: มูลนิธิดรุณาทร 57/7 ถ.ทุ่งโฮเต็ล ซอย 3/1 <br />
                  ต.วัดเกต อ.เมือง จ.เชียงใหม่ 50000 โทร 266426
                </Typography>
                <Typography variant='h5'>
                  เบอร์โทรศัพท์:
                  <Link to='tel:053266426'> 053 266 426 - 9</Link>
                </Typography>
                <Typography variant='h5'>
                  E-mail:{' '}
                  <Link
                    to='mailto:cithpr@gmail.com'
                    onClick={(e) => {
                      window.location.href = 'mailto:cithpr@gmail.com'
                      e.preventDefault()
                    }}
                  >
                    cithpr@gmail.com
                  </Link>
                </Typography>
                <Typography variant='h5'>Line: @Compassionth</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Footer></Footer>
    </ThemeProvider>
  )
}

export default AboutNamjai
