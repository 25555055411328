import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { theme } from '../../constants/theme'
import { Close, DeleteOutlineRounded, LogoutRounded } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { createContext, useEffect, useState } from 'react'
import NoRecentCampaigns from './NoRecentCampaigns'
import ChurchManagementProfile from '../../components/ChurchManagementProfile'
import ModalWithButton from '../../components/Modal/ModalWithButton'
import NormalModal from '../../components/Modal/NormalModal'
import ChurchMemberManagement from './ChurchMemberManagement'
import ModalDeleteWithConfirm from '../../components/Modal/ModalDeleteWithConfirm'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import ChurchCampaigns from './ChurchCampaigns'
import { useGet, usePatch, usePost } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import axios from 'axios'
import { ChurchMgmtDetail } from '../../models/ChurchDetail'
import { useUser } from '../../services/UserContext'
import PermissionDeny from '../PermissionDeny'
import ModalWithSearch from '../../components/Modal/ModalWithSearch'
import { ROUTE } from '../../constants/route-constant'
import ChurchConfirmEarning from '../ChurchManagement/ChurchConfirmEarning'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const ChurchMemberManagementContext = createContext<any>([])

// QUESTION: What method do we got this Church's info -> Should get with id
export default function ChurchManagement() {
  const API_URL = process.env.REACT_APP_BASE_API
  const API_KEY = process.env.REACT_APP_XKEY_API ?? ''
  const token = AuthService.getTokenUser()
  const userContext = useUser()
  const params = useParams()
  const navigate = useNavigate()
  const churchID = params.churchId

  const [toPostMemberData, setToPostMemberData] = useState<object>()

  // patchUpdateChurchMember,loadingPatchUpdateChurchMember,sendUpdate,statusCode
  const [churchMgmtDetail, loadingChurchMgmtDetail, sendUpdate, statusCode] =
    useGet<ChurchMgmtDetail>({
      url: `/churches/${churchID}/management`,
      ...(token && { jwtToken: token }),
      autoFetch: true,
    })

  useEffect(() => {
    if (statusCode === 406) {
      navigate(ROUTE.PERMISSION_DENIED)
    }
  }, [statusCode])

  const [
    patchUpdateChurchMember,
    loadingPatchUpdateChurchMember,
    sendMemberUpdate,
    memberUpdateStatusCode,
  ] = usePatch({
    url: `/churches/${churchID}/members/update`,
    ...(token && { jwtToken: token }),
    autoFetch: false,
    data: toPostMemberData,
  })

  useEffect(() => {
    console.log(memberUpdateStatusCode)
    if (memberUpdateStatusCode === 200) {
      sendUpdate()
    }
  }, [memberUpdateStatusCode])

  // control tab
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // control Modals
  const [modalTitle, setModalTitle] = useState<string>('')
  const [modalDescription, setModalDescription] = useState<React.ReactNode>('')
  const [modalConfirmText, setModalConfirmText] = useState<string>('')
  const [modalCancelText, setModalCancelText] = useState<string>('')

  const [openInviteMemberModal, setOpenInviteMemberModal] =
    useState<boolean>(false)
  const [openSuccessInviteMemberModal, setOpenSuccessInviteMemberModal] =
    useState<boolean>(false)
  const [openCantInviteMemberModal, setOpenCantInviteMemberModal] =
    useState<boolean>(false)
  const [openDeleteChurchModal, setOpenDeleteChurchModal] =
    useState<boolean>(false)
  const [openLeaveChurchModal, setOpenLeaveChurchModal] =
    useState<boolean>(false)
  const [openCannotLeaveChurchModal, setOpenCannotLeaveChurchModal] =
    useState<boolean>(false)

  const [openErrorSnackbar, setOpenErrorSnackbar] = useState<boolean>(false)
  const [errorSnackbarText, setOpenErrorSnackbarText] = useState<string>(
    'เกิดข้อผิดพลาด กรุณาลองอีกครั้งในภายหลัง'
  )
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState<boolean>(false)
  const [successSnackbarText, setOpenSuccessSnackbarText] = useState<string>(
    'ข้อมูลของคุณถูกบันทึกแล้ว'
  )
  const [emails, setEmails] = useState<string[]>([])
  const [toPostData, setToPostData] = useState<object>({})

  function handleClickInvite() {
    if (churchMgmtDetail?.members! >= 10) {
      setModalTitle('ไม่สามารถส่งคำเชิญเพิ่มเจ้าหน้าที่คริสตจักร')
      setModalDescription(
        'เนื่องจากระบบได้กำหนดให้มีเจ้าหน้าที่คริสตจักรได้แค่ 10 คนเท่านั้น'
      )
      setOpenCantInviteMemberModal(true)
    } else {
      setModalTitle('ส่งคำเชิญเพิ่มเจ้าหน้าที่คริสตจักร')
      setOpenInviteMemberModal(true)
    }
  }

  const handleClickConfirmInvite = async () => {
    try {
      // await sendInvite()
      const response = await axios.post(
        `${API_URL}/churches/${churchID}/invitation`,
        { emails: emails },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-API-KEY': `${API_KEY}`,
          },
        }
      )
      setOpenInviteMemberModal(false)
      setModalTitle('เชิญเจ้าหน้าที่คริสตจักรเพิ่มเติมสำเร็จ')
      setModalDescription(`คำเชิญได้ถูกส่งไปที่ ${emails} แล้ว`)
      setOpenSuccessInviteMemberModal(true)
    } catch (error) {
      console.log('error', error)
      setOpenErrorSnackbar(true)
    }
  }

  function handleClickEdit() {
    navigate(`/churches/${churchID}/edit`)
  }

  function handleClickDeleteChurch() {
    setModalTitle('แจ้งเตือนการลบข้อมูลคริสตจักร')
    setModalDescription(
      'คุณแน่ใจหรือไม่ว่า ต้องการลบข้อมูลคริสตจักร? การกระทำนี้ไม่สามารถย้อนกลับได้ และส่งผลให้ไม่สามารถเข้าถึงข้อมูลต่าง ๆ ของคริสตจักรได้อีก'
    )
    setModalConfirmText('ฉันเข้าใจและยืนยัน')
    setModalCancelText('ยกเลิก')
    setOpenDeleteChurchModal(true)
  }

  const [
    proposeDeleteChurch,
    proposingDeleteChurch,
    sendDeleteChurchProposal,
    deleteChurchProposalStatusCode,
  ] = usePost<any>({
    url: `/churches/${churchID!}/propose-delete`,
    autoFetch: false,
    jwtToken: AuthService.getTokenUser() ?? '',
    data: {},
  })

  const handleClickConfirmDeleteChurch = () => {
    sendDeleteChurchProposal()
  }

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay))
  }

  useEffect(() => {
    if (deleteChurchProposalStatusCode === -1) {
      return
    } else if (deleteChurchProposalStatusCode === 200) {
      setOpenSuccessSnackbar(true)
      setOpenDeleteChurchModal(false)
    } else if (deleteChurchProposalStatusCode === 409) {
      setOpenErrorSnackbarText(
        'แอดมินกำลังพิจารณาคำร้องขอนี้ ไม่สามารถส่งคำร้องขอได้อีก'
      )
      setOpenErrorSnackbar(true)
      setOpenDeleteChurchModal(false)
    } else {
      setOpenErrorSnackbar(true)
      setOpenDeleteChurchModal(false)
    }
  }, [
    sendDeleteChurchProposal,
    proposingDeleteChurch,
    deleteChurchProposalStatusCode,
  ])

  function handleClickLeaveChurch() {
    if (churchMgmtDetail?.members! > 1) {
      setModalTitle('แจ้งเตือนการออกจากการเป็นเจ้าหน้าที่')
      setModalDescription(
        'คุณแน่ใจหรือไม่ว่าจะออกจากการเป็นเจ้าหน้าที่? การกระทำนี้ไม่สามารถย้อนกลับได้ และส่งผลให้ไม่สามารถเข้าถึงข้อมูลต่าง ๆ ได้'
      )
      setModalConfirmText('เข้าใจและยืนยัน')
      setModalCancelText('ยกเลิก')
      setOpenLeaveChurchModal(true)
    } else {
      setModalTitle('ไม่สามารถดำเนินการออกจากการเป็นเจ้าหน้าที่')
      setModalDescription(
        <Box>
          <Typography variant='h6'>
            เนื่องจากคุณเป็นตัวแทนหลักคนเดียวของคริสตจักร
            ทำให้ไม่สามารถดำเนินการออกจากการเป็นเจ้าหน้าที่
            โดยคุณสามารถดำเนินการดังต่อไปนี้
          </Typography>
          <Typography variant='h6'>
            1 {')'} เชิญเจ้าหน้าที่คริสตจักรท่านอื่น
            เข้ามาในระบบแล้วมอบหมายหน้าที่แทน
          </Typography>
          <Typography variant='h6'>
            2{')'} ติดต่อแอดมินของน้ำใจ ที่ {'('}email{')'} เพื่อขอคำแนะนำ'
          </Typography>
        </Box>
      )
      setOpenCannotLeaveChurchModal(true)
    }
  }

  const handleClickConfirmLeaveChurch = async () => {
    try {
      const response = await axios.delete(`${API_URL}/churches/leave`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-API-KEY': `${API_KEY}`,
        },
      })

      if (response.status === 200) {
        setOpenLeaveChurchModal(false)
        setOpenSuccessSnackbar(true)
        navigate('/')
      } else {
        setOpenErrorSnackbar(true)
      }
    } catch (error) {
      // Handle errors (e.g., display an error message)
      console.error('Error fetching item:', error)
    }
  }

  function checkIfNotContactPerson() {
    // NOT PASTOR OR NOT MCP
    if (churchMgmtDetail?.mainContactPerson) {
      return (
        userContext.userData?.userdetail.email ===
          churchMgmtDetail?.mainContactPerson.email ||
        userContext.userData?.userdetail.email ===
          churchMgmtDetail?.mainContactPerson.email
      )
    } else {
      return (
        userContext.userData?.userdetail.email ===
        churchMgmtDetail?.mainContactPerson.email
      )
    }
  }

  function checkIfNotPastor() {
    return !(
      userContext.userData?.userdetail.email ===
      churchMgmtDetail?.mainContactPerson.email
    )
  }

  // TODO: add loading screen
  return (
    <>
      {loadingChurchMgmtDetail || loadingPatchUpdateChurchMember ? (
        <>
          <Navbar />
          <Box
            display={'flex'}
            justifyContent={'center'}
            padding={'10vw'}
            alignItems={'center'}
            height={'70vh'}
          >
            <CircularProgress />
          </Box>
          <Footer />
        </>
      ) : (
        <ChurchMemberManagementContext.Provider
          value={[
            emails,
            setEmails,
            checkIfNotContactPerson,
            toPostData,
            setToPostData,
            churchID,
            setOpenErrorSnackbar,
            setOpenSuccessSnackbar,
            setOpenInviteMemberModal,
          ]}
        >
          {statusCode !== 200 ? (
            <PermissionDeny />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
              }}
            >
              <Navbar />

              <Container>
                {/*church information */}
                <Box height={'24px'} />
                <ChurchManagementProfile
                  id={churchID!}
                  name={churchMgmtDetail?.churchName!}
                  totalFund={churchMgmtDetail?.fund}
                  totalMember={churchMgmtDetail?.members}
                  totalProject={churchMgmtDetail?.projects}
                  handleClickInvite={handleClickInvite}
                  handleClickEdit={handleClickEdit}
                />

                {/* church campaigns & member */}
                <Tabs
                  textColor='primary'
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                  }}
                >
                  <Tab label='โครงการ' {...a11yProps(0)} />
                  <Tab label='เจ้าหน้าที่คริสตจักร' {...a11yProps(1)} />
                  <Tab label='ตรวจสอบยอดเงิน' {...a11yProps(2)} />
                </Tabs>

                <CustomTabPanel value={value} index={0}>
                  {churchMgmtDetail?.projects! > 0 ? (
                    <ChurchCampaigns
                      key={churchID! + 'church-mgmt-campaigns'}
                      id={churchID!}
                      campaignList={churchMgmtDetail?.campaign!}
                    />
                  ) : (
                    <NoRecentCampaigns />
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <ChurchMemberManagement
                    key={churchID! + 'church-mgmt'}
                    churchID={churchID!}
                    pastor={churchMgmtDetail?.mainContactPerson!}
                    mainContactPerson={churchMgmtDetail?.mainContactPerson!}
                    contactPerson={
                      churchMgmtDetail ? churchMgmtDetail.contactPerson : []
                    }
                    refreshMemberMgmt={sendUpdate}
                    toPostData={toPostMemberData}
                    setToPostData={setToPostMemberData}
                    loadingPatchUpdateChurchMember={
                      loadingPatchUpdateChurchMember
                    }
                    sendMemberUpdate={sendMemberUpdate}
                    memberUpdateStatusCode={memberUpdateStatusCode}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <ChurchConfirmEarning churchID={churchID ?? ''} />
                </CustomTabPanel>

                <Box height={'16px'} />
                <Box display={'flex'} sx={{ gap: theme.spacing(3) }}>
                  {checkIfNotContactPerson() && (
                    <Button
                      color='error'
                      variant='outlined'
                      endIcon={<DeleteOutlineRounded />}
                      onClick={handleClickDeleteChurch}
                    >
                      ลบคริสตจักรออกจากระบบ
                    </Button>
                  )}

                  {checkIfNotPastor() && (
                    <Button
                      sx={{ color: grey[600] }}
                      color='inherit'
                      variant='outlined'
                      endIcon={<LogoutRounded />}
                      onClick={handleClickLeaveChurch}
                      disabled={churchMgmtDetail?.members! > 1 ? false : true}
                    >
                      ออกจากการเป็นเจ้าหน้าที่คริสตจักร
                    </Button>
                  )}
                </Box>
                <Box height={'24px'} />
              </Container>

              <Footer />
            </div>
          )}
          <ModalWithSearch
            id='church-mgmt-invite-member-church'
            title={modalTitle}
            confirmText='เชิญ'
            open={openInviteMemberModal}
            onClickConfirm={handleClickConfirmInvite}
            onClose={() => {
              setOpenInviteMemberModal(false)
              setEmails([])
            }}
          />

          {/** CAN'T INVITE MEMBER CHURCH MODAL */}
          <NormalModal
            id='church-mgmt-invite-member-church-success'
            type='DEFAULT'
            title={modalTitle}
            description={modalDescription}
            open={openSuccessInviteMemberModal}
            onClose={() => setOpenSuccessInviteMemberModal(false)}
          />

          {/** CAN'T INVITE MEMBER CHURCH MODAL */}
          <NormalModal
            id='church-mgmt-cant-invite-member-church'
            type='ERROR'
            title={modalTitle}
            description={modalDescription}
            open={openCantInviteMemberModal}
            onClose={() => setOpenCantInviteMemberModal(false)}
          />

          {/** DELETE CHURCH MODAL */}
          <ModalDeleteWithConfirm
            id='church-mgmt-delete-church'
            type='ERROR'
            confirmText={churchMgmtDetail?.churchName!}
            title={modalTitle}
            description={modalDescription}
            confirmBtnText={modalConfirmText}
            cancleBtnText={modalCancelText}
            open={openDeleteChurchModal}
            onClose={() => setOpenDeleteChurchModal(false)}
            handleClickConfirm={handleClickConfirmDeleteChurch}
          />

          {/** LEAVE CHURCH MODAL */}
          <ModalWithButton
            id='church-mgmt-leave-church'
            type='ERROR'
            title={modalTitle}
            description={modalDescription}
            confirmBtnText={modalConfirmText}
            cancleBtnText={modalCancelText}
            open={openLeaveChurchModal}
            onClose={() => setOpenLeaveChurchModal(false)}
            handleClickConfirm={handleClickConfirmLeaveChurch}
          />

          {/** CAN'T LEAVE CHURCH MODAL */}
          <NormalModal
            id='church-mgmt-cant-leave-church'
            type='ERROR'
            title={modalTitle}
            description={modalDescription}
            open={openCannotLeaveChurchModal}
            onClose={() => setOpenCannotLeaveChurchModal(false)}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={openSuccessSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSuccessSnackbar(false)}
            sx={{ alignItems: 'center' }}
          >
            <Alert severity='success'>
              {successSnackbarText}
              <IconButton
                aria-label='close'
                color='inherit'
                sx={{ p: 0.5, alignItems: 'center' }}
                onClick={() => setOpenSuccessSnackbar(false)}
              >
                <Close />
              </IconButton>
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={openErrorSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenErrorSnackbar(false)}
            sx={{ alignItems: 'center' }}
          >
            <Alert severity='error'>
              {errorSnackbarText}
              <IconButton
                aria-label='close'
                color='inherit'
                sx={{ p: 0.5, alignItems: 'center' }}
                onClick={() => setOpenErrorSnackbar(false)}
              >
                <Close />
              </IconButton>
            </Alert>
          </Snackbar>

          <Box height='24px' />
        </ChurchMemberManagementContext.Provider>
      )}
    </>
  )
}
