import {
  Container,
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  LinearProgress,
} from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ChurchProfileCard from '../components/ChurchProfileCard'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import SearchChurch from '../components/SearchChurch'
import { ROUTE } from '../constants/route-constant'
import { theme } from '../constants/theme'
import { Favorite } from '@mui/icons-material'
import { COLOR } from '../constants/color-constant'
import AuthService from '../services/auth.service'
import { UserContext } from '../services/UserContext'

export default function ThankyouPage() {
  const user = useContext(UserContext)
  const navigate = useNavigate()

  function wait(ms: number) {
    var start = new Date().getTime()
    var end = start
    while (end < start + ms) {
      end = new Date().getTime()
    }
  }

  useEffect(() => {
    setTimeout(() => navigate(ROUTE.ALL_CAMPAIGN), 5000)
  }, [])

  return (
    <>
      <div className='flex min-h-screen flex-col justify-between'>
        <Navbar />
        <Container>
          <Box
            display={'flex'}
            padding={'16px'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            textAlign={'center'}
            gap={'8px'}
          >
            <Favorite
              sx={{ width: '100px', height: '100px', color: COLOR.PRIMARY_4 }}
            />
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
            >
              <Typography variant='h1'>ขอบคุณสำหรับ</Typography>
              <Typography variant='h1' color='primary'>
                “น้ำใจ”
              </Typography>
              <Typography variant='h1'>ของท่าน</Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'center'}
            >
              <Typography variant='h6'>เราได้ส่งจดหมายขอบคุณไปทาง</Typography>
              <Typography variant='h6' color={'primary'}>
                {user?.userData?.userdetail.email}
              </Typography>
              <Typography variant='h6'>เราหวังว่าจะได้เจอคุณอีก!</Typography>
            </Box>

            <Box paddingTop={'40px'} gap={'24px'}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => navigate(ROUTE.ALL_CAMPAIGN)}
              >
                <Typography variant='h5' color={'white'}>
                  ดูโครงการอื่น ๆ ต่อ
                </Typography>
              </Button>
              <LinearProgress />
            </Box>
          </Box>
        </Container>

        <Footer />
      </div>
    </>
  )
}
