import { useEffect, useState } from 'react'
import { Stack, Typography } from '@mui/material'

import SelectBankAccount, { sameBank } from './SelectBankAccount'
import CreateBankAccount from './CreateBankAccount'
import { BankAccount } from '../../models/campaign'
import { AttachmentMetaData } from '../../models/AttachmentMetaData'

interface ExistingBankAccountFormProps {
  viewOnly: boolean
  qr?: AttachmentMetaData
  onQrChange: (qr: AttachmentMetaData) => void
  setFileMissing: (missing: boolean) => void
  fileMissing: boolean
  fileRef: any
  adminView: boolean
  bankAccounts: BankAccount[]
  bankAccount: BankAccount
  handleBankChange: (bankAccount: BankAccount) => void
}

export default function ExistingBankAccountForm({
  viewOnly,
  onQrChange,
  qr,
  setFileMissing,
  fileMissing,
  fileRef,
  bankAccounts,
  bankAccount,
  handleBankChange,
}: ExistingBankAccountFormProps) {
  const [isNewBank, setIsNewBank] = useState(false)
  const [newBank, setNewBank] = useState<BankAccount>({
    id: '',
    bankName: '',
    accountName: '',
    accountNumber: '',
    qrCodeUrl: '',
  })

  const handleCustomBankChange = (custom: BankAccount) => {
    setNewBank(custom)
    handleBankChange(custom)
  }

  useEffect(() => {
    const foundAccount = bankAccounts.find((bank) =>
      sameBank(bank, bankAccount)
    )
    if (!foundAccount) {
      setNewBank(bankAccount)
      setIsNewBank(true)
    }
  }, [])

  useEffect(() => {
    if (isNewBank) {
      handleBankChange(newBank)
    }
  }, [isNewBank, newBank])

  return (
    <Stack
      gap='24px'
      minHeight='220px'
      flexDirection={'row'}
      flexWrap={'wrap'}
      width={'100%'}
    >
      {/* Select Existing Bank Account */}
      <div className='flex-1'>
        <SelectBankAccount
          viewOnly={viewOnly}
          bankAccounts={bankAccounts}
          current={bankAccount}
          setCurrent={(existingBank) => {
            setIsNewBank(false)
            handleBankChange(existingBank)
          }}
        />
      </div>
      {/* Create New Bank Account */}
      <Stack gap='10px' flex={'1'}>
        <Typography variant='h5'>
          หรือ สร้างบัญชีธนาคารใหม่สำหรับโครงการนี้
        </Typography>
        <button
          disabled={viewOnly}
          type='button'
          onClick={(e) => {
            setIsNewBank(true)
          }}
          className={`rounded-[12px] border-[1px] p-[16px]
            shadow-[2px_2px_4px_0px_rgba(33,33,33,0.10)]
            focus:border-[#0079FF] focus:outline-none
          ${
            isNewBank
              ? 'border-[#0079FF] bg-[#f5faff]'
              : 'hover:border-[#0079FF] hover:bg-white'
          }`}
        >
          <CreateBankAccount
            viewOnly={viewOnly}
            onQrChange={onQrChange}
            qr={qr}
            setFileMissing={setFileMissing}
            fileMissing={fileMissing}
            fileRef={fileRef}
            bankAccount={newBank}
            handleBankChange={handleCustomBankChange}
          />
        </button>
      </Stack>
    </Stack>
  )
}
