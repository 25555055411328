import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Container } from '@mui/material'

import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import { Link } from 'react-router-dom'
import { ROUTE } from '../constants/route-constant'

export default function AccordionExpandDefault() {
  return (
    <>
      <div className='flex min-h-screen flex-col justify-between'>
        <div className='flex flex-col'>
          <Navbar />
          <Box
            display={'flex'}
            padding={'40px'}
            flexDirection={'column'}
            alignItems={'center'}
            textAlign={'center'}
            gap={'8px'}
            bgcolor={'#EFFBFF'}
          >
            <Typography variant='h1'>คำถามที่พบบ่อย (FAQ)</Typography>
          </Box>
        </div>
        <Container sx={{ backgroundColor: '#FFFFFF' }}>
          <div className='py-[24px]'>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel1}-content`}
                id={`panel1-header`}
              >
                <Typography variant='h5' color='primary'>
                  "น้ำใจ" คืออะไร?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  น้ำใจเป็นเว็บไซต์ระดมทุนที่เกิดขึ้นเพื่อระดมทรัพยากร
                  สู่การสนับสนุน พัฒนาเด็กและเยาวชนแบบองค์รวม
                  เพื่อให้เด็กและเยาวชนได้รับโอกาสที่ดีและมีช่วงเวลาที่ดีในชีวิต
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel2-content`}
                id={`panel2-header`}
              >
                <Typography variant='h5' color='primary'>
                  "น้ำใจ" ดำเนินการโดยใคร?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  น้ำใจ ดำเนินการโดย{' '}
                  <Link
                    to={'http://www.compassionth.com/'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='text-[#005EB8] underline'>
                      มูลนิธิดรุณาทร หรือ คอมแพสชั่น ประเทศไทย
                    </span>
                  </Link>{' '}
                  เป็นองค์กรคริสเตียนไม่แสวงหากำไร
                  ดำเนินงานในประเทศไทยมาเป็นเวลา กว่า 50 ปี
                  จดทะเบียนเป็นมูลนิธิเมื่อวันที่ 9 พฤศจิกายน พ.ศ.2544
                  ใช้ชื่อว่า “มูลนิธิดรุณาทร” และ ได้รับการรับรองเป็นองค์กร
                  สาธารณประโยชน์เมื่อวันที่ 3 มกราคม พ.ศ.2557
                  ทำงานด้านการพัฒนาเด็กและเยาวชนแบบองค์รวม
                  ร่วมกับคริสตจักรท้องถิ่น และเครือข่ายการทำงานจากภาคส่วนต่าง ๆ
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel3-content`}
                id={`panel3-header`}
              >
                <Typography variant='h5' color='primary'>
                  "น้ำใจ" ช่วยเด็กเหลือและเยาวชนอย่างไร?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  "น้ำใจ"
                  เป็นตัวกลางที่ช่วยระดมทุนเพื่อสนับสนุนโครงการที่มีเป้าหมายในการพัฒนาเด็กและเยาวชน
                  ให้ได้รับโอกาสที่ดีและมีช่วงเวลาที่ดีในชีวิต
                  การร่วมมือกับมูลนิธิดรุณาทรช่วยให้มีการสนับสนุนและความช่วยเหลือที่จำเป็นสำหรับชีวิตของเด็กและเยาวชน
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel4-content`}
                id={`panel4-header`}
              >
                <Typography variant='h5' color='primary'>
                  ทำไมต้องเลือก "น้ำใจ"?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  เพราะเราเชื่อว่าการพัฒนาเด็กและเยาวชนเป็นเรื่องของทุกคน
                  ทุกคนสามารถมีส่วนร่วมในการพัฒนาเด็กและเยาวชนได้ โดยการมอบ
                  "น้ำใจ" คุณจะได้รับการรับรองจากมูลนิธิดรุณาทร
                  ซึ่งเป็นองค์กรสาธารณประโยชน์
                  และมีการดูแลจากผู้เชี่ยวชาญทางด้านการพัฒนาเด็กและเยาวชน
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel5-content`}
                id={`panel5-header`}
              >
                <Typography variant='h5' color='primary'>
                  ฉันสามารถร่วมทุนกับ "น้ำใจ" ได้อย่างไร?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  คุณสามารถร่วมทุน "น้ำใจ" ได้โดยเข้าไปที่เว็บไซต์ของเรา
                  ลงชื่อเข้าใช้หรือเข้าสู่ระบบ เลือกโครงการที่คุณสนใจ
                  บริจาคโดยชำระเงินผ่าน QR Code พร้อมเพย์
                  พร้อมทั้งอัปโหลดหลักฐานการชำระเงิน
                  หลังจากนั้นคุณจะได้รับอีเมล์ยืนยันการบริจาค
                  และคุณสามารถติดตามสถานะของโครงการได้ที่หน้ารายละเอียดของโครงการ
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel6-content`}
                id={`panel6-header`}
              >
                <Typography variant='h5' color='primary'>
                  ฉันสามารถติดตามสถานะของโครงการที่ฉันบริจาคได้ที่ไหน?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  คุณสามารถดูรายละเอียดและผลการดำเนินงานของโครงการที่คุณบริจาคได้ที่หน้าของโครงการ
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel7-content`}
                id={`panel7-header`}
              >
                <Typography variant='h5' color='primary'>
                  ฉันสามารถสร้างโครงการของฉันเองได้หรือไม่?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  เนื่องจากน้ำใจดำเนินงานพัฒนาเด็กแบบองค์รวมผ่านคริสตจักรท้องถิ่นในพื้นที่
                  การเสนอโครงการระดมทุนต้องผ่านคริสตจักรท้องถิ่นที่อยู่ใกล้พื้นที่นั้น
                  ถ้าหากคุณเป็นสมาชิกของคริสตจักรท้องถิ่น
                  คุณสามารถลงทะเบียนสร้างโปรไฟล์คริสตจักรในระบบและเสนอโครงการได้
                  แต่ถ้าหากคุณไม่ได้เป็นสมาชิก
                  คุณสามารถค้นหาคริสตจักรท้องถิ่นที่อยู่ใกล้พื้นที่ของคุณและติดต่อเพื่อร่วมกันเสนอโครงการได้
                  คุณสามารถเสนอโครงการหรือลงทะเบียนคริสตจักรได้{' '}
                  <Link
                    to={ROUTE.WANT_TO_SUBMIT_CAMPAIGN}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='text-[#005EB8] underline'>ที่นี่</span>
                  </Link>{' '}
                  โดยไม่เสียค่าใช้จ่าย
                </Typography>
                <Typography variant='h6' align='center'>
                  ผู้ที่ต้องการสร้างโครงการต้องเตรียมอะไรบ้าง
                </Typography>
                <Typography component='div' align='center'>
                  <ul
                    style={{
                      listStyleType: 'disc',
                      paddingLeft: '20px',
                      textAlign: 'left',
                      display: 'inline-block',
                    }}
                  >
                    <li>ชื่อโครงการ</li>
                    <li>วันที่เริ่มต้น - วันสิ้นสุดโครงการ</li>
                    <li>โครงการที่เสนอนี้เป็นการพัฒนาเด็กในด้านใด</li>
                    <li>
                      เป้าหมายการระดมทุนเป็นจำนวนเท่าใด
                      เริ่มและสิ้นสุดการระดมทุนเมื่อใด
                    </li>
                    <li>ใครเป็นผู้จัดการโครงการ: ชื่อคริสตจักร</li>
                    <li>ใครเป็นผู้ประสานงานโครงการ: ชื่อเจ้าหน้าที่</li>
                    <li>ความเป็นมาของโครงการ และจุดประสงค์</li>
                    <li>ขั้นตอนการทำงาน</li>
                    <li>สถานที่ดำเนินการ</li>
                    <li>กลุ่มเป้าหมาย ระบุจำนวนคน และแยกเป็นหญิงชาย</li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel8-content`}
                id={`panel8-header`}
              >
                <Typography variant='h5' color='primary'>
                  คริสตจักรสามารถร่วมกันเสนอโครงการได้อย่างไร?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  คริสตจักรสามารถร่วมกันเสนอโครงการได้โดยผู้เสนอโครงการสามารถเพิ่มคริสตจักรอื่น
                  ๆ ในหน้าการเสนอโครงการได้เลย
                  ซึ่งคริสตจักรที่เพิ่มเข้ามาจะต้องรับคำเชิญเข้าร่วมโครงการก่อนจึงจะดำเนินการต่อได้
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ border: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel9-content`}
                id={`panel9-header`}
              >
                <Typography variant='h5' color='primary'>
                  ฉันสามารถติดตามสถานะของโครงการที่เสนอหรือสถานะการลงทะเบียนคริสตจักรได้ที่ไหน?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>
                  คุณจะได้รับแจ้งเตือนผ่านทางอีเมลเมื่อมีการเปลี่ยนแปลงสถานะของโครงการ
                  หรือสถานะการลงทะเบียนคริสตจักร
                  คุณอาจจะต้องตรวจสอบในกล่องข้อความของอีเมลของคุณ
                  และดำเนินการตามขั้นตอนที่แจ้งในอีเมล
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Container>
        <Footer />
      </div>
    </>
  )
}
