import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Snackbar,
  SnackbarOrigin,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder'
import DeleteIcon from '@mui/icons-material/Delete'
import { grey } from '@mui/material/colors'
import ProgressBar from '../ProgressBar'
import StatusBar from './StatusBar'

import { ReactComponent as Fund } from '../../assets/icons/Fundforadmin.svg'
import { ReactComponent as Delete } from '../../assets/icons/Admin/delete.svg'
import { ReactSVG } from 'react-svg'
import { generateViewChurchAdminRoute } from '../../utils/routeGenerator'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import AdminDeleteModal from './modal/AdminDeleteModal'
import AdminNormalModal from './modal/AdminNormalModal'
import { useDelete } from '../../hooks/useFetch'
import AuthService from '../../services/auth.service'
import axios from 'axios'

type ChurchListProps = {
  churchId: string
  churchName: string
  churchStatus: string
  reload: () => void
}

export default function ChurchListProps(props: ChurchListProps) {
  const { churchId, churchName, churchStatus, reload } = props
  const navigate = useNavigate()
  const token = AuthService.getTokenUser()
  const API_KEY = process.env.REACT_APP_XKEY_API || ''
  const baseURL = process.env.REACT_APP_BASE_API || ''
  const delteChurchURL = baseURL + '/churches/' + churchId + '/delete'

  const path = generateViewChurchAdminRoute(churchId)

  const StyledTableCell = styled(TableCell)({
    padding: '0px',
    paddingLeft: '32px',
    paddingRight: '8px',
  })

  const [openDeleteChurchModal, setOpenDeleteChurchModal] =
    useState<boolean>(false)

  async function handleClickConfirmDeleteChurch() {
    try {
      const response = await axios.delete(delteChurchURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-API-KEY': API_KEY,
        },
      })
      console.log('delete')
      setOpenDeleteChurchModal(false)
      setOpenDeleteChurchSuccessModal(true)
    } catch {
      handleSnackbarOpen()
      setOpenDeleteChurchModal(false)
      console.log('error')
    }
  }

  const [openDeleteChurchSuccessModal, setOpenDeleteChurchSuccessModal] =
    useState<boolean>(false)
  function handleClickDeleteChurchSuccessModal() {
    setOpenDeleteChurchSuccessModal(false)
    reload()
  }

  //error snackbar
  const [open, setOpen] = useState(false)

  const handleSnackbarOpen = () => {
    setOpen(true)
  }

  const handleSnackbarClose = () => {
    setOpen(false)
  }

  return (
    <TableRow key={props.churchId}>
      <TableCell sx={{ flexWrap: 'wrap' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'Column',
            flexWrap: 'wrap',
            alignItems: 'left',
            width: '80vm',
          }}
        >
          <Typography
            variant='subtitle1'
            color={'text.primary'}
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(path)}
          >
            {churchName}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            gap: '16px',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <StatusBar variant={churchStatus}></StatusBar>
        </Box>
      </TableCell>

      <StyledTableCell width={'40px'}>
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'Row',
            alignItems: 'center',
            justifyContent: 'right',
            minHeight: 0,
            minWidth: 0,
            padding: 0,
          }}
          disabled={churchStatus == 'INACTIVE' ? true : false}
          onClick={() => setOpenDeleteChurchModal(true)}
        >
          <DeleteIcon
            style={{
              color: churchStatus == 'ACTIVE' ? '#FF0038' : '#808080',
              fontSize: '36px',
            }}
          />
        </Button>
      </StyledTableCell>

      <AdminDeleteModal
        id={'admin-delete-church'}
        type={'ERROR'}
        confirmText={churchName}
        title={'Delete church'}
        description={
          'Are you sure you want to delete this campaign? This action cannot be reversed.'
        }
        confirmBtnText={'I understand and confirm'}
        cancleBtnText={'Cancle'}
        open={openDeleteChurchModal}
        onClose={() => setOpenDeleteChurchModal(false)}
        handleClickConfirm={handleClickConfirmDeleteChurch}
      />

      <AdminNormalModal
        id='admin-delete-church-success'
        type='ERROR'
        title={churchName + ' has been removed.'}
        open={openDeleteChurchSuccessModal}
        onClose={handleClickDeleteChurchSuccessModal}
      />

      <Snackbar
        autoHideDuration={5000}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={open}
        onClose={handleSnackbarClose}
        message={
          <Typography variant='body2'>
            An error occurred please try again later
          </Typography>
        }
        key={'error'}
        ContentProps={{
          sx: {
            background: '#D32F2F',
          },
        }}
      />
    </TableRow>
  )
}
