export const getAreaHolisticTranslation = (area: string) => {
  switch (area) {
    case 'EDUCATION':
      return 'การศึกษา'
    case 'CHILD_SAFEGUARDING':
      return 'การคุ้มครองเด็ก'
    case 'INFRASTRUCTURE':
      return 'สาธารณูปโภค'
    case 'YOUTH_LEADERSHIP':
      return 'เยาวชนผู้นำ'
    case 'WELL_BEING':
      return 'สุขภาวะ'
    default:
      return area
  }
}
