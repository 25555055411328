import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { common, grey } from '@mui/material/colors'
import { useContext, useEffect, useMemo, useState } from 'react'
import { CloseRounded, FileUpload } from '@mui/icons-material'
import { ModalType } from '../../../constants/modalType'
import UploadCoverImage from '../UploadCoverImage'
import {
  CreateCampaignForm,
  CreateCampaignFormWithFile,
} from '../../../models/admin/AdminCreateCampaignForm'
import { useGet } from '../../../hooks/useFetch'

interface ModalProps {
  id: string
  type: ModalType
  open: boolean
  requestId: string
  token: string
  loading: boolean
  OnClickConfirmSave: (data: CreateCampaignFormWithFile) => void
  onClose: () => void
}

const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
}

export default function AdminCreateCampaignModal(props: ModalProps) {
  const {
    id,
    type,
    open,
    requestId,
    token,
    loading,
    OnClickConfirmSave,
    onClose,
  } = props

  const [description, setDescription] = useState('')
  const [qoute, setQuote] = useState('')
  const [imgURL, setImgURL] = useState('')
  const [haveImg, setHaveImg] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>()

  const [infoData, infoDataLoading, infoDataReq, infoDataStatusCode] =
    useGet<CreateCampaignForm>({
      url: `/campaign-requests/${requestId}/admin-filled-info`,
      jwtToken: token ?? '',
      autoFetch: true,
    })

  useEffect(() => {
    if (open) {
      infoDataReq()
    }
  }, [])

  useEffect(() => {
    if (infoDataStatusCode === -1) return

    if (infoDataStatusCode === 200) {
      setDescription(infoData?.description!)
      setQuote(infoData?.quote!)
      setImgURL(infoData?.coverImageUrl!)
    }
  }, [infoDataStatusCode])

  const color: any = useMemo(() => {
    if (type === 'DEFAULT') {
      return 'primary'
    } else if (type === 'ERROR') {
      return 'error'
    } else if (type === 'SUCCESS') {
      return 'secondary'
    }
  }, [type])

  const handleFile = ({
    files,
    haveFile,
  }: {
    files: File | null
    haveFile: boolean
  }) => {
    setHaveImg(haveFile)
    setSelectedFile(files)
  }

  return (
    <Backdrop
      sx={{ color: common.black, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Modal
        open={open}
        onClose={() => {
          setDescription(infoData?.description ? infoData?.description! : '')
          setQuote(infoData?.quote ? infoData?.quote! : '')
          setSelectedFile(null)
          onClose()
        }}
      >
        {infoDataLoading || loading ? (
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            alignItems={'center'}
            flexShrink={0}
            sx={ModalStyle}
            gap={'8px'}
            width={{ xs: '80vw', md: '640px' }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            alignItems={'center'}
            flexShrink={0}
            sx={ModalStyle}
            gap={'8px'}
            width={{ xs: '80vw', md: '640px' }}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'flex-start'}
              alignSelf={'stretch'}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
              >
                <Typography id='modal-modal-title' variant='h4' color={color}>
                  Create new campaign
                </Typography>
                <Typography
                  id='modal-modal-description'
                  variant='subtitle1'
                  color={grey[600]}
                >
                  Please fill in the following information to create a campaign
                </Typography>
              </Box>
              <Button // close button
                onClick={() => {
                  setDescription(
                    infoData?.description ? infoData?.description! : ''
                  )
                  setQuote(infoData?.quote ? infoData?.quote! : '')
                  setSelectedFile(null)
                  onClose()
                }}
                color='inherit'
                size='small'
                sx={{
                  minWidth: '16px',
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  p: '16px',
                }}
              >
                <CloseRounded sx={{ color: grey[400] }} fontSize='small' />
              </Button>
            </Box>

            <Stack width={'100%'} gap={'10px'}>
              <Divider />
              <Typography variant='h6'>Campaign description</Typography>
              <textarea
                value={description}
                rows={0}
                maxLength={50000}
                onChange={(e) => setDescription(e.target.value)}
              />

              <Typography variant='subtitle1' color='primary'>
                *ต้องมีข้อมูล
              </Typography>
            </Stack>

            <Stack width={'100%'} gap={'10px'}>
              <Divider />
              <Typography variant='h6'>Quote</Typography>
              <textarea
                value={qoute}
                rows={0}
                maxLength={50000}
                onChange={(e) => setQuote(e.target.value)}
              />

              <Typography variant='subtitle1' color='primary'>
                *ต้องมีข้อมูล
              </Typography>
            </Stack>

            <Stack width={'100%'} gap={'10px'}>
              <Divider />
              <Typography variant='h6'>Cover Image</Typography>
              <UploadCoverImage onChange={handleFile} imgURL={imgURL} />
              <Typography variant='subtitle1' color='primary'>
                *ต้องมีข้อมูล
              </Typography>
            </Stack>

            <Stack
              display={'flex'}
              justifyContent={'end'}
              alignItems={'end'}
              width={'100%'}
              flexDirection={'row'}
              gap={'10px'}
            >
              <Button
                variant='outlined'
                color='error'
                onClick={() => {
                  setDescription(
                    infoData?.description ? infoData?.description! : ''
                  )
                  setQuote(infoData?.quote ? infoData?.quote! : '')
                  setSelectedFile(null)
                  onClose()
                }}
                sx={{ minWidth: '7vw', padding: '4px' }}
              >
                <Typography variant='subtitle1'>Cancel</Typography>
              </Button>
              <Button
                variant='outlined'
                color='primary'
                disabled={description === '' || qoute === '' || !haveImg}
                sx={{ minWidth: '7vw', padding: '4px' }}
                onClick={() => {
                  OnClickConfirmSave({
                    type: 'SAVE',
                    description: description,
                    quote: qoute,
                    oldImgURL: imgURL,
                    imagefile: selectedFile,
                  })
                }}
              >
                <Typography variant='subtitle1'>Save</Typography>
              </Button>
              <Button
                variant='outlined'
                color='primary'
                disabled={description === '' || qoute === '' || !haveImg}
                sx={{ minWidth: '7vw', padding: '4px' }}
                onClick={() => {
                  OnClickConfirmSave({
                    type: 'LAUNCH',
                    description: description,
                    quote: qoute,
                    oldImgURL: imgURL,
                    imagefile: selectedFile,
                  })
                }}
              >
                <Typography variant='subtitle1'>launch to Namjai</Typography>
              </Button>
            </Stack>
          </Box>
        )}
      </Modal>
    </Backdrop>
  )
}
