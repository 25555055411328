import {
  Box,
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material'
import { theme } from '../../constants/theme'
import RequestList from '../../components/Admin/RequestList'
import { useEffect, useState } from 'react'
import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { Role, UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import { Navigate } from 'react-router-dom'
import { useGet } from '../../hooks/useFetch'
import { CampaignRequest } from '../../models/admin/CampaignRequest'
import AuthService from '../../services/auth.service'
import { useAuthMiddleware } from '../../services/AuthMiddleware'

export default function CampaignRequestList() {
  const [page, setPage] = useState(1)
  const mobile = useMediaQuery('(min-width:600px)')

  const token = AuthService.getTokenUser()
  const tokenChecked = useAuthMiddleware()
  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()

  const [requestList, loading] = useGet<CampaignRequest>({
    url: '/campaign-requests/list',
    ...(token && { jwtToken: token }),
    autoFetch: true,
    queryParams: {
      page: page,
    },
  })

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
  })
  const StyledTableHeadCell2 = styled(TableCell)({
    paddingLeft: '0px',
    paddingBottom: '8px',
    paddingTop: '8px',
  })

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <ThemeProvider theme={theme}>
          {userRole === 'ADMIN' || userRole === 'SUPER_ADMIN' ? (
            <div>
              <ResponsiveAppBar></ResponsiveAppBar>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingBottom: '16px',
                }}
              >
                <Typography variant='h3' paddingY={3}>
                  Campaign Requests
                </Typography>
                <Box
                  display={'flex'}
                  padding={'8px'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  textAlign={'center'}
                  marginBottom={'12px'}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: theme.palette.grey[200],
                    backgroundColor: 'white',
                    width: '90%',
                  }}
                >
                  <TableContainer>
                    <Table>
                      {mobile && (
                        <TableHead>
                          <TableRow key={'head'}>
                            <StyledTableHeadCell width={600}>
                              <Typography variant='h6' color={'text.primary'}>
                                Campaign name
                              </Typography>
                            </StyledTableHeadCell>
                            <StyledTableHeadCell2 align='left' width={200}>
                              <Typography variant='h6' color={'text.primary'}>
                                Holder Church
                              </Typography>
                            </StyledTableHeadCell2>
                            <StyledTableHeadCell align='right'>
                              <Typography variant='h6' color={'text.primary'}>
                                Version
                              </Typography>
                            </StyledTableHeadCell>
                            <StyledTableHeadCell align='right'>
                              <Typography variant='h6' color={'text.primary'}>
                                Type
                              </Typography>
                            </StyledTableHeadCell>
                            <StyledTableHeadCell align='right'>
                              <Typography variant='h6' color={'text.primary'}>
                                Status
                              </Typography>
                            </StyledTableHeadCell>
                          </TableRow>
                        </TableHead>
                      )}
                      {!mobile && (
                        <TableHead>
                          <TableRow>
                            <StyledTableHeadCell>
                              <Typography variant='h6' color={'text.primary'}>
                                Request detail
                              </Typography>
                            </StyledTableHeadCell>
                          </TableRow>
                        </TableHead>
                      )}
                      {!loading && (
                        <TableBody>
                          {requestList?.campaignRequestPage.map((req) => (
                            <RequestList
                              variant={'campaign'}
                              requestId={req.id}
                              name={req.name}
                              holder={req.holderChurches}
                              type={req.campaignRequestType}
                              status={req.campaignRequestStatus}
                              date={req.lastModifiedDate}
                              version={req.version}
                            ></RequestList>
                          ))}
                        </TableBody>
                      )}
                      {!loading && !requestList && (
                        <TableBody>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant='h6' color={'text.primary'}>
                              There are no campaign requests
                            </Typography>
                          </Box>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>

                  {loading && (
                    <Box paddingY={2}>
                      <CircularProgress
                        color='primary'
                        disableShrink
                        size={60}
                        sx={{ margin: 'auto' }}
                      />
                    </Box>
                  )}
                  <Pagination
                    count={requestList?.totalPages}
                    page={page}
                    onChange={handleChange}
                    sx={{ paddingTop: '5px' }}
                  />
                </Box>
              </Box>
            </div>
          ) : (
            <Navigate to='/' />
          )}
        </ThemeProvider>
      )
    }
  }
}
