import { Box, Typography } from '@mui/material'
import React from 'react'
import { COLOR } from '../../constants/color-constant'
import { DonorType } from '../../models/campaign'

export default function DonorTypeBox(props: {
  donorType: DonorType
  selectedDonorType: DonorType
}) {
  const { donorType, selectedDonorType } = props
  return (
    <Box
      display={'flex'}
      width={'200px'}
      padding={'12px'}
      borderRadius={'14px'}
      border={'1px solid #C1C1C1'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background: selectedDonorType === donorType ? COLOR.PRIMARY_2 : 'white',
        cursor: 'pointer',
      }}
      boxShadow={
        selectedDonorType === donorType
          ? '0px 4px 10px 5px rgba(0, 0, 0, 0.10)'
          : ''
      }
    >
      <Typography
        variant='h4'
        color={selectedDonorType === donorType ? 'white' : 'gray'}
      >
        {donorType === 'PERSONAL' ? 'ส่วนบุคคล' : 'นิติบุคคล'}
      </Typography>
    </Box>
  )
}
