import React from 'react'
// import { image, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel'
import '../css/slide.css'
import banner1 from '../assets/images/banner1.webp'
import banner2 from '../assets/images/banner2.webp'
import banner3 from '../assets/images/banner3.webp'
import banner4 from '../assets/images/banner4.webp'
import { Box } from '@mui/material'

const Slide = () => {
  return (
    <div>
      <Carousel
        autoPlay
        interval={5000}
        infiniteLoop
        showThumbs={false}
        swipeable={false}
        verticalSwipe={'natural'}
      >
        <div>
          <Box
            component='img'
            loading='lazy'
            draggable={false}
            alt='1'
            className='object-cover'
            sx={{
              height: { xs: '50vh', md: '50vh' },
              maxWidth: '100%',
            }}
            src={banner1}
          />
          <div className='overlay-text'>
            <div className='text'>
              <div className='text-head'>น้ำใจ แพลตฟอร์ม</div>
              <div className='text-body'>
                แพลตฟอร์มเพื่อการระดมทุน ส่งเสริมสังคมที่มีน้ำใจ
                เชื่อมต่อผู้มีน้ำใจ สู่ ผู้ต้องการน้ำใจ
              </div>
            </div>
            <div className='text-bt'>
              <a
                href={'/about'}
                target='_blank'
                rel='noreferrer'
                className='legend'
              >
                รู้จักน้ำใจ
              </a>
            </div>
          </div>
        </div>
        <div>
          <Box
            component='img'
            loading='lazy'
            draggable={false}
            alt='2'
            className='object-cover'
            sx={{
              height: { xs: '50vh', md: '50vh' },
              maxWidth: '100%',
            }}
            src={banner2}
          />
          <div className='overlay-text'>
            <div className='text'>
              <div className='text-head'>เด็กและเยาวชน</div>
              <div className='text-body'>
                คือ กลุ่มเป้าหมายหลัก ของน้ำใจ เพราะเด็กคือปัจจุบัน และอนาคต
              </div>
            </div>
            <div className='text-bt'>
              <a
                href={'https://www.compassionth.com'}
                target='_blank'
                rel='noreferrer'
                className='legend'
              >
                ข้อมูลเพิ่มเติม
              </a>
            </div>
          </div>
        </div>
        <div>
          <Box
            component='img'
            loading='lazy'
            draggable={false}
            alt='3'
            className='object-cover'
            sx={{
              height: { xs: '50vh', md: '50vh' },
              maxWidth: '100%',
            }}
            src={banner3}
          />
          <div className='overlay-text'>
            <div className='text'>
              <div className='text-head'>การพัฒนาเด็กและเยาวชนแบบองค์รวม </div>
              <div className='text-body'>
                ร่วมมือกัน เพื่อมอบโอกาสในการพัฒนาตนเองแก่เด็กและเยาวชน
                ทั้งในด้านการเรียนรู้ ด้านสุขภาพ ด้านสังคม-อารมณ์ ด้านจริยธรรม
              </div>
            </div>
            <div className='text-bt'>
              <a
                href={
                  'https://www.compassionth.com/about-us/the-holistic-child-development/'
                }
                target='_blank'
                rel='noreferrer'
                className='legend'
              >
                ข้อมูลเพิ่มเติม
              </a>
            </div>
          </div>
        </div>
        <div>
          <Box
            component='img'
            loading='lazy'
            draggable={false}
            alt='4'
            className='object-cover'
            sx={{
              height: { xs: '50vh', md: '50vh' },
              maxWidth: '100%',
            }}
            src={banner4}
          />
          <div className='overlay-text'>
            <div className='text'>
              <div className='text-head'>ช่วยเหลือ | ร่วมมือ | ความสุข</div>
              <div className='text-body'>
                โอกาสในการช่วยเหลือ เด็กและเยาวชน
                เพื่อส่งเสริมการพัฒนาในชีวิตของพวกเขา โอกาสที่จะร่วมมือ
                กันในสังคม โอกาสที่จะสร้างความสุข ทั้งผู้ให้น้ำใจ และผู้รับน้ำใจ
              </div>
            </div>
            <div className='text-bt'>
              <a
                href={'/campaigns'}
                target='_blank'
                rel='noreferrer'
                className='legend'
              >
                ดูแคมเปญ
              </a>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default Slide
