import {
  Box,
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  styled,
  Tabs,
  useMediaQuery,
  Tab,
  Card,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import { theme } from '../../constants/theme'

import { useEffect, useState } from 'react'
// import ResponsiveAppBar from '../../components/Admin/ResponsiveAppBar'
import { Role, UserData } from '../../models/UserTypes'
import { useUser } from '../../services/UserContext'
import { Navigate, useParams } from 'react-router-dom'
import { useDelete, useGet } from '../../hooks/useFetch'
import {
  ChurchCampaignRequests,
  ChurchCampaignFundDone,
} from '../../models/church/ChurchCampaignList'
import AuthService from '../../services/auth.service'
import { useAuthMiddleware } from '../../services/AuthMiddleware'
import { COLOR } from '../../constants/color-constant'
import ChurchCampaignRow from '../../components/Church/ChurchCampaignRow'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'
import ModalWithButton from '../../components/Modal/ModalWithButton'
import { ToastContainer, toast } from 'react-toastify'
interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
export default function ChurchCampaignManagement() {
  const [requestpage, setRequestPage] = useState(1)
  const [campaignpage, setCampaignPage] = useState(1)
  const isLargeScreen = useMediaQuery('(min-width:600px)')

  const token = AuthService.getTokenUser()
  const tokenChecked = useAuthMiddleware()
  const userData: UserData | null = useUser().userData
  const [userRole, setUserRole] = useState<Role>()

  const params = useParams()
  const churchID = params.churchId
  const [tabPage, setTabPage] = useState('0')
  const handleTabPageChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabPage(newValue)
  }
  const [openDeleteRequestModal, setOpenDeleteRequestModal] =
    useState<boolean>(false)
  const [deleteRequestId, setDeleteRequestId] = useState<String>('')
  const [statusSelec, setStatusSelec] = useState('FUNDING')
  const [requestList, requestLoading, getCampaignRequestTrigger] =
    useGet<ChurchCampaignRequests>({
      url: `/churches/${churchID}/campaign-requests`,
      ...(token && { jwtToken: token }),
      autoFetch: true,
      queryParams: {
        page: requestpage,
      },
    })

  const [campaignList, campaignLoading] = useGet<ChurchCampaignFundDone>({
    url: `/churches/${churchID}/campaigns`,
    ...(token && { jwtToken: token }),
    autoFetch: true,
    queryParams: {
      page: campaignpage,
      status: statusSelec,
    },
  })

  const [
    delCampaignRequest,
    delCampaignRequestLoading,
    delCampaignRequestTrigger,
    delCampaignRequestStatusCode,
  ] = useDelete({
    url: `/campaign-requests/${deleteRequestId}/delete`,
    ...(token && { jwtToken: token }),
    autoFetch: false,
  })

  useEffect(() => {
    if (userData) {
      setUserRole(userData.userrole.userRole)
    }
  }, [userData])

  const StyledTableHeadCell = styled(TableCell)({
    paddingBottom: '8px',
    paddingTop: '8px',
  })
  const StyledTableHeadCell2 = styled(TableCell)({
    paddingLeft: '0px',
    paddingBottom: '8px',
    paddingTop: '8px',
  })
  useEffect(() => {
    if (delCampaignRequestStatusCode === -1) return

    if (delCampaignRequestStatusCode === 200) {
      toast.error('Request Campaign are delete.')
      getCampaignRequestTrigger()
    } else {
      const statusCode = delCampaignRequestStatusCode
      toast.error(
        `ไม่สามารถส่งคำขอได้ กรุณาลองอีกครั้งในภายหลัง: ERROR(${statusCode})`
      )
    }
  }, [delCampaignRequestStatusCode])

  const handleRequestChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setRequestPage(value)
  }
  const handleCamListChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCampaignPage(value)
  }
  const StatusSelect = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          paddingX: '24px',
        }}
      >
        <FormControl
          sx={{
            minWidth: 150,
            '&:focus-within': {
              boxShadow: '0 0 3px 3px rgba(0, 0, 0, 0.1)',
            },
          }}
          variant='standard'
        >
          <Select
            disableUnderline
            variant='standard'
            value={statusSelec}
            onChange={(event) => setStatusSelec(event.target.value)}
            autoWidth
            renderValue={(selected) => (
              <Typography variant='subtitle1' color={COLOR.PRIMARY_1}>
                {selected}
              </Typography>
            )}
          >
            <MenuItem value={'FUNDING'}>FUNDING</MenuItem>
            <MenuItem value={'DONE'}>DONE</MenuItem>
          </Select>
        </FormControl>
      </Box>
    )
  }
  if (!tokenChecked) {
    return null // Don't render until Middleware check is complete
  } else {
    if (!userRole) {
      return null
    } else {
      return (
        <>
          <Box
            className='flex min-h-screen flex-col'
            sx={{ backgroundColor: COLOR.BACKGROUD_MAIN }}
          >
            <Navbar />
            <Container>
              <ToastContainer />
              <ModalWithButton
                id='church-mgmt-delete-church'
                type='ERROR'
                title={'แจ้งเตือนการลบข้อเสนอโครงการ'}
                description={
                  'แน่ใจหรือไม่ที่จะลบข้อเสนอโครงการนี้? การกระทำนี้ไม่สามารถย้อนกลับได้'
                }
                confirmBtnText={'ฉันเข้าใจและยืนยัน'}
                cancleBtnText={'ยกเลิก'}
                open={openDeleteRequestModal}
                onClose={() => setOpenDeleteRequestModal(false)}
                handleClickConfirm={async () => {
                  try {
                    delCampaignRequestTrigger()
                    await new Promise((resolve) => setTimeout(resolve, 1000))
                    setOpenDeleteRequestModal(false)
                  } catch (error) {
                    const resMessage =
                      (error instanceof Error && error.message) ||
                      'An error occurred while delete'

                    toast.error('delete failed: ' + resMessage)
                  }
                }}
              />
              <ThemeProvider theme={theme}>
                {userRole === 'CHURCH' && (
                  <div>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '16px',
                      }}
                    >
                      <Typography variant='h3' paddingY={3}>
                        โครงการทั้งหมด
                      </Typography>
                      <Box sx={{ width: '100%' }}>
                        <TabContext value={tabPage}>
                          <Box>
                            <Tabs
                              textColor='primary'
                              value={tabPage}
                              onChange={handleTabPageChange}
                              variant='scrollable'
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                alignSelf: 'stretch',
                              }}
                            >
                              <Tab label='โครงการที่กำลังเสนอ' value={'0'} />
                              <Tab
                                label='โครงการที่กำลังดำเนินการ'
                                value={'1'}
                              />
                            </Tabs>

                            {tabPage == '1' ? (
                              <Box sx={{ marginTop: '20px' }}>
                                <StatusSelect />
                              </Box>
                            ) : (
                              <Box sx={{ marginTop: '53px' }}></Box>
                            )}
                          </Box>
                          <CustomTabPanel value={tabPage} index={'0'}>
                            <Box className='RequestList'>
                              {ChurchRequestList()}
                            </Box>
                          </CustomTabPanel>
                          <CustomTabPanel value={tabPage} index={'1'}>
                            <Box
                              className='CampaignList'
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                              }}
                            >
                              {ChurchCampaignList()}
                            </Box>
                          </CustomTabPanel>
                        </TabContext>
                      </Box>
                    </Box>
                  </div>
                )}
                {userRole !== 'CHURCH' && <Navigate to='/' />}
              </ThemeProvider>
            </Container>
          </Box>
          <Footer />
        </>
      )
    }
  }

  function ChurchRequestList() {
    return (
      <Box
        display={'flex'}
        padding={'8px'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        marginBottom={'12px'}
        sx={{
          borderRadius: '8px',
          border: '1px solid',
          borderColor: theme.palette.grey[200],
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        <TableContainer>
          <Table>
            {isLargeScreen && (
              <TableHead>
                <TableRow key={'head'}>
                  <StyledTableHeadCell width={600}>
                    <Typography variant='h6' color={'text.primary'}>
                      Request name
                    </Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell2 align='left' width={400}>
                    <Typography variant='h6' color={'text.primary'}>
                      Holder Church
                    </Typography>
                  </StyledTableHeadCell2>
                  <StyledTableHeadCell align='right'>
                    <Typography variant='h6' color={'text.primary'}>
                      Version
                    </Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align='left'>
                    <Typography variant='h6' color={'text.primary'}>
                      Status
                    </Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align='left'></StyledTableHeadCell>
                </TableRow>
              </TableHead>
            )}
            {!isLargeScreen && (
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell>
                    <Typography variant='h6' color={'text.primary'}>
                      Request detail
                    </Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
            )}
            {!requestLoading && (
              <TableBody>
                {requestList?.campaignRequests.map((req) => (
                  <ChurchCampaignRow
                    key={req.id}
                    variant={'proposed'}
                    userCanDelete={req.userCanDelete}
                    onDeleteRequestId_hook={setDeleteRequestId}
                    onDeleteClick_hook={setOpenDeleteRequestModal}
                    requestId={req.id}
                    name={req.name}
                    holder={req.holderChurches}
                    type={req.campaignRequestType}
                    status={req.campaignRequestStatus}
                    version={req.version}
                    currentDonationAmount={null}
                    targetBudget={null}
                  ></ChurchCampaignRow>
                ))}
              </TableBody>
            )}

            {!requestLoading &&
              requestList &&
              (!requestList.campaignRequests ||
                requestList.campaignRequests.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align='center'
                      sx={{
                        // width: '100%',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'Row',
                          flexWrap: 'no wrap',
                          alignItems: 'left',
                          gap: '8px',
                        }}
                      >
                        <Typography variant='h6' color={'text.primary'}>
                          There are no campaign requests
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              )}
          </Table>
        </TableContainer>

        {requestLoading && (
          <Box paddingY={2}>
            <CircularProgress
              color='primary'
              disableShrink
              size={60}
              sx={{ margin: 'auto' }}
            />
          </Box>
        )}
        <Pagination
          count={requestList?.totalPages}
          page={requestpage}
          onChange={handleRequestChange}
          sx={{ paddingTop: '5px' }}
        />
      </Box>
    )
  }

  function ChurchCampaignList() {
    return (
      <Box
        display={'flex'}
        padding={'8px'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        marginBottom={'12px'}
        sx={{
          borderRadius: '8px',
          border: '1px solid',
          borderColor: theme.palette.grey[200],
          backgroundColor: 'white',
          width: '100%',
        }}
      >
        <TableContainer>
          <Table>
            {isLargeScreen && (
              <TableHead>
                <TableRow key={'head'}>
                  <StyledTableHeadCell width={600}>
                    <Typography variant='h6' color={'text.primary'}>
                      Campaign name
                    </Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell2 align='left' width={200}>
                    <Typography variant='h6' color={'text.primary'}>
                      Holder Church
                    </Typography>
                  </StyledTableHeadCell2>

                  <StyledTableHeadCell align='left'>
                    <Typography variant='h6' color={'text.primary'}>
                      Status
                    </Typography>
                  </StyledTableHeadCell>
                  <StyledTableHeadCell align='left'>
                    <Typography variant='h6' color={'text.primary'}>
                      Funds
                    </Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
            )}
            {!isLargeScreen && (
              <TableHead>
                <TableRow>
                  <StyledTableHeadCell>
                    <Typography variant='h6' color={'text.primary'}>
                      Campaign detail
                    </Typography>
                  </StyledTableHeadCell>
                </TableRow>
              </TableHead>
            )}
            {!campaignLoading && (
              <TableBody>
                {campaignList?.campaigns.map((c) => (
                  <ChurchCampaignRow
                    key={c.id}
                    variant={'funding'}
                    userCanDelete={false}
                    onDeleteRequestId_hook={setDeleteRequestId}
                    onDeleteClick_hook={setOpenDeleteRequestModal}
                    requestId={c.id}
                    name={c.name}
                    holder={c.holderChurches}
                    type={c.status}
                    status={c.fundingStatus}
                    version={1}
                    currentDonationAmount={c.currentDonationAmount}
                    targetBudget={c.targetBudget}
                  ></ChurchCampaignRow>
                ))}
              </TableBody>
            )}
            {!campaignLoading &&
              campaignList &&
              (!campaignList.campaigns ||
                campaignList.campaigns.length === 0) && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align='center'
                      sx={{
                        // width: '100%',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Stack
                        sx={{
                          display: 'flex',
                          flexDirection: 'Row',
                          flexWrap: 'no wrap',
                          alignItems: 'left',
                          gap: '8px',
                        }}
                      >
                        <Typography variant='h6' color={'text.primary'}>
                          There are no campaigns
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              )}
          </Table>
        </TableContainer>

        {campaignLoading && (
          <Box paddingY={2}>
            <CircularProgress
              color='primary'
              disableShrink
              size={60}
              sx={{ margin: 'auto' }}
            />
          </Box>
        )}
        <Pagination
          count={campaignList?.totalPages}
          page={campaignpage}
          onChange={handleCamListChange}
          sx={{ paddingTop: '5px' }}
        />
      </Box>
    )
  }
}
