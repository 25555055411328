import {
  Autocomplete,
  Typography,
  Stack,
  Box,
  TextField,
  CircularProgress,
} from '@mui/material'
import { useEffect, useState } from 'react'

import { ChurchMemberCard } from '../components/ChurchMemberCard'
import { COLOR } from '../constants/color-constant'
import { churchMemberPosition } from '../constants/churchMemberType'
import { useGet } from '../hooks/useFetch'
import AuthService from '../services/auth.service'
import { useDebounce } from '../controller/useDebounce'
import { UserShortDetail } from '../models/UserTypes'
import React from 'react'

interface MemberProps {
  members: UserShortDetail[]
  showSideTitle?: boolean
  title?: string
  subtitle?: string
  position: churchMemberPosition
  isRequired?: boolean
  addMember: (newMember: UserShortDetail) => void
  deleteMember: (deletedMember: UserShortDetail) => void
  maxMembers: number
  excludedMembersID: string[]
  churchIds?: string[] // if not provided, will fetch all users
  customNoOptionsText?: string
  disabled?: boolean // disable the whole section
  adminView?: boolean
}

const MemberSection: React.FC<MemberProps> = ({
  members,
  showSideTitle: showTitle,
  title,
  subtitle,
  position,
  isRequired,
  addMember,
  deleteMember,
  maxMembers,
  excludedMembersID,
  churchIds,
  customNoOptionsText = 'ไม่พบผู้ใช้',
  disabled = false,
  adminView,
}) => {
  const [options, setOptions] = useState<UserShortDetail[]>([])
  const [open, setOpen] = React.useState(false)
  const [autocompleteInputVal, setAutocompleteInputVal] = useState('')
  const [autocompleteVal, setAutocompleteVal] =
    useState<UserShortDetail | null>(null)

  const [foundUsers, loading, fetchUsers, statusCode] = useGet<
    UserShortDetail[]
  >({
    url: churchIds ? '/users/short/search-by-churches' : '/users/short',
    autoFetch: false,
    queryParams: {
      query: autocompleteInputVal,
      churchIds,
    },
    jwtToken: AuthService.getTokenUser()!,
  })

  const debouncedFetchUsers = useDebounce(fetchUsers, 500) // refetch every 500ms

  // filter out excluded members
  useEffect(() => {
    if (statusCode === 200 && foundUsers) {
      const filteredUsers = foundUsers.filter(
        (user) => !excludedMembersID.includes(user.id)
      )
      setOptions(filteredUsers)
    }
  }, [foundUsers, excludedMembersID, statusCode])

  // clear options when churchIds change
  useEffect(() => {
    setOptions([])
  }, [churchIds])

  // fetch users when input change
  useEffect(() => {
    if (autocompleteInputVal.trim()) {
      debouncedFetchUsers()
    }
  }, [autocompleteInputVal])

  const handleAddMember = (newMember: UserShortDetail | null) => {
    setAutocompleteInputVal('')
    setAutocompleteVal(null)
    if (newMember) {
      addMember(newMember)
    }
  }

  const handleAutocompleteInputChange = (event: any, value: string) => {
    if (value.length === 0) {
      if (open) setOpen(false)
    } else {
      if (!open) setOpen(true)
    }
    setAutocompleteInputVal(value)
  }

  return (
    <Stack flexDirection='row' flexWrap='wrap' gap='20px'>
      {showTitle && (
        <Stack flexDirection='column' width='250px'>
          <span>
            {isRequired && (
              <Typography display='inline' variant='h5' color={COLOR.PRIMARY_1}>
                *{' '}
              </Typography>
            )}
            <Typography display='inline' variant='h5'>
              {title}
            </Typography>
          </span>
          <Typography variant='subtitle1' color={COLOR.GRAY_600}>
            {subtitle}
          </Typography>
        </Stack>
      )}
      <Stack maxWidth={'100%'} gap={'16px'}>
        <Autocomplete
          id={`${position}-selector`}
          noOptionsText={customNoOptionsText}
          open={open}
          onClose={() => setOpen(false)}
          size='small'
          disabled={members.length >= maxMembers || disabled || adminView}
          sx={{
            width: { xs: '250px', sm: '320px', md: '350px' },
            maxWidth: { sm: '100%' },
            '& fieldset': { borderRadius: '8px' },
          }}
          onChange={(event: any, newValue: UserShortDetail | null) =>
            handleAddMember(newValue)
          }
          value={autocompleteVal}
          inputValue={autocompleteInputVal}
          onInputChange={handleAutocompleteInputChange}
          options={loading ? [] : options}
          loading={loading}
          autoHighlight
          getOptionLabel={(option) =>
            `${option.firstName} ${option.lastName} ${option.email}`
          }
          renderOption={(props, option) => (
            <Box component='li' {...props}>
              {option.firstName} {option.lastName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={title}
              placeholder='กรุณากรอกชื่อหรืออีเมล'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color='primary' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Stack gap={'16px'}>
          <Stack
            display={'flex'}
            flexDirection={'row'}
            gap={'24px'}
            flexWrap='wrap'
          >
            {members.map((m, i) => (
              <ChurchMemberCard
                key={i}
                isMe={false}
                variant={
                  adminView ? 'ADMIN-VIEW' : disabled ? 'VIEW' : 'REMOVABLE'
                }
                position={position}
                firstName={m.firstName}
                lastName={m.lastName}
                handleClickDeleteMember={() => {
                  deleteMember(m)
                }}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default MemberSection
